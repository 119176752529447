import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Box,
  Flex,
  Text,
  Divider,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Image,
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@chakra-ui/react";

import "../../../styles/Calender.css";
import { useSelector } from "react-redux";

import buttonPullDown from "../../../images/icons/button_pulldown.svg";
import { DateContext } from "../../../lib/globalStates/DateState";

import menuItems from "../../../lib/sortOptionLabels";

type TeamActionInMemberInfoProps = {
  sortMember: (typeSort: string, typeLetter: string) => void;
};

interface RootState {
  team: {
    selectedTeam: TeamInfo;
    discoveryData: TeamInfoArray;
  };
}

const TeamActionInMemberInfo = React.memo(
  (props: TeamActionInMemberInfoProps) => {
    const { sortMember } = props;
    const [selectedItem, setSelectedItem] = useState(menuItems[0].jp);
    const [selectedSort, setSelectedSort] = useState("昇順");

    // // グローバルで状態管理する日付情報を扱うためのhooks
    const [dateState] = useContext(DateContext);

    const changeType = (item: string) => {
      setSelectedItem(item);
    };

    const changeLetter = (item: string) => {
      setSelectedSort(item);
    };

    useEffect(() => {
      sortMember(selectedItem, selectedSort);
    }, [selectedItem, selectedSort, sortMember]);

    const selectedTeam = useSelector(
      (state: RootState) => state.team.selectedTeam
    );

    useEffect(() => {
      setSelectedItem(menuItems[0].jp);
      setSelectedSort("昇順");
    }, [dateState, selectedTeam]);

    const isTextOverflowing = selectedTeam?.teamname?.length * 0.5 > 15;
    return (
      <>
        <Flex flexDirection="row" height={"3.125rem"} px="6" justify="space-between">
          {/* <Box display="flex" alignItems="center" flex={1.6 / 7.2}> */}
          <Box display="flex" alignItems="center">
            {isTextOverflowing ? (
              <Popover placement="top-start" trigger="hover" isLazy>
                <PopoverTrigger>
                  <Flex alignItems="center" cursor="pointer">
                    <Text className="team-name-title">
                      {selectedTeam?.teamname}
                    </Text>
                  </Flex>
                </PopoverTrigger>
                <PopoverContent
                  padding="0.5rem 0.5rem"
                  width="-webkit-fit-content"
                  maxWidth="35rem"
                  bg="#f2f2f2"
                  fontFamily="Source Han Sans"
                  boxShadow="1px 1px 3px #00000029"
                >
                  <Text fontSize="0.6875rem">{selectedTeam?.teamname}</Text>
                </PopoverContent>
              </Popover>
            ) : (
              <Text className="team-name-title">{selectedTeam?.teamname}</Text>
            )}
          </Box>

          {/* <Box display="flex" alignItems="center" flex={2.0 / 7.2}> */}
          <Box display="flex" alignItems="center">
            <Flex className="sort-by-info" alignItems="center">
              <Text className="sort-title">並び替え</Text>
              <Flex mx="3">
                <Menu>
                  <MenuButton
                    data-testid="first-sort-menu-button"
                    style={{ background: "#ffffff" }}
                    className="first-sort"
                    as={Button}
                    rightIcon={
                      <Image
                        src={buttonPullDown}
                        mb="0"
                        h="0.5rem"
                        w="0.79rem"
                      />
                    }
                  >
                    <Text data-testid="selected-item" className="selected_item">
                      {selectedItem}
                    </Text>
                  </MenuButton>
                  <MenuList
                    data-testid="first-sort-menu-list"
                    className="first-sort-list"
                  >
                    {menuItems.map((item) => (
                      <MenuItem
                        key={item.jp}
                        onClick={() => changeType(item.jp)}
                        background="#ffffff"
                        style={{ height: "2.5rem" }}
                        _hover={{ bg: "#ededed" }}
                        data-testid={`menu-item-${item.jp}`}
                      >
                        <Text className="first-sort-item">{item.jp}</Text>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
              <Menu>
                <MenuButton
                  data-testid="second-sort-menu-button"
                  style={{ background: "#ffffff" }}
                  className="second-sort"
                  as={Button}
                  rightIcon={
                    <Image src={buttonPullDown} mb="0" h="0.5rem" w="0.79rem" />
                  }
                >
                  <Text data-testid="selected-sort" className="selected-sort">
                    {selectedSort}
                  </Text>
                </MenuButton>
                <MenuList
                  data-testid="second-sort-menu-list"
                  className="second-sort-list"
                >
                  <MenuItem
                    background="#ffffff"
                    style={{ height: "2.5rem" }}
                    _hover={{ bg: "#ededed" }}
                    onClick={() => changeLetter("昇順")}
                    data-testid="menu-item-昇順"
                  >
                    <Text className="second-sort-item">昇順</Text>
                  </MenuItem>
                  <MenuItem
                    background="#ffffff"
                    style={{ height: "2.5rem" }}
                    _hover={{ bg: "#ededed" }}
                    onClick={() => changeLetter("降順")}
                    data-testid="menu-item-降順"
                  >
                    <Text className="second-sort-item">降順</Text>
                  </MenuItem>
                </MenuList>
              </Menu>
            </Flex>
          </Box>
          {/* <Box
            display="flex"
            alignItems="center"
            flex={1.1}
            className="filter-status-box"
          >
            <Button className="filter-status-button">
              <Text className="filter-status-text">ステータスの絞り込み</Text>
            </Button>
          </Box>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
            flex={2.5}
            className="box-time-format"
          >
            <Image
              src={buttonBack}
              style={{ opacity: "0.3" }}
              h="2.5rem"
              w="1.575rem"
            ></Image>
            <Text className="time-format">前の時間へ</Text>
            <Button className="time-format-24h">
              <Text className="time-format-text">24h</Text>
            </Button>

            <Button className="time-format-12h">
              <Text className="time-format-text">12h</Text>
            </Button>
            <Text className="time-format">次の時間へ</Text>
            <Image
              src={buttonNext}
              style={{ opacity: "0.3" }}
              h="2.5rem"
              w="1.575rem"
            ></Image>
          </Box> */}
        </Flex>
        <Flex justifyContent="center">
          <Divider
            color="#d6d6d6"
            opacity="1"
            style={{
              width: "95%",
            }}
          />
        </Flex>
      </>
    );
  }
);

TeamActionInMemberInfo.displayName = "TeamActionInMemberInfo";
export default TeamActionInMemberInfo;

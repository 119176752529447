import { radioAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(radioAnatomy.keys);

const custom = definePartsStyle({
  // define the part you're going to style
  control: {
    height: "24px",
    width: "24px",
    borderWidth: "1px",
    borderColor: "#707070",
    _checked: {
      borderColor: "#707070",
      color: "#707070",
      background: "#FFF",
      borderWidth: "1px",
      _hover: {
        background: "#FFF",
        borderWidth: "1px",
        borderColor: "#707070",
      },
      _focusVisible: {
        borderColor: "#707070",
        boxShadow: "unset",
      },
      _before: {
        w: "12px",
        h: "12px",
      },
    },
  },
  label: {
    ml: "10px",
    lineHeight: "20px",
    color: "#2C2C2C",
    fontSize: "14px",
    fontWeight: "bold",
  },
});

export const radioTheme = defineMultiStyleConfig({ variants: { custom } });

import { Flex, Text } from "@chakra-ui/react";
import { FC } from "react";
import { useRemoveApplication } from "../../../../lib/hooks/ManagementSettings/useRemoveApplication";
import { CommonModal } from "../../CommonModal";
import { ButtonPrimary } from "../Button/ButtonPrimary";

export type RemoveApplicationModalProps = {
  isOpen: boolean;
  onClose: () => void;
  onSubmit?: (cb?: () => void) => void;
};

export const RemoveApplicationModal: FC<RemoveApplicationModalProps> = (
  props
) => {
  const { isOpen, onClose } = props;
  const { state, handler } = useRemoveApplication(props);

  return (
    <CommonModal
      isOpen={isOpen}
      title={""}
      isShowCloseButton={false}
      onClose={() => {
        onClose?.();
      }}
      minW={500}
      isCentered
      overflowBody="hidden"
    >
      <Flex direction="column" align="center" gap="20px">
        <Text color="#2C2C2C" fontWeight="700" fontSize="16px">
          削除しますか？
        </Text>
        <ButtonPrimary
          width="200px"
          marginTop="20px"
          onClick={() => {
            handler?.onSubmit();
          }}
          background="#00BA0F"
          isDisabled={state?.loading}
          _disabled={{
            background: "#D6D6D6",
            cursor: "default",
            _hover: { background: "#D6D6D6" },
          }}
        >
          はい
        </ButtonPrimary>
        <ButtonPrimary
          width="200px"
          onClick={() => {
            onClose?.();
          }}
          background="#2C2C2C"
          _hover={{
            background: "#2C2C2C",
            opacity: 0.8,
          }}
          isDisabled={state?.loading}
          _disabled={{
            background: "#D6D6D6",
            cursor: "default",
            _hover: { background: "#D6D6D6" },
          }}
        >
          キャンセル
        </ButtonPrimary>
      </Flex>
    </CommonModal>
  );
};

import { FC, ReactNode } from "react";
import { MemberFrame } from "../../../../@types/Member";
import { useUpdateMember } from "../../../../lib/hooks/ManagementSettings/useUpdateMember";
import { CommonModal } from "../../CommonModal";
import { UserForm } from "../Form/UserForm";

type UpdateMemberModalProps = {
  member: MemberFrame | null;
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const UpdateMemberModal: FC<UpdateMemberModalProps> = (props) => {
  const { isOpen, title, member, onClose } = props;
  const { formState, handleSubmit, onSubmit, register, closeModal } =
    useUpdateMember({ member, onClose });

  return (
    <CommonModal
      isOpen={isOpen}
      title={title}
      onClose={closeModal}
      minW={970}
      isCentered
    >
      <UserForm
        userData={member}
        formState={formState}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
      />
    </CommonModal>
  );
};

import { checkboxAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(checkboxAnatomy.keys);

const custom = definePartsStyle({
  control: {
    h: "24px",
    w: "24px",
    borderRadius: "4px",
    border: "1px solid #707070",
    borderColor: "#707070",
    _checked: {
      background: "#FFF",
      borderColor: "#707070",
      _hover: {
        background: "#FFF",
        borderColor: "#707070",
      },
    },
  },
  icon: {
    color: "#707070",
    fontSize: "14px",
    w: "16px",
    h: "16px",
  },
  label: {
    fontSize: "14px",
    color: "#2C2C2C",
    fontWeight: "bold",
    ml: "10px",
  },
});

export const checkboxTheme = defineMultiStyleConfig({ variants: { custom } });

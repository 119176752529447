import React from "react";
import { Box, Flex, Text } from "@chakra-ui/react";

const MemberInformation = React.memo(() => {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const adcompanyid = params.get("adcompanyid");
  const aduserid = params.get("aduserid");
  const admemberid = params.get("admemberid");
  return (
    <Box>
      <Text fontSize='2xl'>Member Information</Text>
      <Flex>
        <Text fontSize='xl' fontWeight='bold'>
          adcompanyid:{" "}
        </Text>
        <Text fontSize='xl'>{adcompanyid}</Text>
      </Flex>
      <Flex>
        <Text fontSize='xl' fontWeight='bold'>
          aduserid:{" "}
        </Text>
        <Text fontSize='xl'>{aduserid}</Text>
      </Flex>
      <Flex>
        <Text fontSize='xl' fontWeight='bold'>
          admemberid:{" "}
        </Text>
        <Text fontSize='xl'>{admemberid}</Text>
      </Flex>
    </Box>
  );
});

MemberInformation.displayName = "MemberInformation";
export default MemberInformation;

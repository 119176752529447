import { useMemo } from "react";

export const useCheckSession = () => {
  const TAB_ID = useMemo(() => {
    return `${
      "id" + Math.random().toString(16).slice(2) + Date.now().toString()
    }`.toString();
  }, []);

  function getTabIds() {
    const tabIds = JSON.parse(localStorage.getItem("openTabs") || "[]");
    return tabIds;
  }

  function addTabId() {
    const tabIds = getTabIds();
    tabIds.push(TAB_ID);
    localStorage.setItem("openTabs", JSON.stringify(tabIds));
    sessionStorage.setItem("sessionActive", "true");
  }

  function removeTabId() {
    let tabIds = getTabIds();
    tabIds = tabIds.filter((id: string) => id?.toString() !== TAB_ID);
    localStorage.setItem("openTabs", JSON.stringify(tabIds));
  }
  window.addEventListener("beforeunload", () => {
    removeTabId();
  });

  return { addTabId };
};

import {
  defineStyle,
  defineStyleConfig
} from "@chakra-ui/react";

const baseStyle = defineStyle({
  minH: "21px",
});

export const skeletonTheme = defineStyleConfig({
  baseStyle,
});


import { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

type ScheduleDataGridNum = number | undefined;

type Values = {
  scheduleDataGridNum: ScheduleDataGridNum;
  isScheduleModalOpen: boolean;
};

type Actions = {
  setScheduleDataGridNum: SetStateActionDispatcher<ScheduleDataGridNum>;
  onScheduleModalClose: () => void;
  openScheduleModalControll: (showScheduleDataGridNum: number) => void;
};

type UseScheduleModal = [Values, Actions];

/**
 * @remarks
 * スケジュールを表示するためのモーダルの状態管理するためのhooks
 *
 * Shortest name:  {@link useScheduleModal}
 * Full name:      {@link useScheduleModal}
 *
 * @returns
 * [
 *  values: {
 *    scheduleDataGridNum: スケジュールを表示するためのモーダルに表示するデータのindex,
 *    isScheduleModalOpen: スケジュールを表示するためのモーダルが開いているかどうかのフラグ
 *  },
 *  actions: {
 *    setScheduleDataGridNum: スケジュールを表示するためのモーダルに表示するデータのindexを更新する,
 *    onScheduleModalClose: スケジュールを表示するためのモーダルを閉じる
 *    openScheduleModalControll: {@link openScheduleModalControll}
 *  }
 * ]
 *
 * @public
 */
const useScheduleModal = (): UseScheduleModal => {
  const [scheduleDataGridNum, setScheduleDataGridNum] =
    useState<ScheduleDataGridNum>(undefined);

  const {
    isOpen: isScheduleModalOpen,
    onOpen: onScheduleModalOpen,
    onClose: onScheduleModalClose,
  } = useDisclosure();

  /**
   * @remarks
   * スケジュールを表示するためのモーダルを開く
   *
   * @param showScheduleDataGridNum スケジュールを表示するためのモーダルに表示するデータのindex
   */
  const openScheduleModalControll = (showScheduleDataGridNum: number) => {
    setScheduleDataGridNum(showScheduleDataGridNum);
    onScheduleModalOpen();
    return;
  };

  const values: Values = {
    scheduleDataGridNum,
    isScheduleModalOpen,
  };

  const actions: Actions = {
    setScheduleDataGridNum,
    onScheduleModalClose,
    openScheduleModalControll,
  };

  return [values, actions];
};

export default useScheduleModal;

import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Button,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Grid,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import buttonPullDown from "../../../images/icons/button_pulldown.svg";
import { useDispatch, useSelector } from "react-redux";
import TeamMessage from "../../messages/Message";

import statusTitle from "../../../lib/statusTitle";

interface RootState {
  team: {
    selectedTeam: TeamInfo;
    selectedSubTeam: TeamInfo;
    discoveryData: TeamInfoArray;
  };
}

interface TeamInfoProps {
  isErrorAPI: boolean;
}

const TeamInfo: React.FC<TeamInfoProps> = React.memo(({ isErrorAPI }) => {
  const selectedTeam = useSelector(
    (state: RootState) => state.team.selectedTeam
  );
  const dispatch = useDispatch();
  const listTeam = useSelector((state: RootState) => state.team.discoveryData);
  // const [countChange, setCountChange] = useState<number>(0);
  const [selectedSubTeam, setSelectedSubTeam] = useState<TeamInfo | null>(null);

  /**
   * The function `timeStringToMinutes` converts a time string in the format "HH:MM" to the total
   * number of minutes.
   * @param {string} timeString - The `timeString` parameter is a string that represents a time in the
   * format "HH:MM", where HH represents the hours and MM represents the minutes.
   * @returns The function `timeStringToMinutes` returns the total number of minutes represented by the
   * `timeString` input.
   */
  const timeStringToMinutes = (timeString: string) => {
    try {
      if (timeString) {
        const [hours, minutes] = timeString.split(":").map(Number);
        return hours * 60 + minutes;
      }
      return 0;
    } catch (error) {
      console.log(error);
      return NaN;
    }
  };

  /**
   * The `compareTime` function compares two properties of an object and returns the difference between
   * them, accounting for different data types.
   * @param {string} property1 - A string representing the first property to compare. It is used to
   * access a specific value from the `selectedTeam.teamdata` object.
   * @param {string} property2 - property2 is a string representing a property name in the
   * `selectedTeam.teamdata` object.
   * @returns The function `compareTime` returns a string representing the difference between two
   * values. If both values are numbers, it returns the difference as a string with a "+" sign if the
   * result is positive. If both values are strings, it converts them to minutes using the
   * `timeStringToMinutes` function and returns the difference as a string with a "+" sign if the
   * result is positive.
   */
  const compareTime = (property1: string, property2: string) => {
    const value1: any =
      selectedSubTeam?.teamdata[0]?.[
        property1.trim() as keyof (typeof selectedSubTeam.teamdata)[0]
      ];
    const value2: any =
      selectedSubTeam?.teamdata[0]?.[
        property2.trim() as keyof (typeof selectedSubTeam.teamdata)[0]
      ];
    const typeOfValue = [typeof value1, typeof value2];
    if (typeOfValue.every((t) => t === "number")) {
      const result = value1 - value2;
      return result > 0 ? `+${result}` : result.toString();
    }
    if (typeOfValue.every((t) => t === "string")) {
      const result = timeStringToMinutes(value1) - timeStringToMinutes(value2);
      return result > 0 ? `+${result}` : result.toString();
    } else {
      return NaN;
    }
  };

  const handleTeamInfoClick = (team: TeamInfo) => {
    setSelectedSubTeam(team);
  };

  // Change left change all
  useEffect(() => {
    setSelectedSubTeam(selectedTeam);
  }, [dispatch, selectedTeam]);

  // Change left keep top
  // useEffect(() => {
  //   if (!countChange && selectedTeam) {
  //     setSelectedSubTeam(selectedTeam);
  //     // dispatch(selectSubTeam(selectedTeam));
  //     setCountChange(countChange + 1);
  //   }
  // }, [countChange, dispatch, selectedTeam]);

  const isTextOverflowing = (selectedSubTeam?.teamname?.length || 0) * 0.5 > 15;

  return (
    <>
      <Box>
        <Button className="tab-button-1" py="6px" mr="10px">
          PC操作時間
        </Button>
        {/* <Button className="tab-button-2" py="6px">
          マウス・キーボード操作量
        </Button> */}

        <Box className="overall-info">
          <Flex
            justify="space-between"
            borderBottom="1px #EDEDED solid "
            pb="1"
          >
            <Box className="overall-team-name" flex="9" mb="8px">
              {isTextOverflowing ? (
                <Tooltip
                  label={selectedSubTeam?.teamname}
                  placement="top-start"
                  bg="#f2f2f2"
                  justifyContent="center"
                  alignItems="center"
                  fontFamily="Source Han Sans"
                  color="#2c2c2c"
                  boxShadow="1px 1px 3px #00000029"
                  maxWidth="35rem"
                  fontSize="0.6875rem"
                  padding="0.5rem 0.5rem"
                  width="-webkit-fit-content"
                >
                  <Text
                    m="0"
                    maxW="15rem"
                    whiteSpace="nowrap"
                    overflow="hidden"
                    textOverflow="ellipsis"
                  >
                    {selectedSubTeam?.teamname}
                  </Text>
                </Tooltip>
              ) : (
                <Text
                  m="0"
                  maxW="15rem"
                  whiteSpace="nowrap"
                  overflow="hidden"
                  textOverflow="ellipsis"
                >
                  {selectedSubTeam?.teamname}
                </Text>
              )}
            </Box>
            <Box flex="1" justifyContent="center">
              <Flex>
                <Text
                  width="7.5625rem"
                  height="1rem"
                  fontSize="0.6875rem"
                  fontWeight="bold"
                  lineHeight="1rem"
                  letterSpacing="0"
                  color="#919191"
                  textAlign="center"
                  my="auto"
                  fontFamily="Source Han Sans"
                >
                  他チームの平均値を表示
                </Text>
                <Menu>
                  <MenuButton
                    style={{
                      width: "9.375rem",
                      height: "1.875rem",
                      border: "1px solid #B1B1B1",
                      borderRadius: "4px",
                      background: "#ffffff",
                      opacity: 1,
                      fontSize: "0.6875rem",
                      justifyContent: "center",
                      fontFamily: "Source Han Sans",
                    }}
                    textAlign="left"
                    ml="10px"
                    px="0.5rem"
                    as={Button}
                    rightIcon={
                      <Image
                        src={buttonPullDown}
                        mb="0"
                        h="0.5rem"
                        w="0.7875rem"
                      />
                    }
                  >
                    <Text
                      maxW="6rem"
                      whiteSpace="nowrap"
                      overflow="hidden"
                      textOverflow="ellipsis"
                    >
                      {selectedSubTeam?.teamname}
                    </Text>
                  </MenuButton>
                  <MenuList
                    className="dropdown-item"
                    py="0"
                    maxH="8rem"
                    overflowY="auto"
                  >
                    {listTeam?.map((item: TeamInfo, index: number) => (
                      <MenuItem
                        key={index}
                        fontWeight="bold"
                        background="#ffffff"
                        fontFamily="Source Han Sans"
                        px="0.5rem"
                        fontSize="0.75rem"
                        _hover={{ bg: "#ededed" }}
                        onClick={() => {
                          handleTeamInfoClick(item);
                        }}
                        className={
                          index === 0
                            ? "first-menu-item"
                            : index === listTeam.length - 1
                            ? "last-menu-item"
                            : ""
                        }
                      >
                        <Text
                          maxW="6rem"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {item?.teamname}
                        </Text>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Menu>
              </Flex>
            </Box>
          </Flex>
          <Box className="team-status" mt="0.9375rem">
            {!isErrorAPI ? (
              selectedSubTeam &&
              selectedSubTeam?.teamdata?.length > 0 && (
                <Grid className="grid-container" gap="0">
                  <Box borderRight="1px #EDEDED solid" h="3.6rem" mr="2rem">
                    <Text className="status-title">{statusTitle[0]}</Text>
                    <Flex>
                      <Text className="status-data">
                        {selectedSubTeam?.teamdata[0].pcactive}
                      </Text>
                      <Text className="status-addition-info" my="auto" ml="4px">
                        週平均
                        {compareTime("weekavgpcactive", "pcactive")}分
                      </Text>
                    </Flex>
                  </Box>
                  <Box h="3.6rem">
                    <Text className="status-title">{statusTitle[1]}</Text>
                    <Flex>
                      <Text className="status-data" color="#FA9E00">
                        {selectedSubTeam?.teamdata[0].worktime}
                      </Text>
                      <Text className="status-addition-info" my="auto" ml="4px">
                        週平均
                        {compareTime("weekavgworktime", "worktime")}分
                      </Text>
                    </Flex>
                  </Box>
                  <Box h="3.6rem">
                    <Text className="status-title">{statusTitle[2]}</Text>
                    <Flex>
                      <Text className="status-data" color="#8FCF00">
                        {selectedSubTeam?.teamdata[0].webmeeting}
                      </Text>
                      <Text className="status-addition-info" my="auto" ml="4px">
                        週平均
                        {compareTime("weekavgwebmeeting", "webmeeting")}分
                      </Text>
                    </Flex>
                  </Box>
                  <Box h="3.6rem">
                    <Text className="status-title">{statusTitle[3]}</Text>
                    <Flex>
                      <Text className="status-data" color="#5B5FFF">
                        {selectedSubTeam?.teamdata[0].websearch}
                      </Text>
                      <Text className="status-addition-info" my="auto" ml="4px">
                        週平均
                        {compareTime("weekavgwebsearch", "websearch")}分
                      </Text>
                    </Flex>
                  </Box>
                  <Box borderRight="1px #d8d8d8 solid" h="3.6rem" mr="2rem">
                    <Text className="status-title">{statusTitle[4]}</Text>
                    <Flex>
                      <Text className="status-data" color="#02B3FF">
                        {selectedSubTeam?.teamdata[0].plan}
                      </Text>
                      <Text className="status-addition-info" my="auto" ml="4px">
                        週平均
                        {compareTime("weekavgplan", "plan")}分
                      </Text>
                    </Flex>
                  </Box>
                  <Box h="3.6rem">
                    <Text className="status-title">{statusTitle[5]}</Text>
                    <Flex>
                      <Text className="status-data" color="#2C2C2C">
                        {selectedSubTeam?.teamdata[0].todayovertime}
                      </Text>
                      <Text className="status-addition-info" my="auto" ml="4px">
                        週平均
                        {compareTime("weekavgtodayovertime", "todayovertime")}分
                      </Text>
                    </Flex>
                  </Box>

                  <Box borderRight="1px #EDEDED solid" h="2.625rem" mr="2rem">
                    <Text className="status-title">{statusTitle[6]}</Text>
                    <Flex>
                      <Text className="status-data">
                        {selectedSubTeam?.teamdata[0].pcnonactive}
                      </Text>
                      <Text className="status-addition-info" my="auto" ml="4px">
                        週平均
                        {compareTime("weekavgpcnonactive", "pcnonactive")}分
                      </Text>
                    </Flex>
                  </Box>
                  <Box h="3.6rem">
                    <Text className="status-title">{statusTitle[7]}</Text>
                    <Flex>
                      <Text className="status-data" color="#FF4B45">
                        {selectedSubTeam?.teamdata[0].mailchat}
                      </Text>
                      <Text className="status-addition-info" my="auto" ml="4px">
                        週平均
                        {compareTime("weekavgmailchat", "mailchat")}分
                      </Text>
                    </Flex>
                  </Box>
                  <Box h="3.6rem">
                    <Text className="status-title">{statusTitle[8]}</Text>
                    <Flex>
                      <Text className="status-data" color="#9439FF">
                        {selectedSubTeam?.teamdata[0].others}
                      </Text>
                      <Text className="status-addition-info" my="auto" ml="4px">
                        週平均
                        {compareTime("weekavgothers", "others")}分
                      </Text>
                    </Flex>
                  </Box>
                  <Box h="2.625rem">
                    <Text className="status-title">{statusTitle[9]}</Text>
                    <Flex>
                      <Text className="status-data" color="#666666">
                        {selectedSubTeam?.teamdata[0].secret}
                      </Text>
                      <Text
                        className="status-addition-info"
                        my="auto"
                        ml="0.25rem"
                      >
                        週平均{compareTime("weekavgsecret", "secret")}分
                      </Text>
                    </Flex>
                  </Box>
                  <Box
                    borderRight="1px #d8d8d8 solid"
                    h="2.625rem"
                    mr="2rem"
                  ></Box>
                  <Box h="3.6rem">
                    <Text className="status-title">{statusTitle[10]}</Text>
                    <Flex>
                      <Text className="status-data" color="#2C2C2C">
                        {selectedSubTeam?.teamdata[0].monthovertime}
                      </Text>
                      <Text className="status-addition-info" my="auto" ml="4px">
                        先月
                        {compareTime("predictionovertime", "monthovertime")}分
                      </Text>
                    </Flex>
                  </Box>
                </Grid>
              )
            ) : (
              <Box h="3.6rem">
                <Text
                  textAlign="center"
                  mt="-0.9375rem"
                  color="red"
                  fontSize="3rem"
                  my="auto"
                  fontFamily="Source Han Sans"
                >
                  {TeamMessage.discovery.errorAPIProcess}
                </Text>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
});

export default TeamInfo;

import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { categoryOptions, EMPTY_STRING } from "../../Const";
import { REQUIRED_FIELD } from "../../util/messages";
import { EditApplicationModalProps } from "../../../components/parts/ManagementSettings/Modal/EditApplicationModal";
import { useMessage } from "../useMessage";
import { applicationService } from "services/applicationService";
import { useAppSelector } from "redux/storeConfig";
import {
  TRegisterListApplication,
  TRegisterNewApplication,
  TUpdateRegisteredApplication,
} from "../../../@types/Application";

export const useEditApplication = (props: EditApplicationModalProps) => {
  const { showMessage } = useMessage();
  const { adcompanyid } = useAppSelector((state) => state.userInfo);
  const [loading, setLoading] = useState(false);
  const [isSecret, setIsSecret] = useState(false);
  const [listUser, setListUser] = useState<string[] | null>(null);
  const schema = z.object({
    executeName:
      props?.applicationSelected?.applicationName === null ||
      props?.applicationSelected?.applicationName === undefined
        ? z
            .string()
            .trim()
            .min(1, REQUIRED_FIELD)
            .max(100, "このフィールドが100文字まで入力してください。")
            .regex(
              // eslint-disable-next-line no-useless-escape
              /^[^\\\/:\*\?"<>\|]+$/,
              'このフィールドに「\\/:*?"<>|」の文字を入力出来ません。'
            )
        : z.string().optional(),
    name: z
      .string()
      .trim()
      .min(1, REQUIRED_FIELD)
      .max(100, "このフィールドが100文字まで入力してください。")
      .regex(
        // eslint-disable-next-line no-useless-escape
        /^[^\\\/:\*\?"<>\|]+$/,
        'このフィールドに「\\/:*?"<>|」の文字を入力出来ません。'
      ),
    applicationType: z.string().trim().min(1, REQUIRED_FIELD),
    typing: z.boolean({
      required_error: REQUIRED_FIELD,
    }),
    capture: z.boolean({
      required_error: REQUIRED_FIELD,
    }),
  });
  type EditApplicationForm = z.infer<typeof schema>;
  const {
    handleSubmit,
    register,
    reset,
    formState,
    setValue,
    setError,
    watch,
  } = useForm<EditApplicationForm>({
    resolver: zodResolver(schema),
    values: {
      executeName:
        props?.applicationSelected?.applicationExecuteName || EMPTY_STRING,
      name: props?.applicationSelected?.applicationName || EMPTY_STRING,
      applicationType:
        props?.applicationSelected?.applicationType &&
        (categoryOptions as any)?.[props?.applicationSelected?.applicationType]
          ?.value
          ? (categoryOptions as any)?.[
              props?.applicationSelected?.applicationType
            ]?.value
          : EMPTY_STRING,
      typing: props?.applicationSelected?.typing || false,
      capture: props?.applicationSelected?.capture || false,
    },
  });
  const applicationTypeValue = watch("applicationType");

  useEffect(() => {
    setIsSecret(
      props?.applicationSelected?.applicationType?.toString() ===
        categoryOptions?.secret?.value
    );
    if (props?.applicationSelected?.applicationName) {
      getListUser();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props?.applicationSelected?.applicationType]);

  const onSubmit = (values: EditApplicationForm) => {
    setLoading(true);

    if (!props?.applicationSelected?.applicationName) {
      // Register new
      const formatValues: TRegisterNewApplication = {
        companyid: adcompanyid?.toString() ?? EMPTY_STRING,
        appsdetail: {
          registeredfile: values?.executeName ?? EMPTY_STRING,
          registeredname: values?.name ?? EMPTY_STRING,
          category: values?.applicationType ?? EMPTY_STRING,
          keystroke: values?.typing ?? false,
          screenshot: values?.capture ?? false,
        },
      };
      onRegisterNewApp(formatValues);
    } else if (!props?.isRegistered) {
      // Register un-register
      const formatValues: TRegisterListApplication = {
        companyid: adcompanyid?.toString() ?? EMPTY_STRING,
        appsdetails: [
          {
            registeredname:
              props?.applicationSelected?.applicationName ?? EMPTY_STRING,
            registeredname_new:
              values?.name?.toString()?.toLowerCase() ===
              props?.applicationSelected?.applicationName
                ?.toString()
                ?.toLowerCase()
                ? EMPTY_STRING
                : values?.name?.toString(),
            category: values?.applicationType ?? EMPTY_STRING,
            keystroke: values?.typing ?? false,
            screenshot: values?.capture ?? false,
          },
        ],
      };
      onRegisterSingleApp(formatValues);
    } else {
      // Update app
      const formatValues: TUpdateRegisteredApplication = {
        companyid: adcompanyid?.toString() ?? EMPTY_STRING,
        appsdetail: {
          registeredname:
            props?.applicationSelected?.applicationName ?? EMPTY_STRING,
          registeredname_new:
            values?.name?.toString()?.toLowerCase() ===
            props?.applicationSelected?.applicationName
              ?.toString()
              ?.toLowerCase()
              ? EMPTY_STRING
              : values?.name?.toString(),
          category: values?.applicationType ?? EMPTY_STRING,
          keystroke: values?.typing ?? false,
          screenshot: values?.capture ?? false,
        },
      };
      onUpdateRegisteredApp(formatValues);
    }
  };

  const onRegisterSingleApp = async (payload: TRegisterListApplication) => {
    try {
      await applicationService.registerListApplication(payload);
      props?.onClose({ isFetchData: true });
      showMessage({
        status: "success",
        title: "アプリケーションの登録に成功しました。",
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (
        error?.response?.data?.[0]?.error?.includes(
          "このアプリケーション名はすでに存在しております。"
        )
      ) {
        setError("name", {
          message: error?.response?.data?.[0]?.error,
        });
      }
      showMessage({
        status: "error",
        title: "アプリケーションの登録に失敗しました。",
      });
    }
  };

  const onRegisterNewApp = async (payload: TRegisterNewApplication) => {
    try {
      await applicationService.registerNewApplication(payload);
      props?.onClose({ isFetchData: true });
      showMessage({
        status: "success",
        title: "新規アプリケーションの登録に成功しました。",
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (
        error?.response?.data?.error?.includes(
          "このアプリケーション名はすでに存在しております。"
        )
      ) {
        setError("name", {
          message: error?.response?.data?.error,
        });
      }
      showMessage({
        status: "error",
        title: "新規アプリケーションの登録に失敗しました。",
      });
    }
  };

  const onUpdateRegisteredApp = async (
    payload: TUpdateRegisteredApplication
  ) => {
    try {
      await applicationService.updateRegisteredApplication(payload);
      props?.onClose({ isFetchData: true });
      showMessage({
        status: "success",
        title: "アプリケーションの更新に成功しました。",
      });
      setLoading(false);
    } catch (error: any) {
      if (
        error?.response?.data?.error?.includes(
          "このアプリケーション名はすでに存在しております。"
        )
      ) {
        setError("name", {
          message: error?.response?.data?.error,
        });
      }
      setLoading(false);
      showMessage({
        status: "error",
        title: "アプリケーションの更新に失敗しました。",
      });
    }
  };

  const onCopy = () => {
    try {
      const formatString = listUser?.join("\n") ?? EMPTY_STRING;

      navigator.clipboard.writeText(formatString);
      showMessage({
        status: "success",
        title: "テキストのコピーに成功しました。",
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const getListUser = async () => {
    let listUserTemp: string[] = [];
    try {
      const response = await applicationService.getPersonalSecret({
        companyid: adcompanyid?.toString() ?? EMPTY_STRING,
        appsdetails: [
          {
            registeredname:
              props?.applicationSelected?.applicationName ?? EMPTY_STRING,
            category:
              props?.applicationSelected?.applicationType ?? EMPTY_STRING,
          },
        ],
      });
      listUserTemp = response?.data?.[0]?.user ?? [];
      setListUser(JSON.parse(JSON.stringify(listUserTemp)));
    } catch (_) {}
  };

  return {
    state: {
      formState,
      applicationTypeValue,
      loading,
      isSecret,
      listUser,
    },
    handler: {
      register,
      handleSubmit,
      setValue,
      reset,
      setLoading,
      onSubmit,
      onCopy,
      watch,
      getListUser,
    },
  };
};

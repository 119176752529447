import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { MemberFrame, UpdateMemberProfile } from "../../../@types/Member";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  getMemberListThunk,
  updateMemberThunk,
} from "../../../redux/thunk/memberThunk";
import { EMPTY_STRING } from "../../Const";
import { REQUIRED_FIELD } from "../../util/messages";
import { useMessage } from "../useMessage";

type UseUpdateMemberProps = {
  member: MemberFrame | null;
  onClose: () => void;
};

const schema = z.object({
  firstname: z.string().trim().min(1, REQUIRED_FIELD),
  lastname: z.string().trim().min(1, REQUIRED_FIELD),
  firstnamekana: z.string().trim().min(1, REQUIRED_FIELD),
  lastnamekana: z.string().trim().min(1, REQUIRED_FIELD),
  occupation: z.string().trim(),
  title: z.string().trim(),
  contract: z.string().trim(),
  location: z.string().trim(),
  authority: z.string().trim().min(1, REQUIRED_FIELD),
});

type UserDataForm = z.infer<typeof schema>;

export const useUpdateMember = (props: UseUpdateMemberProps) => {
  const { member, onClose } = props;
  const dispatch = useAppDispatch();
  const { adcompanyid } = useAppSelector((state) => state.userInfo);
  const { showMessage } = useMessage();

  const { handleSubmit, register, reset, formState } = useForm<UserDataForm>({
    resolver: zodResolver(schema),
    values: {
      authority: member?.gtauthority?.toString() || EMPTY_STRING,
      contract: member?.gtcontract || EMPTY_STRING,
      location: member?.gtlocation || EMPTY_STRING,
      occupation: member?.gtoccupation || EMPTY_STRING,
      title: member?.gttitle || EMPTY_STRING,
      firstname: member?.gtuserfirstname || EMPTY_STRING,
      lastname: member?.gtuserlastname || EMPTY_STRING,
      firstnamekana: member?.gtuserfirstnamekana || EMPTY_STRING,
      lastnamekana: member?.gtuserlastnamekana || EMPTY_STRING,
    },
  });

  const closeModal = () => {
    onClose();
    reset();
  };

  const onSubmit = async (values: UserDataForm) => {
    if (member && adcompanyid) {
      const payload: UpdateMemberProfile = {
        upcrud: "U",
        upcompanyid: adcompanyid,
        upuserid: member.gtuserid,
        upuserpic: member.gtuserpic,
        upuserpassword: member.gtuserpassword,
        upuserfirstname: values.firstname,
        upuserfirstnamekana: values.firstnamekana,
        upuserlastname: values.lastname,
        upuserlastnamekana: values.lastnamekana,
        upoccupation: values.occupation,
        uplocation: values.location,
        uptitle: values.title,
        upcontract: values.contract,
        upauthority: Number(values.authority),
      };
      dispatch(
        updateMemberThunk({
          payload,
          onSuccess: (message) => {
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
            reset();
            onClose();
            dispatch(
              getMemberListThunk({
                payload: {
                  urcompanyid: adcompanyid,
                },
              })
            );
          },
          onError: (message) => {
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  };

  return {
    formState,
    closeModal,
    handleSubmit,
    register,
    reset,
    onSubmit,
  };
};

import { ChevronLeftIcon } from "@chakra-ui/icons";
import {
  Flex,
  Heading,
  LayoutProps,
  Modal,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  ModalProps,
  Text,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";

interface CommonModalProps extends ModalProps {
  title: ReactNode;
  minW?: LayoutProps["minW"];
  minH?: LayoutProps["minH"];
  w?: LayoutProps["w"];
  h?: LayoutProps["h"];
  cancelText?: ReactNode;
  overflowBody?: LayoutProps["overflowY"];
  isShowCloseButton?: boolean;
  fixedContent?: boolean;
}

export const CommonModal: FC<CommonModalProps> = (props) => {
  const {
    children,
    onClose,
    title,
    minW = 500,
    h,
    minH,
    w,
    isOpen,
    cancelText = "キャンセル",
    overflowBody = "auto",
    isShowCloseButton = true,
    fixedContent = false,
    ...otherProps
  } = props;

  if (!isOpen) return null;

  return (
    <Modal isOpen={isOpen} onClose={onClose} useInert={false} {...otherProps}>
      <ModalOverlay />
      <ModalContent
        minW={minW}
        borderRadius="10px"
        minH={minH}
        w={w}
        h={h}
        overflow={fixedContent ? "hidden" : "auto"}
      >
        <ModalHeader
          pl="12px"
          pr="20px"
          pt="15px"
          pb="10px"
          position="relative"
        >
          {isShowCloseButton ? (
            <Flex
              cursor="pointer"
              align="center"
              w="fit-content"
              onClick={onClose}
            >
              <ChevronLeftIcon boxSize="24px" />
              <Text
                color="#666"
                fontSize="16px"
                lineHeight="24px"
                letterSpacing="0px"
              >
                {cancelText}
              </Text>
            </Flex>
          ) : null}

          <Heading
            as="h4"
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
            fontSize="16px"
            color="#666"
            lineHeight="24px"
          >
            {title}
          </Heading>
        </ModalHeader>
        <ModalBody
          px="30px"
          py="20px"
          overflowY={overflowBody}
          sx={{
            "::-webkit-scrollbar": {
              display: "none",
            },
          }}
        >
          {children}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

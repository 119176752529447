/* eslint-disable react-hooks/exhaustive-deps */
import {
  Checkbox,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { zodResolver } from "@hookform/resolvers/zod";
import { createColumnHelper } from "@tanstack/table-core";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import { TfiMoreAlt } from "react-icons/tfi";
import { applicationService } from "services/applicationService";
import { z } from "zod";
import {
  TApplicationDetail,
  TApplicationListTable,
  TApplicationPayload,
  TApplicationUnRegistered,
} from "../../../@types/Application";
import { useAppSelector } from "../../../redux/storeConfig";
import { categoryOptions, EMPTY_STRING } from "lib/Const";

export const APPLICATION_UNREGISTERED_MODAL = {
  ADD_APPLICATION: "ADD_APPLICATION",
  EDIT_APPLICATION: "EDIT_APPLICATION",
};

export const schema = z.object({
  ids: z.array(
    z.object({
      id: z.boolean(),
    })
  ),
});

export type SelectForm = z.infer<typeof schema>;

export const useApplicationUnRegistered = () => {
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);
  const columnHelper = createColumnHelper<TApplicationUnRegistered>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<string[]>([]);
  const [modalName, setModalName] = useState<string | null>(null);
  const [applicationList, setApplicationList] = useState<
    TApplicationUnRegistered[]
  >([]);
  const [total, setTotal] = useState<number>(0);
  const [applicationSelected, setApplicationSelected] = useState<
    TApplicationDetail | TApplicationListTable[] | null
  >(null);
  const { handleSubmit, register, reset, setValue, watch } =
    useForm<SelectForm>({
      resolver: zodResolver(schema),
      values: {
        ids: [],
      },
    });

  const columns = [
    columnHelper.accessor("companyid", {
      cell: (info) => {
        return (
          <Checkbox
            isChecked={watch(`ids.${info.row.index}.id`)}
            _checked={{
              span: {
                background: "#00BA0F",
                borderColor: "#00BA0F",
                _hover: {
                  background: "#00BA0F",
                  borderColor: "#00BA0F",
                },
              },
            }}
            css={{
              ".chakra-checkbox__control": {
                width: "20px",
                height: "20px",
                svg: {
                  height: "14px",
                  width: "14px",
                },
              },
            }}
            {...register(`ids.${info.row.index}.id`)}
          ></Checkbox>
        );
      },
      header: () => {
        return (
          <Checkbox
            isChecked={
              !!(
                watch("ids")?.length &&
                !watch("ids")?.some((value) => {
                  return !value?.id;
                })
              )
            }
            onChange={(event) => {
              if (event.target.checked) {
                setValue("ids", Array(pageSize).fill({ id: true }));
              } else {
                reset();
              }
            }}
            _checked={{
              span: {
                background: "#00BA0F",
                borderColor: "#00BA0F",
                _hover: {
                  background: "#00BA0F",
                  borderColor: "#00BA0F",
                },
              },
            }}
            css={{
              ".chakra-checkbox__control": {
                width: "20px",
                height: "20px",
                svg: {
                  height: "14px",
                  width: "14px",
                },
              },
            }}
          ></Checkbox>
        );
      },
      enableSorting: false,
      meta: {
        size: "3%",
      },
    }),
    columnHelper.accessor("registeredname", {
      cell: (info) => {
        const value = info.getValue();
        const truncatedValue =
          value.length > 18 ? `${value.slice(0, 18)}...` : value;

        return (
          <Tooltip label={value} isDisabled={value.length <= 18} hasArrow>
            <Text fontSize="12px" color="#2C2C2C" fontWeight="bold">
              {truncatedValue}
            </Text>
          </Tooltip>
        );
      },
      header: () => {
        return (
          <Flex width="100%">
            <Text>アプリケーション名</Text>
            {sort?.includes("registeredname") ? (
              sort?.includes("asc") ? (
                <GoArrowUp size="15px" aria-label="sorted ascending" />
              ) : (
                <GoArrowDown size="15px" aria-label="sorted descending" />
              )
            ) : null}
          </Flex>
        );
      },
      enableSorting: false,
      // sortingFn: "sortByJapanese",
      meta: {
        size: "45%",
        customSort: () => {
          onSortChange("registeredname");
        },
      },
    }),
    columnHelper.accessor("datediscovery", {
      cell: (info) => {
        const date = new Date(info.getValue());
        const formattedDate = `${date?.getFullYear()}/${(date?.getMonth() + 1)
          .toString()
          .padStart(2, "0")}/${date?.getDate().toString().padStart(2, "0")}`;

        return (
          <Text fontSize="12px" color="#2C2C2C" fontWeight="bold">
            {formattedDate}
          </Text>
        );
      },
      header: () => {
        return (
          <Flex width="100%">
            <Text>検知日</Text>
            {sort?.includes("datediscovery") ? (
              sort?.includes("asc") ? (
                <GoArrowUp size="15px" aria-label="sorted ascending" />
              ) : (
                <GoArrowDown size="15px" aria-label="sorted descending" />
              )
            ) : null}
          </Flex>
        );
      },
      // sortingFn: "sortByJapanese",
      meta: {
        size: "45%",
        customSort: () => {
          onSortChange("datediscovery");
        },
      },
    }),
    columnHelper.accessor("registeredfile", {
      cell: (info) => (
        <Menu variant="custom">
          <Flex align="center" justifyContent="right" w="100%">
            <MenuButton
              as={IconButton}
              variant="outline"
              size="sm"
              textAlign="center"
              aria-label="Search team"
              borderColor="transparent"
              w="28px"
              h="28px"
              icon={<TfiMoreAlt size={16} />}
            />
          </Flex>
          <MenuList w="260px">
            <MenuItem
              onClick={() => {
                openModalEdit(info.row.original);
              }}
            >
              登録する
            </MenuItem>
          </MenuList>
        </Menu>
      ),
      header: EMPTY_STRING,
      enableSorting: false,
    }),
  ];

  const openModalAdd = (values: {
    ids: {
      id: boolean;
    }[];
  }) => {
    const submitValues: TApplicationListTable[] = applicationList
      ?.filter((_, index) => {
        return values?.ids?.[index]?.id;
      })
      ?.map((value, index) => {
        return {
          id: `${index}`,
          applicationExecuteName: value?.registeredfile ?? EMPTY_STRING,
          applicationName: value?.registeredname ?? EMPTY_STRING,
          applicationType:
            value?.category &&
            (categoryOptions as any)?.[value?.category]?.value
              ? (categoryOptions as any)?.[value?.category]?.value
              : EMPTY_STRING,
          typing: value?.keystroke ?? true,
          capture: value?.screenshot ?? true,
          isSecret:
            value?.category?.toString() === categoryOptions?.secret?.value,
        };
      });

    setApplicationSelected(submitValues);
    setModalName(APPLICATION_UNREGISTERED_MODAL.ADD_APPLICATION);
  };

  const openModalEdit = (
    applicationSelectedProp?: TApplicationUnRegistered
  ) => {
    setApplicationSelected(
      {
        applicationExecuteName:
          applicationSelectedProp?.registeredfile ?? EMPTY_STRING,
        applicationName:
          applicationSelectedProp?.registeredname ?? EMPTY_STRING,
        applicationType: applicationSelectedProp?.category ?? EMPTY_STRING,
        typing: applicationSelectedProp?.keystroke ?? true,
        capture: applicationSelectedProp?.screenshot ?? true,
      } ?? null
    );
    setModalName(APPLICATION_UNREGISTERED_MODAL.EDIT_APPLICATION);
  };

  const onCloseModal = ({ isFetchData }: { isFetchData?: boolean } = {}) => {
    setModalName(null);
    setApplicationSelected(null);

    if (isFetchData) {
      getDataUnRegistered();
    }
  };

  const onResetCheckBox = () => {
    reset();
  };

  const onSortChange = (sortField: string) => {
    let sortTemp = sort;
    if (!sortTemp?.includes(sortField)) {
      sortTemp = [sortField, "asc"];
    } else if (sortTemp?.includes(sortField)) {
      if (sortTemp?.[1] === "desc") {
        sortTemp = [];
      } else {
        sortTemp = [sortField, "desc"];
      }
    }
    getDataUnRegistered({
      pageNumberProp: 1,
      sortProp: sortTemp,
    });
  };

  const onPageNumberChange = (pageNumberProp: number) => {
    getDataUnRegistered({
      pageNumberProp,
    });
  };

  const onPageSizeChange = (pageSizeProp: number) => {
    getDataUnRegistered({
      pageNumberProp: 1,
      pageSizeProp: pageSizeProp,
    });
  };

  useEffect(() => {
    getDataUnRegistered();
  }, []);

  const getDataUnRegistered = async ({
    pageNumberProp = pageNumber,
    pageSizeProp = pageSize,
    sortProp = sort,
  }: {
    pageNumberProp?: number;
    pageSizeProp?: number;
    sortProp?: string[];
  } = {}) => {
    const response = await applicationService.getUnRegisteredApplication(
      getPayload({
        pageNumberProp,
        pageSizeProp,
        sortProp,
      })
    );
    if (
      response?.data?.total &&
      response?.data?.total <= (pageNumberProp - 1) * pageSizeProp
    ) {
      onPageNumberChange(Math.ceil(response?.data?.total / pageSizeProp));
    } else {
      setPageNumber(pageNumberProp);
      setPageSize(pageSizeProp);
      setSort(sortProp);
      setApplicationList(response?.data?.data);
      setTotal(response?.data?.total);
      onResetCheckBox();
    }
  };

  const getPayload = ({
    pageNumberProp,
    pageSizeProp,
    sortProp,
  }: {
    pageNumberProp: number;
    pageSizeProp: number;
    sortProp: string[];
  }): TApplicationPayload => {
    const payload: TApplicationPayload = {
      companyid: adcompanyid ?? EMPTY_STRING,
      userid: aduserid ?? EMPTY_STRING,
      pageindex: pageNumberProp?.toString(),
      pagesize: pageSizeProp?.toString(),
    };
    if (sortProp?.length) {
      payload.column = sortProp?.[0];
      payload.asc = sortProp?.[1] === "asc" ? 1 : 0;
    }
    return payload;
  };

  return {
    state: {
      columns,
      applicationList,
      modalName,
      applicationSelected,
      pageNumber,
      pageSize,
      total,
    },
    handler: {
      openModalAdd,
      openModalEdit,
      onCloseModal,
      onPageNumberChange,
      onPageSizeChange,
      handleSubmit,
      watch,
    },
  };
};

'use strict;';

import { formatHourAndMinute } from './timeCalculate';
import {
  TIMEZONE30MINDEX,
  MINTIMEZONE,
  MAXTIMEZONE,
  addRangeEndTime,
  gridUnitsForDiscovery
} from './Const';

type Props = {
  timeZoneIndex: number | undefined;
  scheduleData: ScheduleDatas;
  activeTimeZone: HoveredTimeZoneItemPosition;
};

type DisplayTimeSpotArrayProps = {
  timeZoneIndex: number;
  scheduleData: ScheduleData[];
  activeTimeZone: string | number;
};

type GenerateTimeSpotArray = {
  isOverflowMinTimeZone: boolean;
  isOverflowMaxTimeZone: boolean;
  timeZoneIndex: number;
  stringActiveTimeZone: string;
  numberOfGridUnits: number;
  unitsNumberForBeforeAndAfterActiveTimeZone: number;
};

/**
 * @remarks
 * ISO8601時間表記になっている時間を「時：分」形式に返す
 *
 * @param ISO8601 {string} - ISO8601形式の時間の文字列
 *
 * @returns
 * 「時：分」形式の文字列
 */
const _formatISO8601ToHourAndMinute = (ISO8601: string) => {
  const hourMinuteSecond = new Date(ISO8601)
    .toLocaleString('ja-JP', { timeZone: 'Asia/Tokyo' })
    .split(' ')[1]
    .split(':');
  const hourMinute = `${hourMinuteSecond[0]}:${hourMinuteSecond[1]}`;
  return hourMinute;
};

/**
 * @remarks
 * 「時：分」形式の文字列をもとに、秒数を返す
 *
 * @param timeZoneString {string} - 「時：分」形式の文字列
 *
 * @returns
 * 秒数の数値
 */
const _convertTimeZoneStringToSeconds = (timeZoneString: string) => {
  const splitHourAndMinutes = timeZoneString.split(':');
  const time = {
    hour: parseInt(splitHourAndMinutes[0]),
    minutes: parseInt(splitHourAndMinutes[1]),
  };
  const timeToSeconds = time.hour * 60 * 60 + time.minutes * 60;
  return timeToSeconds;
};

/**
 * @remarks
 * 最小時間、最大時間の範囲を超えるかを判定する
 *
 * @param timeZoneString {string} - 「時：分」形式の文字列（0:00~24:00）
 * @param timeZoneIndex {number} - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 * @param unitsNumberForBeforeAndAfterActiveTimeZone {number} - 指定した時間をもとに最小時間、最大時間を算出するための単位
 *
 * @returns
 * {
 *  isOverflowMinTimeZone: 最小時間が0:00より時間が少ない時間帯になったかどうかのフラグ,
 *  isOverflowMaxTimeZone: 最大時間が24:00より時間が大きい時間帯になったかどうかのフラグ
 * }
 */
const _isOverflowTimeZoneRange = (
  timeZoneString: string,
  timeZoneIndex: number,
  unitsNumberForBeforeAndAfterActiveTimeZone: number
) => {
  const timeToSeconds = _convertTimeZoneStringToSeconds(timeZoneString);
  const addTimeToSeconds =
    addRangeEndTime[timeZoneIndex] *
    unitsNumberForBeforeAndAfterActiveTimeZone *
    60;
  const isOverflowMinTimeZone = timeToSeconds - addTimeToSeconds < 0;
  const isOverflowMaxTimeZone = timeToSeconds + addTimeToSeconds > 86400;
  return { isOverflowMinTimeZone, isOverflowMaxTimeZone };
};

/**
 * @remarks
 * スケジュールデータを指定された時間範囲と単位に合わせた配列にして返す
 *
 * @param timeZoneString {string} - 「時：分」形式の文字列（0:00~24:00）
 * @param timeZoneIndex {number} - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 * @param unitsNumberForBeforeAndAfterActiveTimeZone {number} - 指定した時間をもとに最小時間、最大時間を算出するための単位
 * @param plusOrMinus {'plus' | 'minus'} - 最小時間、最大時間の算出のフラグ
 *
 * @returns
 * number[] 時間範囲を秒数にした配列
 */
const _calculateTimeZoneRange = (
  timeZoneString: string,
  timeZoneIndex: number,
  unitsNumberForBeforeAndAfterActiveTimeZone: number,
  plusOrMinus: 'plus' | 'minus'
) => {
  const splitHourAndMinutes = timeZoneString.split(':');
  const time = {
    hour: parseInt(splitHourAndMinutes[0]),
    minutes: parseInt(splitHourAndMinutes[1]),
  };
  const result = [...Array(unitsNumberForBeforeAndAfterActiveTimeZone)].map(
    (_: undefined, i: number) => {
      const timeToSeconds = time.hour * 60 * 60 + time.minutes * 60;
      const addTimeToSeconds = addRangeEndTime[timeZoneIndex] * i * 60;
      const calculatedTime =
        plusOrMinus === 'plus'
          ? timeToSeconds + addTimeToSeconds
          : timeToSeconds - addTimeToSeconds;
      return calculatedTime;
      // return formatHourAndMinute(calculatedTime);
    }
  );
  return result;
};

/**
 * @remarks
 * スケジュールデータを指定された時間範囲と単位に合わせた配列にして返す
 * 最小時間、最大時間の範囲を超えた場合の処理も行う
 *
 * @param GenerateTimeSpotArray - {@link GenerateTimeSpotArray}
 *  @param isOverflowMinTimeZone {boolean} - 最小時間が0:00より時間が少ない時間帯になったかどうかのフラグ
 *  @param isOverflowMaxTimeZone {boolean} - 最大時間が24:00より時間が大きい時間帯になったかどうかのフラグ
 *  @param timeZoneIndex {number} - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *  @param stringActiveTimeZone {string} - 「時：分」形式の文字列（0:00~24:00）
 *  @param numberOfGridUnits {number} - Grid状に表示する数
 *  @param unitsNumberForBeforeAndAfterActiveTimeZone {number} - 指定した時間をもとに最小時間、最大時間を算出するための単位
 *
 * @returns
 * number[] 時間範囲を秒数にした配列
 */
const _generateTimeSpotArray = ({
  isOverflowMinTimeZone,
  isOverflowMaxTimeZone,
  timeZoneIndex,
  stringActiveTimeZone,
  numberOfGridUnits,
  unitsNumberForBeforeAndAfterActiveTimeZone,
}: GenerateTimeSpotArray): number[] => {
  if (isOverflowMinTimeZone) {
    const result = _calculateTimeZoneRange(
      MINTIMEZONE,
      timeZoneIndex,
      numberOfGridUnits,
      'plus'
    );
    return result;
  }

  if (isOverflowMaxTimeZone) {
    const result = _calculateTimeZoneRange(
      MAXTIMEZONE,
      timeZoneIndex,
      numberOfGridUnits,
      'minus'
    ).reverse();
    return result;
  }

  const unitsForBeforeActiveTimeZone = _calculateTimeZoneRange(
    stringActiveTimeZone,
    timeZoneIndex,
    unitsNumberForBeforeAndAfterActiveTimeZone + 1,
    'minus'
  ).reverse();
  unitsForBeforeActiveTimeZone.pop();
  const unitsForAfterActiveTimeZone = _calculateTimeZoneRange(
    stringActiveTimeZone,
    timeZoneIndex,
    unitsNumberForBeforeAndAfterActiveTimeZone + 1,
    'plus'
  );
  const displayTimeSpotRange = [
    ...unitsForBeforeActiveTimeZone,
    ...unitsForAfterActiveTimeZone,
  ];
  return displayTimeSpotRange;
};

/**
 * @remarks
 * スケジュールデータと時間帯情報をもとに、グラフ下部にGrid表示するためのデータを整形する
 *
 * @param DisplayTimeSpotArrayProps = {@link DisplayTimeSpotArrayProps}
 *  @param timeZoneIndex {number} - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *  @param scheduleData @see [DummyData](src/data/dummy1/schedule_dummy.json)
 *  @param activeTimeZone {string | number} - 時間帯（24時間帯：number、12時間帯以下：string）
 *
 * @returns
 * [
 *  scheduledItemIndexArray スケジュールデータの時間と指定した時間帯で表示するGrid状の時間を比較して、該当する時間帯に存在するスケジュールデータだけをまとめたデータ,
 *  generateTimeSpotToHourAndMinuteArray 指定した時間帯で表示するGrid状の時間を「時：分」形式にまとめたデータ
 * ]
 * ※jsonデータをまとめる
 */
const _displayTimeSpotArray = ({
  timeZoneIndex,
  scheduleData,
  activeTimeZone,
}: DisplayTimeSpotArrayProps): [[], []] | [(number | false)[], string[]] => {
  if (timeZoneIndex === undefined || activeTimeZone === undefined)
    return [[], []];

  const stringActiveTimeZone =
    typeof activeTimeZone === 'number'
      ? `${activeTimeZone}:00`
      : activeTimeZone;
  const numberOfGridUnits = gridUnitsForDiscovery[timeZoneIndex];
  const unitsNumberForBeforeAndAfterActiveTimeZone = Math.floor(
    numberOfGridUnits / 2
  );

  const { isOverflowMinTimeZone, isOverflowMaxTimeZone } =
    _isOverflowTimeZoneRange(
      stringActiveTimeZone,
      timeZoneIndex,
      unitsNumberForBeforeAndAfterActiveTimeZone + 1
    );

  const generatedTimeSpotArray = _generateTimeSpotArray({
    isOverflowMinTimeZone,
    isOverflowMaxTimeZone,
    timeZoneIndex,
    stringActiveTimeZone,
    numberOfGridUnits,
    unitsNumberForBeforeAndAfterActiveTimeZone,
  });

  // スケジュールデータの時間と指定した時間帯で表示するGrid状の時間を比較して、該当する時間帯に存在するスケジュールデータをまとめる
  const scheduledItemIndexArray: (number | false)[] = scheduleData.map(
    (schedule: ScheduleData, i: number): number | false => {
      const timeSeconds = _convertTimeZoneStringToSeconds(schedule.start);

      const foundIndex = generatedTimeSpotArray.findIndex(
        (second: number) => timeSeconds <= second
      );

      if (foundIndex < 0) return false;

      if (timeZoneIndex !== TIMEZONE30MINDEX) {
        if (
          timeSeconds > generatedTimeSpotArray[foundIndex - 1] &&
          timeSeconds < generatedTimeSpotArray[foundIndex]
        ) {
          return foundIndex - 1;
        }
      }
      return foundIndex;
    }
  );

  // 指定した時間帯で表示するGrid状の時間を「時：分」形式にまとめる
  const generateTimeSpotToHourAndMinuteArray = generatedTimeSpotArray.map(
    (v: number) => formatHourAndMinute(v)
  );

  return [scheduledItemIndexArray, generateTimeSpotToHourAndMinuteArray];
};

/**
 * @remarks
 * ISO8601時間表記になっているスケジュールの開始時間と終了時間を「時：分」にして1つのオブジェクトにまとめて返す
 *
 * @param scheduleData @see [DummyData](src/data/dummy1/schedule_dummy.json)
 *
 * @returns
 * @see {@link ScheduleDatas}
 */
const formatScheduleTimeISO8601ToHourAndMinute = (
  scheduleData: ScheduleDatas
): ScheduleDatas => {
  if (!scheduleData.length) return [];
  const newScheduleData = scheduleData.map(
    (schedule: ScheduleData, i: number) => {
      schedule.start = _formatISO8601ToHourAndMinute(schedule.start);
      schedule.end = _formatISO8601ToHourAndMinute(schedule.end);
      return schedule;
    }
  );
  return newScheduleData;
};

/**
 * @remarks
 * スケジュールデータと時間帯情報をもとに、グラフ下部にGrid表示するためのデータを整形する
 *
 * @param Props - {@link Props}
 *  @param timeZoneIndex {number} - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *  @param scheduleData @see [DummyData](src/data/dummy1/schedule_dummy.json)
 *  @param activeTimeZone {string | number} - 時間帯（24時間帯：number、12時間帯以下：string）
 *
 * @returns
 * [
 *  scheduledItemIndexArray スケジュールデータの時間と指定した時間帯で表示するGrid状の時間を比較して、該当する時間帯に存在するスケジュールデータだけをまとめたデータ,
 *  generateTimeSpotToHourAndMinuteArray 指定した時間帯で表示するGrid状の時間を「時：分」形式にまとめたデータ
 * ]
 * ※jsonデータをまとめる
 */
const generateScheduleData = ({
  timeZoneIndex,
  scheduleData,
  activeTimeZone = undefined,
}: Props): [[], []] | [(number | false)[], string[]] => {
  if (
    timeZoneIndex === undefined ||
    activeTimeZone === undefined ||
    !scheduleData?.length
  )
    return [[], []];
  // activeTimeZoneとtimeZoneIndexを基準に、時間範囲によるGridItemの作成数を
  switch (timeZoneIndex) {
    case 0:
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return _displayTimeSpotArray({
        timeZoneIndex,
        scheduleData,
        activeTimeZone,
      });

    default:
      return [[], []];
  }
};

export { formatScheduleTimeISO8601ToHourAndMinute, generateScheduleData };

import {
  Avatar,
  Box,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
  Text,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { Controller } from "react-hook-form";
import { BiSearchAlt } from "react-icons/bi";
import { useAddMember } from "../../../../lib/hooks/ManagementSettings/useAddMember";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonModal } from "../../CommonModal";
import { ButtonSubmit } from "../Button/ButtonSubmit";
import { TextSecond } from "../Typography/TextSecond";
import { getName } from "../../../../lib/util/helper";

type AddMemberModalProps = {
  title: ReactNode;
  isOpen: boolean;
  type: "TEAM_INFO" | "TEAM_LIST";
  onClose: () => void;
  onOk?: () => void;
};

export const AddMemberModal: FC<AddMemberModalProps> = (props) => {
  const { isOpen, title, type, onClose, onOk } = props;
  const { isLoading } = useAppSelector((state) => state.loading);
  const {
    searchValue,
    control,
    data,
    isEmpty,
    handleSubmit,
    onSubmit,
    onCloseModal,
    onChangeSearchValue,
    onSearchTeam,
  } = useAddMember({ type, onClose, onOk });

  return (
    <CommonModal
      minW={600}
      title={title}
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered
    >
      <Stack align="center" justify="center" px="30px" spacing="23px">
        <Flex align="end" gap="4px" w="100%">
          <FormControl>
            <FormLabel mb="10px">
              <TextSecond fontWeight="bold">
                チームに追加したいメンバーを選択してください
              </TextSecond>
            </FormLabel>
            <Input
              variant="custom"
              name="search"
              placeholder="名前を検索"
              border="2px solid #D5D5D5"
              borderRadius="10px"
              value={searchValue}
              onChange={onChangeSearchValue}
              autoComplete="off"
              flex={1}
            />
          </FormControl>
          <IconButton
            variant="outline"
            aria-label="Search team"
            borderColor="transparent"
            w="37px"
            h="37px"
            icon={<BiSearchAlt size={24} />}
            onClick={onSearchTeam}
          />
        </Flex>
        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: "100%", height: "100%" }}
        >
          <Box
            h="420px"
            w="100%"
            border="2px solid #D5D5D5"
            overflow="auto"
            sx={{
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <FormControl>
              <Controller
                name="upteamuserid"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <CheckboxGroup {...rest}>
                    <Stack>
                      {data.map((item) => (
                        <Flex
                          w="100%"
                          htmlFor={item.gtuserid}
                          as={FormLabel}
                          key={item.gtuserid}
                          m="0px"
                          p="16px"
                          justify="space-between"
                          cursor={isLoading ? "not-allowed" : "pointer"}
                          _hover={{
                            background: "#EDEDED",
                          }}
                        >
                          <Flex align="center" gap="10px">
                            <Avatar src="" w="26px" h="26px" />
                            <Text
                              color="#2C2C2C"
                              fontSize="12px"
                              fontWeight="bold"
                            >
                              {getName([
                                item.gtuserlastname,
                                item.gtuserfirstname,
                              ])}
                            </Text>
                          </Flex>
                          <Checkbox
                            id={item.gtuserid}
                            variant="custom"
                            value={item.gtuserid}
                            size="lg"
                            disabled={isLoading}
                          />
                        </Flex>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                )}
              />
            </FormControl>
          </Box>
          <Center pt="30px" pb="20px">
            <ButtonSubmit
              type="submit"
              isLoading={isLoading}
              isDisabled={isEmpty}
            >
              追加する
            </ButtonSubmit>
          </Center>
        </form>
      </Stack>
    </CommonModal>
  );
};


import {
  generate12HourData,
  generateUnder12HourData,
} from './commonDataProcessing';

/**
 * @remarks
 * 使用したアプリで扱った作業の種類を判定する
 *
 * @param appInfoItem {@link AppInfoItem}
 *
 * @returns
 * @see {@link InfoType}
 */
const _generateInfoType = (appInfoItem: AppInfoItem): InfoType => {
  if (!appInfoItem.appInfoPath.length) return 'none';
  // アプリを使って行った作業がURLを含んでいるかどうかで、ブラウザの作業・ファイル作業を判定する
  return appInfoItem.appInfoPath.indexOf('http') >= 0 ? 'url' : 'file';
};

/**
 * @remarks
 * アプリの情報と使用アプリのカウント情報をまとめて返す
 *
 * @param appInfoItem {@link AppInfoItem}
 *
 * @returns
 * @see {@link AppInfoSumData}
 */
const _generateNewAppInfoItem = (appInfoItem: AppInfoItem): AppInfoSumData => {
  return {
    appName: appInfoItem.appName,
    category: appInfoItem.category,
    useTime: appInfoItem.useTime,
    detail: [
      {
        useDetail: appInfoItem.appUseDetail,
        infoPath: appInfoItem.appInfoPath,
        useTime: appInfoItem.useTime,
        infoType: _generateInfoType(appInfoItem),
        category: appInfoItem.category,
      },
    ],
  };
};

/**
 * @remarks
 * 使用アプリのカウント情報をまとめて返す
 *
 * @param appInfoItem {@link AppInfoItem}
 *
 * @returns
 * @see {@link AppInfoDetailSumData}
 */
const _generateNewAppDetailInfoItem = (
  appInfoItem: AppInfoItem
): AppInfoDetailSumData => {
  return {
    useDetail: appInfoItem.appUseDetail,
    infoPath: appInfoItem.appInfoPath,
    useTime: appInfoItem.useTime,
    infoType: _generateInfoType(appInfoItem),
    category: appInfoItem.category,
  };
};

/**
 * @remarks
 * 使用されたアプリの情報に入っていないアプリの情報を追加する
 *
 * @param appInfoCountObject {@link AppInfoCountObject}
 * @param appInfoItem {@link AppInfoItem}
 *
 * @returns
 * @see {@link AppInfoCountObject}
 */
const _addNewAppInfoItem = (
  appInfoCountObject: AppInfoCountObject,
  appInfoItem: AppInfoItem
): AppInfoCountObject => {
  // アプリの情報と使用アプリのカウント情報をまとめて返す
  const newItem: AppInfoSumData = _generateNewAppInfoItem(appInfoItem);
  (appInfoCountObject.appSum as AppInfoSumData[]).push(newItem);
  return appInfoCountObject;
};

/**
 * @remarks
 * 詳細情報の中に該当する作業ファイル名・タブ名が存在しない場合
 *
 * @param appInfoCountObject {@link AppInfoCountObject}
 * @param appInfoItem {@link AppInfoItem}
 * @param alreadAddedAppIndex {number} - すでに入っている使用されたアプリのindex
 *
 * @returns
 * @see {@link AppInfoCountObject}
 */
const _addNewAppDetailInfoItem = (
  appInfoCountObject: AppInfoCountObject,
  appInfoItem: AppInfoItem,
  alreadAddedAppIndex: number
): AppInfoCountObject => {
  // 使用されたアプリの配列に使用アプリのカウント情報をまとめたオブジェクトを追加して、返す
  const newDetailItem: AppInfoDetailSumData =
    _generateNewAppDetailInfoItem(appInfoItem);
  (
    appInfoCountObject.appSum[alreadAddedAppIndex]
      .detail as AppInfoDetailSumData[]
  ).push(newDetailItem);
  return appInfoCountObject;
};

/**
 * @remarks
 * 使用されたアプリの情報がすでに入っている場合、そのindexを取得する
 *
 * @param appInfoCountObject {@link AppInfoCountObject}
 * @param appInfoItem {@link AppInfoItem}
 *
 * @returns
 * 使用されたアプリの配列の中で見つかった特定の名前のアプリのindex
 */
const _findAlreadAddedAppIndex = (
  appInfoCountObject: AppInfoCountObject,
  appInfoItem: AppInfoItem
): number => {
  // 使用されたアプリの配列から指定したアプリの名前が見つかったら、そのindexを返す
  return appInfoCountObject.appSum.findIndex(
    (appSumItem: AppInfoSumData) => appSumItem.appName === appInfoItem.appName
  );
};

/**
 * @remarks
 * 使用されたアプリの詳細情報がすでに入っている場合、そのindexを取得する
 *
 * @param appInfoCountObject {@link AppInfoCountObject}
 * @param appInfoItem {@link AppInfoItem}
 * @param alreadAddedAppIndex {number} - すでに入っている使用されたアプリのindex
 *
 * @returns
 * 使用されたアプリの配列の中で見つかった特定のアプリの詳細情報のindex
 */
const _findAlreadAddedDetailIndex = (
  appInfoCountObject: AppInfoCountObject,
  appInfoItem: AppInfoItem,
  alreadAddedAppIndex: number
): number => {
  // 使用されたアプリの配列から指定したアプリの詳細情報が見つかったら、そのindexを返す
  return appInfoCountObject.appSum[alreadAddedAppIndex].detail.findIndex(
    (appDetailItem: AppInfoDetailSumData) =>
      appDetailItem.useDetail === appInfoItem.appUseDetail
  );
};

/**
 * @remarks
 * 合計したアプリの情報の中で最もよく使ったアプリの情報のindexを指定する
 *
 * @param appSumResult {@link AppInfoCountObject} {@link InitAppInfoCountObject}
 *
 * @returns
 * 使用時間が一番長いアプリのindex
 */
const _findMaxUsedTimeIndex = (
  appSumResult: AppInfoCountObject | InitAppInfoCountObject
): number => {
  // 合計したアプリの情報の使用した時間を配列に格納
  const totalUsedTimeByAppArray = appSumResult.appSum.map(
    (appInfoSumadata: AppInfoSumData) => {
      return appInfoSumadata.useTime;
    }
  );
  // 使用時間が一番長い値を見つける
  const maxUsedTimeValue = totalUsedTimeByAppArray.reduce(
    (maxUsedTime: number, usedTime: number) => {
      return Math.max(maxUsedTime, usedTime);
    }
  );
  // 見つけた値のindexを返す
  return totalUsedTimeByAppArray.indexOf(maxUsedTimeValue);
};

/**
 * @remarks
 * 指定された範囲のアプリ使用状況のデータを各パラメータごとに集計する
 *
 * @param CountAppInfoData - {@link CountAppInfoData}
 *  @param id {number | string} id - 時間帯（24時間帯：number、12時間帯以下：string）
 *  @param data {MultipeAppInfo} data - {@link MultipeAppInfo}
 *
 * @returns
 * @see [DummyData](src/data/example/countAppInfoDataByTimeZone.json)
 */
const _countAppInfoDataByTimeZone = ({
  id,
  detaildata,
}: CountAppInfoData): AppInfoCountObject | InitAppInfoCountObject => {
  let countData: AppInfoCountObject | InitAppInfoCountObject = {
    id: id,
    mostUsedApp: { isUsedApp: false, mostUsedAppIndex: 0 },
    appSum: [],
  };

  const appSumResult: AppInfoCountObject | InitAppInfoCountObject = detaildata.reduce(
    (
      appInfoCountObject: AppInfoCountObject | InitAppInfoCountObject,
      appInfoAtOneMinute: AppInfoAtOneMinute
    ) => {
      // 1分単位のデータのアプリ情報が空だった場合
      // 各パラメータごとに集計するオブジェクトをそのまま返す
      if (!appInfoAtOneMinute.appInfo.length) return appInfoCountObject;

      // 1分単位のデータのアプリ情報にデータがある場合
      appInfoAtOneMinute.appInfo.map((appInfoItem: AppInfoItem): any => {
        // 使用されたアプリの情報がすでに入っている場合、そのindexを取得する
        const alreadAddedAppIndex = _findAlreadAddedAppIndex(
          appInfoCountObject,
          appInfoItem
        );

        // appSumにオブジェクトが何も入っていない場合
        // 使用されたアプリが一度もカウントされていない場合
        if (!appInfoCountObject.appSum.length || alreadAddedAppIndex < 0) {
          return _addNewAppInfoItem(appInfoCountObject, appInfoItem);
        }

        // 使用されたアプリがカウントされているが、詳細情報がない場合
        if (!appInfoCountObject.appSum[alreadAddedAppIndex].detail.length) {
          return _addNewAppDetailInfoItem(
            appInfoCountObject,
            appInfoItem,
            alreadAddedAppIndex
          );
        }

        // 使用されたアプリがカウントされていて、何かしらの詳細情報がある場合
        // そのアプリで作業した時間をアプリの総使用時間に追加する
        appInfoCountObject.appSum[alreadAddedAppIndex].useTime +=
          appInfoItem.useTime;

        // 使用されたアプリの詳細情報がすでに入っている場合、そのindexを取得する
        const alreadAddedDetailIndex = _findAlreadAddedDetailIndex(
          appInfoCountObject,
          appInfoItem,
          alreadAddedAppIndex
        );

        // 詳細情報の中に該当する作業ファイル名・タブ名が存在しない場合
        if (alreadAddedDetailIndex < 0) {
          return _addNewAppDetailInfoItem(
            appInfoCountObject,
            appInfoItem,
            alreadAddedAppIndex
          );
        }

        // 詳細情報の中に該当する作業ファイル名・タブ名が存在する場合
        return (appInfoCountObject.appSum[alreadAddedAppIndex].detail[
          alreadAddedDetailIndex
        ].useTime += appInfoItem.useTime);
      });

      return appInfoCountObject;
    },
    countData
  );

  // 合計したアプリの情報が1つ以上ある場合
  if (appSumResult.appSum.length > 0) {
    // 合計したアプリの情報の中で最もよく使ったアプリの情報のindexを指定する
    appSumResult.mostUsedApp = {
      isUsedApp: true,
      mostUsedAppIndex: _findMaxUsedTimeIndex(appSumResult),
    };
  }

  return appSumResult;
};

/**
 * @remarks
 * 24時間帯より詳細なアプリ使用状況のデータを1次元配列にカウントして返す
 *
 * @param result
 *
 * @returns
 * AppInfoAtOneMinute[][]
 */
const _generateUnder24HourAppInfoByAppInfoAtOneMinutesData = (
  result: AppInfoAtOneMinute[][]
): (AppInfoCountObject | InitAppInfoCountObject)[] => {
  return result.map(
    (appInfoAtOneMinutesData: AppInfoAtOneMinute[], index: number) => {
      const id = appInfoAtOneMinutesData[0].time;
      return _countAppInfoDataByTimeZone({ id, detaildata: appInfoAtOneMinutesData });
    }
  );
};

/**
 * @remarks
 * 24時間帯のアプリ使用状況のデータを生成する
 *
 * @param appInfoData @see [DummyData](src/data/dummy1/allPushWithIndexAppInfoData.json)
 *
 * @returns
 * @see [DummyData](src/data/example/generate24HourAppInfo.json)
 */
const generate24HourAppInfo = (
  appInfoData: AppInfoAt24Hours
): (AppInfoCountObject | InitAppInfoCountObject)[] => {
  const data: (AppInfoCountObject | InitAppInfoCountObject)[] = appInfoData.map(
    (oneHourValue: AppInfoAtOneHour, index: number) => {
      return _countAppInfoDataByTimeZone({
        id: oneHourValue.time_range,
        detaildata: oneHourValue.detaildata,
      });
    }
  );
  return data;
};

/**
 * @remarks
 * 12時間帯のアプリ使用状況のデータを生成する
 *
 * @param GenerateAppInfoDataProps - {@link GenerateAppInfoDataProps}
 *  @param activeTimeZone {string | number} - 時間帯（24時間帯：number、12時間帯以下：string）
 *  @param appInfoData @see [DummyData](src/data/dummy1/allPushWithIndexAppInfoData.json)
 *
 * @returns
 * @see [DummyData](src/data/example/generate12HourAppInfo.json)
 */
const generate12HourAppInfo = ({
  activeTimeZone,
  appInfoData,
}: GenerateAppInfoDataProps):
  | (AppInfoCountObject | InitAppInfoCountObject)[]
  | undefined => {
  // mouseHoveredTimeZone;
  // activeTimeZone: 0~24のうちのどれか

  if (!activeTimeZone) return undefined;

  const result = generate12HourData<
    HoveredTimeZoneItemPosition,
    AppInfoAt24Hours,
    AppInfoAtOneMinute
  >(activeTimeZone, appInfoData);

  if (!result) return undefined;

  return _generateUnder24HourAppInfoByAppInfoAtOneMinutesData(result);
};

/**
 * @remarks
 * 12時間帯より詳細なアプリ使用状況のデータを生成する
 * 6時間帯、3時間帯、1時間帯、30分帯
 *
 * @param GenerateUnder12HourAppInfoDataProps - {@link GenerateUnder12HourAppInfoDataProps}
 *  @param activeTimeZone {string | number} - 時間帯（24時間帯：number、12時間帯以下：string）
 *  @param appInfoData @see [DummyData](src/data/dummy1/allPushWithIndexAppInfoData.json)
 *  @param timeZoneIndex {number} - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *
 * @returns
 * @see [DummyData](src/data/example/generateUnder12HourAppInfo6HResult.json)
 * @see [DummyData](src/data/example/generateUnder12HourAppInfo3HResult.json)
 * @see [DummyData](src/data/example/generateUnder12HourAppInfo1HResult.json)
 * @see [DummyData](src/data/example/generateUnder12HourAppInfo30mResult.json)
 *
 */
const generateUnder12HourAppInfo = ({
  activeTimeZone,
  appInfoData,
  timeZoneIndex,
}: GenerateUnder12HourAppInfoDataProps):
  | (AppInfoCountObject | InitAppInfoCountObject)[]
  | undefined => {
  // mouseHoveredTimeZone;
  // activeTimeZone: 0:00~24:00のうち、15分きざみのどれか

  if (!activeTimeZone) return undefined;

  
  const result = generateUnder12HourData<
    HoveredTimeZoneItemPosition,
    AppInfoAt24Hours,
    number,
    AppInfoAtOneMinute
  >(activeTimeZone, appInfoData, timeZoneIndex);

  if (!result) return undefined;

  return _generateUnder24HourAppInfoByAppInfoAtOneMinutesData(result);
};

export {
  generate24HourAppInfo,
  generate12HourAppInfo,
  generateUnder12HourAppInfo,
};

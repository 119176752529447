import {
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { useUpdateTeam } from "../../../../lib/hooks/ManagementSettings/useUpdateTeam";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonModal } from "../../CommonModal";
import { ButtonSubmit } from "../Button/ButtonSubmit";
import { TextHeading } from "../Typography/TextHeading";

type UpdateTeamModalProps = {
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onOk?: () => void;
};

export const UpdateTeamModal: FC<UpdateTeamModalProps> = (props) => {
  const { isOpen, title, onClose, onOk } = props;
  const { errors, handleSubmit, onSubmit, register, closeModal } =
    useUpdateTeam({
      onClose,
      onOk,
    });
  const { isLoading } = useAppSelector((state) => state.loading);

  return (
    <CommonModal title={title} isOpen={isOpen} onClose={closeModal} isCentered>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FormControl isInvalid={!!errors.teamname}>
          <FormLabel mb="10px">
            <TextHeading>チーム名を設定してください</TextHeading>
          </FormLabel>
          <Input
            variant="custom"
            border="2px solid #D5D5D5"
            borderRadius="10px"
            disabled={isLoading}
            {...register("teamname")}
          />
          <FormErrorMessage fontSize="14px">
            {errors.teamname && errors.teamname.message}
          </FormErrorMessage>
        </FormControl>
        <Center pt="30px" pb="20px">
          <ButtonSubmit type="submit" isLoading={isLoading}>
            確定
          </ButtonSubmit>
        </Center>
      </form>
    </CommonModal>
  );
};

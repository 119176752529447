const ranges = [
  { start: 54, end: 59, value: 0.9 },
  { start: 48, end: 53, value: 0.8 },
  { start: 42, end: 47, value: 0.7 },
  { start: 36, end: 41, value: 0.6 },
  { start: 30, end: 35, value: 0.5 },
  { start: 24, end: 29, value: 0.4 },
  { start: 18, end: 23, value: 0.3 },
  { start: 12, end: 17, value: 0.2 },
  { start: 1, end: 11, value: 0.1 },
];

export default ranges;

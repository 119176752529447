import CryptoJS from "crypto-js";
import { BASE_AVATAR_URL } from "../../configs/env";
import { EMPTY_STRING } from "../Const";

export const getImageUrl = (pic: string) => {
  if (pic) {
    return BASE_AVATAR_URL + pic;
  }
  return EMPTY_STRING;
};

export const getName = (strArr: string[]) => {
  return strArr.join("");
};

export const getUserURL = ({
  adcompanyid,
  adid,
  dateSelected,
  memberid,
  membername,
}: {
  adcompanyid: string;
  adid: string;
  dateSelected: string;
  memberid: string;
  membername: string;
}) => {
  return `${process.env.REACT_APP_LINK_USER_INFO}?adcompanyid=${adcompanyid}&adid=${adid}&addate=${dateSelected}&admemberid=${memberid}&membername=${membername}`;
};

export const generatePassword = () => {
  const length = 8;
  const characters =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let password = "";
  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    password += characters[randomIndex];
  }
  return password;
};

export const sortByJapanese = (a: string, b: string): number => {
  const isJapanese = (word: string): boolean => {
    const japaneseRegex = /[\u3040-\u309F\u30A0-\u30FF\u4E00-\u9FAF]/;
    return japaneseRegex.test(word);
  };

  if (!a && !b) return 0;
  if (!a) return 1;
  if (!b) return -1;

  if (isJapanese(a) && !isJapanese(b)) {
    return -1;
  } else if (!isJapanese(a) && isJapanese(b)) {
    return 1;
  } else {
    return a.localeCompare(b, "ja");
  }
};

export const generateHashId = (email: string) => {
  const currentTime = Date.now().toString();
  const combinedData = email + currentTime;

  const hash = CryptoJS.SHA256(combinedData);

  return hash.toString(CryptoJS.enc.Hex);
};

export enum EAlertTiming {
  MEET_CONDITION = 0,
  NEXT_DAY = 1,
}

export enum EEmailNotification {
  ON = 1,
  OFF = 2,
}

export enum ETargetNotification {
  ALL = 0,
  MEMBER = 1,
}

export enum EAlertType {
  TYPE_1 = 1,
  TYPE_2 = 2,
  TYPE_3 = 3,
}

import { Box, Stack } from "@chakra-ui/layout";
import { useTeamManagement } from "../../../lib/hooks/ManagementSettings/useTeamManagement";
import { ButtonPrimary } from "./Button/ButtonPrimary";
import { CreateTeamModalModal } from "./Modal/CreateTeamModal";
import { TableTeam } from "./Table/TableTeam";

export const TeamManagement = () => {
  const { modalName, openModal, closeModal } = useTeamManagement();

  return (
    <Box borderRadius="10px" background="white" p="20px">
      <Stack gap="10px">
        <Box>
          <ButtonPrimary onClick={() => openModal("CREATE_TEAM")}>
            チームを追加
          </ButtonPrimary>
        </Box>
        <TableTeam />
      </Stack>

      {/* Modals */}
      <CreateTeamModalModal
        title="チームの作成"
        isOpen={modalName === "CREATE_TEAM"}
        onClose={closeModal}
      />
    </Box>
  );
};

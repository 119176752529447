import { TableContainer } from "@chakra-ui/table";
import { FC, Fragment } from "react";
import { useTableTeam } from "../../../../lib/hooks/ManagementSettings/useTableTeam";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonTable } from "../../CommonTable";
import { DeleteModal } from "../../DeleteModal";
import { AddMemberModal } from "../Modal/AddMemberModal";
import { TeamInfoModal } from "../Modal/TeamInfoModal";
import { UpdateTeamModal } from "../Modal/UpdateTeamModal";

export const TableTeam: FC = () => {
  const { columns, modalName, closeModal, deleteTeam } = useTableTeam();
  const { teamList, team } = useAppSelector((state) => state.team);

  // reset teamToUpdate and close modal
  const onCloseModal = () => {
    closeModal();
  };

  return (
    <Fragment>
      <TableContainer>
        <CommonTable columns={columns} data={teamList} />
      </TableContainer>

      {/* Modal */}
      <TeamInfoModal
        title="チーム情報"
        isOpen={modalName === "TEAM_INFO"}
        onClose={closeModal}
      />

      <AddMemberModal
        type="TEAM_LIST"
        title="メンバーを追加"
        isOpen={modalName === "ADD_MEMBER"}
        onClose={closeModal}
      />

      <UpdateTeamModal
        title="チーム名の変更"
        isOpen={modalName === "UPDATE_TEAM"}
        onClose={onCloseModal}
      />

      <DeleteModal
        isOpen={modalName === "DELETE_TEAM" && !!team}
        message="このチームを削除しますか？"
        onOk={deleteTeam}
        onClose={closeModal}
      />
    </Fragment>
  );
};

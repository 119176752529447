import { Avatar, Box, Stack } from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { useAccountSettings } from "../../../lib/hooks/ManagementSettings/useAccountSettings";
import { getImageUrl, getName } from "../../../lib/util/helper";
import { useAppSelector } from "../../../redux/storeConfig";
import { ButtonLink } from "./Button/ButtonLink";
import { ChangeAdminProfileModal } from "./Modal/ChangeAdminProfileModal";
import { ChangeAvatarModal } from "./Modal/ChangeAvatarModal";
import { ChangeEmailAddressModal } from "./Modal/ChangeEmailAddressModal";
import { ChangePasswordModal } from "./Modal/ChangePasswordModal";
import { TextHeading } from "./Typography/TextHeading";
import { TextSecond } from "./Typography/TextSecond";
import { EMPTY_STRING } from "../../../lib/Const";

export const AccountSettings: FC = () => {
  const { admin: user } = useAppSelector((state) => state.userInfo);
  const { modalName, openModal, closeModal, logout } = useAccountSettings();

  return (
    <Fragment>
      <Stack direction="column" spacing="20px">
        {/* Avatar */}
        <Box bgColor="white" borderRadius="10px" px="20px" pb="20px" pt="18px">
          <Stack direction="column" align="start" spacing={0}>
            <TextHeading mb="10px">プロフィール画像</TextHeading>
            {/* <Box mb="12px"> */}
            <Box>
              <Avatar
                src={getImageUrl(user?.gtaduserpic || EMPTY_STRING)}
                w={70}
                h={70}
                display="block"
              />
            </Box>
            {/* <ButtonLink onClick={() => openModal("CHANGE_AVATAR")}>
                変更する
              </ButtonLink> */}
          </Stack>
        </Box>

        {/* Personal information */}
        <Box bgColor="white" borderRadius="10px" px="20px" pb="20px" pt="18px">
          <Stack direction="column" align="start" spacing="20px">
            <Box>
              <TextHeading mb="4px">ユーザー名</TextHeading>
              <TextSecond>
                {getName([
                  user?.gtaduserlastname || EMPTY_STRING,
                  user?.gtaduserfirstname || EMPTY_STRING,
                ])}
              </TextSecond>
            </Box>
            <Box>
              <TextHeading mb="4px">職種</TextHeading>
              <TextSecond>{user?.gtadoccupation}</TextSecond>
            </Box>
            <Box>
              <TextHeading mb="4px">肩書</TextHeading>
              <TextSecond>{user?.gtadtitle}</TextSecond>
            </Box>
            <Box>
              <TextHeading mb="4px">契約形態</TextHeading>
              <TextSecond>{user?.gtadcontract}</TextSecond>
            </Box>
            <Box>
              <TextHeading mb="4px">勤務地</TextHeading>
              <TextSecond>{user?.gtadlocation}</TextSecond>
            </Box>
            <ButtonLink onClick={() => openModal("CHANGE_PROFILE")}>
              変更する
            </ButtonLink>
          </Stack>
        </Box>

        {/* Email */}
        <Box bgColor="white" borderRadius="10px" p="20px">
          <Stack direction="column" align="start" spacing={0}>
            <TextHeading mb="4px">メールアドレス</TextHeading>
            <Box>
              <TextSecond>{user?.gtaduserid}</TextSecond>
            </Box>
            {/* TODO: Uncomment when API is available */}
            {/* <Box mb="20px">
              <TextSecond>{user?.[0]?.gtaduserid}</TextSecond>
            </Box>
            <ButtonLink onClick={() => openModal("CHANGE_EMAIL")}>
              変更する
            </ButtonLink> */}
          </Stack>
        </Box>

        {/* Change password */}
        <Box bgColor="white" borderRadius="10px" p="20px">
          <Box>
            <TextHeading mb="10px">パスワード</TextHeading>
            <ButtonLink onClick={() => openModal("CHANGE_PASSWORD")}>
              変更する
            </ButtonLink>
          </Box>
        </Box>

        {/* Logout */}
        <Box bgColor="white" borderRadius="10px" px="20px" pt="25px" pb="24px">
          <ButtonLink danger onClick={logout}>
            ログアウト
          </ButtonLink>
        </Box>
      </Stack>

      {/* Modal */}
      <ChangeAvatarModal
        title="プロフィール画像の変更"
        isOpen={modalName === "CHANGE_AVATAR"}
        onClose={closeModal}
      />

      {user && (
        <ChangeAdminProfileModal
          title="メンバー情報"
          isOpen={modalName === "CHANGE_PROFILE"}
          onClose={closeModal}
        />
      )}

      <ChangeEmailAddressModal
        title="メールアドレスの変更"
        isOpen={modalName === "CHANGE_EMAIL"}
        onClose={closeModal}
      />

      <ChangePasswordModal
        title="パスワードの変更"
        isOpen={modalName === "CHANGE_PASSWORD"}
        onClose={closeModal}
      />
    </Fragment>
  );
};

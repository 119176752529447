import { AuthorityFrame } from "../../@types/User";
import { Authority } from "../../enums/authority";
import { EMPTY_STRING } from "../Const";

export const renderAuthority = (
  authorityList: AuthorityFrame[],
  authority: Authority
) => {
  return (
    authorityList.find((item) => item.gtauthorityid === authority)
      ?.gtauthorityname || EMPTY_STRING
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ChangeUserPassword } from "../../../@types/User";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import { changeUserPasswordThunk } from "../../../redux/thunk/userThunk";
import { EMPTY_STRING } from "../../Const";
import {
  PASSWORD_DO_NOT_MATCH,
  PASSWORD_VALIDATION,
  REQUIRED_FIELD,
} from "../../util/messages";
import { PASSWORD_REGEX } from "../../util/regex";
import { useMessage } from "../useMessage";

type UseChangePasswordType = {
  onClose: () => void;
};

const schema = z
  .object({
    password: z
      .string()
      .min(1, REQUIRED_FIELD)
      .regex(PASSWORD_REGEX, PASSWORD_VALIDATION),
    confirmpassword: z
      .string()
      .min(1, REQUIRED_FIELD)
      .regex(PASSWORD_REGEX, PASSWORD_VALIDATION),
  })
  .refine((data) => data.password === data.confirmpassword, {
    message: PASSWORD_DO_NOT_MATCH,
    path: ["confirmpassword"],
  });

type ChangeUserPasswordForm = z.infer<typeof schema>;

export const useChangePassword = (props: UseChangePasswordType) => {
  const { onClose } = props;
  const dispatch = useAppDispatch();
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);
  const { showMessage } = useMessage();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ChangeUserPasswordForm>({ resolver: zodResolver(schema) });

  const onCloseModal = () => {
    onClose();
    reset();
  };

  const onSubmit = async (values: ChangeUserPasswordForm) => {
    if (adcompanyid && aduserid) {
      const payload: ChangeUserPassword = {
        upadcompanyid: adcompanyid,
        upaduserid: aduserid,
        upaduserpassword: values.password,
      };
      dispatch(
        changeUserPasswordThunk({
          payload,
          onSuccess: (message?: string) => {
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
            onCloseModal();
          },
        })
      );
    }
  };

  return {
    errors,
    isSubmitting,
    handleSubmit,
    register,
    reset,
    onSubmit,
    onCloseModal,
  };
};

import {
  Box,
  Button,
  Flex,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { Fragment, ReactNode } from "react";
import buttonNext from "../../images/icons/button_next.svg";

export type TUsePagination = {
  total: number;
  pageNumber: number;
  pageSize?: number;
  siblingCount?: number;
  onPageNumberChange: (pageNumber: number) => void;
  onPageSizeChange?: (pageSize: number) => void;
};

export function Pagination(props: TUsePagination) {
  const {
    total = 0,
    pageSize = 10,
    pageNumber = 1,
    siblingCount = 1,
    onPageNumberChange,
    onPageSizeChange,
  } = props;

  const ItemPage = ({
    value,
    index,
    selected = false,
    canClick = true,
  }: {
    value: string | number | ReactNode;
    index: number;
    selected?: boolean;
    canClick?: boolean;
  }) => {
    return (
      <Box
        cursor="pointer"
        fontSize="16"
        border={selected ? "1px solid #E4E4E7" : "unset"}
        paddingInline="10px"
        paddingBlock="1px"
        _hover={{
          background: "#f4f4f5",
        }}
        onClick={() => {
          if (
            index?.toString() &&
            !isNaN(Number(index)) &&
            pageNumber !== Number(index) + 1 &&
            canClick
          ) {
            onPageNumberChange(Number(index) + 1);
          }
        }}
      >
        {value}
      </Box>
    );
  };

  return (
    <Flex align="center" justify="center" position="relative">
      <Flex
        flex="1"
        align="center"
        justify="center"
        gap="6px"
        className="pagination-wrapper"
      >
        {pageNumber > 1 ? (
          <ItemPage
            index={pageNumber - 2}
            value={
              <Image
                src={buttonNext}
                alt="icon-back"
                width="8px"
                transform="rotate(180deg)"
              />
            }
          />
        ) : null}
        {total
          ? Array(Math.ceil(total / pageSize))
              .fill(null)
              .map((_, index) => {
                const currentPageNumber = index + 1;
                if (
                  index && // border bottom
                  currentPageNumber < Math.ceil(total / pageSize) && // border top
                  Math.abs(pageNumber - currentPageNumber) >= siblingCount + 1
                ) {
                  return null;
                }
                if (Math.abs(pageNumber - currentPageNumber) === siblingCount) {
                  return (
                    <Fragment key={index}>
                      {currentPageNumber - 1 > 1 &&
                      currentPageNumber >= siblingCount &&
                      pageNumber - currentPageNumber === siblingCount ? (
                        <ItemPage
                          index={index}
                          value={"..."}
                          canClick={false}
                        />
                      ) : null}
                      <ItemPage index={index} value={currentPageNumber} />
                      {currentPageNumber + 1 < Math.ceil(total / pageSize) &&
                      currentPageNumber <=
                        Math.ceil(total / pageSize) - (siblingCount - 1) &&
                      currentPageNumber - pageNumber === siblingCount ? (
                        <ItemPage
                          index={index}
                          value={"..."}
                          canClick={false}
                        />
                      ) : null}
                    </Fragment>
                  );
                }
                return (
                  <ItemPage
                    key={index}
                    selected={
                      currentPageNumber?.toString() === pageNumber?.toString()
                    }
                    index={index}
                    value={index + 1}
                  />
                );
              })
          : null}
        {pageNumber < Math.ceil(total / pageSize) ? (
          <ItemPage
            index={pageNumber}
            value={<Image src={buttonNext} alt="icon-next" width="8px" />}
          />
        ) : null}
      </Flex>
      <Menu>
        <MenuButton
          as={Button}
          rightIcon={
            <Image
              src={buttonNext}
              alt="icon-select"
              width="8px"
              transform="rotate(90deg)"
            />
          }
          borderRadius="10px"
          height="34px"
          textAlign="justify"
          background="white !important"
          border="1px solid #B1B1B1"
          position="absolute"
          right="0"
        >
          {`${pageSize} 項目 / ページ`}
        </MenuButton>
        <MenuList
          borderRadius="10px"
          boxShadow="md"
          border="1px solid gray"
          maxW="fit-content"
          minW="fit-content"
        >
          {[10, 20, 30, 50].map((optionValue) => (
            <MenuItem
              key={optionValue}
              onClick={() => {
                onPageSizeChange?.(optionValue);
              }}
            >
              {`${optionValue} 項目 / ページ`}
            </MenuItem>
          ))}
        </MenuList>
      </Menu>
    </Flex>
  );
}

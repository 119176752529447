import { TableContainer } from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { useTableTeamInfo } from "../../../../lib/hooks/ManagementSettings/useTableTeamInfo";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonTable } from "../../CommonTable";
import { DeleteModal } from "../../DeleteModal";
import { ChangeUserProfileModal } from "../Modal/ChangeUserProfileModal";

export const TableTeamInfo: FC<{ onOk?: () => void }> = ({ onOk }) => {
  const { columns, modalName, onCloseModal, deleteMember } = useTableTeamInfo({
    onOk,
  });
  const { member } = useAppSelector((state) => state.member);
  const { teamUserData, team } = useAppSelector((state) => state.team);

  return (
    <Fragment>
      <TableContainer>
        <CommonTable
          columns={columns}
          data={teamUserData?.gtteamuserframe || []}
          showHeader={false}
        />
      </TableContainer>

      {/* Modal */}
      <ChangeUserProfileModal
        user={member}
        title="メンバー情報"
        isOpen={modalName === "CHANGE_PROFILE"}
        onClose={onCloseModal}
        onOk={onOk}
      />

      <DeleteModal
        isOpen={modalName === "DELETE_MEMBER" && !!team}
        message="チームからメンバーを外しますか？"
        onOk={deleteMember}
        onClose={onCloseModal}
      />
    </Fragment>
  );
};

import { Box, Flex, Grid, Text } from "@chakra-ui/react";
import React from "react";

import "../../../styles/Calender.css";
import "../../../styles/discovery.css";
import statusTitle from '../../../lib/statusTitle';

const MemberStatus: React.FC<{ member: TeamMember }> = ({ member }) => {
  /**
   * The function `timeStringToMinutes` converts a time string in the format "HH:MM" to the total number
   * of minutes.
   * @param {string} timeString - A string representing a time in the format "HH:MM", where HH represents
   * the hours and MM represents the minutes.
   * @returns The function `timeStringToMinutes` returns the total number of minutes represented by the
   * `timeString`.
   */
  const timeStringToMinutes = (timeString: string) => {
    const [hours, minutes] = timeString.split(":")?.map(Number);
    return hours * 60 + minutes;
  };

  /**
   * The function "compareTime" compares two time values and returns the difference in minutes, with a
   * "+" sign if the first value is greater.
   * @param {string} weekAVGData - A string representing the average time for a week.
   * @param {string} dayData - The `dayData` parameter is a string representing the time data for a
   * single day.
   * @returns the difference between the weekAVGData and dayData in minutes. If the result is greater
   * than 0, it returns a string with a "+" sign followed by the result. Otherwise, it returns the result
   * as is.
   */
  const compareTime = (
    weekAVGData: string | undefined,
    dayData: string | undefined
  ) => {
    // Check if either weekAVGData or dayData is undefined
    if (weekAVGData === undefined || dayData === undefined) {
      return NaN;
    }

    var result =
      timeStringToMinutes(weekAVGData) - timeStringToMinutes(dayData);
    if (result > 0) {
      return `+${result}`;
    }
    return result;
  };

  const noData: string = "NaN";

  return (
    <>
      <Box className="member-status" mt="0.9375rem" px="0.3125rem">
        {member && member.memberdata.length > 0 && (
          <Grid gap="0" className="grid-container-member">
            <Box
              className="box-border-right"
              borderRight="0.0625rem #d8d8d8 solid"
              h="3.5625rem"
            >
              <Text className="status-title">{statusTitle[0]}</Text>
              <Flex>
                <Text className="status-data">
                  {member.memberdata[0]?.pcactive
                    ? member.memberdata[0].pcactive
                    : noData}
                </Text>
                <Text className="status-addition-info" my="auto" ml="0.25rem">
                  週平均
                  {compareTime(
                    member.memberdata[0]?.weekavgpcactive,
                    member.memberdata[0]?.pcactive
                  )}
                  分
                </Text>
              </Flex>
            </Box>
            <Box h="3.5625rem">
              <Text className="status-title">{statusTitle[1]}</Text>
              <Flex>
                <Text className="status-data" color="#FA9E00">
                  {member.memberdata[0]?.worktime
                    ? member.memberdata[0].worktime
                    : noData}
                </Text>
                <Text className="status-addition-info" my="auto" ml="0.25rem">
                  週平均
                  {compareTime(
                    member.memberdata[0]?.weekavgworktime,
                    member.memberdata[0]?.worktime
                  )}
                  分
                </Text>
              </Flex>
            </Box>
            <Box h="3.5625rem">
              <Text className="status-title">{statusTitle[2]}</Text>
              <Flex>
                <Text className="status-data" color="#8FCF00">
                  {member.memberdata[0]?.webmeeting
                    ? member.memberdata[0].webmeeting
                    : noData}
                </Text>
                <Text className="status-addition-info" my="auto" ml="0.25rem">
                  週平均
                  {compareTime(
                    member.memberdata[0]?.weekavgwebmeeting,
                    member.memberdata[0]?.webmeeting
                  )}
                  分
                </Text>
              </Flex>
            </Box>
            <Box h="3.5625rem">
              <Text className="status-title">{statusTitle[3]}</Text>
              <Flex>
                <Text className="status-data" color="#5B5FFF">
                  {member.memberdata[0]?.websearch
                    ? member.memberdata[0].websearch
                    : noData}
                </Text>
                <Text className="status-addition-info" my="auto" ml="0.25rem">
                  週平均
                  {compareTime(
                    member.memberdata[0]?.weekavgwebsearch,
                    member.memberdata[0]?.websearch
                  )}
                  分
                </Text>
              </Flex>
            </Box>
            <Box
              className="box-border-right"
              borderRight="0.0625rem #d8d8d8 solid"
              height="3.5625rem"
            >
              <Text className="status-title">{statusTitle[4]}</Text>
              <Flex>
                <Text className="status-data" color="#02B3FF">
                  {member.memberdata[0]?.plan
                    ? member.memberdata[0].plan
                    : noData}
                </Text>
                <Text className="status-addition-info" my="auto" ml="0.25rem">
                  週平均
                  {compareTime(
                    member.memberdata[0]?.weekavgplan,
                    member.memberdata[0]?.plan
                  )}
                  分
                </Text>
              </Flex>
            </Box>
            <Box h="3.5625rem">
              <Text className="status-title">{statusTitle[5]}</Text>
              <Flex>
                <Text className="status-data" color="#2C2C2C">
                  {member.memberdata[0]?.todayovertime
                    ? member.memberdata[0].todayovertime
                    : noData}
                </Text>
                <Text className="status-addition-info" my="auto" ml="0.25rem">
                  週平均
                  {compareTime(
                    member.memberdata[0]?.weekavgtodayovertime,
                    member.memberdata[0]?.todayovertime
                  )}
                  分
                </Text>
              </Flex>
            </Box>

            <Box
              className="box-border-right"
              borderRight="0.0625rem #d8d8d8 solid"
              height="2.625rem"
            >
              <Text className="status-title">{statusTitle[6]}</Text>
              <Flex>
                <Text className="status-data" color="#2C2C2C">
                  {member.memberdata[0]?.pcnonactive
                    ? member.memberdata[0].pcnonactive
                    : noData}
                </Text>
                <Text className="status-addition-info" my="auto" ml="0.25rem">
                  週平均
                  {compareTime(
                    member.memberdata[0]?.weekavgpcnonactive,
                    member.memberdata[0]?.pcnonactive
                  )}
                  分
                </Text>
              </Flex>
            </Box>
            <Box h="3.5625rem">
              <Text className="status-title">{statusTitle[7]}</Text>
              <Flex>
                <Text className="status-data" color="#FF4B45">
                  {member.memberdata[0]?.mailchat
                    ? member.memberdata[0].mailchat
                    : noData}
                </Text>
                <Text className="status-addition-info" my="auto" ml="0.25rem">
                  週平均
                  {compareTime(
                    member.memberdata[0]?.weekavgmailchat,
                    member.memberdata[0]?.mailchat
                  )}
                  分
                </Text>
              </Flex>
            </Box>
            <Box h="3.5625rem">
              <Text className="status-title">{statusTitle[8]}</Text>
              <Flex>
                <Text className="status-data" color="#9439FF">
                  {member.memberdata[0]?.others
                    ? member.memberdata[0].others
                    : noData}
                </Text>
                <Text className="status-addition-info" my="auto" ml="0.25rem">
                  週平均
                  {compareTime(
                    member.memberdata[0]?.weekavgothers,
                    member.memberdata[0]?.others
                  )}
                  分
                </Text>
              </Flex>
            </Box>
            <Box height="2.625rem">
              <Text className="status-title">{statusTitle[9]}</Text>
              <Flex>
                <Text className="status-data" color="#666666">
                  {member.memberdata[0]?.secret
                    ? member.memberdata[0].secret
                    : noData}
                </Text>
                <Text className="status-addition-info" my="auto" ml="0.25rem">
                  週平均
                  {compareTime(
                    member.memberdata[0]?.weekavgsecret,
                    member.memberdata[0]?.secret
                  )}
                  分
                </Text>
              </Flex>
            </Box>
            <Box
              className="box-border-right"
              borderRight="0.0625rem #d8d8d8 solid"
              height="2.625rem"
            ></Box>
            <Box h="3.5625rem">
              <Text className="status-title">{statusTitle[10]}</Text>
              <Flex>
                <Text className="status-data" color="#2C2C2C">
                  {member.memberdata[0]?.monthovertime
                    ? member.memberdata[0].monthovertime
                    : noData}
                </Text>
                <Text className="status-addition-info" my="auto" ml="0.25rem">
                  今月の予測 : {member.memberdata[0]?.predictionovertime}
                  時間
                </Text>
              </Flex>
            </Box>
          </Grid>
        )}
      </Box>
    </>
  );
};

MemberStatus.displayName = "MemberStatus";
export default MemberStatus;

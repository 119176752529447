import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/table-core";
import { useCallback, useLayoutEffect, useState } from "react";
import { TfiMoreAlt } from "react-icons/tfi";
import { TAlertFrame } from "../../../@types/Alert";
import { TextTableItem } from "../../../components/parts/ManagementSettings/Typography/TextTableItem";
import {
  setAlertList,
  setSelectedAlertData,
} from "../../../redux/slice/alertSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  deleteAdminAlertDataThunk,
  getAdminAlertDataThunk,
} from "../../../redux/thunk/alertThunk";
import { EMPTY_STRING } from "../../Const";
import { useMessage } from "../useMessage";

type ModalName = "UPDATE_ALERT" | "DELETE_ALERT";

export const useTableAlert = () => {
  const dispatch = useAppDispatch();
  const { showMessage } = useMessage();
  const { adcompanyid } = useAppSelector((state) => state.userInfo);
  const { alertList, selectedAlertData } = useAppSelector(
    (state) => state.alert
  );
  const columnHelper = createColumnHelper<TAlertFrame>();
  const [modalName, setModalName] = useState<ModalName | null>(null);

  const columns = [
    columnHelper.accessor("gtalertname", {
      cell: (info) => {
        return (
          <Text color="#2C2C2C" fontSize="12px" fontWeight="bold">
            {info.getValue()}
          </Text>
        );
      },
      header: "アラート名",
      enableSorting: false,
    }),
    columnHelper.accessor("gtltalertcondition", {
      cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
      header: "条件",
      enableSorting: false,
    }),
    columnHelper.accessor("gtltalerttarget", {
      cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
      header: "アラート対象",
      enableSorting: false,
    }),
    columnHelper.accessor("gtltnotifymail", {
      cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
      header: "メール通知",
      enableSorting: false,
    }),
    columnHelper.accessor("gtltnotifytarget", {
      cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
      header: "通知対象",
      enableSorting: false,
    }),
    columnHelper.accessor("gtltnotifytime", {
      cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
      header: "通知時間",
      enableSorting: false,
    }),
    columnHelper.accessor("gtalertid", {
      cell: (info) => (
        <Menu variant="custom">
          <Flex align="center" justifyContent="right" w="100%">
            <MenuButton
              as={IconButton}
              variant="outline"
              size="sm"
              textAlign="center"
              aria-label="Search team"
              borderColor="transparent"
              w="28px"
              h="28px"
              icon={<TfiMoreAlt size={16} />}
            />
          </Flex>
          <MenuList minW="96px">
            <MenuItem
              onClick={() => onOpenModal("UPDATE_ALERT", info.row.original)}
            >
              編集する
            </MenuItem>
            <MenuItem
              color="#FF4B45"
              onClick={() => onOpenModal("DELETE_ALERT", info.row.original)}
            >
              削除する
            </MenuItem>
          </MenuList>
        </Menu>
      ),
      header: "",
      enableSorting: false,
    }),
  ];

  const onOpenModal = (name: ModalName, alertData: TAlertFrame) => {
    dispatch(setSelectedAlertData(alertData));
    setModalName(name);
  };

  const onCloseModal = useCallback(() => {
    dispatch(setSelectedAlertData(null));
    setModalName(null);
  }, [dispatch]);

  const onGetAdminAlertList = useCallback(() => {
    if (adcompanyid) {
      dispatch(
        getAdminAlertDataThunk({
          payload: {
            adcompanyid,
          },
        })
      );
    }
  }, [dispatch, adcompanyid]);

  const onDeleteAdminAlertData = useCallback(() => {
    if (selectedAlertData && adcompanyid) {
      dispatch(
        deleteAdminAlertDataThunk({
          payload: {
            upcrud: "D",
            uphashid: selectedAlertData.gtalerthashid,
          },
          onSuccess: (message) => {
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
            const newAlertList = alertList.filter(
              (item) => item.gtalerthashid !== selectedAlertData?.gtalerthashid
            );
            dispatch(setAlertList(newAlertList));
            onCloseModal();
          },
          onError: (message) => {
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  }, [
    adcompanyid,
    selectedAlertData,
    alertList,
    showMessage,
    dispatch,
    onCloseModal,
  ]);

  useLayoutEffect(() => {
    onGetAdminAlertList();
  }, [onGetAdminAlertList]);

  return {
    columns,
    modalName,
    onOpenModal,
    onCloseModal,
    onDeleteAdminAlertData,
  };
};

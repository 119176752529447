import {
  Box,
  Flex,
  FormControl,
  FormHelperText,
  Link,
  Stack,
} from "@chakra-ui/react";
import type { FC } from "react";
import pic from "../../images/logeep.png";
import { useAuth } from "../../lib/hooks/useAuth";
import { useAppSelector } from "../../redux/storeConfig";
import { FormComponent } from "../parts/LoginData/FormComponent";

const LoginData: FC = () => {
  const { isLoading } = useAppSelector((state) => state.loading);
  const { login } = useAuth();

  return (
    <Flex
      flexDirection="column"
      width="100wh"
      height="100vh"
      backgroundColor="whiteAlpha.900"
      justifyContent="center"
      alignItems="center"
    >
      <Stack
        flexDir="column"
        mb="2"
        justifyContent="center"
        alignItems="center"
      >
        <img src={pic} alt="Logeep logo" />
        <Box minW={{ base: "90%", md: "468px" }}>
          <FormComponent loading={isLoading} login={login} />
        </Box>
      </Stack>
      <FormControl>
        <FormHelperText textAlign="center">
          <Link>パスワードを忘れた場合</Link>
        </FormHelperText>
      </FormControl>
    </Flex>
  );
};
export default LoginData;

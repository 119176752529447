import { Box } from "@chakra-ui/layout";
import { TableContainer } from "@chakra-ui/react";
import { useApplicationRegistered } from "../../../lib/hooks/ManagementSettings/useApplicationRegistered";
import { CommonTable } from "../CommonTable";
import { ButtonPrimary } from "./Button/ButtonPrimary";
import { EditApplicationModal } from "./Modal/EditApplicationModal";
import { RemoveApplicationModal } from "./Modal/RemoveApplicationModal";
import { Pagination } from "components/pagination/Pagination";

export const ApplicationRegistered = () => {
  const { state, handler } = useApplicationRegistered();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="20px"
      css={{
        tr: {
          "th:nth-child(2)": {
            paddingLeft: "0px",
          },
          "td:nth-child(2)": {
            paddingLeft: "0px",
          },
          "td:nth-child(1), td:nth-child(2)": {
            maxWidth: "28%",
          },
          "td:nth-child(3), td:nth-child(4)": {
            maxWidth: "20%",
          },
          td: {
            overflow: "hidden",
            wordBreak: "break-word",
            whiteSpace: "break-spaces",

            "&>div": {
              height: "auto",
            },
          },
        },
      }}
    >
      <ButtonPrimary
        width="fit-content"
        onClick={() => {
          handler?.openModalEdit();
        }}
        _disabled={{
          background: "#D6D6D6",
          cursor: "default",
          _hover: { background: "#D6D6D6" },
        }}
      >
        アプリケーションを追加
      </ButtonPrimary>
      {state?.applicationList?.length ? (
        <TableContainer>
          <CommonTable columns={state?.columns} data={state?.applicationList} />
        </TableContainer>
      ) : null}
      {state?.applicationList?.length ? (
        <Pagination
          total={state?.total}
          pageNumber={state?.pageNumber}
          pageSize={state?.pageSize}
          siblingCount={1}
          onPageNumberChange={handler?.onPageNumberChange}
          onPageSizeChange={handler?.onPageSizeChange}
        />
      ) : null}
      {state?.modalName === "EDIT_APPLICATION" ? (
        <EditApplicationModal
          applicationSelected={state?.applicationSelected}
          isOpen={state?.modalName === "EDIT_APPLICATION"}
          isRegistered={true}
          title={"アプリケーション情報"}
          onClose={handler?.onCloseModal}
        />
      ) : null}
      {state?.modalName === "REMOVE_APPLICATION" ? (
        <RemoveApplicationModal
          isOpen={state?.modalName === "REMOVE_APPLICATION"}
          onClose={handler?.onCloseModal}
          onSubmit={handler?.onRemove}
        />
      ) : null}
    </Box>
  );
};

import {
  Box,
  Center,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormLabel,
  IconButton,
  Input,
  Stack,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { Controller } from "react-hook-form";
import { BiSearchAlt } from "react-icons/bi";
import { MemberFrame } from "../../../../@types/Member";
import { useChangeTeam } from "../../../../lib/hooks/ManagementSettings/useChangeTeam";
import { CommonModal } from "../../CommonModal";
import { ButtonSubmit } from "../Button/ButtonSubmit";
import { TextHeading } from "../Typography/TextHeading";

type ChangeTeamModalProps = {
  isLoading: boolean;
  member: MemberFrame | null;
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const ChangeTeamModal: FC<ChangeTeamModalProps> = (props) => {
  const { isLoading, isOpen, title, member, onClose } = props;
  const {
    searchValue,
    control,
    data,
    isEmpty,
    onCloseModal,
    handleSubmit,
    onSubmit,
    onChangeSearchValue,
    onSearchTeam,
  } = useChangeTeam({ member, onClose });

  return (
    <CommonModal
      minW={600}
      title={title}
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered
    >
      <Stack align="center" justify="center" px="30px" spacing="23px">
        <Flex align="center" gap="4px" w="100%">
          <Input
            variant="custom"
            name="search"
            placeholder="フィルター"
            border="2px solid #D5D5D5"
            borderRadius="10px"
            value={searchValue}
            onChange={onChangeSearchValue}
            autoComplete="off"
            flex={1}
          />
          <IconButton
            variant="outline"
            aria-label="Search team"
            borderColor="transparent"
            w="37px"
            h="37px"
            icon={<BiSearchAlt size={24} />}
            onClick={onSearchTeam}
          />
        </Flex>

        <form
          onSubmit={handleSubmit(onSubmit)}
          style={{ width: "100%", height: "100%" }}
        >
          <Box
            h="420px"
            w="100%"
            border="2px solid #D5D5D5"
            overflow="auto"
            sx={{
              "::-webkit-scrollbar": {
                display: "none",
              },
            }}
          >
            <FormControl>
              <Controller
                name="tb_teamid"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <CheckboxGroup {...rest}>
                    <Stack>
                      {data.map((item) => (
                        <Flex
                          w="100%"
                          htmlFor={item.gtteamid}
                          as={FormLabel}
                          key={item.gtteamid}
                          m="0px"
                          p="16px"
                          justify="space-between"
                          cursor={isLoading ? "not-allowed" : "pointer"}
                          _hover={{
                            background: "#EDEDED",
                          }}
                        >
                          <TextHeading>{item.gtteamname}</TextHeading>
                          <Checkbox
                            id={item.gtteamid}
                            variant="custom"
                            value={item.gtteamid}
                            size="lg"
                            disabled={isLoading}
                          />
                        </Flex>
                      ))}
                    </Stack>
                  </CheckboxGroup>
                )}
              />
            </FormControl>
          </Box>
          <Center pt="30px" pb="20px">
            <ButtonSubmit
              type="submit"
              isLoading={isLoading}
              isDisabled={isEmpty}
            >
              追加する
            </ButtonSubmit>
          </Center>
        </form>
      </Stack>
    </CommonModal>
  );
};

import { AxiosResponse } from "axios";
import {
  TApplicationPayload,
  TCancelApplicationPayload,
  TGetPersonalSecret,
  TGetPersonalSecretResponse,
  TRegisteredApplicationResponse,
  TRegisterListApplication,
  TRegisterNewApplication,
  TUnRegisteredApplicationResponse,
  TUpdateRegisteredApplication,
} from "../@types/Application";
import axiosInstance from "../configs/axiosConfig";
import { APPLICATION_ENDPOINT } from "../configs/endpoints";
import { EMPTY_STRING } from "lib/Const";

export const applicationService = {
  getUnRegisteredApplication: async (
    payload: TApplicationPayload
  ): Promise<AxiosResponse<TUnRegisteredApplicationResponse>> => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value.toString());
    });
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    formData.append("useridauth", userId);
    const response = await axiosInstance.post(
      APPLICATION_ENDPOINT.GET_UNREGISTERED_DATA,
      formData
    );
    return response;
  },
  getRegisteredApplication: async (
    payload: TApplicationPayload
  ): Promise<AxiosResponse<TRegisteredApplicationResponse>> => {
    const formData = new FormData();
    Object.entries(payload).forEach(([key, value]) => {
      formData.append(key, value.toString());
    });
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    formData.append("useridauth", userId);
    const response = await axiosInstance.post(
      APPLICATION_ENDPOINT.GET_REGISTERED_DATA,
      formData
    );
    return response;
  },
  registerListApplication: async (
    payload: TRegisterListApplication
  ): Promise<AxiosResponse<any>> => {
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    const response = await axiosInstance.post(
      APPLICATION_ENDPOINT.REGISTER_LIST_APPLICATION,
      { ...payload, useridauth: userId }
    );
    return response;
  },
  registerNewApplication: async (
    payload: TRegisterNewApplication
  ): Promise<AxiosResponse<any>> => {
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    const response = await axiosInstance.post(
      APPLICATION_ENDPOINT.REGISTER_NEW_APPLICATION,
      { ...payload, useridauth: userId }
    );
    return response;
  },
  updateRegisteredApplication: async (
    payload: TUpdateRegisteredApplication
  ): Promise<AxiosResponse<any>> => {
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    const response = await axiosInstance.post(
      APPLICATION_ENDPOINT.UPDATE_REGISTERED_APPLICATION,
      { ...payload, useridauth: userId }
    );
    return response;
  },
  getPersonalSecret: async (
    payload: TGetPersonalSecret
  ): Promise<AxiosResponse<TGetPersonalSecretResponse[]>> => {
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    const response = await axiosInstance.post(
      APPLICATION_ENDPOINT.GET_PERSONAL_SECRET,
      { ...payload, useridauth: userId }
    );
    return response;
  },
  cancelRegisteredApplication: async (
    payload: TCancelApplicationPayload
  ): Promise<AxiosResponse<any>> => {
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    const response = await axiosInstance.post(
      APPLICATION_ENDPOINT.CANCEL_REGISTERED_APPLICATION,
      { ...payload, useridauth: userId }
    );
    return response;
  },
};

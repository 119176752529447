import { createSlice } from "@reduxjs/toolkit";
import { MemberFrame, TTeamFrame } from "../../@types/Member";
import {
  getAdminTeamBelongMemberThunk,
  getMemberListThunk,
} from "../thunk/memberThunk";

type UserState = {
  memberList: MemberFrame[];
  member: MemberFrame | null;
  teamBelongMember: TTeamFrame[];
};

const initialState: UserState = {
  memberList: [],
  member: null,
  teamBelongMember: [],
};

const memberSlice = createSlice({
  name: "member",
  initialState,
  reducers: {
    setMemberList: (state, action) => {
      state.memberList = action.payload;
    },
    setMemberData: (state, action) => {
      state.member = action.payload;
    },
    resetMemberData: (state, action) => {
      state.member = null;
    },
    setTeamBelongMember: (state, action) => {
      state.teamBelongMember = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getMemberListThunk.fulfilled, (state, action) => {
      state.memberList = action.payload;
    });

    builder.addCase(
      getAdminTeamBelongMemberThunk.fulfilled,
      (state, action) => {
        state.teamBelongMember = action.payload;
      }
    );
  },
});

export const {
  setMemberList,
  resetMemberData,
  setMemberData,
  setTeamBelongMember,
} = memberSlice.actions;
export default memberSlice.reducer;

/* eslint-disable react-hooks/exhaustive-deps */
import { zodResolver } from "@hookform/resolvers/zod";
import { useEffect, useState } from "react";
import { useForm, UseFormReturn } from "react-hook-form";
import { z } from "zod";
import { REQUIRED_FIELD } from "../../util/messages";
import { useMessage } from "../useMessage";
import { AddApplicationModalProps } from "components/parts/ManagementSettings/Modal/RegisterApplicationModal";
import {
  TApplicationListTable,
  TRegisterListApplication,
} from "../../../@types/Application";
import { applicationService } from "services/applicationService";
import { useAppSelector } from "redux/storeConfig";
import { EMPTY_STRING } from "lib/Const";
import { useDisclosure } from "@chakra-ui/react";

export const APPLICATION_UNREGISTERED_MODAL = {
  REMOVE_APPLICATION: "REMOVE_APPLICATION",
};

export const schema = z.object({
  applications: z.array(
    z.object({
      name: z
        .string()
        .trim()
        .min(1, REQUIRED_FIELD)
        .max(100, "このフィールドが100文字まで入力してください。")
        .regex(
          // eslint-disable-next-line no-useless-escape
          /^[^\\\/:\*\?"<>\|]+$/,
          'このフィールドに「\\/:*?"<>|」の文字を入力出来ません。'
        ),
      applicationType: z.string().trim().min(1, REQUIRED_FIELD),
      typing: z.boolean(),
      capture: z.boolean(),
    })
  ),
});

export type EditApplicationForm = z.infer<typeof schema>;

export type TUseRegisterApplicationModal = {
  form: UseFormReturn<
    {
      applications: {
        name: string;
        applicationType: string;
        typing: boolean;
        capture: boolean;
      }[];
    },
    any,
    undefined
  >;
};

export const useRegisterApplicationModal = (
  props: AddApplicationModalProps
) => {
  const [loading, setLoading] = useState(false);
  const [id, setId] = useState<string | number | null>(null);
  const [isOpenNote, setIsOpenNote] = useState(false);
  const [countSelect, setCountSelect] = useState(0);
  const { showMessage } = useMessage();
  const { adcompanyid } = useAppSelector((state) => state.userInfo);
  const [applicationList, setApplicationList] = useState<
    TApplicationListTable[]
  >([]);
  const [idSelected, setIdSelected] = useState<number | null>(null);
  const [modalName, setModalName] = useState<string | null>(null);
  const [listUser, setListUser] = useState<(string[] | null)[]>([]);
  const [currentSelectedMenu, setCurrentSelectedMenu] = useState<string | null>(
    ""
  );
  const [scrollCount, setScrollCount] = useState<number>(0);
  const {
    formState,
    handleSubmit,
    register,
    reset,
    setValue,
    setError,
    getValues,
    watch,
  } = useForm<EditApplicationForm>({
    resolver: zodResolver(schema),
    values: {
      applications: [],
    },
    shouldUnregister: true,
  });

  const onCloseModal = () => {
    setApplicationList([]);
    setListUser([]);
    reset();
  };

  useEffect(() => {
    const applicationListTemp = props?.applicationSelected;
    setApplicationList(applicationListTemp);
    setListUser(Array(applicationListTemp?.length).fill(null));
    applicationListTemp?.forEach((value, index) => {
      setValue(`applications.${index}`, {
        name: value?.applicationName,
        applicationType: value?.applicationType,
        typing: value?.typing,
        capture: value?.capture,
      });
    });
    getListUser();
  }, []);

  const handleScroll = (_event: any) => {
    setScrollCount(scrollCount + 1);
  };

  useEffect(() => {
    if (currentSelectedMenu && scrollCount) {
      const transform = (
        document.querySelector(`#${currentSelectedMenu}`)
          ?.firstElementChild as any
      )?.style?.transform;

      const regex =
        /translate3d\((-?\d+(?:\.\d+)?)px,\s*(-?\d+(?:\.\d+)?)px,\s*(-?\d+(?:\.\d+)?)px\)/;
      const match = transform.match(regex);

      if (match) {
        const number = parseInt(match[2], 10);
        if (number < 100 || number > 497) {
          onCloseMenu();
          setCurrentSelectedMenu(null);
        }
      }
    }
  }, [scrollCount]);

  const onSubmit = async (values: EditApplicationForm) => {
    const payload: TRegisterListApplication = getValuesSubmit(values);
    setLoading(true);
    try {
      await applicationService.registerListApplication(payload);
      props?.onClose({ isFetchData: true });
      showMessage({
        status: "success",
        title: "アプリケーションリストの更新に成功しました。",
      });
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      if (
        error?.response?.data?.[0]?.error?.includes(
          "このアプリケーション名はすでに存在しております。"
        )
      ) {
        const listAppName = error?.response?.data?.[1]?.map(
          (value: any) => value?.registeredname
        );
        applicationList?.forEach((value, index) => {
          if (listAppName?.includes(value?.applicationName)) {
            setError(`applications.${index}.name`, {
              message: error?.response?.data?.[0]?.error,
            });
          }
        });
      }
      showMessage({
        status: "error",
        title: "アプリケーションリストの更新に失敗しました。",
      });
    }
  };

  const getValuesSubmit = (
    values: EditApplicationForm
  ): TRegisterListApplication => {
    return {
      companyid: adcompanyid?.toString() ?? EMPTY_STRING,
      appsdetails: values?.applications
        ?.map((app, index) => {
          return {
            registeredname:
              applicationList?.[index]?.applicationName?.toString() ??
              EMPTY_STRING,
            registeredname_new:
              app?.name?.toString()?.toLowerCase() ===
              applicationList?.[index]?.applicationName
                ?.toString()
                ?.toLowerCase()
                ? EMPTY_STRING
                : app?.name,
            category: app?.applicationType?.toString() ?? EMPTY_STRING,
            keystroke: app?.typing ?? true,
            screenshot: app?.capture ?? true,
          };
        })
        ?.filter((values) => values),
    };
  };

  const onDeleteItem = (index: number) => {
    if (applicationList?.length === 1) {
      onCloseModal();
      props?.onClose();
    }

    const applicationListTemp = [
      ...applicationList.slice(0, index),
      ...applicationList.slice(index + 1, applicationList.length),
    ];
    const listUserTemp = [
      ...listUser.slice(0, index),
      ...listUser.slice(index + 1, listUser.length),
    ];
    const listErrorTemp = Array(formState?.errors?.applications?.length)
      .fill(0)
      .map((_, indexError) => {
        return formState?.errors?.applications?.[indexError];
      })
      ?.filter?.((_, indexError) => indexError !== index);
    setApplicationList(applicationListTemp);
    setListUser(listUserTemp);
    const currentValues = getValues();
    const newValue = [
      ...currentValues?.applications.slice(0, index),
      ...currentValues?.applications.slice(
        index + 1,
        currentValues?.applications.length
      ),
    ];
    reset(
      { applications: [] },
      {
        keepDirty: !!newValue?.length,
        keepTouched: !!newValue?.length,
        keepIsSubmitted: !!newValue?.length,
      }
    );

    newValue?.forEach((value, indexValue) => {
      setValue(`applications.${indexValue}`, value);

      if (listErrorTemp?.[indexValue]) {
        if (listErrorTemp?.[indexValue]?.name?.message) {
          setError(`applications.${indexValue}.name`, {
            message: listErrorTemp?.[indexValue]?.name?.message,
          });
        }
        if (listErrorTemp?.[indexValue]?.applicationType?.message) {
          setError(`applications.${indexValue}.applicationType`, {
            message: listErrorTemp?.[indexValue]?.applicationType?.message,
          });
        }
      }
    });
    if (id?.toString()?.toLowerCase() === index?.toString()?.toLowerCase()) {
      closeNote();
    }
  };

  const onRemove = (cb?: () => void) => {
    if (idSelected !== null && idSelected !== undefined) {
      onDeleteItem(idSelected);
      onCloseModalRemove();
      cb?.();
    } else {
      cb?.();
    }
  };

  const openModalRemove = (index?: number) => {
    setIdSelected(index ?? null);
    setModalName(APPLICATION_UNREGISTERED_MODAL.REMOVE_APPLICATION);
  };

  const onCloseModalRemove = () => {
    setModalName(null);
    setIdSelected(null);
  };

  const openNote = (idProp: number) => {
    if (
      id === null ||
      id === undefined ||
      idProp?.toString() !== id?.toString()
    ) {
      setIsOpenNote(true);
      setId(idProp);
    } else {
      closeNote();
    }
  };

  const getListUser = async () => {
    try {
      const response = await applicationService.getPersonalSecret({
        companyid: adcompanyid?.toString() ?? EMPTY_STRING,
        appsdetails: props?.applicationSelected?.map((app) => {
          return {
            registeredname: app?.applicationName ?? EMPTY_STRING,
            category: app?.applicationType ?? EMPTY_STRING,
          };
        }),
      });
      setListUser(response?.data?.map((user) => user?.user));
    } catch (_) {}
  };

  // Get list user when select category
  // const getListUser = async (idProp: number, category?: string) => {
  //   const listUserTemp = listUser;
  //   try {
  //     const response = await applicationService.getPersonalSecret({
  //       companyid: adcompanyid?.toString() ?? EMPTY_STRING,
  //       appsdetails: [
  //         {
  //           registeredname: applicationList?.[idProp]?.applicationName,
  //           category:
  //             category ?? watch(`applications.${idProp}.applicationType`),
  //         },
  //       ],
  //     });
  //     listUserTemp[idProp] = response?.data?.[0]?.user ?? [];
  //     setListUser(JSON.parse(JSON.stringify(listUserTemp)));
  //   } catch (_) {
  //     listUserTemp[idProp] = [];
  //     setListUser(JSON.parse(JSON.stringify(listUserTemp)));
  //   }
  // };

  const closeNote = () => {
    setIsOpenNote(false);
    setId(null);
  };

  const onCopy = () => {
    try {
      let formatString = EMPTY_STRING;
      if (listUser?.length && id?.toString() && !isNaN(Number(id))) {
        formatString = listUser?.[Number(id)]?.join("\n") ?? EMPTY_STRING;
      }

      navigator.clipboard.writeText(formatString);
      showMessage({
        status: "success",
        title: "テキストのコピーに成功しました。",
      });
    } catch (error) {
      console.log({ error });
    }
  };

  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();

  return {
    state: {
      formState,
      loading,
      applicationList,
      isOpenNote,
      id,
      modalName,
      countSelect,
      listUser,
      isOpenMenu,
      currentSelectedMenu,
    },
    handler: {
      register,
      handleSubmit,
      setValue,
      reset,
      watch,
      setLoading,
      onSubmit,
      onCloseModal,
      onDeleteItem,
      openNote,
      closeNote,
      onCopy,
      setId,
      onCloseModalRemove,
      onRemove,
      openModalRemove,
      setCountSelect,
      getListUser,
      setListUser,
      onOpenMenu,
      onCloseMenu,
      setCurrentSelectedMenu,
      handleScroll,
    },
  };
};

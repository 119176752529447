import React, { createContext, useState } from "react";

type Props = { children: React.ReactNode };

// 初期値の型
type InitDateStateType = [date: Date, setDate: null];
// グローバルステートとして管理するデータの型
type DateStateType = [
  date: Date,
  setDate: React.Dispatch<React.SetStateAction<Date>>
];

// 日付の初期値
// 前日を基準とする
const initialDate = new Date(new Date().setDate(new Date().getDate() - 1));

// 日付を管理するコンテキストを生成する
const DateContext = createContext<DateStateType | InitDateStateType>([
  initialDate,
  null,
]);

/**
 * @remarks
 * 日付のデータを配下のコンポーネントでも扱えるように、高階関数を定義
 *
 * @param Props - {@link Props}
 *  @param children - Reactの要素
 *
 * @returns
 * JSX.Components
 */
const DateStore = ({ children }: Props) => {
  const [date, setDate] = useState(initialDate);

  return (
    <DateContext.Provider value={[date, setDate]}>
      {children}
    </DateContext.Provider>
  );
};

export { DateContext, DateStore };

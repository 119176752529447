import { Box, Flex, Divider } from "@chakra-ui/react";
import React, { useMemo } from "react";

import "../../../styles/Calender.css";

import MemberStatus from "./MemberStatus";
import MemberTitle from "./MemberTitle";
import MostUsageAppTimeline from "./MostUsageAppTimeline";

const MemberDetail: React.FC<{ member: TeamMember; isLastDivider: boolean }> =
  React.memo(({ member, isLastDivider }) => {
    const generateMostUsedAppTimeline = useMemo(() => {
      return <MostUsageAppTimeline member={member} />;
    }, [member]);

    const dividerStyle = isLastDivider ? { marginBottom: "3rem" } : {};
    return (
      <Box my="3">
        {/* <Flex justifyContent="flex-end" width="96%">
          <Box width="1rem" height="1rem" bg="#d2dcff" borderRadius="50%"></Box>
          <Text
            fontFamily="Source Han Sans"
            fontSize="0.625rem"
            color="#919191"
            ms="0.1rem"
          >
            無操作時間
          </Text>
        </Flex> */}
        <Flex justifyContent="space-between">
          <Flex width="15%">
            <MemberTitle member={member} />
          </Flex>
          <Flex flexDir="column" width="85%">
            <Box height="8.75rem">{generateMostUsedAppTimeline}</Box>

            <MemberStatus member={member} />
          </Flex>
        </Flex>
        <Flex justifyContent="center">
          <Divider
            className="member-detail-divider"
            color="#d6d6d6"
            opacity="1"
            width="95%"
            mt="5"
            style={dividerStyle}
          ></Divider>
        </Flex>
      </Box>
    );
  });

MemberDetail.displayName = "MemberDetail";
export default MemberDetail;

import { Box, Heading, Stack } from "@chakra-ui/react";
import type { FC } from "react";
import { memo } from "react";
import { MenuSettingItem } from "./MenuSettingItem";

type Props = {
  menuItems: MenuItemType[];
  onSelect: (_item: MenuItemType) => void;
  selectedId: number;
};

export const MenuSettingList: FC<Props> = memo(
  ({ menuItems, onSelect, selectedId }) => {
    return (
      <Box w={330}>
        <Heading
          mb="18px"
          fontSize="21px"
          fontWeight={800}
          lineHeight="30px"
          color="#2C2C2C"
        >
          設定
        </Heading>
        <Stack direction="column" gap="10px">
          {menuItems.map((item) => (
            <MenuSettingItem
              id={item.id}
              key={item.id}
              onSelect={() => onSelect(item)}
              selectedId={selectedId}
              text={item.text}
            />
          ))}
        </Stack>
      </Box>
    );
  }
);

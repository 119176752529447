import { createSlice } from "@reduxjs/toolkit";

type ApplicationState = {
  data: { id: string | number }[];
};

const initialState: ApplicationState = {
  data: [],
};

const applicationSlice = createSlice({
  name: "application",
  initialState,
  reducers: {},
  extraReducers: (builder) => {},
});

export default applicationSlice.reducer;

import { TableContainer } from "@chakra-ui/table";
import { FC, Fragment } from "react";
import { useTableAlert } from "../../../../lib/hooks/ManagementSettings/useTableAlert";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonTable } from "../../CommonTable";
import { DeleteModal } from "../../DeleteModal";
import { CreateAlertModal } from "../Modal/CreateAlertModal";

export const TableAlert: FC = () => {
  const { columns, modalName, onCloseModal, onDeleteAdminAlertData } =
    useTableAlert();
  const { alertList: alertData, selectedAlertData } = useAppSelector(
    (state) => state.alert
  );

  return (
    <Fragment>
      <TableContainer>
        <CommonTable columns={columns} data={alertData} />
      </TableContainer>

      {/* Modal */}

      {modalName === "UPDATE_ALERT" && !!selectedAlertData ? (
        <CreateAlertModal
          isUpdate
          alertData={selectedAlertData}
          title="アラートを編集"
          isOpen={modalName === "UPDATE_ALERT" && !!selectedAlertData}
          onClose={onCloseModal}
        />
      ) : null}

      {modalName === "DELETE_ALERT" && !!selectedAlertData ? (
        <DeleteModal
          isOpen={modalName === "DELETE_ALERT" && !!selectedAlertData}
          message="この警告を削除しますか?"
          onClose={onCloseModal}
          onOk={onDeleteAdminAlertData}
        />
      ) : null}
    </Fragment>
  );
};

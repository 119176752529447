import { useState } from "react";

export const APPLICATION_TAB = {
  UN_REGISTERED: {
    id: 0,
    name: "未登録アプリケーション",
  },
  REGISTERED: {
    id: 1,
    name: "登録済アプリケーション",
  },
};

export const useApplicationManagement = () => {
  const [tabId, setTabId] = useState<number>(0);

  return {
    state: {
      tabId,
    },
    handler: {
      setTabId,
    },
  };
};

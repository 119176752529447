import React, { Fragment } from "react";

import {
  Box,
  Divider,
  Flex,
  GridItem,
  HStack,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";

import CircleIcon from "./CircleIcon";

import { getTimeFromString } from "../../lib/timeCalculate";

type Props = {
  scheduleDatas: ScheduleData[];
  showScheduleDataGridNum: number;
  modalOpen: (showScheduleDataGridNum: number) => void;
};

// 特定の時間帯で予定されたスケジュールをポップアップで表示するためのパーツ
const SchedulePopOverCustom = React.memo((props: Props) => {
  const { scheduleDatas, showScheduleDataGridNum, modalOpen } = props;
  return (
    <Popover placement="bottom-start" trigger="hover" isLazy>
      <PopoverTrigger>
        <GridItem
          key={`grid-schedulePopOver-${showScheduleDataGridNum}`}
          colStart={showScheduleDataGridNum + 1}
          colEnd={showScheduleDataGridNum + 2}
          h={{ "2xl": 10, xl: 8 }}
          display="flex"
          justifyContent="center"
          alignItems="center"
          onClick={() => modalOpen(showScheduleDataGridNum)}
          cursor="pointer"
        >
          <CircleIcon
            color="#15A2FF"
            iconName="schedule"
            width={1.875}
            height={1.875}
          />
        </GridItem>
      </PopoverTrigger>
      <PopoverContent
        w="100%"
        maxH="15rem"
        boxShadow="rgba(0, 0, 0, 0.16) 0px 0.25rem 0.5rem, rgba(0, 0, 0, 0.23) 0px 0.25rem 0.5rem;"
        p="3"
        overflowY={"auto"}
        fontFamily="Source Han Sans"
        fontWeight="bold"
      >
        {scheduleDatas.length > 0 &&
          scheduleDatas.map((scheduleData: ScheduleData, i: number) => {
            return (
              <Fragment key={i}>
                <Box w="100%" h="100%" p="0">
                  <Flex align="start" w="100%">
                    <Box>
                      <HStack h="100%">
                        <Box>
                          <VStack alignItems="left">
                            <Text fontSize="0.875rem" color="#2c2c2c">
                              {scheduleData.subject}
                            </Text>
                            <Text
                              fontSize="0.75rem"
                              style={{
                                marginTop: "0rem",
                              }}
                              color="#a5a5a5"
                            >
                              {`${getTimeFromString(
                                scheduleData.start
                              )}~${getTimeFromString(scheduleData.end)}`}
                            </Text>
                          </VStack>
                        </Box>
                      </HStack>
                    </Box>
                  </Flex>
                </Box>
                {i + 1 < scheduleDatas.length && <Divider mb={2} />}
              </Fragment>
            );
          })}
      </PopoverContent>
    </Popover>
  );
});
SchedulePopOverCustom.displayName = "SchedulePopOverCustom";
export default SchedulePopOverCustom;

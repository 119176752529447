'use strict;';

import {
  countTimeZoneData,
  generate24HourTimeZone,
  generate12HourTimeZone,
  generateUnder12HourTimeZoneData,
} from './timeZoneCalculate';

import { totallingKeyData } from './totallingKeyData';

import { calculateTimeByCategory } from './caterogyCulculate';

import {
  makeDataOneLine,
  countTimeZoneDataFromOneLineTimeZoneData,
} from './commonDataProcessing';

import { DAYSECONDS } from './Const';

type Props = {
  timeZoneIndex: number | undefined;
  timeZoneData: TimeZone24Hour;
  activeTimeZone: HoveredTimeZoneItemPosition;
};

/**
 * @remarks
 * キー入力データと時間帯情報をもとに、グラフに表示するためのデータを整形する
 *
 * @param props {@link Props}
 *  @param timeZoneIndex 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *  @param timeZoneData @see [DummyData](src/data/dummy1/allPushWithIndexHourData.json)
 *  @param activeTimeZone グラフで表示する中央の時間帯（24時間帯：number、12時間帯以下：string） {@link useMouseHoveredTimeZone}
 *
 * @returns
 * @see {@link TimeZoneDataCountArray}
 * @see [DummyData](src/data/example/generate24HourTimeZoneResult.json)
 * @see [DummyData](src/data/example/generate12HourTimeZoneResult.json)
 * @see [DummyData](src/data/example/generateUnder12HourTimeZoneData6hResultCount.json)
 * @see [DummyData](src/data/example/generateUnder12HourTimeZoneData3hResultCount.json)
 * @see [DummyData](src/data/example/generateUnder12HourTimeZoneData1hResultCount.json)
 * @see [DummyData](src/data/example/generateUnder12HourTimeZoneData30mResultCount.json)
 */
const generateData = ({
  timeZoneIndex,
  timeZoneData,
  activeTimeZone = undefined,
}: Props): TimeZoneDataCountArray | undefined => {
  switch (timeZoneIndex) {
    case 0:
      /** @see [DummyData](src/data/example/generate24HourTimeZoneResult.json) */
      return generate24HourTimeZone(timeZoneData);

    case 1:
      /** @see [DummyData](src/data/example/generate12HourTimeZoneResult.json) */
      return generate12HourTimeZone({
        activeTimeZone,
        timeZoneData,
      });

    case 2:
    case 3:
    case 4:
    case 5:
      /**
       * @see [DummyData](src/data/example/generateUnder12HourTimeZoneData6hResultCount.json)
       * @see [DummyData](src/data/example/generateUnder12HourTimeZoneData3hResultCount.json)
       * @see [DummyData](src/data/example/generateUnder12HourTimeZoneData1hResultCount.json)
       * @see [DummyData](src/data/example/generateUnder12HourTimeZoneData30mResultCount.json)
       */
      return generateUnder12HourTimeZoneData({
        activeTimeZone,
        timeZoneData,
        timeZoneIndex,
      });

    default:
      return undefined;
  }
};

/**
 * @remarks
 * 時間帯をもとにX軸に表示する要素の間隔を返す
 *
 * @param timeZoneIndex 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *
 * @returns
 * 表示するX軸の間隔を表す数値（0もしくは1）
 */
const generatexAxisInterval = (timeZoneIndex: number | undefined): number => {
  switch (timeZoneIndex) {
    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      return 1;

    case 0:
    default:
      return 0;
  }
};

/**
 * @remarks
 * 試験用関数
 *
 * @param timeZoneData @see [DummyData](src/data/dummy1/allPushWithIndexHourData.json)
 *
 * @returns
 * TimeZone1Minute[][]
 */
const generateForRechartsBrush = (timeZoneData: TimeZone24Hour) => {
  const timeZoneOnelineData = makeDataOneLine<TimeZone1Hour, TimeZone1Minute>(
    timeZoneData
  );

  const result = countTimeZoneDataFromOneLineTimeZoneData(
    timeZoneOnelineData,
    5,
    '24:00'
  );
  return result.map((halfHourData: TimeZone1Minute[], index: number) => {
    const id = halfHourData[0].time;

    return countTimeZoneData({
      id: id,
      data: halfHourData,
    });
  });
};

/**
 * @remarks
 * 総作業時間、PC操作時間・無操作時間、作業時間内訳に表示するためのデータを集計する
 *
 * @param appInfoData @see [DummyData](src/data/dummy1/allPushWithIndexAppInfoData.json)
 *
 * @returns
 * @see {@link useOneDayTotalData}
 * [
 *  workingHourBreakdownItems：カテゴリ別の作業時間を秒数でまとめたオブジェクト, @see [DummyData](src/data/example/workingHourBreakdownItems.json)
 *  workAndEmptySecond：PC操作時間・無操作時間をオブジェクトにし、まとめた配列, @see [DummyData](src/data/example/workAndEmptySecond.json)
 *  organizeAppInfoByCategory：使用したアプリケーションとその作業内容、使用した時間をオブジェクトにまとめた配列を作業カテゴリごとにを集約したオブジェクト, @see [DummyData](src/data/example/organizeAppInfoByCategory.json)
 * ]
 */
const generate1DayTotalData = (
  appInfoData: AppInfoDetailMultipleData
):
  | [[], [], {}]
  | [KeyAndSecondItems, KeyAndSecondItems, OrganizeAppInfoByCategory] => {
  /**
   * @remarks
   * 1. 作業時間内訳
   *
   * @see {@link useOneDayTotalData}
   * [
   *  workingHourBreakdownItems：カテゴリ別の作業時間を秒数でまとめたオブジェクト, @see [DummyData](src/data/example/workingHourBreakdown.json)
   *  organizeAppInfoByCategory：使用したアプリケーションとその作業内容、使用した時間をオブジェクトにまとめた配列を作業カテゴリごとにを集約したオブジェクト, @see [DummyData](src/data/example/organizeAppInfoByCategory.json)
   * ]
   */
  const [workingHourBreakdown, organizeAppInfoByCategory]: [
    CalculatedCategoryPer1Minute | undefined,
    OrganizeAppInfoByCategory | {}
  ] = calculateTimeByCategory(appInfoData);
  if (workingHourBreakdown === undefined) return [[], [], {}];
  // 2. 総作業時間
  // 24時間 - 作業時間内訳で総作業時間を計算する
  const totalWorkSeconds: number = Object.keys(workingHourBreakdown).reduce(
    (totalSeconds: number, keyName: string) => {
      return (totalSeconds += workingHourBreakdown[keyName]);
    },
    0
  );

  /** @see [DummyData](src/data/example/workingHourBreakdownItems.json) */
  const workingHourBreakdownItems = Object.entries(workingHourBreakdown).map(
    ([key, value]) => ({ key, value })
  );

  /** @see [DummyData](src/data/example/workAndEmptySecond.json) */
  const workAndEmptySecond = [
    {
      key: 'totalWork',
      value: totalWorkSeconds,
    },
    {
      key: 'empty',
      value: DAYSECONDS -12000 -totalWorkSeconds,
    },
    {
      key: 'offline',
      value: 12000,
    },
  ];

  return [
    workingHourBreakdownItems,
    workAndEmptySecond,
    organizeAppInfoByCategory,
  ];
};

/**
 * @remarks
 * キーボード操作、マウス操作に表示するためのデータを集計する
 *
 * @param timeZoneData 総作業時間グラフを表示するための情報 @see [DummyData](src/data/dummy1/allPushWithIndexHourData.json)
 * @returns キーボード操作・マウス操作量のログ情報 @see {@link useOneDayKeyData}
 */
const generate1DayTotalKeyData = (
  timeZoneData: TimeZone24Hour
): TotallingKeyDataPer1Minute | {} => {
  /**
   * @remarks
   * 1日の左クリック回数とマウスカーソル移動量を集計する
   *
   * キーボード操作・マウス操作量のログ情報 @see {@link useOneDayKeyData}
   */
  const result: TotallingKeyDataPer1Minute | undefined =
    totallingKeyData(timeZoneData);
  if (result === undefined) return {};
  return result;
};

export {
  generateData,
  generatexAxisInterval,
  generateForRechartsBrush,
  generate1DayTotalData,
  generate1DayTotalKeyData,
};

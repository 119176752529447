import { Box, Flex } from "@chakra-ui/react";
import React, { useEffect, useState } from "react";

import "../../../styles/Calender.css";

import TeamActionInMemberInfo from "./TeamActionInMemberInfo";
import MemberDetail from "./MemberDetail";
import { useSelector } from "react-redux";

import menuItems from "../../../lib/sortOptionLabels";

interface RootState {
  team: {
    selectedTeam: TeamInfo;
    discoveryData: TeamInfoArray;
  };
}

const MemberInfo = React.memo(() => {
  const [typeSort, setTypeSort] = useState("");
  const [typeLetter, setTypeLetter] = useState("");

  const selectedTeam = useSelector(
    (state: RootState) => state.team.selectedTeam
  );

  const sort = (firstSort: string, secondSort: string) => {
    setTypeSort(firstSort);
    setTypeLetter(secondSort);
  };

  /**
   * The function `timeStringToMinutes` converts a time string in the format "HH:MM" to the total
   * number of minutes.
   * @param {string} timeString - The `timeString` parameter is a string representing a time in the
   * format "HH:MM", where HH represents the hours and MM represents the minutes.
   * @returns the total number of minutes represented by the given time string.
   */
  const timeStringToMinutes = (timeString: string) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const defaultMembers = selectedTeam?.teamdata[0]?.member;
  const [members, setMembers] = useState<TeamMember[]>([]);

  useEffect(() => {
    const getTimeComparator =
      (property: keyof MemberDataString) =>
      (firstMember: TeamMember, secondMember: TeamMember) => {
        if (
          firstMember.memberdata.length > 0 &&
          firstMember.memberdata.length
        ) {
          const timeFirstMember = timeStringToMinutes(
            firstMember.memberdata[0][property]
          );
          const timeSecondMember = timeStringToMinutes(
            secondMember.memberdata[0][property]
          );
          return typeLetter === "昇順"
            ? timeFirstMember - timeSecondMember
            : timeSecondMember - timeFirstMember;
        }
        return 0;
      };

    /* The `sortOptions` variable is an object that maps each sort option to a sorting function. It is
    used to determine the sorting order of the `members` array in the `useEffect` hook. */
    const sortOptions: Record<
      string,
      (firstMember: TeamMember, secondMember: TeamMember) => number
    > = {
      [menuItems[0].jp]: (firstMember, secondMember) => {
        return typeLetter === "昇順"
          ? firstMember.memberkana.localeCompare(secondMember.memberkana)
          : secondMember.memberkana.localeCompare(firstMember.memberkana);
      },
    };

    for (let index = 1; index < menuItems.length; index++) {
      const menuItem = menuItems[index];
      sortOptions[menuItem.jp] = getTimeComparator(
        menuItem.en as keyof MemberDataString
      );
    }

    if (defaultMembers && typeSort in sortOptions) {
      const sortingFunction = sortOptions[typeSort];
      const newMembers = [...defaultMembers].sort(sortingFunction);
      setMembers(newMembers);
    }
  }, [defaultMembers, typeSort, typeLetter]);

  return (
    <>
      <Flex className="member-info" flexDir="column" mt="3">
        <TeamActionInMemberInfo sortMember={sort} />

        {defaultMembers &&
          members?.map((member: TeamMember, index: Number) => (
            <MemberDetail
              key={member.memberid}
              member={member}
              isLastDivider={index === members.length - 1}
            />
          ))}

        <Box></Box>
      </Flex>
    </>
  );
});

MemberInfo.displayName = "MemberInfo";
export default MemberInfo;

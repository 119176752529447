import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { UpdateTeam } from "../../../@types/Team";
import { setTeam } from "../../../redux/slice/teamSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  getTeamListThunk,
  getTeamUserDataThunk,
  updateTeamThunk,
} from "../../../redux/thunk/teamThunk";
import { EMPTY_STRING } from "../../Const";
import { REQUIRED_FIELD } from "../../util/messages";

type UseUpdateTeamProps = {
  onClose: () => void;
  onOk?: () => void;
};

const schema = z.object({
  teamname: z.string().trim().min(1, REQUIRED_FIELD),
});

type UpdateTeamForm = z.infer<typeof schema>;

export const useUpdateTeam = (props: UseUpdateTeamProps) => {
  const { onClose, onOk } = props;
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);
  const { team } = useAppSelector((state) => state.team);
  const dispatch = useAppDispatch();
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UpdateTeamForm>({
    resolver: zodResolver(schema),
    values: {
      teamname: team?.gtteamname || EMPTY_STRING,
    },
  });

  const closeModal = () => {
    onClose();
    reset();
  };

  const onSubmit = (values: UpdateTeamForm) => {
    if (adcompanyid && aduserid && team) {
      const payload: UpdateTeam = {
        upcrud: "U",
        upteamname: values.teamname,
        upcompanyid: adcompanyid,
        upteamid: team.gtteamid,
        upteamkana: team.gtteamkana,
      };

      dispatch(
        updateTeamThunk({
          payload,
          onSuccess: () => {
            dispatch(
              setTeam({
                ...team,
                gtteamname: values.teamname,
              })
            );
            dispatch(
              getTeamUserDataThunk({
                payload: {
                  adcompanyid,
                  adteamid: team.gtteamid,
                },
              })
            );
            dispatch(
              getTeamListThunk({
                payload: {
                  adcompanyid,
                  aduserid,
                },
              })
            );
            onOk?.();
            closeModal();
          },
        })
      );
    }
  };

  return {
    errors,
    isSubmitting,
    closeModal,
    handleSubmit,
    register,
    reset,
    onSubmit,
  };
};

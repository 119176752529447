const hours = [
  { value: 0, label: "00時" },
  { value: 1, label: "01時" },
  { value: 2, label: "02時" },
  { value: 3, label: "03時" },
  { value: 4, label: "04時" },
  { value: 5, label: "05時" },
  { value: 6, label: "06時" },
  { value: 7, label: "07時" },
  { value: 8, label: "08時" },
  { value: 9, label: "09時" },
  { value: 10, label: "10時" },
  { value: 11, label: "11時" },
  { value: 12, label: "12時" },
  { value: 13, label: "13時" },
  { value: 14, label: "14時" },
  { value: 15, label: "15時" },
  { value: 16, label: "16時" },
  { value: 17, label: "17時" },
  { value: 18, label: "18時" },
  { value: 19, label: "19時" },
  { value: 20, label: "20時" },
  { value: 21, label: "21時" },
  { value: 22, label: "22時" },
  { value: 23, label: "23時" },
];

const hourNoUnits = [
  { value: 0, label: "0" },
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "11" },
  { value: 12, label: "12" },
  { value: 13, label: "13" },
  { value: 14, label: "14" },
  { value: 15, label: "15" },
  { value: 16, label: "16" },
  { value: 17, label: "17" },
  { value: 18, label: "18" },
  { value: 19, label: "19" },
  { value: 20, label: "20" },
  { value: 21, label: "21" },
  { value: 22, label: "22" },
  { value: 23, label: "23" },
];

const minutes = [
  { value: 0, label: "00分" },
  { value: 10, label: "10分" },
  { value: 20, label: "20分" },
  { value: 30, label: "30分" },
  { value: 40, label: "40分" },
  { value: 50, label: "50分" },
];

const alertTimeOptions = [
  { value: "0:00", label: "0:00" },
  { value: "0:30", label: "0:30" },
  { value: "1:00", label: "1:00" },
  { value: "1:30", label: "1:30" },
  { value: "2:00", label: "2:00" },
  { value: "2:30", label: "2:30" },
  { value: "3:00", label: "3:00" },
  { value: "3:30", label: "3:30" },
  { value: "4:00", label: "4:00" },
  { value: "4:30", label: "4:30" },
  { value: "5:00", label: "5:00" },
  { value: "5:30", label: "5:30" },
  { value: "6:00", label: "6:00" },
  { value: "6:30", label: "6:30" },
  { value: "7:00", label: "7:00" },
  { value: "7:30", label: "7:30" },
  { value: "8:00", label: "8:00" },
  { value: "8:30", label: "8:30" },
  { value: "9:00", label: "9:00" },
  { value: "9:30", label: "9:30" },
  { value: "10:00", label: "10:00" },
  { value: "10:30", label: "10:30" },
  { value: "11:00", label: "11:00" },
  { value: "11:30", label: "11:30" },
  { value: "12:00", label: "12:00" },
  { value: "12:30", label: "12:30" },
  { value: "13:00", label: "13:00" },
  { value: "13:30", label: "13:30" },
  { value: "14:00", label: "14:00" },
  { value: "14:30", label: "14:30" },
  { value: "15:00", label: "15:00" },
  { value: "15:30", label: "15:30" },
  { value: "16:00", label: "16:00" },
  { value: "16:30", label: "16:30" },
  { value: "17:00", label: "17:00" },
  { value: "17:30", label: "17:30" },
  { value: "18:00", label: "18:00" },
  { value: "18:30", label: "18:30" },
  { value: "19:00", label: "19:00" },
  { value: "19:30", label: "19:30" },
  { value: "20:00", label: "20:00" },
  { value: "20:30", label: "20:30" },
  { value: "21:00", label: "21:00" },
  { value: "21:30", label: "21:30" },
  { value: "22:00", label: "22:00" },
  { value: "22:30", label: "22:30" },
  { value: "23:00", label: "23:00" },
];

export { alertTimeOptions, hourNoUnits, hours, minutes };

import {
  Avatar,
  Center,
  FormControl,
  FormLabel,
  Input,
  Stack,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { useChangeAvatar } from "../../../../lib/hooks/ManagementSettings/useChangeAvatar";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonModal } from "../../CommonModal";
import { ButtonSubmit } from "../Button/ButtonSubmit";
import { TextSecond } from "../Typography/TextSecond";

type ChangeAvatarModalProps = {
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const ChangeAvatarModal: FC<ChangeAvatarModalProps> = (props) => {
  const { isOpen, title, onClose } = props;
  const { admin } = useAppSelector((state) => state.userInfo);
  const { isLoading } = useAppSelector((state) => state.loading);
  const { errors, handleSubmit, onSubmit, register, onCloseModal } =
    useChangeAvatar({
      onClose,
    });

  return (
    <CommonModal
      title={title}
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered
    >
      <form onSubmit={handleSubmit(onSubmit)}>
        <Stack align="center" justify="center" spacing="10px">
          <Avatar src={admin?.gtaduserpic} w="100px" h="100px" />
          <Center>
            <FormControl isInvalid={!!errors.file}>
              <FormLabel
                m="0px"
                color="#3796FC"
                fontSize="15px"
                fontWeight="medium"
                cursor="pointer"
                _hover={{
                  textDecoration: "underline",
                }}
              >
                ファイルを選択
              </FormLabel>
              <Input
                type="file"
                hidden
                disabled={isLoading}
                {...register("file")}
              />
            </FormControl>
          </Center>
          <TextSecond>XXX.png</TextSecond>
        </Stack>
        <Center pt="30px" pb="20px">
          <ButtonSubmit type="submit" isLoading={isLoading}>
            確定
          </ButtonSubmit>
        </Center>
      </form>
    </CommonModal>
  );
};

import { Heading, HeadingProps } from "@chakra-ui/react";
import { FC } from "react";

export const TextHeading: FC<HeadingProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Heading
      as="h5"
      fontSize="15px"
      fontWeight="bold"
      color="#2C2C2C"
      lineHeight="21px"
      {...otherProps}
    >
      {children}
    </Heading>
  );
};

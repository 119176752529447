import { ChakraProvider } from "@chakra-ui/react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import { RouterProvider } from "react-router-dom";
import { theme } from "./styles/Theme";

import { DateStore } from "./lib/globalStates/DateState";
import { LoginUserProvider } from "./providers/LoginUserProvider";
import storeConfig from "./redux/storeConfig";
import router from "./router/routes";
import Loading from "./components/parts/Loading";
import { axiosSetup } from "./configs/axiosConfig";
import "./styles/app.css";
import { useCheckSession } from "lib/hooks/useCheckSession";
import { useLayoutEffect } from "react";

async function enableMocking() {
  // if (process.env.NODE_ENV !== "development") {
  //   return;
  // }
  // const { worker } = await import("./mocks/browser");
  // // `worker.start()` returns a Promise that resolves
  // // once the Service Worker is up and ready to intercept requests.
  // return worker.start();
}

axiosSetup(storeConfig);
const App = () => {
  const { addTabId } = useCheckSession();

  useLayoutEffect(() => {
    addTabId();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Provider store={storeConfig}>
      <DateStore>
        <ChakraProvider theme={theme}>
          <LoginUserProvider>
            <RouterProvider router={router} />
          </LoginUserProvider>
          <Loading />
        </ChakraProvider>
      </DateStore>
    </Provider>
  );
};

enableMocking().then(() => {
  ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <App />
  );
});

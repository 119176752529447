const menuItems = [
  { jp : "五十音順", en: ""},
  { jp : "PC操作時間", en: "pcactive"},
  { jp : "PC無操作時間", en: "pcnonactive"},
  { jp : "作業時間", en: "worktime"},
  { jp : "チャット・Web会議時間", en: "webmeeting"},
  { jp : "WEB検索時間", en: "websearch"},
  { jp : "メール時間", en: "mailchat"},
  { jp : "その他時間", en: "others"},
  { jp : "シークレット時間", en: "secret"},
  { jp : "時間外PC作業時間", en: "monthovertime"},
  { jp : "予定", en: "plan"},
];
export default menuItems;

import { createSlice } from "@reduxjs/toolkit";
import { TeamFrame, TeamUserData } from "../../@types/Team";
import { getTeamListThunk, getTeamUserDataThunk } from "../thunk/teamThunk";

type TeamState = {
  selectedTeam: null;
  discoveryData: TeamInfoArray[];
  scheduleData: TeamScheduleData[];
  appInfoData: TeamAppInfo[];
  screenShotData: TeamScreenShotData[];
  mouseKeyboardData: TeamMountKeyboardReport[];
  teamList: TeamFrame[];
  teamUserData: TeamUserData | null;
  team: TeamFrame | null;
};

const initialState: TeamState = {
  selectedTeam: null,
  discoveryData: [],
  scheduleData: [],
  appInfoData: [],
  screenShotData: [],
  mouseKeyboardData: [],
  teamList: [],
  teamUserData: null,
  team: null,
};

const teamSlice = createSlice({
  name: "team",
  initialState,
  reducers: {
    selectTeam: (state, action) => {
      state.selectedTeam = action.payload;
    },
    setDiscoveryData: (state, action) => {
      state.discoveryData = action.payload;
    },
    setScheduleData: (state, action) => {
      state.scheduleData = action.payload;
    },
    setAppInfoData: (state, action) => {
      state.appInfoData = action.payload;
    },
    setScreenShotData: (state, action) => {
      state.screenShotData = action.payload;
    },
    setMouseKeyboardData: (state, action) => {
      state.mouseKeyboardData = action.payload;
    },
    resetState: (state) => {
      return initialState;
    },
    setTeamUserData: (state, action) => {
      state.teamUserData = action.payload;
    },
    setTeam: (state, action) => {
      state.team = action.payload;
    },
    setTeamList: (state, action) => {
      state.teamList = action.payload;
    },
  },
  extraReducers(builder) {
    builder.addCase(getTeamListThunk.fulfilled, (state, action) => {
      state.teamList = action.payload;
    });

    builder.addCase(getTeamUserDataThunk.fulfilled, (state, action) => {
      state.teamUserData = action.payload;
    });
  },
});

export const {
  selectTeam,
  setTeamUserData,
  setDiscoveryData,
  setScheduleData,
  setAppInfoData,
  setScreenShotData,
  setMouseKeyboardData,
  resetState,
  setTeam,
  setTeamList,
} = teamSlice.actions;
export default teamSlice.reducer;

import { AddIcon, CloseIcon, ExternalLinkIcon } from "@chakra-ui/icons";
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  Icon,
  IconButton,
  Input,
  Stack,
} from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { Link } from "react-router-dom";
import { otherLinks } from "../../../configs/url";
import { useOtherSettings } from "../../../lib/hooks/ManagementSettings/useOtherSettings";
import { useAppSelector } from "../../../redux/storeConfig";
import { ButtonLink } from "./Button/ButtonLink";
import { TextHeading } from "./Typography/TextHeading";
import { TextSecond } from "./Typography/TextSecond";

export const OtherSettings: FC = () => {
  const {
    isEditIP,
    fields,
    errors,
    onAppend,
    onRemove,
    register,
    openEditIP,
    closeEditIP,
    onSubmit,
    handleSubmit,
  } = useOtherSettings();
  const { ips } = useAppSelector((state) => state.ip);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <Stack spacing="20px">
        <Box p="20px" background="#FFF" borderRadius="10px">
          <TextHeading>オフィスのIPアドレス</TextHeading>
          <Stack mb="20px">
            {isEditIP ? (
              <Box>
                <Stack spacing="8px" mb="8px">
                  {fields.map((field, index) => (
                    <Flex
                      key={field.id}
                      align="center"
                      gap="8px"
                      _first={{
                        paddingTop: "8px",
                      }}
                    >
                      <FormControl
                        maxW="350px"
                        w="100%"
                        isInvalid={!!errors.upipaddress?.[index]?.upofficeip}
                      >
                        <Flex align="center" gap="8px">
                          <Input
                            variant="custom"
                            placeholder="XXX.XXX.XXX.XXX"
                            {...register(`upipaddress.${index}.upofficeip`)}
                          />
                          <IconButton
                            variant="outline"
                            icon={<CloseIcon />}
                            aria-label="delete"
                            colorScheme="red"
                            size="sm"
                            onClick={() => onRemove(index)}
                          />
                        </Flex>
                        <FormErrorMessage fontSize="14px">
                          {errors.upipaddress?.[index]?.upofficeip &&
                            errors.upipaddress?.[index]?.upofficeip?.message}
                        </FormErrorMessage>
                      </FormControl>
                    </Flex>
                  ))}
                </Stack>
                <IconButton
                  icon={<AddIcon />}
                  aria-label="add"
                  size="sm"
                  onClick={onAppend}
                />
              </Box>
            ) : (
              <Fragment>
                {ips.map((ip, index) => (
                  <TextSecond key={index} mt="4px">
                    {ip.gtipaddress}
                  </TextSecond>
                ))}
              </Fragment>
            )}
          </Stack>
          <Box>
            {isEditIP ? (
              <Flex gap="20px">
                <ButtonLink type="submit">保存する</ButtonLink>
                <ButtonLink danger onClick={closeEditIP}>
                  キャンセル
                </ButtonLink>
              </Flex>
            ) : (
              <ButtonLink onClick={openEditIP}>変更する</ButtonLink>
            )}
          </Box>
        </Box>
        {/* <Box p="20px" background="#FFF" borderRadius="10px">
          <TextHeading mb="16px">チュートリアルガイド</TextHeading>
          <Stack spacing={0}>
            {guidelineUrl.map((url) => (
              <Flex
                key={url.id}
                align="center"
                justify="space-between"
                borderTop="1px solid #B1B1B1"
                py="16px"
                gap="20px"
                _last={{
                  paddingBottom: "0px",
                }}
              >
                <TextHeading fontSize="14px" fontWeight="medium">
                  {url.title}
                </TextHeading>
                <Flex align="center" gap="20px">
                  <TextSecond fontSize="12px">{url.description}</TextSecond>
                  <ButtonPrimary>チュートリアルを開始</ButtonPrimary>
                </Flex>
              </Flex>
            ))}
          </Stack>
        </Box> */}
        <Box
          px="20px"
          pt="23px"
          pb="22px"
          background="#FFF"
          borderRadius="10px"
        >
          <Stack spacing="20px">
            {otherLinks.map((link) => (
              <Link key={link.id} to={link.url} target="_blank">
                <ButtonLink>
                  {link.title}
                  <Icon as={ExternalLinkIcon} color="#919191" ml="10px" />
                </ButtonLink>
              </Link>
            ))}
          </Stack>
        </Box>
      </Stack>
    </form>
  );
};

import { Box, Flex, Select, Stack } from "@chakra-ui/react";
import { FC } from "react";
import { EMPTY_STRING } from "../../../lib/Const";
import { useMemberManagement } from "../../../lib/hooks/ManagementSettings/useMemberManagement";
import { useAppSelector } from "../../../redux/storeConfig";
import { ButtonPrimary } from "./Button/ButtonPrimary";
import { InviteMemberModal } from "./Modal/InviteMemberModal";
import { TableMember } from "./Table/TableMember";

export const MemberManagement: FC = () => {
  const { modalName, filter, onChangeFilter, openModal, closeModal } =
    useMemberManagement();
  const { teamList } = useAppSelector((state) => state.team);

  return (
    <Box borderRadius="10px" background="white" p="20px">
      <Stack spacing="10px">
        <Flex justify="space-between">
          <ButtonPrimary onClick={() => openModal("INVITE_MEMBER")}>
            メンバーを招待
          </ButtonPrimary>
          <Select variant="custom" w="200px" h="30px" onChange={onChangeFilter}>
            <option value={EMPTY_STRING}>すべてのメンバー</option>
            {teamList.map((option, index) => (
              <option key={index} value={option.gtteamname}>
                {option.gtteamname}
              </option>
            ))}
          </Select>
        </Flex>
        <TableMember filter={filter} />
      </Stack>

      {/* Modal */}
      <InviteMemberModal
        title="メンバーを招待"
        isOpen={modalName === "INVITE_MEMBER"}
        onClose={closeModal}
      />
    </Box>
  );
};

import { Button, ButtonProps } from "@chakra-ui/react";
import { FC } from "react";

interface ButtonLinkProps extends ButtonProps {
  danger?: boolean;
}

export const ButtonLink: FC<ButtonLinkProps> = (props) => {
  const { children, danger = false, ...otherProps } = props;
  return (
    <Button
      variant="link"
      color={danger ? "#FC3737" : "#3796FC"}
      fontSize="15px"
      fontWeight="medium"
      lineHeight="21px"
      {...otherProps}
    >
      {children}
    </Button>
  );
};

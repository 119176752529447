import { useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import { getTeamListThunk } from "../../../redux/thunk/teamThunk";

type ModalName = "CREATE_ALERT";

export const useAlertManagement = () => {
  const [modalName, setModalName] = useState<ModalName | null>(null);
  const dispatch = useAppDispatch();
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);

  const openModal = (name: ModalName) => {
    setModalName(name);
  };

  const closeModal = () => {
    setModalName(null);
  };

  useLayoutEffect(() => {
    if (adcompanyid && aduserid) {
      dispatch(
        getTeamListThunk({
          payload: {
            adcompanyid,
            aduserid,
          },
        })
      );
    }
  }, [adcompanyid, aduserid, dispatch]);

  return {
    modalName,
    openModal,
    closeModal,
  };
};

import React from "react";

import { Image } from "@chakra-ui/react";

import iconList from "../../lib/Icons";

type Props = {
  color: string;
  iconName: string;
  width?: number;
  height?: number;
  widthMediumScreen?: number;
  heightMediumScreen?: number;
};

// 作業ログ画面内で丸のアイコンを表示する際に使うパーツ
const CircleIcon = React.memo((props: Props) => {
  const color = !props.color?.length ? "gray" : props.color;
  const src = iconList[props.iconName] ?? null;
  return (
    <Image
      src={src}
      borderRadius="50%"
      bg={color}
      w={`${props?.width}rem`}
      h={`${props?.height}rem`}
    />
  );
});
CircleIcon.displayName = "CircleIcon";
export default CircleIcon;

import { FC, ReactNode } from "react";
import { MemberFrame } from "../../../../@types/Member";
import { useChangeUserProfile } from "../../../../lib/hooks/ManagementSettings/useChangeUserProfile";
import { CommonModal } from "../../CommonModal";
import { UserForm } from "../Form/UserForm";

type ChangeUserProfileModalProps = {
  user: MemberFrame | null;
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onOk?: () => void;
};

export const ChangeUserProfileModal: FC<ChangeUserProfileModalProps> = (
  props
) => {
  const { isOpen, title, user, onClose, onOk } = props;
  const { formState, handleSubmit, onSubmit, register, onCloseModal } =
    useChangeUserProfile({ user, onClose, onOk });

  return (
    <CommonModal
      isOpen={isOpen}
      title={title}
      onClose={onCloseModal}
      minW={970}
      isCentered
    >
      <UserForm
        userData={user}
        formState={formState}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
      />
    </CommonModal>
  );
};

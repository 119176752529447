'use strict;';

import {
  generate24HourScreenShotsInfo,
  generateUnder24HourScreenShotsInfo,
} from './screenShotsCalculate';

type Props = {
  timeZoneIndex: number | undefined;
  screenShotsOneArrayData: ScreenShotsAtOneMinute[];
  screenShotsData: ScreenShotsDataAtOneHour[];
  activeTimeZone: HoveredTimeZoneItemPosition;
};

/**
 * @remarks
 * 複数時間のスクリーンショットデータを1次元配列にして返す
 *
 * @param screenshotsData スクリーンショット情報 @see [DummyData](src/data/dummy1/screenshots_dummy.json)
 *
 * @returns
 * @see [DummyData](src/data/example/screenShotsOneline.json)
 */
const generateScreenShotsOneLine = (
  screenshotsData: ScreenShotsDataAtOneHour[]
): ScreenShotsAtOneMinute[] => {
  const result = screenshotsData
    .map((oneHourValue: ScreenShotsDataAtOneHour, index: number) => {
      return oneHourValue.detaildata.map(
        (data: ScreenShotsAtOneMinute, i: number) => {
          return data;
        }
      );
    })
    .reduce((pre, current) => {
      pre.push(...current);
      return pre;
    }, []);
  return result;
};

/**
 * @remarks
 * 各時間帯に合わせてGridに表示するスクリーンショットの情報を返す
 *
 * @param Props {@link Props}
 *  @param timeZoneIndex 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *  @param screenShotsOneArrayData 1次元配列のスクリーンショットデータ　@see [DummyData](src/data/example/screenShotsOneline.json)
 *  @param screenShotsData スクリーンショット情報 @see [DummyData](src/data/dummy1/screenshots_dummy.json)
 *  @param activeTimeZone グラフで表示する中央の時間帯（24時間帯：number、12時間帯以下：string） {@link useMouseHoveredTimeZone}
 *
 * @returns
 * @see {@link ScreenShotsGridDataArray}
 * @see [DummyData](src/data/example/generate24HourScreenShotsInfo.json)
 * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo12hCount.json)
 * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo6hCount.json)
 * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo3hCount.json)
 * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo1hCount.json)
 * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo30mCount.json)
 */
const generateScreenShotsData = ({
  timeZoneIndex,
  screenShotsOneArrayData,
  screenShotsData,
  activeTimeZone = undefined,
}: Props): ScreenShotsGridData[] | undefined => {
  switch (timeZoneIndex) {
    case 0:
      /** @see [DummyData](src/data/example/generate24HourScreenShotsInfo.json) */
      return generate24HourScreenShotsInfo(
        screenShotsOneArrayData,
        screenShotsData
      );

    case 1:
    case 2:
    case 3:
    case 4:
    case 5:
      if (!activeTimeZone) return undefined;
      /**
       * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo12hCount.json)
       * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo6hCount.json)
       * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo3hCount.json)
       * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo1hCount.json)
       * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo30mCount.json)
       */
      return generateUnder24HourScreenShotsInfo(
        activeTimeZone,
        timeZoneIndex,
        screenShotsOneArrayData,
        screenShotsData
      );

    default:
      return undefined;
  }
};

export { generateScreenShotsOneLine, generateScreenShotsData };


import { useState } from 'react';

type Values = {
  mouseKeyboardDatas: MountKeyboardReport1Hour[];
};

type Actions = {
  setMouseKeyboardDatas: SetStateActionDispatcher<MountKeyboardReport1Hour[]>;
};

type UseMouseKeyboardDatas = [Values, Actions];

/**
 * @remarks
 * スケジュール情報を状態管理するためのhooks
 *
 * Shortest name:  {@link useMouseKeyboardDatas}
 * Full name:      {@link useMouseKeyboardDatas}
 *
 * @returns
 * [
 *  values: {
 *    mouseKeyboardDatas: [
 *      {
 *        "time_range": 5,
 *        "detaildata": {
 *             "repeat": 6,
 *             "backspace": 11,
 *             "cancel": 3,
 *             "mouse": 9,
 *             "keyboard": 16,
 *             "others": 4,
 *             "shortcut": 8
 *            }
 *      },
 *    ︙
 *    ]
 *  },
 *  actions: {
 *    setScheduleDatas: スケジュール情報を更新する
 *  }
 * ]
 *
 * @public
 */
const useMouseKeyboardDatas = (): UseMouseKeyboardDatas => {
  /**
   * ダミーデータを使った場合のmouseKeyboardDatasの値の例
   *
   * @example
   * [
   *      {
   *        "time_range": 5,
   *        "detaildata": {
   *             "repeat": 6,
   *             "backspace": 11,
   *             "cancel": 3,
   *             "mouse": 9,
   *             "keyboard": 16,
   *             "others": 4,
   *             "shortcut": 8
   *            }
   *      },
   *      {
   *        "time_range": 5,
   *        "detaildata": {
   *             "repeat": 6,
   *             "backspace": 11,
   *             "cancel": 3,
   *             "mouse": 9,
   *             "keyboard": 16,
   *             "others": 4,
   *             "shortcut": 8
   *            }
   *      },
   * ]
   */
  const [mouseKeyboardDatas, setMouseKeyboardDatas] = useState<MountKeyboardReport1Hour[]>([]);

  const values = { mouseKeyboardDatas };

  const actions = { setMouseKeyboardDatas };

  return [values, actions];
};

export default useMouseKeyboardDatas;

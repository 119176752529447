
import { useState } from 'react';

type ScreenShotsOneArrayData = [] | ScreenShotsAtOneMinute[];
type ScreenShotsData = [] | ScreenShotsDataAtOneHour[];

type Values = {
  screenShotsOneArrayData: ScreenShotsOneArrayData;
  screenShotsData: ScreenShotsData;
};

type Actions = {
  setScreenShotsOneArrayData: SetStateActionDispatcher<ScreenShotsOneArrayData>;
  setScreenshotsData: SetStateActionDispatcher<ScreenShotsData>;
};

type UseScreenShotsOneArrayData = [Values, Actions];

/**
 * @remarks
 * スクリーンショット情報を状態管理するためのhooks
 *
 * Shortest name:  {@link useScreenShotsOneArrayData}
 * Full name:      {@link useScreenShotsOneArrayData}
 *
 * @returns
 * [
 *  values: {
 *    screenShotsOneArrayData: スクリーンショット表示用のデータを1次元配列に変換したデータ,
 *    screenShotsData: スクリーンショット表示用のデータ
 *  },
 *  actions: {
 *    setScreenShotsOneArrayData: スクリーンショット表示用のデータを1次元配列に変換したデータを更新する,
 *    setScreenshotsData: スクリーンショット表示用のデータを更新する
 *  }
 * ]
 *
 * @public
 */
const useScreenShotsOneArrayData = (): UseScreenShotsOneArrayData => {
  /**
   * ダミーデータを使った場合のscreenShotsOneArrayDataの値の例
   * @see [DummyData](src/data/example/screenShotsOneline.json)
   * */
  const [screenShotsOneArrayData, setScreenShotsOneArrayData] =
    useState<ScreenShotsOneArrayData>([]);

  /**
   * ダミーデータを使った場合のscreenShotsDataの値の例
   * @see [DummyData](src/data/dummy1/screenshots_dummy.json)
   * */
  const [screenShotsData, setScreenshotsData] = useState<ScreenShotsData>([]);

  const values = { screenShotsOneArrayData, screenShotsData };

  const actions = { setScreenShotsOneArrayData, setScreenshotsData };

  return [values, actions];
};

export default useScreenShotsOneArrayData;

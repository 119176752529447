import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { ChangeUserPIC } from "../../../@types/User";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import { changeUserPictureThunk } from "../../../redux/thunk/userThunk";
import { ACCEPTED_IMAGE_TYPES, EMPTY_STRING, MAX_FILE_SIZE } from "../../Const";

type UseChangeAvatarType = {
  onClose: () => void;
};

const schema = z.object({
  file: z
    .any()
    .optional()
    .refine(
      (file) =>
        file.length === 1
          ? ACCEPTED_IMAGE_TYPES.includes(file?.[0]?.type)
            ? true
            : false
          : true,
      // TODO: handle file extension validation
      "Invalid file. Choose PNG image"
    )
    .refine(
      (file) =>
        file.length === 1
          ? file[0]?.size <= MAX_FILE_SIZE
            ? true
            : false
          : true,
      // TODO: handle max file size validation
      "Max file size allowed is 5MB."
    ),
});

type UpdateAvatarForm = z.infer<typeof schema>;

export const useChangeAvatar = (props: UseChangeAvatarType) => {
  const { onClose } = props;
  const dispatch = useAppDispatch();
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<UpdateAvatarForm>({
    resolver: zodResolver(schema),
  });

  const onCloseModal = () => {
    onClose();
    reset();
  };

  const onSubmit = async (values: UpdateAvatarForm) => {
    const payload: ChangeUserPIC = {
      upcrud: "U",
      upcompanyid: adcompanyid || EMPTY_STRING,
      upuserid: aduserid || EMPTY_STRING,
      // TODO
      upuserpic: values.file?.[0] || EMPTY_STRING,
    };
    dispatch(
      changeUserPictureThunk({
        payload,
        onSuccess: () => {
          onCloseModal();
        },
      })
    );
  };

  return {
    errors,
    isSubmitting,
    handleSubmit,
    register,
    reset,
    onSubmit,
    onCloseModal,
  };
};

import { TableContainer } from "@chakra-ui/react";
import { FC, Fragment } from "react";
import { useTableMember } from "../../../../lib/hooks/ManagementSettings/useTableMember";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonTable } from "../../CommonTable";
import { DeleteModal } from "../../DeleteModal";
import { ChangeEmailAddressModal } from "../Modal/ChangeEmailAddressModal";
import { ChangeTeamModal } from "../Modal/ChangeTeamModal";
import { UpdateMemberModal } from "../Modal/UpdateMemberModal";

type TableMemberProps = {
  filter: string;
};

export const TableMember: FC<TableMemberProps> = (props) => {
  const { filter } = props;
  const { data, columns, modalName, selectedMember, closeModal, deleteMember } =
    useTableMember({ filter });
  const { isLoading } = useAppSelector((state) => state.loading);

  return (
    <Fragment>
      <TableContainer>
        <CommonTable
          columns={columns}
          data={data}
          containerClass="table-member-container"
        />
      </TableContainer>

      {/* Modal */}
      <UpdateMemberModal
        member={selectedMember}
        title="メンバー情報"
        isOpen={modalName === "CHANGE_PROFILE"}
        onClose={closeModal}
      />

      <ChangeTeamModal
        isLoading={isLoading}
        member={selectedMember}
        title="所属チームの変更"
        isOpen={modalName === "CHANGE_TEAM"}
        onClose={closeModal}
      />

      <ChangeEmailAddressModal
        title="メールアドレスの変更"
        isOpen={modalName === "CHANGE_EMAIL"}
        onClose={closeModal}
      />

      <DeleteModal
        isOpen={modalName === "DELETE_MEMBER" && !!selectedMember}
        message="このメンバーを削除しますか？"
        onOk={deleteMember}
        onClose={closeModal}
      />
    </Fragment>
  );
};

import { MINTIMEZONE, MAXTIMEZONE, addRangeEndTime } from "./Const";

import ranges from "./timeConfig";

// hour 0~24のうちの1つ
// minutes 0~59のうちの1つ
// sumOrSub true:加算、false:減算
type TimeCalculateProps = {
  time: { hour: number; minutes: number };
  addTime: { hour: number; minutes: number };
};

/**
 * @remarks
 * 秒数から分を計算する
 *
 * @param timeSeconds {number} - 秒数
 *
 * @returns
 * 「分」形式の文字列
 */
const formatMinute = (timeSeconds: number): string => {
  const usedTime = Math.floor((Math.abs(timeSeconds) % 3600) / 60);
  if (usedTime < 1) {
    let minutes: number = convertSecondsToMinutes(timeSeconds);
    return `${minutes}分`;
  }
  return `${usedTime}分`;
};

function convertSecondsToMinutes(seconds: number): number {
  for (const range of ranges) {
    if (seconds >= range.start && seconds <= range.end) {
      return range.value;
    }
  }
  return 0;
}

const getHourFromString = (inputString: string): string => {
  let dateObject: Date;
  // ISO 8601
  if (inputString.includes("T") && inputString.includes("+")) {
    dateObject = new Date(inputString);
    const localHour = dateObject.toLocaleString("en-US", {
      hour: "numeric",
      hour12: false,
      timeZone: "Asia/Tokyo",
    });
    return String(parseInt(localHour, 10));
  } else {
    const hours = inputString.split(":")[0];
    return String(parseInt(hours, 10));
  }
};

function getTimeFromString(inputString: string): string {
  let dateObject: Date;
  // ISO 8601
  if (inputString.includes("T") && inputString.includes("+")) {
    dateObject = new Date(inputString);
    const localHour = dateObject.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
      timeZone: "Asia/Tokyo",
    });
    return localHour;
  } else {
    return inputString;
  }
}

/**
 * @remarks
 * 秒数から「時:分」形式の文字列に変換する
 *
 * @param timeSeconds {number} - 秒数
 *
 * @returns
 * 「時：分」形式の文字列
 */
const formatHourAndMinute = (timeSeconds: number): string => {
  const hour = Math.floor(Math.abs(timeSeconds) / 3600);
  let minute: number | string = Math.floor((Math.abs(timeSeconds) % 3600) / 60);

  const paddingZero = (n: number) => {
    return n < 10 ? "0" + n : n;
  };

  minute = paddingZero(minute);
  return `${hour}:${minute}`;
};

/**
 * @remarks
 * 開始時間と表示している時間帯をもとに終了時間を計算する
 *
 * @param timeZoneString {string} - 「時：分」式の文字列
 * @param timeZoneIndex {number} - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *
 * @returns
 * 「時：分」形式の文字列
 */
const endTimeZoneRange = (timeZoneString: string, timeZoneIndex: number) => {
  const splitHourAndMinutes = timeZoneString.split(":");
  const time = {
    hour: parseInt(splitHourAndMinutes[0]),
    minutes: parseInt(splitHourAndMinutes[1]),
  };
  return calculateActiveRangeEndTime(time, addRangeEndTime[timeZoneIndex]);
};

/**
 * @remarks
 * 開始時間と加算する時間単位をもとに終了時間を計算する
 *
 * @param time { hour: number; minutes: number } - 中心となる時間と分
 * @param addTime { hour: number; minutes: number } - 範囲計算用として加算・減算用の時間と分
 *
 * @returns
 * 「時：分」形式の文字列
 */
const calculateActiveRangeEndTime = (
  time: { hour: number; minutes: number },
  addTime: number
): string => {
  const timeToSeconds = time.hour * 60 * 60 + time.minutes * 60;
  const addTimeToSeconds = addTime * 60;
  const addedTime = timeToSeconds + addTimeToSeconds;

  if (addedTime >= 86400) return MAXTIMEZONE;
  return formatHourAndMinute(addedTime);
};

/**
 * @remarks
 * 中心となる時間と範囲計算用の時間をもとに、時間の範囲を計算する
 *
 * @param TimeCalculateProps @see {@link TimeCalculateProps}
 *  @param time { hour: number; minutes: number } - 中心となる時間と分
 *  @param addTime { hour: number; minutes: number } - 範囲計算用として加算・減算用の時間と分
 *
 * @returns
 * @see {@link MinMaxTimeZone}
 */
const timeSumOrSub = ({
  time,
  addTime,
}: TimeCalculateProps): MinMaxTimeZone => {
  let minTime: string, maxTime: string;

  const activeTimeZoneSecond = time.hour * 60 * 60 + time.minutes * 60;
  const addTimeSecond = addTime.hour * 60 * 60 + addTime.minutes * 60;

  const maxTImeIfsubResultsUnder0 = addTimeSecond * 2;
  const minTImeIfsumResultOver24 = 86400 - maxTImeIfsubResultsUnder0;

  const subResult = activeTimeZoneSecond - addTimeSecond;
  const sumResult = activeTimeZoneSecond + addTimeSecond;

  if (subResult <= 0) {
    minTime = MINTIMEZONE;
    maxTime = formatHourAndMinute(maxTImeIfsubResultsUnder0);
  } else if (sumResult >= 86400) {
    minTime = formatHourAndMinute(minTImeIfsumResultOver24);
    maxTime = MAXTIMEZONE;
  } else {
    minTime = formatHourAndMinute(subResult);
    maxTime = formatHourAndMinute(sumResult);
  }

  return { minTime, maxTime };
};

export {
  timeSumOrSub,
  formatMinute,
  formatHourAndMinute,
  endTimeZoneRange,
  calculateActiveRangeEndTime,
  getHourFromString,
  getTimeFromString,
};

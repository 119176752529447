import { createSlice } from "@reduxjs/toolkit";
import {
  TAlertFrame,
  TAdminAlertMaster,
  TAdminAlertAppUrlFrame,
} from "../../@types/Alert";
import {
  getAdminAlertDataThunk,
  getAdminAlertMasterDataThunk,
  getAdminAlertAppUrlDataThunk,
} from "../thunk/alertThunk";

type TAlertState = {
  alertAppUrlData: TAdminAlertAppUrlFrame[];
  alertMasterData: TAdminAlertMaster[];
  alertList: TAlertFrame[];
  selectedAlertData: TAlertFrame | null;
};

const initialState: TAlertState = {
  alertAppUrlData: [],
  alertMasterData: [],
  alertList: [],
  selectedAlertData: null,
};

const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlertList: (state, action) => {
      state.alertList = action.payload;
    },
    setSelectedAlertData: (state, action) => {
      state.selectedAlertData = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getAdminAlertDataThunk.fulfilled, (state, action) => {
      state.alertList = action.payload;
    });

    builder.addCase(getAdminAlertMasterDataThunk.fulfilled, (state, action) => {
      state.alertMasterData = action.payload;
    });

    builder.addCase(getAdminAlertAppUrlDataThunk.fulfilled, (state, action) => {
      state.alertAppUrlData = action.payload;
    });
  },
});

export const { setAlertList, setSelectedAlertData } = alertSlice.actions;
export default alertSlice.reducer;

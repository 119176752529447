
import { useState } from 'react';

type Values = {
  scheduleDatas: ScheduleDatas;
};

type Actions = {
  setScheduleDatas: SetStateActionDispatcher<ScheduleDatas>;
};

type UseScheduleDatas = [Values, Actions];

/**
 * @remarks
 * スケジュール情報を状態管理するためのhooks
 *
 * Shortest name:  {@link useScheduleDatas}
 * Full name:      {@link useScheduleDatas}
 *
 * @returns
 * [
 *  values: {
 *    scheduleDatas: [
 *      {
 *        "subject": 会議名,
 *        "start": 会議が開始する時間（時：分）,
 *        "end": 会議が終了する時間（時：分）,
 *        "timeZone": 地域のタイムゾーン（"Asia/Tokyo"）
 *      },
 *    ︙
 *    ]
 *  },
 *  actions: {
 *    setScheduleDatas: スケジュール情報を更新する
 *  }
 * ]
 *
 * @public
 */
const useScheduleDatas = (): UseScheduleDatas => {
  /**
   * ダミーデータを使った場合のscheduleDatasの値の例
   *
   * @example
   * [
   *  {
   *    "subject": "開発×営業　共有会",
   *    "start": "17:00",
   *    "end": "18:00",
   *    "timeZone": "Asia/Tokyo"
   *  },
   *  {
   *    "subject": "開発×営業　共有会",
   *    "start": "17:00",
   *    "end": "18:00",
   *    "timeZone": "Asia/Tokyo"
   *  },
   *  {
   *    "subject": "機能開発・改善に関する会議",
   *    "start": "18:00",
   *    "end": "18:29",
   *    "timeZone": "Asia/Tokyo"
   *  }
   * ]
   */
  const [scheduleDatas, setScheduleDatas] = useState<ScheduleDatas>([]);

  const values = { scheduleDatas };

  const actions = { setScheduleDatas };

  return [values, actions];
};

export default useScheduleDatas;

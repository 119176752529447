import {
  Avatar,
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/table-core";
import { useCallback, useLayoutEffect, useMemo, useState } from "react";
import { TfiMoreAlt } from "react-icons/tfi";
import {
  GetMemberList,
  MemberFrame,
  TGetAdminTeamBelongDataPayload,
} from "../../../@types/Member";
import { TextTableItem } from "../../../components/parts/ManagementSettings/Typography/TextTableItem";
import { setMemberList } from "../../../redux/slice/memberSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  deleteMemberThunk,
  getAdminTeamBelongMemberThunk,
  getMemberListThunk,
} from "../../../redux/thunk/memberThunk";
import { EMPTY_STRING } from "../../Const";
import { getName } from "../../util/helper";
import { renderAuthority } from "../../util/render";
import { useMessage } from "../useMessage";

type ModalName =
  | "CHANGE_PROFILE"
  | "CHANGE_EMAIL"
  | "CHANGE_TEAM"
  | "DELETE_MEMBER";

type UseTableMemberProps = {
  filter: string;
};

export const useTableMember = (props: UseTableMemberProps) => {
  const { filter } = props;
  const dispatch = useAppDispatch();
  const { showMessage } = useMessage();
  const columnHelper = createColumnHelper<MemberFrame>();
  const { adcompanyid, adminAuthorityList: adminAuthority } = useAppSelector(
    (state) => state.userInfo
  );
  const { memberList } = useAppSelector((state) => state.member);
  const [modalName, setModalName] = useState<ModalName | null>(null);
  const [selectedMember, setSelectedMember] = useState<MemberFrame | null>(
    null
  );

  const data = useMemo(() => {
    if (filter === EMPTY_STRING) {
      return memberList;
    }
    return memberList.filter((item) => item.gtteamname.includes(filter)) || [];
  }, [memberList, filter]);

  const getMemberList = useCallback(() => {
    if (adcompanyid) {
      const payload: GetMemberList = {
        urcompanyid: adcompanyid,
      };
      dispatch(getMemberListThunk({ payload }));
    }
  }, [dispatch, adcompanyid]);

  const getTeamBelongMember = useCallback(
    (member: MemberFrame) => {
      if (adcompanyid) {
        const payload: TGetAdminTeamBelongDataPayload = {
          adcompanyid,
          aduserid: member.gtuserid,
        };
        dispatch(
          getAdminTeamBelongMemberThunk({
            payload,
            onSuccess: () => {
              openModal("CHANGE_TEAM", member);
            },
          })
        );
      }
    },
    [adcompanyid, dispatch]
  );

  const deleteMember = useCallback(() => {
    if (selectedMember && adcompanyid) {
      dispatch(
        deleteMemberThunk({
          payload: {
            upcrud: "D",
            upcompanyid: adcompanyid,
            upuserid: selectedMember.gtuserid,
          },
          onSuccess: (message) => {
            const newMemberList = memberList.filter(
              (item) => item.gtuserid !== selectedMember.gtuserid
            );
            dispatch(setMemberList(newMemberList));
            closeModal();
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
          },
          onError: (message) => {
            closeModal();
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  }, [adcompanyid, selectedMember, memberList, dispatch, showMessage]);

  const openModal = (name: ModalName, member: MemberFrame) => {
    setSelectedMember(member);
    setModalName(name);
  };

  const closeModal = () => {
    setSelectedMember(null);
    setModalName(null);
  };

  const columns = useMemo(() => {
    return [
      columnHelper.accessor("gtuserlastname", {
        cell: (info) => {
          return (
            <Flex align="center" gap="10px">
              <Avatar src={info.row.original?.gtuserpic} w="26px" h="26px" />
              <TextTableItem color="#2C2C2C" fontSize="12px" fontWeight="bold">
                {getName([
                  info.row.original.gtuserlastname,
                  info.row.original.gtuserfirstname,
                ])}
              </TextTableItem>
            </Flex>
          );
        },
        header: "名前",
        sortingFn: "sortByJapanese",
      }),
      columnHelper.accessor("gtteamname", {
        cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
        header: "所属チーム",
        sortingFn: "sortByJapanese",
      }),
      columnHelper.accessor("gtoccupation", {
        cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
        header: "職種",
        sortingFn: "sortByJapanese",
      }),
      columnHelper.accessor("gttitle", {
        cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
        header: "肩書",
        sortingFn: "sortByJapanese",
      }),
      columnHelper.accessor("gtcontract", {
        cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
        header: "契約形態",
        sortingFn: "sortByJapanese",
      }),
      columnHelper.accessor("gtlocation", {
        cell: (info) => <TextTableItem>{info.getValue()}</TextTableItem>,
        header: "勤務地",
        sortingFn: "sortByJapanese",
      }),
      columnHelper.accessor("gtauthority", {
        cell: (info) => (
          <TextTableItem>
            {renderAuthority(adminAuthority, info.getValue())}
          </TextTableItem>
        ),
        header: "権限",
        sortingFn: "sortByJapanese",
      }),
      columnHelper.accessor("gtuserid", {
        cell: (info) => (
          <Menu variant="custom">
            <Flex align="center" justifyContent="right" w="100%">
              <MenuButton
                as={IconButton}
                variant="outline"
                size="sm"
                textAlign="center"
                aria-label="Search team"
                borderColor="transparent"
                w="28px"
                h="28px"
                icon={<TfiMoreAlt size={16} />}
              />
            </Flex>
            <MenuList w="260px">
              <MenuItem
                onClick={() => openModal("CHANGE_PROFILE", info.row.original)}
              >
                メンバー情報を編集する
              </MenuItem>
              <MenuItem onClick={() => getTeamBelongMember(info.row.original)}>
                所属チームを編集する
              </MenuItem>
              {/* TODO */}
              {/* <MenuItem onClick={() => openModal("CHANGE_EMAIL")}>
                メールアドレスを変更する
              </MenuItem> */}
              <MenuItem
                color="#FF4B45"
                onClick={() => openModal("DELETE_MEMBER", info.row.original)}
              >
                アカウントを削除する
              </MenuItem>
            </MenuList>
          </Menu>
        ),
        header: "",
        enableSorting: false,
      }),
    ];
  }, [columnHelper, adminAuthority, getTeamBelongMember]);

  useLayoutEffect(() => {
    getMemberList();
  }, [getMemberList]);

  return {
    data,
    columns,
    modalName,
    selectedMember,
    openModal,
    closeModal,
    deleteMember,
  };
};

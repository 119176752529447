import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, useCallback, useLayoutEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  TAdminAlertMaster,
  TAlertFrame,
  TUpdateAdminAlertDataPayload,
} from "../../../@types/Alert";
import {
  EAlertTiming,
  EAlertType,
  EEmailNotification,
  ETargetNotification,
} from "../../../enums/alert";
import { setSelectedAlertData } from "../../../redux/slice/alertSlice";
import { setTeamUserData } from "../../../redux/slice/teamSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  getAdminAlertDataThunk,
  updateAdminAlertDataThunk,
} from "../../../redux/thunk/alertThunk";
import { getTeamUserDataThunk } from "../../../redux/thunk/teamThunk";
import { EMPTY_STRING } from "../../Const";
import { alertTimeOptions, hourNoUnits, hours, minutes } from "../../timeData";
import { generateHashId } from "../../util/helper";
import {
  REQUIRED_AT_LEAST_ONE_MEMBER,
  REQUIRED_FIELD,
} from "../../util/messages";
import { useMessage } from "../useMessage";

type UseCreateAlertType = {
  isOpen: boolean;
  isUpdate?: boolean;
  alertData: TAlertFrame | null;
  onClose: () => void;
};

const schema = z
  .object({
    displayname: z.string().trim().min(1, REQUIRED_FIELD),
    alertname: z.string().trim().optional(),
    alertid: z.string().trim().optional(),
    alerttimestart: z.string().trim().optional(),
    alerttimeend: z.string().trim().optional(),
    alertmax: z.string().trim().optional(),
    alertexe: z.string().trim().optional(),
    alerturl: z.string().trim().optional(),
    notifymail: z.string().trim().optional(),
    notifyadmin: z.boolean().optional(),
    notifymember: z.boolean().optional(),
    notifytiming: z.string().trim().optional(),
    notifyhour: z.string().trim().optional(),
    notifyminute: z.string().trim().optional(),
    teamid: z.string().trim().optional(),
    notifytarget: z.union([
      z.literal(ETargetNotification.ALL.toString()),
      z.literal(ETargetNotification.MEMBER.toString()),
    ]),
    userid: z.string().array(),
  })
  .superRefine((data, ctx) => {
    if (
      data.notifytarget === ETargetNotification.MEMBER.toString() &&
      !data.userid?.length
    ) {
      ctx.addIssue({
        code: "custom",
        path: ["userid"],
        message: REQUIRED_AT_LEAST_ONE_MEMBER,
      });
    }
  });

type AlertForm = z.infer<typeof schema>;

export const useCreateAlert = (props: UseCreateAlertType) => {
  const { isOpen, isUpdate, alertData, onClose } = props;
  const [timeType, setTimeType] = useState("before");
  const dispatch = useAppDispatch();
  const { showMessage } = useMessage();
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);
  const { teamList, teamUserData } = useAppSelector((state) => state.team);
  const { alertMasterData, alertAppUrlData } = useAppSelector(
    (state) => state.alert
  );
  const [selectedAlertType, setSelectedAlertType] =
    useState<TAdminAlertMaster | null>(alertMasterData?.[0] || null);

  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    control,
    trigger,
    setValue,
    watch,
  } = useForm<AlertForm>({
    resolver: zodResolver(schema),
    values: {
      alertid:
        alertData?.gtalertid?.toString() ||
        alertMasterData?.[0]?.gtmalertid?.toString(),
      alertexe:
        alertData?.gtalertexe ||
        alertAppUrlData.filter((item) => item.gtappurltype === 1)?.[0]
          ?.gtappurlname ||
        EMPTY_STRING,
      alertmax:
        alertData?.gtalertmax?.toString() || hourNoUnits[0].value.toString(),
      alertname: alertData?.gtalertname || EMPTY_STRING,
      alerttimestart:
        alertData?.gtalerttimestart?.toString() || alertTimeOptions[0].value,
      alerttimeend:
        alertData?.gtalerttimeend?.toString() || alertTimeOptions[0].value,
      displayname: alertData?.gtalertname || EMPTY_STRING,
      notifyadmin:
        isUpdate && alertData?.gtnotifyadmin !== undefined
          ? Boolean(alertData?.gtnotifyadmin)
          : true,
      notifymember:
        isUpdate && alertData?.gtnotifymember !== undefined
          ? Boolean(alertData?.gtnotifymember)
          : true,
      notifytiming: alertData?.gtnotifytiming
        ? alertData?.gtnotifytiming.toString()
        : EAlertTiming.MEET_CONDITION.toString(),
      notifytarget: alertData?.gttarget
        ? alertData?.gttarget.toString()
        : ETargetNotification.ALL.toString(),
      notifymail: alertData?.gtnotifymail
        ? alertData?.gtnotifymail.toString()
        : EEmailNotification.ON.toString(),
      alerturl: alertData?.gtalerturl || EMPTY_STRING,
      notifyhour:
        alertData?.gtnotifyhour?.toString() || hours[0].value.toString(),
      notifyminute:
        alertData?.gtnotifyminute?.toString() || minutes[0].value.toString(),
      teamid: alertData?.gtteamid || teamList[0]?.gtteamid,
      userid:
        alertData?.gtuserframe?.reduce((userList, curr) => {
          if (curr.gtusercheck) {
            return [...userList, curr.gtuserid];
          }
          return userList;
        }, [] as string[]) || [],
    },
  });

  const alertId = watch("alertid");
  const teamid = watch("teamid");
  const notifyTarget = watch("notifytarget");
  const notifyTiming = watch("notifytiming");

  const onChangeAlertType = useCallback(
    (id: string) => {
      const foundAlertType = alertMasterData.find(
        (alert) => alert.gtmalertid?.toString() === id
      );
      setSelectedAlertType(foundAlertType || null);
    },
    [alertMasterData]
  );

  const onCloseModal = () => {
    dispatch(setSelectedAlertData(null));
    dispatch(setTeamUserData(null));
    onClose();
    reset();
  };

  const onChangeTimeType = (e: ChangeEvent<HTMLInputElement>) => {
    const type = e.target.value;
    setValue(
      type === "before" ? "alerttimeend" : "alerttimestart",
      alertTimeOptions[0].value
    );
    setTimeType(type);
  };

  const onGetTeamUserDataList = useCallback(() => {
    if (teamid && adcompanyid && isOpen) {
      dispatch(
        getTeamUserDataThunk({
          payload: {
            adcompanyid,
            adteamid: teamid,
          },
        })
      );
    } else if (notifyTarget === ETargetNotification.ALL.toString()) {
      trigger("userid");
    }
  }, [teamid, adcompanyid, notifyTarget, isOpen, dispatch, trigger]);

  const onSubmit = (values: AlertForm, isClose?: boolean) => {
    if (adcompanyid) {
      const payload: TUpdateAdminAlertDataPayload = {
        upalertid: selectedAlertType?.gtmalertid,
        upcrud: isUpdate ? "U" : "C",
        uphashid:
          alertData?.gtalerthashid || generateHashId(aduserid || EMPTY_STRING),
        upcompanyid: adcompanyid,
        upalerttype: selectedAlertType?.gtmalerttype,

        updisplayname: values.displayname.trim(),
        upalertmin: 0,
        upalertmax: Number(values.alertmax) || 0,
        upalertexe:
          (selectedAlertType?.gtmalerttype === EAlertType.TYPE_1 ||
            selectedAlertType?.gtmalerttype === EAlertType.TYPE_3) &&
          selectedAlertType?.gtmalertname === "特定アプリ使用時間"
            ? values.alertexe?.trim()
            : EMPTY_STRING,
        upalerturl:
          (selectedAlertType?.gtmalerttype === EAlertType.TYPE_1 ||
            selectedAlertType?.gtmalerttype === EAlertType.TYPE_3) &&
          selectedAlertType?.gtmalertname === "特定Webページ使用時間"
            ? values.alerturl?.trim()
            : EMPTY_STRING,
        upalerttimestart:
          timeType === "before" &&
          selectedAlertType?.gtmalerttype === EAlertType.TYPE_2
            ? values.alerttimestart?.toString()
            : EMPTY_STRING,
        upalerttimeend:
          timeType === "after" &&
          selectedAlertType?.gtmalerttype === EAlertType.TYPE_2
            ? values.alerttimeend?.toString()
            : EMPTY_STRING,
        upnotifymail: Number(values.notifymail),
        upnotifyadmin: values.notifyadmin ? 1 : 0,
        upnotifymember: values.notifymember ? 1 : 0,
        upnotifytiming: Number(values.notifytiming),
        upnotifyhour:
          values.notifytiming === EAlertTiming.NEXT_DAY.toString()
            ? Number(values.notifyhour)
            : 0,
        upnotifyminute:
          values.notifytiming === EAlertTiming.NEXT_DAY.toString()
            ? Number(values.notifyminute)
            : 0,
        upteamid: values.teamid,
        upnotifytarget: Number(values.notifytarget),
        upuserid:
          teamUserData?.gtteamuserframe.map((user) => user.gtteamuserid) || [],
        upusercheck:
          values.notifytarget === ETargetNotification.MEMBER.toString()
            ? teamUserData?.gtteamuserframe.map((user) =>
                values.userid?.includes(user.gtteamuserid) ? 1 : 0
              )
            : Array(teamUserData?.gtteamuserframe.length || 0).fill(1),
      };

      dispatch(
        updateAdminAlertDataThunk({
          payload,
          onSuccess: (message) => {
            if (isClose) {
              onCloseModal();
            } else {
              reset();
              dispatch(setTeamUserData(null));
            }
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
            dispatch(
              getAdminAlertDataThunk({
                payload: {
                  adcompanyid,
                },
              })
            );
          },
          onError: (message) => {
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  };

  useLayoutEffect(() => {
    onGetTeamUserDataList();
  }, [onGetTeamUserDataList]);

  useLayoutEffect(() => {
    if (alertData) {
      onChangeAlertType(alertData.gtalertid.toString());
    }
  }, [alertData, onChangeAlertType]);

  return {
    errors,
    isSubmitting,
    control,
    alertId,
    notifyTarget,
    timeType,
    selectedAlertType,
    notifyTiming,
    setValue,
    register,
    reset,
    watch,
    onSubmit,
    handleSubmit,
    onChangeTimeType,
    onChangeAlertType,
    onCloseModal,
  };
};

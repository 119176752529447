import { useState } from "react";
import { RemoveApplicationModalProps } from "../../../components/parts/ManagementSettings/Modal/RemoveApplicationModal";

export const useRemoveApplication = (props: RemoveApplicationModalProps) => {
  const [loading, setLoading] = useState(false);

  const onSubmit = () => {
    setLoading(true);
    props?.onSubmit?.(() => {
      setLoading(false);
    });
  };

  return {
    state: { loading },
    handler: { onSubmit },
  };
};

/* eslint-disable react-hooks/exhaustive-deps */
import {
  Flex,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  Tooltip,
} from "@chakra-ui/react";
import { createColumnHelper } from "@tanstack/table-core";
import { categoryOptions, EMPTY_STRING } from "lib/Const";
import { useEffect, useState } from "react";
import { GoArrowDown, GoArrowUp } from "react-icons/go";
import { TfiMoreAlt } from "react-icons/tfi";
import { applicationService } from "services/applicationService";
import {
  TApplicationDetail,
  TApplicationPayload,
  TApplicationRegistered,
} from "../../../@types/Application";
import { useAppSelector } from "../../../redux/storeConfig";
import { useMessage } from "../useMessage";

export const APPLICATION_UNREGISTERED_MODAL = {
  EDIT_APPLICATION: "EDIT_APPLICATION",
  REMOVE_APPLICATION: "REMOVE_APPLICATION",
};

export const useApplicationRegistered = () => {
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);
  const { showMessage } = useMessage();
  const columnHelper = createColumnHelper<TApplicationRegistered>();
  const [pageSize, setPageSize] = useState<number>(10);
  const [pageNumber, setPageNumber] = useState<number>(1);
  const [sort, setSort] = useState<string[]>([]);
  const [modalName, setModalName] = useState<string | null>(null);
  const [applicationList, setApplicationList] = useState<
    TApplicationRegistered[]
  >([]);
  const [total, setTotal] = useState<number>(0);
  const [applicationSelected, setApplicationSelected] =
    useState<TApplicationDetail | null>(null);

  const columns = [
    columnHelper.accessor("registeredname", {
      cell: (info) => {
        const value = info.getValue();
        const truncatedValue =
          value.length > 18 ? `${value.slice(0, 18)}...` : value;

        return (
          <Tooltip label={value} isDisabled={value.length <= 18} hasArrow>
            <Text fontSize="12px" color="#2C2C2C" fontWeight="bold">
              {truncatedValue}
            </Text>
          </Tooltip>
        );
      },
      header: () => {
        return (
          <Flex width="100%">
            <Text>アプリケーション名</Text>
            {sort?.includes("registeredname") ? (
              sort?.includes("asc") ? (
                <GoArrowUp size="15px" aria-label="sorted ascending" />
              ) : (
                <GoArrowDown size="15px" aria-label="sorted descending" />
              )
            ) : null}
          </Flex>
        );
      },
      enableSorting: false,
      meta: {
        size: "28%",
        customSort: () => {
          onSortChange("registeredname");
        },
      },
    }),
    columnHelper.accessor("category", {
      cell: (info) => (
        <Text fontSize="12px" color="#2C2C2C" fontWeight="bold">
          {(categoryOptions as any)?.[info.getValue()]?.name}
        </Text>
      ),
      header: () => {
        return (
          <Flex width="100%">
            <Text>カテゴリ</Text>
            {sort?.includes("category") ? (
              sort?.includes("asc") ? (
                <GoArrowUp size="15px" aria-label="sorted ascending" />
              ) : (
                <GoArrowDown size="15px" aria-label="sorted descending" />
              )
            ) : null}
          </Flex>
        );
      },
      enableSorting: false,
      meta: {
        size: "28%",
        customSort: () => {
          onSortChange("category");
        },
      },
    }),
    columnHelper.accessor("keystroke", {
      cell: (info) => {
        return (
          <Text fontSize="12px" color="#2C2C2C" fontWeight="bold">
            {formatRecordCell(info.getValue())}
          </Text>
        );
      },
      header: () => {
        return (
          <Flex width="100%">
            <Text>キー入力内容</Text>
            {sort?.includes("keystroke") ? (
              sort?.includes("asc") ? (
                <GoArrowUp size="15px" aria-label="sorted ascending" />
              ) : (
                <GoArrowDown size="15px" aria-label="sorted descending" />
              )
            ) : null}
          </Flex>
        );
      },
      enableSorting: false,
      meta: {
        size: "20%",
        customSort: () => {
          onSortChange("keystroke");
        },
      },
    }),
    columnHelper.accessor("screenshot", {
      cell: (info) => (
        <Text fontSize="12px" color="#2C2C2C" fontWeight="bold">
          {formatRecordCell(info.getValue())}
        </Text>
      ),
      header: () => {
        return (
          <Flex width="100%">
            <Text>スクリーンショット</Text>
            {sort?.includes("screenshot") ? (
              sort?.includes("asc") ? (
                <GoArrowUp size="15px" aria-label="sorted ascending" />
              ) : (
                <GoArrowDown size="15px" aria-label="sorted descending" />
              )
            ) : null}
          </Flex>
        );
      },
      enableSorting: false,
      meta: {
        size: "20%",
        customSort: () => {
          onSortChange("screenshot");
        },
      },
    }),
    columnHelper.accessor("registeredfile", {
      cell: (info) => {
        return (
          <Menu variant="custom">
            <Flex align="center" justifyContent="right" w="100%">
              <MenuButton
                as={IconButton}
                variant="outline"
                size="sm"
                textAlign="center"
                aria-label="Search team"
                borderColor="transparent"
                w="28px"
                h="28px"
                icon={<TfiMoreAlt size={16} />}
              />
            </Flex>
            <MenuList w="260px">
              <MenuItem
                onClick={() => {
                  openModalEdit(info.row.original);
                }}
              >
                編集する
              </MenuItem>
              <MenuItem
                color="#FF4B45"
                onClick={() => {
                  openModalRemove(info.row.original);
                }}
              >
                削除する
              </MenuItem>
            </MenuList>
          </Menu>
        );
      },
      header: EMPTY_STRING,
      enableSorting: false,
    }),
  ];

  const formatRecordCell = (isRecord: boolean) => {
    return isRecord ? "記録する" : "記録しない";
  };

  const openModalEdit = (applicationSelectedProp?: TApplicationRegistered) => {
    setApplicationSelected(
      applicationSelectedProp
        ? {
            applicationExecuteName:
              applicationSelectedProp?.registeredfile ?? EMPTY_STRING,
            applicationName:
              applicationSelectedProp?.registeredname ?? EMPTY_STRING,
            applicationType: applicationSelectedProp?.category ?? EMPTY_STRING,
            typing: applicationSelectedProp?.keystroke ?? true,
            capture: applicationSelectedProp?.screenshot ?? true,
          }
        : null
    );
    setModalName(APPLICATION_UNREGISTERED_MODAL.EDIT_APPLICATION);
  };

  const openModalRemove = (
    applicationSelectedProp?: TApplicationRegistered
  ) => {
    setApplicationSelected(
      applicationSelectedProp
        ? {
            applicationExecuteName:
              applicationSelectedProp?.registeredfile ?? EMPTY_STRING,
            applicationName:
              applicationSelectedProp?.registeredname ?? EMPTY_STRING,
            applicationType: applicationSelectedProp?.category ?? EMPTY_STRING,
            typing: applicationSelectedProp?.keystroke ?? false,
            capture: applicationSelectedProp?.screenshot ?? false,
          }
        : null
    );
    setModalName(APPLICATION_UNREGISTERED_MODAL.REMOVE_APPLICATION);
  };

  const onCloseModal = ({ isFetchData }: { isFetchData?: boolean } = {}) => {
    setModalName(null);
    setApplicationSelected(null);
    if (isFetchData) {
      getDataRegistered();
    }
  };

  const onRemove = async (cb?: () => void) => {
    try {
      await applicationService.cancelRegisteredApplication({
        companyid: adcompanyid?.toString() ?? EMPTY_STRING,
        registeredname:
          applicationSelected?.applicationName?.toString() ?? EMPTY_STRING,
      });
      cb?.();
      onCloseModal({ isFetchData: true });
      showMessage({
        status: "success",
        title: "アプリケーションの削除に成功しました。",
      });
    } catch (_error) {
      cb?.();
      showMessage({
        status: "error",
        title: "アプリケーションの削除に失敗しました。",
      });
    }
  };

  const onSortChange = (sortField: string) => {
    let sortTemp = sort;
    if (!sortTemp?.includes(sortField)) {
      sortTemp = [sortField, "asc"];
    } else if (sortTemp?.includes(sortField)) {
      if (sortTemp?.[1] === "desc") {
        sortTemp = [];
      } else {
        sortTemp = [sortField, "desc"];
      }
    }
    getDataRegistered({
      pageNumberProp: 1,
      sortProp: sortTemp,
    });
  };

  const onPageNumberChange = (pageNumberProp: number) => {
    getDataRegistered({
      pageNumberProp,
    });
  };

  const onPageSizeChange = (pageSizeProp: number) => {
    getDataRegistered({
      pageNumberProp: 1,
      pageSizeProp: pageSizeProp,
    });
  };

  useEffect(() => {
    getDataRegistered();
  }, []);

  const getDataRegistered = async ({
    pageNumberProp = pageNumber,
    pageSizeProp = pageSize,
    sortProp = sort,
  }: {
    pageNumberProp?: number;
    pageSizeProp?: number;
    sortProp?: string[];
  } = {}) => {
    const response = await applicationService.getRegisteredApplication(
      getPayload({
        pageNumberProp,
        pageSizeProp,
        sortProp,
      })
    );
    if (
      response?.data?.total &&
      response?.data?.total <= (pageNumberProp - 1) * pageSizeProp
    ) {
      onPageNumberChange(Math.ceil(response?.data?.total / pageSizeProp));
    } else {
      setPageNumber(pageNumberProp);
      setPageSize(pageSizeProp);
      setSort(sortProp);
      setApplicationList(response?.data?.data);
      setTotal(response?.data?.total);
    }
  };

  const getPayload = ({
    pageNumberProp,
    pageSizeProp,
    sortProp,
  }: {
    pageNumberProp: number;
    pageSizeProp: number;
    sortProp: string[];
  }): TApplicationPayload => {
    const payload: TApplicationPayload = {
      companyid: adcompanyid ?? EMPTY_STRING,
      userid: aduserid ?? EMPTY_STRING,
      pageindex: pageNumberProp?.toString(),
      pagesize: pageSizeProp?.toString(),
    };
    if (sortProp?.length) {
      payload.column = sortProp?.[0];
      payload.asc = sortProp?.[1] === "asc" ? 1 : 0;
    }
    return payload;
  };

  return {
    state: {
      columns,
      applicationList,
      modalName,
      applicationSelected,
      pageNumber,
      pageSize,
      total,
    },
    handler: {
      openModalEdit,
      onCloseModal,
      onRemove,
      onPageNumberChange,
      onPageSizeChange,
    },
  };
};

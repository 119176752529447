
import { useMemo, useState } from 'react';

import { generatexAxisInterval } from '../../lib/rechartsData';
import { tooltipCursorStrokeWidth } from '../../lib/Const';

type Values = {
  timeZoneIndex: number;
  getActiveTimeZoneIndex: number;
  xAxisInterval: number;
  toolTipStrokeWidth: number;
};

type Actions = {
  setTimeZoneIndex: SetStateActionDispatcher<number>;
};

type UseTimeZoneIndex = [Values, Actions];

/**
 * @remarks
 * 総作業時間グラフで選択した時間帯のindexとグラフ表示に必要なプロパティの状態管理するためのhooks
 *
 * Shortest name:  {@link useTimeZoneIndex}
 * Full name:      {@link useTimeZoneIndex}
 *
 * @returns
 * [
 *  values: {
 *    timeZoneIndex: 表示する時間帯
 *    getActiveTimeZoneIndex: 正確な時間帯
 *    xAxisInterval: x軸に表示するメモリの間隔
 *    toolTipStrokeWidth: グラフにマウスでホバーしたときに表示される棒グラフの背景の幅
 *  },
 *  actions: {
 *    setTimeZoneIndex: 表示する時間帯を更新する
 *  }
 * ]
 *
 * @public
 */
const useTimeZoneIndex = (): UseTimeZoneIndex => {
  /**
   * @remarks
   * 表示する時間帯を格納する
   * 0: 24時間
   * 1: 12時間
   * 2: 6時間
   * 3: 3時間
   * 4: 1時間
   * 5: 30分
   *
   * @defaultValue `0`
   */
  const [timeZoneIndex, setTimeZoneIndex] = useState<number>(0);

  const getActiveTimeZoneIndex = useMemo(
    () => (timeZoneIndex > 0 ? timeZoneIndex - 1 : timeZoneIndex),
    [timeZoneIndex]
  );

  const xAxisInterval = useMemo(
    () => generatexAxisInterval(timeZoneIndex),
    [timeZoneIndex]
  );

  const toolTipStrokeWidth = useMemo(
    () => tooltipCursorStrokeWidth[timeZoneIndex],
    [timeZoneIndex]
  );

  const values: Values = {
    timeZoneIndex,
    getActiveTimeZoneIndex,
    xAxisInterval,
    toolTipStrokeWidth,
  };
  const actions: Actions = { setTimeZoneIndex };

  return [values, actions];
};

export default useTimeZoneIndex;

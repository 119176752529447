
import { adjustTime, gridProps, MINTIMEZONE, MAXTIMEZONE } from './Const';
import { calculateMinMaxTimezone } from './commonDataProcessing';

/**
 * @remarks
 * 表示する最小・最大の時間帯、左右の時間帯移動ができるかどうかの真偽値を算出する
 *
 * @param IsTimeZoneMinMaxRangeAbleProps {@link IsTimeZoneMinMaxRangeAbleProps}
 *  @param activeTimeZone {string} - 「時：分」形式の文字列（0:00~24:00）
 *  @param timeZoneIndex 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *
 * @returns
 * @see {@link TimeZoneMinMaxRange}
 */
const timeZoneMinMaxRange = ({
  activeTimeZone,
  timeZoneIndex,
}: IsTimeZoneMinMaxRangeAbleProps): TimeZoneMinMaxRange => {
  // 指定した時間帯と時間から最小時間帯と最大時間帯を計算する
  const { minTime, maxTime } = calculateMinMaxTimezone({
    activeTimeZone,
    adjustTimeByGenerateTimeZone: adjustTime[timeZoneIndex],
  });

  return {
    minTime,
    maxTime,
    isMinTimeAvailable: minTime === MINTIMEZONE ? false : true,
    isMaxTimeAvailable: maxTime === MAXTIMEZONE ? false : true,
  };
};

/**
 * @remarks
 * 表示する時間帯によるGridの設定を返す
 *
 * @param timeZoneIndex 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *
 * @returns
 * 時間帯によるグラフ下部に表示する際に使うGridの大きさ、間隔、padding-leftの設定値
 */
const generateGridPropsUnit = (timeZoneIndex: number): GridProps => {
  switch (timeZoneIndex) {
    case 3:
      return gridProps[1];

    case 4:
      return gridProps[2];

    case 5:
      return gridProps[3];

    case 0:
    case 1:
    case 2:
    default:
      return gridProps[0];
  }
};

/**
 * @remarks
 * 画面に表示する正確な時間帯のindexを返す
 *
 * @param timeZoneIndex 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *
 * @returns
 * 時間帯のindex
 */
const getActiveTimeZoneIndex = (timeZoneIndex: number): number =>
  timeZoneIndex > 0 ? timeZoneIndex - 1 : timeZoneIndex;

export { timeZoneMinMaxRange, generateGridPropsUnit, getActiveTimeZoneIndex };

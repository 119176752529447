import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import teamReducer from "./slice/teamSlice";
import userReducer from "./slice/userSlice";
import memberSlice from "./slice/memberSlice";

import {
  loadFromLocalStorage,
  saveToLocalStorage,
} from "../lib/browserStorage";
import ipSlice from "./slice/ipSlice";
import loadingSlice from "./slice/loadingSlice";
import alertSlice from "./slice/alertSlice";
import applicationSlice from "./slice/applicationSlice";

// Storeの生成
const storeConfig = configureStore({
  // https://redux.js.org/usage/structuring-reducers/initializing-state#summary
  // localStorageに保存されている値があれば、ロードする
  //  基本的にreducerによって指定されたstateより優先される
  //  それにより、reducerへ指定するデフォルトの引数（初期データ）として指定できる
  //  reduxのストア初期化時に既存データとしてロードすることもできる
  preloadedState: loadFromLocalStorage(),
  reducer: {
    team: teamReducer,
    userInfo: userReducer,
    member: memberSlice,
    ip: ipSlice,
    loading: loadingSlice,
    alert: alertSlice,
    application: applicationSlice,
  },
});

// https://redux.js.org/api/store#subscribelistener
// actionがdispatchされるたび、subscribeに指定されたイベントが実行される
// store.getState()で現在のstoreのオブジェクトが取得できる
storeConfig.subscribe(() =>
  saveToLocalStorage({ userInfo: storeConfig.getState().userInfo })
);

// RootStateにStoreの型を指定する
export type RootState = ReturnType<typeof storeConfig.getState>;
// AppDispatchにStoreへdispatchするアクションの型を指定する
export type AppDispatch = typeof storeConfig.dispatch;

export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
export const useAppDispatch: () => typeof storeConfig.dispatch = useDispatch;

export default storeConfig;

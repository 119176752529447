import { Avatar } from "@chakra-ui/avatar";
import { Checkbox, CheckboxGroup } from "@chakra-ui/checkbox";
import {
  FormControl,
  FormErrorMessage,
  FormLabel,
} from "@chakra-ui/form-control";
import { Input } from "@chakra-ui/input";
import { Box, Center, Flex, SimpleGrid, Stack, Text } from "@chakra-ui/layout";
import { Radio, RadioGroup } from "@chakra-ui/radio";
import { Divider } from "@chakra-ui/react";
import { Select } from "@chakra-ui/select";
import { FC, Fragment, ReactNode } from "react";
import { Controller } from "react-hook-form";
import { TAlertFrame } from "../../../../@types/Alert";
import {
  EAlertTiming,
  EAlertType,
  ETargetNotification,
} from "../../../../enums/alert";
import {
  alertTimingOptions,
  emailNotificationOptions,
  targetNotificationOptions,
} from "../../../../lib/Const";
import { useCreateAlert } from "../../../../lib/hooks/ManagementSettings/useCreateAlert";
import {
  alertTimeOptions,
  hourNoUnits,
  hours,
  minutes,
} from "../../../../lib/timeData";
import { getName } from "../../../../lib/util/helper";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonModal } from "../../CommonModal";
import { ButtonSubmit } from "../Button/ButtonSubmit";
import { TextHeading } from "../Typography/TextHeading";

type CreateAlertModalProps = {
  isUpdate?: boolean;
  alertData: TAlertFrame | null;
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const CreateAlertModal: FC<CreateAlertModalProps> = (props) => {
  const { isOpen, isUpdate = false, title, alertData, onClose } = props;
  const { isLoading } = useAppSelector((state) => state.loading);
  const { teamList, teamUserData } = useAppSelector((state) => state.team);
  const { alertMasterData, alertAppUrlData } = useAppSelector(
    (state) => state.alert
  );
  const {
    control,
    errors,
    notifyTarget,
    timeType,
    selectedAlertType,
    notifyTiming,
    setValue,
    handleSubmit,
    onSubmit,
    register,
    onChangeTimeType,
    onCloseModal,
    onChangeAlertType,
  } = useCreateAlert({ isOpen, isUpdate, alertData, onClose });

  const renderByAlertId = () => {
    switch (selectedAlertType?.gtmalerttype) {
      case EAlertType.TYPE_1:
      case EAlertType.TYPE_3:
        return (
          <Flex align="center" gap="10px">
            <FormControl w="87px" isInvalid={!!errors.alertmax}>
              <Select
                variant="custom"
                disabled={isLoading}
                {...register("alertmax")}
              >
                {hourNoUnits.map((hour) => (
                  <option value={hour.value} key={hour.value}>
                    {hour.label}
                  </option>
                ))}
              </Select>
            </FormControl>
            <TextHeading whiteSpace="nowrap" fontSize="14px">
              時間以上経過
            </TextHeading>
          </Flex>
        );
      case EAlertType.TYPE_2:
        return (
          <RadioGroup value={timeType}>
            <Stack spacing="20px">
              <Flex align="center" gap="10px">
                <Radio
                  variant="custom"
                  value="before"
                  onChange={onChangeTimeType}
                  disabled={isLoading}
                />
                <Flex align="center" gap="10px">
                  <FormControl w="87px" isInvalid={!!errors.alerttimestart}>
                    <Select
                      variant="custom"
                      disabled={timeType !== "before" || isLoading}
                      {...register("alerttimestart")}
                    >
                      {alertTimeOptions.map((time) => (
                        <option value={time.value} key={time.value}>
                          {time.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <TextHeading
                    whiteSpace="nowrap"
                    fontSize="14px"
                    color={timeType !== "before" ? "#B1B1B1" : "inherit"}
                  >
                    以前
                  </TextHeading>
                </Flex>
              </Flex>
              <Flex align="center" gap="10px">
                <Radio
                  variant="custom"
                  value="after"
                  onChange={onChangeTimeType}
                />
                <Flex align="center" gap="10px">
                  <FormControl w="87px" isInvalid={!!errors.alerttimeend}>
                    <Select
                      variant="custom"
                      disabled={timeType !== "after" || isLoading}
                      {...register("alerttimeend")}
                    >
                      {alertTimeOptions.map((time) => (
                        <option value={time.value} key={time.value}>
                          {time.label}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  <TextHeading
                    whiteSpace="nowrap"
                    fontSize="14px"
                    color={timeType !== "after" ? "#B1B1B1" : "inherit"}
                  >
                    以降
                  </TextHeading>
                </Flex>
              </Flex>
            </Stack>
          </RadioGroup>
        );
      default:
        return <Fragment />;
    }
  };

  const renderConditionInput = () => {
    if (selectedAlertType?.gtmalerttype === EAlertType.TYPE_3) {
      if (selectedAlertType?.gtmalertname === "特定Webページ使用時間")
        return (
          <FormControl isInvalid={!!errors.alerturl}>
            <Flex align="center" gap="10px">
              <FormLabel mb={0} mr={0}>
                <TextHeading>ページURL</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                disabled={isLoading}
                flex={1}
                {...register("alerturl")}
              />
            </Flex>
            <FormErrorMessage fontSize="14px">
              {errors.alerturl && errors.alerturl.message}
            </FormErrorMessage>
          </FormControl>
        );
      if (selectedAlertType?.gtmalertname === "特定アプリ使用時間") {
        return (
          <FormControl isInvalid={!!errors.alertexe}>
            <Select
              variant="custom"
              disabled={isLoading}
              w="220px"
              {...register("alertexe")}
            >
              {alertAppUrlData.map((application) =>
                application.gtappurltype === 1 ? (
                  <option
                    value={application.gtappurlname}
                    key={application.gtappurlid}
                  >
                    {application.gtappurlname}
                  </option>
                ) : null
              )}
            </Select>
            <FormErrorMessage fontSize="14px">
              {errors.alertexe && errors.alertexe.message}
            </FormErrorMessage>
          </FormControl>
        );
      }
    }
    return <Fragment />;
  };

  return (
    <CommonModal
      title={title}
      isOpen={isOpen}
      onClose={onCloseModal}
      minW={970}
      isCentered
    >
      <form
        onSubmit={handleSubmit((values) => onSubmit(values))}
        style={{ width: "100%", height: "100%" }}
        autoComplete="off"
      >
        <SimpleGrid columns={2} spacing="30px">
          <Box>
            <FormControl mb="20px" isInvalid={!!errors.displayname}>
              <FormLabel mb="10px">
                <TextHeading>アラート名</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                disabled={isLoading}
                {...register("displayname")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.displayname && errors.displayname.message}
              </FormErrorMessage>
            </FormControl>
            <Stack spacing="20px" mb="20px">
              <Box>
                <FormLabel mb="10px">
                  <TextHeading>条件</TextHeading>
                </FormLabel>
                <Flex align="start" justify="space-between">
                  <FormControl w="220px" isInvalid={!!errors.alertid}>
                    <Select
                      variant="custom"
                      disabled={isLoading}
                      {...register("alertid", {
                        onChange: (e) => {
                          onChangeAlertType(e.target.value);
                        },
                      })}
                    >
                      {alertMasterData?.map((type) => (
                        <option value={type.gtmalertid} key={type.gtmalertid}>
                          {type.gtmalertname}
                        </option>
                      ))}
                    </Select>
                  </FormControl>
                  {renderByAlertId()}
                </Flex>
              </Box>
              {renderConditionInput()}
            </Stack>
            <Divider borderColor="#D6D6D6" />
            <Box mt="30px">
              <FormControl marginBottom="26px" isInvalid={!!errors.notifymail}>
                <FormLabel mb="17px">
                  <TextHeading>メールで通知する</TextHeading>
                </FormLabel>
                <Controller
                  name="notifymail"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <RadioGroup {...rest}>
                      <Stack spacing="30px" direction="row">
                        {emailNotificationOptions.map((item) => (
                          <Radio
                            key={item.value}
                            variant="custom"
                            value={item.value}
                            disabled={isLoading}
                          >
                            {item.label}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </FormControl>
              <Box mb="26px">
                <FormLabel mb="17px">
                  <TextHeading>通知対象</TextHeading>
                </FormLabel>
                <Stack spacing="30px" direction="row">
                  <FormControl isInvalid={!!errors.notifyadmin} w="fit-content">
                    <Controller
                      name="notifyadmin"
                      control={control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <Checkbox
                          variant="custom"
                          whiteSpace="nowrap"
                          disabled={isLoading}
                          isChecked={value}
                          {...rest}
                        >
                          管理者
                        </Checkbox>
                      )}
                    />
                  </FormControl>
                  <FormControl
                    isInvalid={!!errors.notifymember}
                    w="fit-content"
                  >
                    <Controller
                      name="notifymember"
                      control={control}
                      render={({ field: { ref, value, ...rest } }) => (
                        <Checkbox
                          variant="custom"
                          whiteSpace="nowrap"
                          disabled={isLoading}
                          isChecked={value}
                          {...rest}
                        >
                          メンバー
                        </Checkbox>
                      )}
                    />
                  </FormControl>
                </Stack>
              </Box>
              <FormControl marginBottom="8px">
                <FormLabel mb="17px">
                  <TextHeading>通知の送信タイミング</TextHeading>
                </FormLabel>
                <Controller
                  name="notifytiming"
                  control={control}
                  render={({ field: { ref, ...rest } }) => (
                    <RadioGroup {...rest}>
                      <Stack spacing="20px">
                        {alertTimingOptions.map((timing) => (
                          <Radio
                            key={timing.value}
                            variant="custom"
                            value={timing.value}
                            disabled={isLoading}
                          >
                            {timing.label}
                          </Radio>
                        ))}
                      </Stack>
                    </RadioGroup>
                  )}
                />
              </FormControl>
              <Flex align="center" gap="10px" ml="34px">
                <TextHeading
                  whiteSpace="nowrap"
                  fontSize="14px"
                  color={
                    notifyTiming === EAlertTiming.MEET_CONDITION.toString()
                      ? "#B1B1B1"
                      : "inherit"
                  }
                >
                  送信時間
                </TextHeading>
                <FormControl w="87px" isInvalid={!!errors.notifyhour}>
                  <Select
                    variant="custom"
                    disabled={
                      notifyTiming === EAlertTiming.MEET_CONDITION.toString() ||
                      isLoading
                    }
                    {...register("notifyhour")}
                  >
                    {hours.map((hour) => (
                      <option value={hour.value} key={hour.value}>
                        {hour.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
                <FormControl w="87px" isInvalid={!!errors.notifyminute}>
                  <Select
                    variant="custom"
                    disabled={
                      notifyTiming === EAlertTiming.MEET_CONDITION.toString() ||
                      isLoading
                    }
                    {...register("notifyminute")}
                  >
                    {minutes.map((minute) => (
                      <option value={minute.value} key={minute.value}>
                        {minute.label}
                      </option>
                    ))}
                  </Select>
                </FormControl>
              </Flex>
            </Box>
          </Box>
          <Box>
            <FormControl marginBottom="20px" isInvalid={!!errors.teamid}>
              <FormLabel mb="10px">
                <TextHeading>アラート対象</TextHeading>
              </FormLabel>
              <Select
                variant="custom"
                disabled={isLoading}
                {...register("teamid", {
                  onChange: () => {
                    setValue("userid", []);
                  },
                })}
              >
                {teamList.map((team) => (
                  <option value={team.gtteamid} key={team.gtteamid}>
                    {team.gtteamname}
                  </option>
                ))}
              </Select>
            </FormControl>
            <FormControl marginBottom="18px">
              <Controller
                name="notifytarget"
                control={control}
                render={({ field: { ref, ...rest } }) => (
                  <RadioGroup {...rest}>
                    <Stack gap="24px">
                      {targetNotificationOptions.map((item) => (
                        <Radio
                          key={item.value}
                          variant="custom"
                          value={item.value}
                          disabled={isLoading}
                        >
                          {item.label}
                        </Radio>
                      ))}
                    </Stack>
                  </RadioGroup>
                )}
              />
            </FormControl>

            <FormControl isInvalid={!!errors.userid}>
              <Box
                w="100%"
                h="418px"
                border={!errors.userid ? `1px solid #B1B1B1` : "none"}
                outline={errors.userid && `2px solid #E53E3E`}
                borderRadius="4px"
                overflowY="auto"
                sx={{
                  "::-webkit-scrollbar": {
                    display: "none",
                  },
                }}
              >
                {notifyTarget === ETargetNotification.MEMBER.toString() ? (
                  <Controller
                    name="userid"
                    control={control}
                    render={({ field: { ref, ...rest } }) => (
                      <CheckboxGroup {...rest}>
                        <Stack spacing={0}>
                          {teamUserData?.gtteamuserframe.map((item) => (
                            <Flex
                              key={item.gtteamuserid}
                              w="100%"
                              htmlFor={item.gtteamuserid}
                              as={FormLabel}
                              m="0px"
                              p="18px"
                              justify="space-between"
                              cursor={isLoading ? "not-allowed" : "pointer"}
                              _hover={{
                                background: "#EDEDED",
                              }}
                            >
                              <Flex align="center" gap="10px">
                                <Avatar src="" w="26px" h="26px" />
                                <Text
                                  color="#2C2C2C"
                                  fontSize="12px"
                                  fontWeight="bold"
                                >
                                  {getName([
                                    item.gtuserlastname,
                                    item.gtuserfirstname,
                                  ])}
                                </Text>
                              </Flex>
                              <Checkbox
                                id={item.gtteamuserid}
                                variant="custom"
                                value={item.gtteamuserid}
                                size="lg"
                                disabled={isLoading}
                              />
                            </Flex>
                          ))}
                        </Stack>
                      </CheckboxGroup>
                    )}
                  />
                ) : null}
              </Box>
              <FormErrorMessage fontSize="14px">
                {errors.userid && errors.userid.message}
              </FormErrorMessage>
            </FormControl>
          </Box>
        </SimpleGrid>
      </form>
      <Center pt="30px" pb="20px">
        <Flex gap="30px">
          <ButtonSubmit
            isLoading={isLoading}
            onClick={handleSubmit((values) => onSubmit(values, true))}
          >
            完了する
          </ButtonSubmit>
          {!isUpdate ? (
            <ButtonSubmit
              isLoading={isLoading}
              onClick={handleSubmit((values) => onSubmit(values))}
            >
              完了して新しいアラートを追加
            </ButtonSubmit>
          ) : null}
        </Flex>
      </Center>
    </CommonModal>
  );
};

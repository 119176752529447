import {
  Avatar,
  // Box,
  Flex,
  HStack,
  Link,
  Popover,
  PopoverContent,
  PopoverTrigger,
  Text,
  VStack,
} from "@chakra-ui/react";
import React, { useContext } from "react";

import { useSelector } from "react-redux";
import { TUserState } from "../../../@types/User";
import { DateContext } from "../../../lib/globalStates/DateState";
import "../../../styles/Calender.css";
import { getUserURL } from "../../../lib/util/helper";

const MemberTitle: React.FC<{ member: TeamMember }> = ({ member }) => {
  // // グローバルで状態管理する日付情報を扱うためのhooks
  const [dateState] = useContext(DateContext);
  const numYear = dateState?.getFullYear();
  const numMonth = dateState?.getMonth() + 1;
  const numDate = dateState?.getDate();
  const dateSelected = `${numYear}/${numMonth
    .toString()
    .padStart(2, "0")}/${numDate?.toString().padStart(2, "0")}`;
  const { aduserid, adcompanyid } = useSelector(
    (state: TUserState) => state.userInfo
  );

  return (
    <>
      <VStack justifyContent="start" alignItems="start" mt="3" ms="6">
        <HStack>
          <Popover placement="top-start" trigger="hover" isLazy>
            <PopoverTrigger>
              <Flex alignItems="center" cursor="pointer">
                <Link
                  href={getUserURL({
                    adcompanyid,
                    adid: aduserid,
                    dateSelected,
                    memberid: member?.memberid,
                    membername: member?.membername,
                  })}
                  isExternal
                  style={{ textDecoration: "none" }}
                >
                  <Avatar
                    src={member?.memberpic}
                    name={member?.membername}
                    size="sm"
                  ></Avatar>
                  <Text
                    fontSize="sm"
                    as="b"
                    color="#2c2c2c"
                    fontFamily="Source Han Sans"
                    ms="0.625rem"
                  >
                    {member?.membername}
                  </Text>
                </Link>
              </Flex>
            </PopoverTrigger>
            <PopoverContent
              bg="#f2f2f2"
              width="10.25rem"
              height="1.875rem"
              justifyContent="center"
              alignItems="center"
              fontFamily="Source Han Sans"
              boxShadow="1px 1px 3px #00000029"
            >
              <Link
                href={getUserURL({
                  adcompanyid,
                  adid: aduserid,
                  dateSelected,
                  memberid: member?.memberid,
                  membername: member?.membername,
                })}
                isExternal
                style={{ textDecoration: "none" }}
              >
                <Text fontSize="0.6875rem">メンバーの詳細データを見る</Text>
              </Link>
            </PopoverContent>
          </Popover>
        </HStack>
        {/* <Box bg="#919191" borderRadius="15" px="3" py="1">
          <Text fontSize="xs" color="white" fontFamily="Source Han Sans">
            メール・チャット 増加
          </Text>
        </Box>
        <Box bg="#919191" borderRadius="15" px="3" py="1">
          <Text fontSize="xs" color="white" fontFamily="Source Han Sans">
            残業時間 チーム最長
          </Text>
        </Box> */}
      </VStack>
    </>
  );
};

MemberTitle.displayName = "MemberTitle";
export default MemberTitle;


import { useState } from 'react';

type AppInfoData = [] | AppInfoAt24Hours;

type Values = {
  appInfo: AppInfoData;
};

type Actions = {
  setAppInfo: SetStateActionDispatcher<AppInfoData>;
};

type UseAppInfo = [Values, Actions];

/**
 * @remarks
 * 使用したアプリケーションのログ情報を状態管理するためのhooks
 *
 * Shortest name:  {@link useAppInfo}
 * Full name:      {@link useAppInfo}
 *
 * @returns
 * [
 *  values: {
 *    appInfo: 使用したアプリケーションのログ情報
 *  },
 *  actions: {
 *    setAppInfo: 使用したアプリケーションのログ情報を更新する
 *  }
 * ]
 *
 * @public
 */
const useAppInfo = (): UseAppInfo => {
  /** @see [DummyData](src/data/dummy1/allPushWithIndexAppInfoData.json) */
  const [appInfo, setAppInfo] = useState<AppInfoData>([]);
  const values = { appInfo };
  const actions = { setAppInfo };
  return [values, actions];
};

export default useAppInfo;

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  GetAdminOfficeIP,
  GetAdminOfficeIPPayload,
  UpdateAdminOfficeIPPayload,
} from "../../@types/Ip";
import { BaseRequest } from "../../@types/Request";
import { BaseResponse } from "../../@types/Response";
import { ipServices } from "../../services/ipService";

export const getOfficeIPThunk = createAsyncThunk(
  "ip/getOfficeIP",
  async ({ payload }: BaseRequest<GetAdminOfficeIPPayload>) => {
    try {
      const response = await ipServices.getAdminOfficeIP(payload);
      return response.data?.[0].gtipaddressframe;
    } catch (error) {
      return [] as GetAdminOfficeIP[];
    }
  }
);

export const updateOfficeIPThunk = createAsyncThunk(
  "ip/updateOfficeIP",
  async ({
    payload,
    onSuccess,
    onError,
  }: BaseRequest<UpdateAdminOfficeIPPayload>) => {
    try {
      const response = await ipServices.updateAdminOfficeIP(payload);
      const { message, status } = response.data?.[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data;
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

import {
  EAlertTiming,
  EEmailNotification,
  ETargetNotification,
} from "../enums/alert";

// 時間帯文字列表示用の配列
const timeZoneName: readonly string[] = ["24h", "12h", "6h", "3h", "1h", "30m"];

/**
 * @remarks
 * 移動した時間帯を記録しておくための配列（他案で使われているため、正式採用の案が決まったら削除予定）
 *
 * @see {@link HoveredTimeZone}
 */
const initMouseHoveredTimeZoneData: HoveredTimeZone = [
  { position: undefined },
  { position: undefined },
  { position: undefined },
  { position: undefined },
  { position: undefined },
  { position: undefined },
];

/**
 * @remarks
 * 移動した時間帯を記録しておくための配列
 *
 * @see {@link HoveredTimeZone}
 */
const initMouseHoveredTimeZone12HourData: HoveredTimeZone = [
  { position: 12 },
  { position: "12:00" },
  { position: "12:00" },
  { position: "12:00" },
  { position: "12:00" },
  { position: "12:00" },
];

/**
 * @remarks
 * 各時間帯を識別するためのindex
 */
const TIMEZONE24HINDEX = 0;
const TIMEZONE12HINDEX = 1;
const TIMEZONE6HINDEX = 2;
const TIMEZONE3HINDEX = 3;
const TIMEZONE1HINDEX = 4;
const TIMEZONE30MINDEX = 5;

// グラフで表示する最小時間帯、最大時間帯
const MINTIMEZONE = "0:00";
const MAXTIMEZONE = "24:00";

// 1日の秒数
const DAYSECONDS = 86400;

/**
 * @remarks
 * 基準となる時間の前後に加算、減算するための単位を時間帯別に集約した配列
 *
 * @see {@link AdjustTimeItem}
 */
const adjustTime: readonly AdjustTimeItem[] = [
  { hour: 0, minutes: 0 },
  { hour: 6, minutes: 0 },
  { hour: 3, minutes: 0 },
  { hour: 1, minutes: 30 },
  { hour: 0, minutes: 30 },
  { hour: 0, minutes: 15 },
];

// 開始時間を基準に何分加算すると終了時間になるのかを計算するための単位を時間帯別に集約した配列
const addRangeEndTime: readonly number[] = [60, 30, 15, 10, 3, 1];

// 集計した1次元配列のデータを分割する単位を時間帯別に集約した配列
const dataSpliceLength: readonly number[] = [0, 30, 15, 10, 3, 1];

// グラフの棒にカーソルをホバーしたときに表示される棒状の背景表示の幅を時間帯別に集約した配列
const tooltipCursorStrokeWidth: readonly number[] = [46, 46, 46, 58, 54, 38];

/**
 * @remarks
 * カテゴリーごとに表示する文字列と色の指定を集約した配列
 *
 * @see {@link CategoryForView}
 */
const categoryColors: CategoryForView = {
  work: { color: "#FC6910", name: "作業" },
  message: { color: "#ff4b45", name: "メール・チャット" },
  netsearch: { color: "#923AFE", name: "インターネット検索" },
  mtg: { color: "#7FC907", name: "Web会議" },
  etc: { color: "#666666", name: "その他" },
};

/**
 * @remarks
 * キー、マウス操作のコンポーネントに表示する文字列を集約した配列
 *
 * @see {@link OneDayTotalDataExplainText}
 */
const oneDayTotalDataExplainText: OneDayTotalDataExplainText = {
  shortCut: {
    title: "ショートカットキー操作回数",
    text: "修飾キーと一般キーを同時入力した\n回数を表示します。",
  },
  leftClick: {
    title: "左クリック回数",
    text: "左クリックを行った回数を\n表示します。",
  },
  mouse_movement: {
    title: "マウスカーソル移動量(px)",
    text: "ドラッグ&ドロップ操作を除く、\nマウスカーソルの移動量(px)を表示します。",
  },
};

/**
 * @remarks
 * グラフ下部に表示する際に使うGridの大きさ、間隔、padding-leftを集約した配列
 *
 * @see {@link GridProps}
 */
const gridProps: readonly GridProps[] = [
  {
    columnLength: { min: 25, max: 32 },
    gridGap: 0.52,
    pl: 100,
  },
  {
    columnLength: { min: 37, max: 42 },
    gridGap: 0.7,
    pl: 100,
  },
  {
    columnLength: { min: 33, max: 38 },
    gridGap: 0.63,
    pl: 100,
  },
  {
    columnLength: { min: 25, max: 26 },
    gridGap: 0.415,
    pl: 98,
  },
];

// グラフ下部に表示するGridの個数を時間帯別に集約した配列
const gridUnits: readonly number[] = [25, 25, 25, 19, 21, 31];

const gridUnitsForDiscovery: readonly number[] = [24, 24, 24, 19, 21, 31];

// モーダル表示を閉じるボタン（<）をスタイリングするための定義
const leftArrowTrianglesStyle = {
  border: "solid black",
  borderWidth: "0 3px 3px 0",
  display: "inline-block",
  padding: "5px",
  transform: "rotate(135deg)",
};

/**
 * @remarks
 * レポート画面で表示するレポートの種類によるタイトルのオブジェクト
 *
 * @see {@link ReportTitleType}
 */
const reportTitleType: ReportTitleType = {
  netsearch: "ウェブブラウザ検索時間",
  backSpace: "バックスペース・デリートキーの繰り返し利用回数",
  returnKey: "取り消しキー(Ctrl+Z)の繰り返し利用回数",
  copyPaste: "コピー&ペーストの繰り返し利用回数",
};

/**
 * @remarks
 * 設定画面のその他で表示するリンクのタイトルとリンク先のURLのオブジェクトの配列
 *
 * @see {@link SettingEtcLinkType}
 */
const settingEtcLink: SettingEtcLinkType[] = [
  { title: "リリースノート", url: "#" },
  { title: "使い方に関して", url: "#" },
  { title: "利用規約", url: "#" },
  { title: "プライバシーポリシー", url: "#" },
  { title: "利用方法に関するお問い合わせ", url: "#" },
];

/**
 * @remarks
 * 設定画面の項目名と識別子のオブジェクトの配列
 *
 * @see {@link SettingItemType}
 */
const settingItemType: SettingItemType[] = [
  { type: "account", name: "アカウント" },
  { type: "schedule", name: "スケジュール連携" },
  { type: "etc", name: "その他" },
];

const EMPTY_STRING = "";

const ACCEPTED_IMAGE_TYPES = ["image/png"];
const MAX_FILE_SIZE = 5242880;

const alertTimingOptions = [
  {
    value: EAlertTiming.MEET_CONDITION.toString(),
    label: "条件が達成されたら都度送信する",
  },
  {
    value: EAlertTiming.NEXT_DAY.toString(),
    label: "翌日にまとめて送信する",
  },
];

const emailNotificationOptions = [
  {
    value: EEmailNotification.ON.toString(),
    label: "ON",
  },
  {
    value: EEmailNotification.OFF.toString(),
    label: "OFF",
  },
];

const targetNotificationOptions = [
  {
    value: ETargetNotification.ALL.toString(),
    label: "全員",
  },
  {
    value: ETargetNotification.MEMBER.toString(),
    label: "指定したメンバーのみ",
  },
];

const applicationOptions = [
  {
    value: "Microsoft Word",
    label: "Microsoft Word",
  },
  {
    value: "Microsoft PowerPoint",
    label: "Microsoft PowerPoint",
  },
  {
    value: "Microsoft Excel",
    label: "Microsoft Excel",
  },

  {
    value: "Google Chrome",
    label: "Google Chrome",
  },
  {
    value: "Microsoft Edge",
    label: "Microsoft Edge",
  },
  {
    value: "メモ帳",
    label: "メモ帳",
  },
];

const categoryOptions = {
  work: { value: "work", name: "作業" },
  mtg: { value: "mtg", name: "チャット・Web会議" },
  netsearch: { value: "netsearch", name: "Web検索" },
  message: { value: "message", name: "メール" },
  secret: { value: "secret", name: "シークレット" },
};

export {
  ACCEPTED_IMAGE_TYPES,
  DAYSECONDS,
  EMPTY_STRING,
  MAXTIMEZONE,
  MAX_FILE_SIZE,
  MINTIMEZONE,
  TIMEZONE12HINDEX,
  TIMEZONE1HINDEX,
  TIMEZONE24HINDEX,
  TIMEZONE30MINDEX,
  TIMEZONE3HINDEX,
  TIMEZONE6HINDEX,
  addRangeEndTime,
  adjustTime,
  alertTimingOptions,
  applicationOptions,
  categoryColors,
  dataSpliceLength,
  emailNotificationOptions,
  gridProps,
  gridUnits,
  gridUnitsForDiscovery,
  initMouseHoveredTimeZone12HourData,
  initMouseHoveredTimeZoneData,
  leftArrowTrianglesStyle,
  oneDayTotalDataExplainText,
  reportTitleType,
  settingEtcLink,
  settingItemType,
  targetNotificationOptions,
  timeZoneName,
  tooltipCursorStrokeWidth,
  categoryOptions,
};

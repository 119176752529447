import { zodResolver } from "@hookform/resolvers/zod";
import { useLayoutEffect, useMemo, useState } from "react";
import { UseFormProps, useFieldArray, useForm } from "react-hook-form";
import { z } from "zod";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  getOfficeIPThunk,
  updateOfficeIPThunk,
} from "../../../redux/thunk/ipThunk";
import {
  INVALID_IP_ADDRESS,
  REQUIRED_FIELD,
  DUPLICATED_IP_ADDRESS,
} from "../../util/messages";
import { IPV4_REGEX } from "../../util/regex";
import { useMessage } from "../useMessage";
import { UpdateAdminOfficeIPPayload } from "../../../@types/Ip";
import { EMPTY_STRING } from "../../Const";
import { variables } from "../../../configs/variables";

const schema = z.object({
  upipaddress: z
    .array(
      z.object({
        upofficeip: z
          .string()
          .trim()
          .min(1, REQUIRED_FIELD)
          .regex(IPV4_REGEX, INVALID_IP_ADDRESS),
      })
    )
    .superRefine((data, ctx) => {
      const ipSet = new Set();
      data.forEach(({ upofficeip }, i) => {
        if (ipSet.has(upofficeip)) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: DUPLICATED_IP_ADDRESS,
            path: [i, "upofficeip"],
          });
        } else {
          ipSet.add(upofficeip);
        }
      });
    }),
});

type UpdateOfficeIPForm = z.infer<typeof schema>;

function useZodForm<TSchema extends z.ZodType>(
  props: Omit<UseFormProps<TSchema["_input"]>, "resolver"> & {
    schema: TSchema;
  }
) {
  return useForm<TSchema["_input"]>({
    ...props,
    resolver: zodResolver(props.schema, undefined, {
      raw: true,
    }),
  });
}

export const useOtherSettings = () => {
  const [isEditIP, setIsEditIP] = useState(false);
  const dispatch = useAppDispatch();
  const { adcompanyid } = useAppSelector((state) => state.userInfo);
  const { ips } = useAppSelector((state) => state.ip);
  const { showMessage } = useMessage();
  const {
    handleSubmit,
    register,
    reset,
    control,
    formState: { errors },
  } = useZodForm({
    schema,
    values: {
      upipaddress: ips.map((ip) => ({
        upofficeip: ip.gtipaddress,
      })),
    },
  });

  const { fields, append, update, remove } = useFieldArray({
    control,
    name: "upipaddress",
  });

  const isLimitedNumberOfIpAddress = useMemo(() => {
    return fields.length >= variables.maxNumberOfIPAddress;
  }, [fields]);

  const openEditIP = () => {
    setIsEditIP(true);
  };

  const closeEditIP = () => {
    reset();
    setIsEditIP(false);
  };

  const onAppend = () => {
    if (!isLimitedNumberOfIpAddress) {
      append({ upofficeip: EMPTY_STRING });
    } else {
      showMessage({
        status: "warning",
        title: `IPアドレスが${variables.maxNumberOfIPAddress}件に達しています。新規IPを追加できるために、IPアドレスを削除してください。`,
      });
    }
  };

  const onRemove = (index: number) => {
    remove(index);
  };

  const onSubmit = (values: UpdateOfficeIPForm) => {
    if (adcompanyid) {
      const payload: UpdateAdminOfficeIPPayload = {
        upcompanyid: adcompanyid,
        upipaddress: values.upipaddress.map((item) => item.upofficeip),
      };
      dispatch(
        updateOfficeIPThunk({
          payload,
          onSuccess: (message) => {
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
            closeEditIP();
            dispatch(
              getOfficeIPThunk({
                payload: {
                  adcompanyid: adcompanyid,
                },
              })
            );
          },
          onError: (message) => {
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  };

  useLayoutEffect(() => {
    if (adcompanyid) {
      dispatch(
        getOfficeIPThunk({
          payload: {
            adcompanyid: adcompanyid,
          },
        })
      );
    }
  }, [dispatch, adcompanyid]);

  return {
    errors,
    isEditIP,
    fields,
    isLimitedNumberOfIpAddress,
    openEditIP,
    closeEditIP,
    handleSubmit,
    register,
    reset,
    onSubmit,
    onAppend,
    update,
    onRemove,
  };
};

import { Box } from "@chakra-ui/layout";
import {
  APPLICATION_TAB,
  useApplicationManagement,
} from "../../../lib/hooks/ManagementSettings/useApplicationManagement";
import { ApplicationRegistered } from "./ApplicationRegistered";
import { ApplicationUnRegistered } from "./ApplicationUnRegistered";
import { ButtonPrimary } from "./Button/ButtonPrimary";

export const ApplicationManagement = () => {
  const { state, handler } = useApplicationManagement();

  return (
    <Box display="flex" flexDirection="column" gap="20px">
      <Box display="flex" gap="10px">
        <ButtonPrimary
          onClick={() => {
            if (state?.tabId !== APPLICATION_TAB.UN_REGISTERED.id) {
              handler?.setTabId(APPLICATION_TAB.UN_REGISTERED.id);
            }
          }}
          background={
            state.tabId === APPLICATION_TAB.UN_REGISTERED.id
              ? "#00BA0F"
              : "#2C2C2C"
          }
          _hover={{
            background:
              state.tabId === APPLICATION_TAB.UN_REGISTERED.id
                ? "#00BA0F"
                : "#2C2C2C",
            opacity: 0.8,
          }}
        >
          {APPLICATION_TAB.UN_REGISTERED.name}
        </ButtonPrimary>
        <ButtonPrimary
          onClick={() => {
            if (state?.tabId !== APPLICATION_TAB.REGISTERED.id) {
              handler?.setTabId(APPLICATION_TAB.REGISTERED.id);
            }
          }}
          background={
            state.tabId === APPLICATION_TAB.REGISTERED.id
              ? "#00BA0F"
              : "#2C2C2C"
          }
          _hover={{
            background:
              state.tabId === APPLICATION_TAB.REGISTERED.id
                ? "#00BA0F"
                : "#2C2C2C",
            opacity: 0.8,
          }}
        >
          {APPLICATION_TAB.REGISTERED.name}
        </ButtonPrimary>
      </Box>
      <Box borderRadius="10px" background="white" p="20px">
        {state?.tabId === APPLICATION_TAB.UN_REGISTERED.id ? (
          <ApplicationUnRegistered />
        ) : (
          <ApplicationRegistered />
        )}
      </Box>
    </Box>
  );
};

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  TAdminAlertAppUrlFrame,
  TAdminAlertMaster,
  TAlertFrame,
  TGetAdminAlertDataPayload,
  TUpdateAdminAlertDataPayload,
} from "../../@types/Alert";
import { BaseRequest } from "../../@types/Request";
import { BaseResponse } from "../../@types/Response";
import { alertServices } from "../../services/alertService";

export const getAdminAlertAppUrlDataThunk = createAsyncThunk(
  "alert/getAdminAlertappurlData",
  async ({ payload }: BaseRequest<TGetAdminAlertDataPayload>) => {
    try {
      const response = await alertServices.getAdminAlertAppUrlData(payload);
      return response.data?.[0]?.gtappurlframe;
    } catch (error) {
      return [] as TAdminAlertAppUrlFrame[];
    }
  }
);

export const getAdminAlertMasterDataThunk = createAsyncThunk(
  "alert/getAdminAlertMasterData",
  async () => {
    try {
      const response = await alertServices.getAdminAlertMasterData();
      return response.data;
    } catch (error) {
      return [] as TAdminAlertMaster[];
    }
  }
);

export const getAdminAlertDataThunk = createAsyncThunk(
  "alert/getAdminAlertData",
  async ({ payload }: BaseRequest<TGetAdminAlertDataPayload>) => {
    try {
      const response = await alertServices.getAdminAlertData(payload);
      return response.data?.[0]?.gtalertframe;
    } catch (error) {
      return [] as TAlertFrame[];
    }
  }
);

export const updateAdminAlertDataThunk = createAsyncThunk(
  "alert/updateAdminAlertData",
  async ({
    payload,
    onSuccess,
    onError,
  }: BaseRequest<TUpdateAdminAlertDataPayload>) => {
    try {
      const response = await alertServices.updateAdminAlertData(payload);
      const { message, status } = response.data?.[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data?.[0];
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

export const deleteAdminAlertDataThunk = createAsyncThunk(
  "alert/deleteAdminAlertData",
  async ({
    payload,
    onSuccess,
    onError,
  }: BaseRequest<TUpdateAdminAlertDataPayload>) => {
    try {
      const response = await alertServices.updateAdminAlertData(payload);
      const { message, status } = response.data?.[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data?.[0];
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

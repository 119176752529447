import React from "react";

import { Box, Text, Button, Flex } from "@chakra-ui/react";

import DatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";

import "../../styles/Calender.css";

import ja from "date-fns/locale/ja";

type Props = {
  title: string;
  description: string;
  date: Date;
  setDateState: React.Dispatch<React.SetStateAction<Date>> | null;
};

const CustomDatePickerInput = React.forwardRef<HTMLInputElement, any>(
  (props, ref) => (
    <input
      type="text"
      {...props}
      className="custom-datepicker-input"
      ref={ref}
      data-testid="custom-datepicker-input"
    />
  )
);

// 画面のタイトルと説明、DatePickerを表示するためのヘッダーパーツ
const PageTitleWithCalendar = React.memo(
  ({ title, description, date, setDateState }: Props) => {
    return (
      <Box
        display="flex"
        alignItems="center"
        justifyContent="space-between"
        mb="20px"
      >
        <Box display="flex" fontWeight="bold">
          <Text className="page-title">{title}</Text>
          <Text lineHeight="40px" ml="10px">
            {description}
          </Text>
        </Box>
        <Flex>
          <DatePicker
            selected={date}
            onChange={(date: Date) =>
              setDateState ? setDateState(date) : null
            }
            customInput={<CustomDatePickerInput />}
            popperPlacement="bottom"
            calendarClassName="size-date-picker-container"
            locale={ja}
            dateFormatCalendar="yyyy年MM月"
            dateFormat="yyyy/MM/dd"
          />
          <Button
            onClick={() => (setDateState ? setDateState(new Date()) : null)}
            className="today-date-picker"
          >
            <Text>今日</Text>
          </Button>
        </Flex>
      </Box>
    );
  }
);
PageTitleWithCalendar.displayName = "PageTitleWithCalendar";
export default PageTitleWithCalendar;

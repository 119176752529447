import { AxiosResponse } from "axios";
import { BaseResponse } from "../@types/Response";
import {
  AdminAuthority,
  AdminData,
  AdminDataPayload,
  ChangeUserPIC,
  ChangeUserPassword,
  ChangeUserProfile,
  GetAdminAuthorityList,
  TAdminLoginTopData,
  TAdminLoginTopPayload,
} from "../@types/User";
import axiosInstance from "../configs/axiosConfig";
import { USER_ENDPOINT } from "../configs/endpoints";
import { EMPTY_STRING } from "lib/Const";

export const userServices = {
  loginAdminTop: async (
    payload: TAdminLoginTopPayload
  ): Promise<AxiosResponse<TAdminLoginTopData[]>> => {
    var data = new FormData();
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    data.append("useridauth", userId);
    data.append("urcompanyid", payload.urcompanyid);
    data.append("urmailaddress", payload.urmailaddress);
    data.append("urpassword", payload.urpassword);
    const response: AxiosResponse<TAdminLoginTopData[]> =
      await axiosInstance.post(USER_ENDPOINT.LOGIN_ADMIN, data);
    return response;
  },
  getAdminProfile: async (
    payload: AdminDataPayload
  ): Promise<AxiosResponse<AdminData[]>> => {
    var data = new FormData();
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    data.append("useridauth", userId);
    data.append("adcompanyid", payload.adcompanyid);
    data.append("aduserid", payload.aduserid);
    const response: AxiosResponse<AdminData[]> = await axiosInstance.post(
      USER_ENDPOINT.GET_ADMIN_PROFILE,
      data
    );
    return response;
  },
  changeUserPicture: async (
    payload: ChangeUserPIC
  ): Promise<AxiosResponse<BaseResponse[]>> => {
    var data = new FormData();
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    data.append("useridauth", userId);
    data.append("adcrud", payload.upcrud);
    data.append("adcompanyid", payload.upcompanyid);
    data.append("aduserid", payload.upuserid);
    data.append("aduserpic", payload.upuserpic);
    const response: AxiosResponse<BaseResponse[]> = await axiosInstance.post(
      USER_ENDPOINT.CHANGE_AVATAR,
      data
    );
    return response;
  },
  changeUserPassword: async (
    payload: ChangeUserPassword
  ): Promise<AxiosResponse<BaseResponse[]>> => {
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    const response: AxiosResponse<BaseResponse[]> = await axiosInstance.post(
      USER_ENDPOINT.CHANGE_PASSWORD,
      { ...payload, useridauth: userId }
    );
    return response;
  },
  changeAdminProfile: async (
    payload: ChangeUserProfile
  ): Promise<AxiosResponse<BaseResponse[]>> => {
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    const response: AxiosResponse<BaseResponse[]> = await axiosInstance.post(
      USER_ENDPOINT.CHANGE_ADMIN_PROFILE,
      { ...payload, useridauth: userId }
    );
    return response;
  },
  getAdminAuthorityList: async (
    payload: GetAdminAuthorityList
  ): Promise<AxiosResponse<AdminAuthority[]>> => {
    const data = new FormData();
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    data.append("useridauth", userId);
    data.append("adcompanyid", payload.adcompanyid);
    const response: AxiosResponse<AdminAuthority[]> = await axiosInstance.post(
      USER_ENDPOINT.GET_ADMIN_AUTHORITY,
      data
    );
    return response;
  },
};

import { Button } from "@chakra-ui/button";
import {
  AlertDialog,
  AlertDialogBody,
  AlertDialogContent,
  AlertDialogFooter,
  AlertDialogOverlay,
} from "@chakra-ui/modal";
import { FC, ReactNode, useRef } from "react";
import { TextHeading } from "./ManagementSettings/Typography/TextHeading";

type DeleteModalType = {
  isOpen: boolean;
  message: ReactNode;
  onOk: () => void;
  onClose: () => void;
};

export const DeleteModal: FC<DeleteModalType> = (props) => {
  const { isOpen, onClose, onOk, message } = props;
  const cancelRef = useRef<HTMLButtonElement>(null);

  return (
    <AlertDialog
      isCentered
      isOpen={isOpen}
      leastDestructiveRef={cancelRef}
      onClose={onClose}
    >
      <AlertDialogOverlay>
        <AlertDialogContent borderRadius="10px" w="400px">
          <AlertDialogBody p="20px">
            <TextHeading>{message}</TextHeading>
          </AlertDialogBody>
          <AlertDialogFooter>
            <Button onClick={onClose} px="20px" py="6px" fontSize="15px">
              キャンセル
            </Button>
            <Button
              colorScheme="red"
              onClick={onOk}
              ml="8px"
              px="20px"
              py="6px"
              fontSize="15px"
            >
              削除
            </Button>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialogOverlay>
    </AlertDialog>
  );
};

import { Text, TextProps, Tooltip } from "@chakra-ui/react";
import { FC, useEffect, useRef, useState } from "react";

export const TextTableItem: FC<TextProps> = (props) => {
  const { children: label, ...otherProps } = props;
  const textRef = useRef<HTMLDivElement>(null);
  const [isTruncated, setIsTruncated] = useState(false);

  const onTruncated = () => {
    const element = textRef.current;
    if (element) {
      setIsTruncated(element.scrollHeight > element.clientHeight);
    }
  };

  useEffect(() => {
    onTruncated();
  }, []);

  window.addEventListener("resize", () => {
    onTruncated();
  });

  const textElement = (
    <Text
      ref={textRef}
      as="div"
      color="#A5A5A5"
      fontSize="12px"
      fontWeight="bold"
      whiteSpace="normal"
      noOfLines={1}
      {...otherProps}
    >
      {label}
    </Text>
  );

  return isTruncated ? (
    <Tooltip label={label}>{textElement}</Tooltip>
  ) : (
    textElement
  );
};

export const guidelineUrl = [
  {
    id: "1",
    title: "メンバー：作業ログ画面の見方",
    description: "各メンバーの作業内容を振り返る画面についてご説明します。",
    url: "",
  },
  {
    id: "2",
    title: "管理者：チーム・メンバーの追加方法",
    description: "メンバーの招待やチームの追加を行う方法をご説明します。",
    url: "",
  },
  {
    id: "3",
    title: "管理者：各画面の見方",
    description:
      "システム管理者・マネージャーが使用できる様々な機能についてご説明します。",
    url: "",
  },
];

// TODO: Assign url when client has confirmed
export const otherLinks = [
  {
    id: "1",
    url: "",
    title: "リリースノート",
  },
  {
    id: "2",
    url: "",
    title: "使い方に関して",
  },
  {
    id: "3",
    url: "",
    title: "利用規約",
  },
  {
    id: "4",
    url: "",
    title: "プライバシーポリシー",
  },
  {
    id: "5",
    url: "",
    title: "利用方法に関するお問い合わせ",
  },
];

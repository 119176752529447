import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import { Flex, Box, Image } from "@chakra-ui/react";
import SidebarItem from "./SidebarItem";

// import menuTimelineSVG from "../../images/menu_timeline.svg";
import menuTimelineSVGActive from "../../images/menu_timeline_active.svg";

// import menuReportSVG from "../../images/menu_report.svg";
import menuReportSVGActive from "../../images/menu_report_active.svg";

import menuSettingSVG from "../../images/menu_setting.svg";
import menuSettingSVGActive from "../../images/menu_setting_active.svg";

import menuDiscoverySVG from "../../images/menu_discovery.svg";
import menuDiscoveryOnSVG from "../../images/menu_discovery_on.svg";

import menuOpenSVG from "../../images/menu_open.svg";
import { ROUTERS } from "../../lib/routers";

// 各画面の左側に表示するサイドバーのパーツ
const Sidebar = React.memo(() => {
  const [navSize, changeNavSize] = useState("small");
  const location = useLocation();

  const sidebarRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    // Function to handle clicks outside the sidebar
    const handleClickOutside = (event: MouseEvent) => {
      if (
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target as Node)
      ) {
        if (navSize === "large") {
          changeNavSize("small");
        }
      }
    };
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, [navSize]);

  const getActiveIcon = (pathName: string) => {
    const icons = {
      [ROUTERS.DISCOVERY]: menuDiscoveryOnSVG,
      [ROUTERS.TIME_LINE]: menuTimelineSVGActive,
      [ROUTERS.ALERT]: menuReportSVGActive,
      [ROUTERS.MANAGEMENT_SETTING]: menuSettingSVGActive,
    };
    const isActive = location.pathname === pathName;
    return !!isActive && icons[pathName];
  };

  return (
    <Flex
      ref={sidebarRef}
      pos="fixed"
      zIndex="10"
      h="100%"
      w={navSize === "small" ? "4.375rem" : "16.875rem"}
      flexDir="column"
      justifyContent="space-between"
      bgColor="#212121"
      data-testid="sidebar-width"
    >
      <Flex p="2px" flexDir="column" w="100%" alignItems="flex-start" as="nav">
        <SidebarItem
          icon={getActiveIcon(ROUTERS.DISCOVERY) || menuDiscoverySVG}
          title="ディスカバリー"
          description={ROUTERS.DISCOVERY}
          navSize={navSize}
        />
        {/* <SidebarItem
          icon={getActiveIcon(ROUTERS.TIME_LINE) || menuTimelineSVG}
          title="タイムライン"
          description={ROUTERS.TIME_LINE}
          navSize={navSize}
        />
        <SidebarItem
          icon={getActiveIcon(ROUTERS.ALERT) || menuReportSVG}
          title="レコメンド"
          description={ROUTERS.ALERT}
          navSize={navSize}
        /> */}
        <SidebarItem
          icon={getActiveIcon(ROUTERS.MANAGEMENT_SETTING) || menuSettingSVG}
          title="設定"
          description={ROUTERS.MANAGEMENT_SETTING}
          navSize={navSize}
        />
      </Flex>

      <Flex
        flexDir="column"
        w="100%"
        alignItems={navSize === "small" ? "center" : "flex-start"}
      >
        <Box
          data-testid="menu-open-button"
          background="none"
          mt="1.25rem"
          pl={navSize === "large" ? "0.9375rem" : undefined}
          _hover={{ background: "none" }}
          color="white"
          onClick={() => {
            if (navSize === "small") changeNavSize("large");
            else changeNavSize("small");
          }}
        >
          <Image
            w="3.75rem"
            h="3.75rem"
            src={menuOpenSVG}
            transform={navSize === "large" ? "rotate(180deg)" : undefined}
          />
        </Box>
      </Flex>
    </Flex>
  );
});
Sidebar.displayName = "Sidebar";
export default Sidebar;


import { useState } from 'react';

type GridViewScheduleDatas = [] | (number | false)[];

type Values = {
  gridViewScheduleDatas: GridViewScheduleDatas;
};

type Actions = {
  setGridViewScheduleDatas: SetStateActionDispatcher<GridViewScheduleDatas>;
};

type UseGridViewScheduleDatas = [Values, Actions];

/**
 * @remarks
 * 総作業時間グラフで選択した時間帯によって、Grid状で表示するスケジュールデータのindexの配列を状態管理するためのhooks
 * grid上で選択可能なスケジュールデータとそうでないデータを識別するための配列
 *
 * Shortest name:  {@link useGridViewScheduleDatas}
 * Full name:      {@link useGridViewScheduleDatas}
 *
 * @returns
 * [
 *  values: {
 *    gridViewScheduleDatas: [ // 総作業時間グラフで選択した時間帯によって、Grid状で表示するスケジュールデータのindexの配列
 *      Grid状で表示するスケジュールデータのindex,…
 *    ]
 *  },
 *  actions: {
 *    setGridViewScheduleDatas: 総作業時間グラフで選択した時間帯によって、Grid状で表示するスケジュールデータのindexの配列を更新する
 *  }
 * ]
 *
 * @public
 */
const useGridViewScheduleDatas = (): UseGridViewScheduleDatas => {
  /**
   * ダミーデータを使った場合のgridViewScheduleDatasの値の例
   * ※24時間帯の表示をした場合
   *
   * @example
   * [ 17, 17, 18 ]
   */
  const [gridViewScheduleDatas, setGridViewScheduleDatas] =
    useState<GridViewScheduleDatas>([]);

  const values = { gridViewScheduleDatas };

  const actions = { setGridViewScheduleDatas };

  return [values, actions];
};

export default useGridViewScheduleDatas;

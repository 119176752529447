import type { ChangeEventHandler, FC, ReactNode } from "react";
import { IconType } from "react-icons";
import {
  FormControl,
  InputGroup,
  InputLeftElement,
  Input,
} from "@chakra-ui/react";
import type { ChakraComponent } from "@chakra-ui/react";

type Props = {
  children?: ReactNode;
  leftElement: { LeftIcon: ChakraComponent<IconType> };
  input: {
    onChange: ChangeEventHandler<HTMLInputElement>;
    placeholder: string;
    type: string;
    value: string;
  };
};
export const FormInputComponent: FC<Props> = ({
  children,
  input: { onChange, type, placeholder, value },
  leftElement: { LeftIcon },
}) => {
  return (
    <FormControl>
      <InputGroup>
        <InputLeftElement
          pointerEvents="none"
          children={<LeftIcon color="gray.300" />}
        />
        <Input
          type={type}
          value={value}
          placeholder={placeholder}
          onChange={onChange}
        />
        {children}
      </InputGroup>
    </FormControl>
  );
};

const statusTitle = [
  "PC操作時間",
  "作業時間",
  "チャット・WEB会議",
  "Web検索",
  "予定",
  "本日の時間外PC作業時間",
  "PC無操作時間",
  "メール",
  "その他",
  "シークレット",
  "今月の時間外PC作業時間",
];

export default statusTitle;

import { extendTheme } from "@chakra-ui/react";
import { checkboxTheme } from "./checkboxTheme";
import { inputTheme } from "./inputTheme";
import { menuTheme } from "./menuTheme";
import { radioTheme } from "./radioTheme";
import { selectTheme } from "./selectTheme";
import { skeletonTheme } from "./skeletonTheme";
import { textareaTheme } from "./textareaTheme";

// ページ全体に共通で指定するスタイルを定義する
export const theme = extendTheme({
  components: {
    Checkbox: checkboxTheme,
    Input: inputTheme,
    Select: selectTheme,
    Textarea: textareaTheme,
    Radio: radioTheme,
    Menu: menuTheme,
    Skeleton: skeletonTheme,
  },
  fonts: {
    heading: `'Source Han Sans'`,
    body: `'Source Han Sans'`,
  },
  styles: {
    global: {
      html: {
        height: "100%",
      },
      body: {
        height: "100%",
        backgroundColor: "gray.100",
        color: "gray.800",
      },
      "#root": {
        height: "100%",
      },
    },
  },
});

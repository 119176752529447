
import { makeOneKeyDataObject } from './commonDataProcessing';

/**
 * @remarks
 * 配列になっている複数要素を同じキー同士で合計して、1つのオブジェクトにする
 *
 * @param calculatedOrUndefinedArray @see [DummyData](src/data/example/calculatedOrUndefinedArray.json)
 *
 * @returns
 * @see {@link CalculatedCategoryPer1Minute}
 */
const _makeOneCalculatedCategoryObject = (
  calculatedOrUndefinedArray: (CalculatedCategoryPer1Minute | undefined)[]
): CalculatedCategoryPer1Minute | undefined => {
  return makeOneKeyDataObject<CalculatedCategoryPer1Minute>(
    calculatedOrUndefinedArray
  );
};

/**
 * @remarks
 * 1分あたりの作業をカテゴリー別に集計する
 * 集計した結果を1つのオブジェクトにまとめる
 *
 * @param appInfo @see [DummyData](src/data/example/calculatedCategoryPer1MinuteAppInfo.json)
 *
 * @returns
 * [
 *  oneMinuteAppInfo @see [DummyData](src/data/example/calculatedCategoryPer1MinuteAppInfo.json),
 *  calculatedCategoryPer1Minute @see [DummyData](src/data/example/calculatedCategoryPer1Minute.json)
 * ]
 */
const _calculatedCategoryPer1Minute = (
  appInfo: AppInfoDetailData[]
): [AppInfoDetailData[], CalculatedCategoryPer1Minute] => {
  const oneMinuteAppInfo = [...appInfo];

  /**
   * @remarks
   * 作業カテゴリーごとに使用した時間（秒数）をまとめたオブジェクト
   *
   * see [DummyData](src/data/example/calculatedCategoryPer1Minute.json)
   */
  const calculatedCategoryPer1Minute = appInfo.reduce(
    (prev: CalculatedCategoryPer1Minute, current: AppInfoDetailData) => {
      if(!current.category) return prev;
      prev[current.category] = (prev[current.category] || 0) + current.useTime;
      return prev;
    },
    {}
  );
  return [oneMinuteAppInfo, calculatedCategoryPer1Minute];
};

/**
 * @remarks
 * 1時間あたりの作業をカテゴリー別に集計する
 * 集計した結果を1つのオブジェクトにまとめる
 *
 * @param appInfo1Hour @see [DummyData](src/data/example/appInfo1Hour.json)
 *
 * @returns
 * [
 *  oneCalculatedCategoryObject @see [DummyData](src/data/example/oneCalculatedCategoryObject.json),
 *  excludeUndefinedOneMinuteAppInfoArray @see [DummyData](src/data/example/excludeUndefinedOneMinuteAppInfoArray.json)
 * ]
 */
const _calculateCategoryPer1Hour = (
  appInfo1Hour: AppInfoDetailPerHour
): [CalculatedCategoryPer1Minute | undefined, AppInfoDetailData[] | []] => {
  const result: [
    (CalculatedCategoryPer1Minute | undefined)[],
    (AppInfoDetailData[] | undefined)[]
  ] = appInfo1Hour.detaildata.reduce(
    (
      prev: [
        (CalculatedCategoryPer1Minute | undefined)[],
        (AppInfoDetailData[] | undefined)[]
      ],
      appInfoData: AppInfoData
    ) => {
      if (!appInfoData.appInfo.length) return prev;
      /**
       * [
       *  oneMinuteAppInfo @see [DummyData](src/data/example/calculatedCategoryPer1MinuteAppInfo.json),
       *  calculatedCategoryPer1Minute @see [DummyData](src/data/example/calculatedCategoryPer1Minute.json)
       * ]
       */
      const [oneMinuteAppInfo, calculatedCategoryPer1Minute] =
        _calculatedCategoryPer1Minute(
          appInfoData.appInfo as AppInfoDetailData[]
        );

      prev[0].push(calculatedCategoryPer1Minute);
      prev[1].push(oneMinuteAppInfo);
      return prev;
    },
    [[], []]
  );

  /**
   * @remarks
   * 使用したアプリと詳細内容の1時間分を1つの配列にまとめる
   *
   * @see [DummyData](src/data/example/excludeUndefinedOneMinuteAppInfoArray.json)
   */
  const excludeUndefinedOneMinuteAppInfoArray: AppInfoDetailData[] | [] =
    result[1]
      .filter(
        (item): item is Exclude<typeof item, undefined> => item !== undefined
      )
      .reduce((prev: AppInfoDetailData[], cur: AppInfoDetailData[]) => {
        prev.push(...cur);
        return prev;
      }, []);

  /**
   * @remarks
   * 作業カテゴリーごとに使用した時間（秒数）をまとめたオブジェクト
   *
   * @see [DummyData](src/data/example/oneCalculatedCategoryObject.json)
   */
  const oneCalculatedCategoryObject = _makeOneCalculatedCategoryObject(
    result[0]
  );

  return [oneCalculatedCategoryObject, excludeUndefinedOneMinuteAppInfoArray];

  // return _makeOneCalculatedCategoryObject(result[0]);
};

/**
 * @remarks
 * 24時間あたりの作業をカテゴリー別に集計する
 * 集計した結果を1つのオブジェクトにまとめる
 *
 * @param appInfoData @see [DummyData](src/data/dummy1/allPushWithIndexAppInfoData.json)
 *
 * @returns
 * [
 *  oneCalculatedCategoryObject @see [DummyData](src/data/example/oneCalculatedCategoryObject.json),
 *  excludeUndefinedOneMinuteAppInfoArray @see [DummyData](src/data/example/excludeUndefinedOneMinuteAppInfoArray.json)
 * ]
 */
const _calculateCategoryPerMultipleHour = (
  appInfoData: AppInfoDetailMultipleData
): [CalculatedCategoryPer1Minute | undefined, AppInfoDetailData[] | []] => {
  const result: [
    (CalculatedCategoryPer1Minute | undefined)[],
    (AppInfoDetailData[] | [])[]
  ] = appInfoData.reduce(
    (
      prev: [
        (CalculatedCategoryPer1Minute | undefined)[],
        (AppInfoDetailData[] | [])[]
      ],
      appInfo1Hour: AppInfoDetailPerHour
    ) => {
      /**
       * [
       *  oneCalculatedCategoryObject @see [DummyData](src/data/example/oneCalculatedCategoryObject.json),
       *  excludeUndefinedOneMinuteAppInfoArray @see [DummyData](src/data/example/excludeUndefinedOneMinuteAppInfoArray.json)
       * ]
       */
      const [
        oneCalculatedCategoryObject,
        excludeUndefinedOneMinuteAppInfoArray,
      ] = _calculateCategoryPer1Hour(appInfo1Hour);
      prev[0].push(oneCalculatedCategoryObject);
      prev[1].push(excludeUndefinedOneMinuteAppInfoArray);
      return prev;

      // return oneCalculatedCategoryObject;
    },
    [[], []]
  );

  /**
   * @remarks
   * 使用したアプリと詳細内容の1時間分を1つの配列にまとめる
   *
   * @see [DummyData](src/data/example/excludeUndefinedOneMinuteAppInfoArray.json)
   */
  const excludeUndefinedOneMinuteAppInfoArray: AppInfoDetailData[] | [] =
    result[1]
      .filter(
        (item): item is Exclude<typeof item, undefined> => item !== undefined
      )
      .reduce((prev: AppInfoDetailData[], cur: AppInfoDetailData[]) => {
        prev.push(...cur);
        return prev;
      }, []);

  /**
   * @remarks
   * 作業カテゴリーごとに使用した時間（秒数）をまとめたオブジェクト
   *
   * @see [DummyData](src/data/example/oneCalculatedCategoryObject.json)
   */
  const oneCalculatedCategoryObject = _makeOneCalculatedCategoryObject(
    result[0]
  );

  return [oneCalculatedCategoryObject, excludeUndefinedOneMinuteAppInfoArray];

  // return _makeOneCalculatedCategoryObject(result);
};

/**
 * @remarks
 * 使用したアプリで扱った作業の種類を判定する
 *
 * @param appInfoItem @see [DummyData](src/data/example/appInfoItem.json)
 *
 * @returns
 * @see {@link InfoType}
 */
const _generateInfoType = (appInfoItem: AppInfoItem): InfoType => {
  if (!appInfoItem.appInfoPath.length) return 'none';
  // アプリを使って行った作業がURLを含んでいるかどうかで、ブラウザの作業・ファイル作業を判定する
  return appInfoItem.appInfoPath.indexOf('http') >= 0 ? 'url' : 'file';
};

/**
 * @remarks
 * アプリの情報と使用アプリのカウント情報をまとめて返す
 *
 * @param appInfoItem @see [DummyData](src/data/example/appInfoItem.json)
 *
 * @returns
 * @see {@link AppInfoByCategoryData}
 */
const _generateNewAppInfoItem = (
  appInfoItem: AppInfoItem
): AppInfoByCategoryData => {
  return {
    appName: appInfoItem.appName,
    // category: appInfoItem.category,
    useTime: appInfoItem.useTime,
    detail: [
      {
        useDetail: appInfoItem.appUseDetail,
        infoPath: appInfoItem.appInfoPath,
        useTime: appInfoItem.useTime,
        infoType: _generateInfoType(appInfoItem),
        category: appInfoItem.category,
      },
    ],
  };
};

/**
 * @remarks
 * 使用アプリのカウント情報をまとめて返す
 *
 * @param appInfoItem @see [DummyData](src/data/example/appInfoItem.json)
 *
 * @returns
 * @see {@link AppInfoDetailSumData}
 */
const _generateNewAppDetailInfoItem = (
  appInfoItem: AppInfoItem
): AppInfoDetailSumData => {
  return {
    useDetail: appInfoItem.appUseDetail,
    infoPath: appInfoItem.appInfoPath,
    useTime: appInfoItem.useTime,
    infoType: _generateInfoType(appInfoItem),
    category: appInfoItem.category,
  };
};

/**
 * @remarks
 * 使用されたアプリの情報に入っていないアプリの情報を追加する
 *
 * @param appInfoCountObject @see [DummyData](src/data/example/appInfoCountObject.json)
 * @param appInfoItem @see [DummyData](src/data/example/appInfoItem.json)
 *
 * @returns
 * @see [DummyData](src/data/example/appInfoCountObject.json)
 */
const _addNewAppInfoItem = (
  appInfoCountObject: OrganizeAppInfoByCategory,
  appInfoItem: AppInfoDetailData
): OrganizeAppInfoByCategory => {
  /**
   * @remarks
   * アプリの情報と使用アプリのカウント情報をまとめて返す
   *
   * @see {@link AppInfoByCategoryData}
   */
  const newItem: AppInfoByCategoryData = _generateNewAppInfoItem(appInfoItem);

  if (appInfoCountObject[appInfoItem.category] === undefined) {
    appInfoCountObject[appInfoItem.category] = [];
  }

  (appInfoCountObject[appInfoItem.category] as AppInfoByCategoryData[]).push(
    newItem
  );

  return appInfoCountObject;
};

/**
 * @remarks
 * 使用されたアプリの情報がすでに入っている場合、そのindexを取得する
 *
 * @param appInfoCountObject @see [DummyData](src/data/example/findAlreadAddedAppIndexAppInfoCountObject.json)
 * @param appInfoItem @see [DummyData](src/data/example/appInfoItem.json)
 *
 * @returns
 * {number} indexの番号
 */
const _findAlreadAddedAppIndex = (
  appInfoCountObject: AppInfoByCategoryData[],
  appInfoItem: AppInfoDetailData
): number => {
  // 使用されたアプリの配列から指定したアプリの名前が見つかったら、そのindexを返す
  return appInfoCountObject.findIndex(
    (appSumItem: AppInfoByCategoryData) =>
      appSumItem.appName === appInfoItem.appName
  );
};

/**
 * @remarks
 * 使用されたアプリの詳細情報がすでに入っている場合、そのindexを取得する
 *
 * @param appInfoCountObject @see [DummyData](src/data/example/findAlreadAddedAppIndexAppInfoCountObject.json)
 * @param appInfoItem @see [DummyData](src/data/example/appInfoItem.json)
 * @param alreadAddedAppIndex {number} - appInfoCountObjectの中にすでに入っている使用されたアプリの詳細情報のindex
 *
 * @returns
 * {number} indexの番号
 */
const _findAlreadAddedDetailIndex = (
  appInfoCountObject: AppInfoByCategoryData[],
  appInfoItem: AppInfoDetailData,
  alreadAddedAppIndex: number
): number => {
  // 使用されたアプリの配列から指定したアプリの詳細情報が見つかったら、そのindexを返す
  return appInfoCountObject[alreadAddedAppIndex].detail.findIndex(
    (appDetailItem: AppInfoDetailSumData) =>
      appDetailItem.useDetail === appInfoItem.appUseDetail
  );
};

/**
 * @remarks
 * 詳細情報の中に該当する作業ファイル名・タブ名が存在しない場合
 *
 * @param appInfoCountObject @see [DummyData](src/data/example/appInfoCountObject.json)
 * @param appInfoItem @see [DummyData](src/data/example/appInfoItem.json)
 * @param alreadAddedAppIndex {number} - appInfoCountObjectの中の特定のカテゴリにすでに入っている使用されたアプリの詳細情報のindex
 *
 * @returns
 * @see [DummyData](src/data/example/appInfoCountObject.json)
 */
const _addNewAppDetailInfoItem = (
  appInfoCountObject: OrganizeAppInfoByCategory,
  appInfoItem: AppInfoDetailData,
  alreadAddedAppIndex: number
): OrganizeAppInfoByCategory => {
  /**
   * @remarks
   * 使用されたアプリの配列に使用アプリのカウント情報をまとめたオブジェクトを追加して、返す
   *
   * @see {@link AppInfoDetailSumData}
   */
  const newDetailItem: AppInfoDetailSumData =
    _generateNewAppDetailInfoItem(appInfoItem);
  (
    appInfoCountObject[appInfoItem.category][alreadAddedAppIndex]
      .detail as AppInfoDetailSumData[]
  ).push(newDetailItem);
  return appInfoCountObject;
};

/**
 * @remarks
 * 1日の作業のカテゴリー別に集計する
 *
 * @param appInfoData @see [DummyData](src/data/dummy1/allPushWithIndexAppInfoData.json)
 *
 * @returns
 * @see {@link useOneDayTotalData}
 * [
 *  workingHourBreakdownItems：カテゴリ別の作業時間を秒数でまとめたオブジェクト,
 *  organizeAppInfoByCategory：使用したアプリケーションとその作業内容、使用した時間をオブジェクトにまとめた配列を作業カテゴリごとにを集約したオブジェクト,
 * ]
 */
const calculateTimeByCategory = (
  appInfoData: AppInfoDetailMultipleData
): [
  CalculatedCategoryPer1Minute | undefined,
  OrganizeAppInfoByCategory | {}
] => {
  /**
   * [
   *  oneCalculatedCategoryObject @see [DummyData](src/data/example/oneCalculatedCategoryObject.json),
   *  excludeUndefinedOneMinuteAppInfoArray @see [DummyData](src/data/example/excludeUndefinedOneMinuteAppInfoArray.json)
   * ]
   */
  const categoryTimePer1Hours: [
    CalculatedCategoryPer1Minute | undefined,
    [] | AppInfoDetailData[]
  ] = _calculateCategoryPerMultipleHour(appInfoData);

  let result = {};

  if (categoryTimePer1Hours[1].length) {
    result = (categoryTimePer1Hours[1] as AppInfoDetailData[]).reduce(
      (
        prev: OrganizeAppInfoByCategory,
        cur: AppInfoDetailData
      ): OrganizeAppInfoByCategory => {
        if(!cur.category) return prev;
        if (prev[cur.category] === undefined) {
          /** @see [DummyData](src/data/example/appInfoCountObject.json) */
          return _addNewAppInfoItem(prev, cur);
        } else {
          const alreadAddedAppIndex = _findAlreadAddedAppIndex(
            prev[cur.category],
            cur
          );

          if (alreadAddedAppIndex < 0) {
            /** @see [DummyData](src/data/example/appInfoCountObject.json) */
            return _addNewAppInfoItem(prev, cur);
          }

          prev[cur.category][alreadAddedAppIndex].useTime += cur.useTime;

          const alreadAddedDetailIndex = _findAlreadAddedDetailIndex(
            prev[cur.category],
            cur,
            alreadAddedAppIndex
          );

          if (alreadAddedDetailIndex < 0) {
            /** @see [DummyData](src/data/example/appInfoCountObject.json) */
            return _addNewAppDetailInfoItem(prev, cur, alreadAddedAppIndex);
          }

          prev[cur.category][alreadAddedAppIndex].detail[
            alreadAddedDetailIndex
          ].useTime += cur.useTime;

          return prev;
        }
      },
      {} as OrganizeAppInfoByCategory
    );
  }

  return [categoryTimePer1Hours[0], result];
};

export { calculateTimeByCategory };

const calculateTimeByCategoryPerHour = (
  appInfoData: AppInfoDetailMultipleData
): [
  CalculatedCategoryPer1Minute | undefined,
  OrganizeAppInfoByCategory | {}
][] => {
  let result: [
    CalculatedCategoryPer1Minute | undefined,
    OrganizeAppInfoByCategory | {}
  ][] = [];

  appInfoData.forEach((appInfo1Hour: AppInfoDetailPerHour) => {
    const categoryTimePer1Hours: [
      CalculatedCategoryPer1Minute | undefined,
      [] | AppInfoDetailData[]
    ] = _calculateCategoryPer1Hour(appInfo1Hour);
    let calculatorResult = {};

    if (categoryTimePer1Hours[1].length) {
      calculatorResult = (
        categoryTimePer1Hours[1] as AppInfoDetailData[]
      ).reduce(
        (
          prev: OrganizeAppInfoByCategory,
          cur: AppInfoDetailData
        ): OrganizeAppInfoByCategory => {
          if (!cur.category) return prev;
          if (prev[cur.category] === undefined) {
            /** @see [DummyData](src/data/example/appInfoCountObject.json) */
            return _addNewAppInfoItem(prev, cur);
          } else {
            const alreadAddedAppIndex = _findAlreadAddedAppIndex(
              prev[cur.category],
              cur
            );

            if (alreadAddedAppIndex < 0) {
              /** @see [DummyData](src/data/example/appInfoCountObject.json) */
              return _addNewAppInfoItem(prev, cur);
            }

            prev[cur.category][alreadAddedAppIndex].useTime += cur.useTime;

            const alreadAddedDetailIndex = _findAlreadAddedDetailIndex(
              prev[cur.category],
              cur,
              alreadAddedAppIndex
            );

            if (alreadAddedDetailIndex < 0) {
              /** @see [DummyData](src/data/example/appInfoCountObject.json) */
              return _addNewAppDetailInfoItem(prev, cur, alreadAddedAppIndex);
            }

            prev[cur.category][alreadAddedAppIndex].detail[
              alreadAddedDetailIndex
            ].useTime += cur.useTime;

            return prev;
          }
        },
        {} as OrganizeAppInfoByCategory
      );
    }
    const categories = categoryTimePer1Hours[0];
    let sortable: Array<[string, number]> = [];
    for (var item in categories) {
      sortable.push([item, categories[item]]);
    }

    const sortedList = sortable.sort(function (a: [string, number], b: [string, number]) {
      return b[1] - a[1];
    });

    let categoryUsedList: CalculatedCategoryPer1Minute | undefined = undefined;
    sortedList.forEach((item: [string, number]) => {
      categoryUsedList = { ...categoryUsedList, [item[0]]: item[1] };
    });

   
    result.push([categoryUsedList, calculatorResult]);
  });

  return result;
};

export {calculateTimeByCategoryPerHour}

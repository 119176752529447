'use strict;';

import {
  generate24HourAppInfo,
  generate12HourAppInfo,
  generateUnder12HourAppInfo,
} from './appInfoCalculate';

type Props = {
  timeZoneIndex: number | undefined;
  appInfoData: AppInfoAt24Hours;
  activeTimeZone: HoveredTimeZoneItemPosition;
};

/**
 * @remarks
 * 表示する時間帯によるアプリ使用状況のデータを生成する
 *
 * @param Props - {@link Props}
 *  @param timeZoneIndex {number} - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *  @param appInfoData @see [DummyData](src/data/dummy1/allPushWithIndexAppInfoData.json)
 *  @param activeTimeZone {string | number} - 時間帯（24時間帯：number、12時間帯以下：string）
 *
 * @returns
 * @see [DummyData](src/data/example/generate24HourAppInfo.json)
 * @see [DummyData](src/data/example/generate12HourAppInfo.json)
 * @see [DummyData](src/data/example/generateUnder12HourAppInfo6HResult.json)
 * @see [DummyData](src/data/example/generateUnder12HourAppInfo3HResult.json)
 * @see [DummyData](src/data/example/generateUnder12HourAppInfo1HResult.json)
 * @see [DummyData](src/data/example/generateUnder12HourAppInfo30mResult.json)
 */
const generateAppInfoData = ({
  timeZoneIndex,
  appInfoData,
  activeTimeZone = undefined,
}: Props): (AppInfoCountObject | InitAppInfoCountObject)[] | undefined => {
  switch (timeZoneIndex) {
    case 0:
      /** @see [DummyData](src/data/example/generate24HourAppInfo.json) */
      return generate24HourAppInfo(appInfoData);

    case 1:
      /** @see [DummyData](src/data/example/generate12HourAppInfo.json) */
      return generate12HourAppInfo({
        activeTimeZone,
        appInfoData,
      });

    case 2:
    case 3:
    case 4:
    case 5:
      /**
       * @see [DummyData](src/data/example/generateUnder12HourAppInfo6HResult.json)
       * @see [DummyData](src/data/example/generateUnder12HourAppInfo3HResult.json)
       * @see [DummyData](src/data/example/generateUnder12HourAppInfo1HResult.json)
       * @see [DummyData](src/data/example/generateUnder12HourAppInfo30mResult.json)
       */
      return generateUnder12HourAppInfo({
        activeTimeZone,
        appInfoData,
        timeZoneIndex,
      });

    default:
      return undefined;
  }
};

export { generateAppInfoData };

import { inputAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(inputAnatomy.keys);

const custom = definePartsStyle({
  field: {
    height: "37px",
    border: "1px solid #B1B1B1",
    borderRadius: "4px",
    px: "10px",
    color: "#2C2C2C",
    fontSize: "14px",
    fontWeight: "bold",
    _placeholder: {
      color: "#BCBCBC",
      fontWeight: "medium",
    },
    _invalid: {
      borderColor: "#E53E3E",
      boxShadow: "0 0 0 1px #E53E3E",
    },
  },
});

export const inputTheme = defineMultiStyleConfig({ variants: { custom } });

import { createAsyncThunk } from "@reduxjs/toolkit";
import {
  DeleteMember,
  GetMemberList,
  MemberDataPayload,
  MemberFrame,
  TGetAdminTeamBelongDataPayload,
  TTeamFrame,
  TUpdateAdminTeamBelongDataPayload,
  UpdateMemberProfile,
} from "../../@types/Member";
import { BaseRequest } from "../../@types/Request";
import { BaseResponse } from "../../@types/Response";
import { memberServices } from "../../services/memberService";

export const getMemberListThunk = createAsyncThunk(
  "member/getMemberList",
  async ({ payload }: BaseRequest<GetMemberList>) => {
    try {
      const response = await memberServices.getMemberList(payload);
      return response.data?.[0]?.gtuserframe;
    } catch (error) {
      return [] as MemberFrame[];
    }
  }
);

export const getMemberThunk = createAsyncThunk(
  "member/getMember",
  async ({ payload, onSuccess }: BaseRequest<MemberDataPayload>) => {
    try {
      const response = await memberServices.getMember(payload);
      onSuccess?.();
      return response.data;
    } catch (error) {
      return [] as MemberFrame[];
    }
  }
);

export const updateMemberThunk = createAsyncThunk(
  "member/updateMember",
  async ({ payload, onSuccess, onError }: BaseRequest<UpdateMemberProfile>) => {
    try {
      const response = await memberServices.updateMember(payload);
      const { message, status } = response.data?.[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data;
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

export const deleteMemberThunk = createAsyncThunk(
  "member/deleteMember",
  async ({ payload, onSuccess, onError }: BaseRequest<DeleteMember>) => {
    try {
      const response = await memberServices.deleteMember(payload);
      const { message, status } = response.data?.[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data;
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

export const getAdminTeamBelongMemberThunk = createAsyncThunk(
  "member/getAdminTeamBelongMember",
  async ({
    payload,
    onSuccess,
    onError,
  }: BaseRequest<TGetAdminTeamBelongDataPayload>) => {
    try {
      const response = await memberServices.getAdminTeamBelongData(payload);
      onSuccess?.();
      return response.data?.[0]?.gtteamframe;
    } catch (error) {
      return [] as TTeamFrame[];
    }
  }
);

export const updateAdminTeamBelongMemberThunk = createAsyncThunk(
  "member/updateAdminTeamBelongMember",
  async ({
    payload,
    onSuccess,
    onError,
  }: BaseRequest<TUpdateAdminTeamBelongDataPayload>) => {
    try {
      const response = await memberServices.updateAdminTeamBelongData(payload);
      const { message, status } = response.data?.[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data;
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

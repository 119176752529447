import React, { Fragment, useEffect } from "react";
import SchedulePopOverCustom from "./SchedulePopOverCustom";
import { generateScheduleData } from "../../lib/scheduleData";
import { getActiveTimeZoneIndex } from "../../lib/UIConditions";
import { Grid, GridItem } from "@chakra-ui/react";
import { getHourFromString } from "../../lib/timeCalculate";

type Props = {
  timeZoneIndex: number;
  mouseHoveredTimeZone: HoveredTimeZone;
  scheduleDatas: ScheduleData[] | [];
  setGridViewScheduleDatas: (
    scheduledItemIndexArray: (number | false)[]
  ) => void;
  setGridStartTimeZoneData: (generatedTimeSpotArray: string[]) => void;
  openScheduleModalControll: (showScheduleDataGridNum: number) => void;
};

// 総作業時間グラフにスケジュールをGrid状で表示するためのパーツ
const ScheduleGridView = React.memo(
  ({
    timeZoneIndex,
    mouseHoveredTimeZone,
    scheduleDatas,
    setGridViewScheduleDatas,
    setGridStartTimeZoneData,
    openScheduleModalControll,
  }: Props) => {
    // 画面に表示する正確な時間帯のindex
    const activeTimeZoneIndex = getActiveTimeZoneIndex(timeZoneIndex);
    const newScheduleData = scheduleDatas?.map((object) => ({ ...object }));

    // 表示する時間帯によるGrid表示用のスケジュールデータを生成する
    const [scheduledItemIndexArray, generatedTimeSpotArray] =
      generateScheduleData({
        timeZoneIndex,
        scheduleData: scheduleDatas,
        activeTimeZone: mouseHoveredTimeZone[activeTimeZoneIndex].position,
      });

    useEffect(() => {
      if (scheduledItemIndexArray.length)
        setGridViewScheduleDatas(scheduledItemIndexArray);
    }, [scheduledItemIndexArray, setGridViewScheduleDatas]);
    useEffect(() => {
      if (generatedTimeSpotArray.length)
        setGridStartTimeZoneData(generatedTimeSpotArray);
    }, [generatedTimeSpotArray, setGridStartTimeZoneData]);

    if (!scheduledItemIndexArray.length || !generatedTimeSpotArray.length)
      return null;

    // 表示するGridの個数だけ生成する
    let generatedTimeSpots: [] | string[];

    if (timeZoneIndex === 0) {
      generatedTimeSpots = generatedTimeSpotArray
        ?.slice(5)
        .concat(generatedTimeSpotArray?.slice(0, 5));
    } else {
      generatedTimeSpots = generatedTimeSpotArray;
    }

    const item = generatedTimeSpots?.map((v: string, i: number) => {
      const foundSameGridPosition = scheduledItemIndexArray.findIndex(
        (numOrFalse: number | false) => i === numOrFalse
      );
      const color = foundSameGridPosition < 0 ? "" : undefined;

      const displayScheduleData = newScheduleData.filter(
        (item: ScheduleData) => {
          const startHour = getHourFromString(item.start);
          const hour = v.split(":")[0];
          return hour === startHour;
        }
      );
      if (displayScheduleData.length > 0) {
        return (
          <Fragment key={`grid-found-schedule-${i}`}>
            <SchedulePopOverCustom
              scheduleDatas={displayScheduleData}
              showScheduleDataGridNum={i}
              modalOpen={openScheduleModalControll}
            />
          </Fragment>
        );
      }

      return (
        <GridItem
          key={`grid-schdule-${i}`}
          colStart={i + 1}
          colEnd={i + 2}
          h="10"
          bg={color}
        />
      );
    });

    return (
      <Grid
        templateColumns={`repeat(${generatedTimeSpotArray.length}, 1fr)`}
        width={"95%"}
        px="10px"
        alignItems="center"
      >
        {item}
      </Grid>
    );
  }
);
ScheduleGridView.displayName = "ScheduleGridView";
export default ScheduleGridView;

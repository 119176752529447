import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { MemberFrame, UpdateMemberProfile } from "../../../@types/Member";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  getMemberListThunk,
  updateMemberThunk,
} from "../../../redux/thunk/memberThunk";
import { EMPTY_STRING } from "../../Const";
import { REQUIRED_FIELD } from "../../util/messages";
import { useCallback } from "react";
import { getTeamUserDataThunk } from "../../../redux/thunk/teamThunk";

type UseChangeUserProfileType = {
  user: MemberFrame | null;
  onClose: () => void;
  onOk?: () => void;
};

const schema = z.object({
  firstname: z.string().trim().min(1, REQUIRED_FIELD),
  lastname: z.string().trim().min(1, REQUIRED_FIELD),
  firstnamekana: z.string().trim().min(1, REQUIRED_FIELD),
  lastnamekana: z.string().trim().min(1, REQUIRED_FIELD),
  occupation: z.string().trim(),
  title: z.string().trim(),
  contract: z.string().trim(),
  location: z.string().trim(),
  authority: z.string().trim().min(1, REQUIRED_FIELD),
});

export type UserDataForm = z.infer<typeof schema>;

export const useChangeUserProfile = (props: UseChangeUserProfileType) => {
  const { user, onClose, onOk } = props;
  const dispatch = useAppDispatch();
  const { adcompanyid } = useAppSelector((state) => state.userInfo);
  const { teamUserData } = useAppSelector((state) => state.team);
  const { handleSubmit, register, reset, formState } = useForm<UserDataForm>({
    resolver: zodResolver(schema),
    values: {
      authority: user?.gtauthority?.toString() || EMPTY_STRING,
      contract: user?.gtcontract || EMPTY_STRING,
      location: user?.gtlocation || EMPTY_STRING,
      occupation: user?.gtoccupation || EMPTY_STRING,
      title: user?.gttitle || EMPTY_STRING,
      firstname: user?.gtuserfirstname || EMPTY_STRING,
      lastname: user?.gtuserlastname || EMPTY_STRING,
      firstnamekana: user?.gtuserfirstnamekana || EMPTY_STRING,
      lastnamekana: user?.gtuserlastnamekana || EMPTY_STRING,
    },
  });

  const onGetTeamInfo = useCallback(() => {
    if (adcompanyid && teamUserData?.gtteamid) {
      dispatch(
        getTeamUserDataThunk({
          payload: {
            adcompanyid,
            adteamid: teamUserData?.gtteamid,
          },
        })
      );
      dispatch(getMemberListThunk({ payload: { urcompanyid: adcompanyid } }));
    }
  }, [adcompanyid, dispatch, teamUserData]);

  const onCloseModal = () => {
    onClose();
    reset();
  };

  const onSubmit = async (values: UserDataForm) => {
    if (user && adcompanyid) {
      const payload: UpdateMemberProfile = {
        upcrud: "U",
        upcompanyid: adcompanyid,
        upuserid: user.gtuserid,
        upuserpic: user.gtuserpic,
        upuserpassword: user.gtuserpassword,
        upuserfirstname: values.firstname,
        upuserfirstnamekana: values.firstnamekana,
        upuserlastname: values.lastname,
        upuserlastnamekana: values.lastnamekana,
        upoccupation: values.occupation,
        uplocation: values.location,
        uptitle: values.title,
        upcontract: values.contract,
        upauthority: Number(values.authority),
      };
      dispatch(
        updateMemberThunk({
          payload,
          onSuccess: () => {
            onGetTeamInfo();
            onCloseModal();
            onOk?.();
          },
        })
      );
    }
  };

  return {
    formState,
    handleSubmit,
    register,
    reset,
    onSubmit,
    onCloseModal,
  };
};

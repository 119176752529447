import { Box } from "@chakra-ui/layout";
import { TableContainer } from "@chakra-ui/react";
import { Pagination } from "components/pagination/Pagination";
import { useApplicationUnRegistered } from "../../../lib/hooks/ManagementSettings/useApplicationUnRegistered";
import { CommonTable } from "../CommonTable";
import { ButtonPrimary } from "./Button/ButtonPrimary";
import { EditApplicationModal } from "./Modal/EditApplicationModal";
import { RegisterApplicationModal } from "./Modal/RegisterApplicationModal";
import {
  TApplicationDetail,
  TApplicationListTable,
} from "../../../@types/Application";

export const ApplicationUnRegistered = () => {
  const { state, handler } = useApplicationUnRegistered();

  return (
    <Box
      display="flex"
      flexDirection="column"
      gap="20px"
      css={{
        tr: {
          "th:nth-child(2)": {
            paddingLeft: "0px",
          },
          "td:nth-child(2)": {
            paddingLeft: "0px",
            maxWidth: "45%",
          },
          "td:nth-child(3)": {
            maxWidth: "45%",
          },
          td: {
            overflow: "hidden",
            wordBreak: "break-word",
            whiteSpace: "break-spaces",

            "&>div": {
              height: "auto",
            },
          },
        },
      }}
    >
      <form
        onSubmit={handler?.handleSubmit(handler?.openModalAdd)}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "20px",
        }}
      >
        <ButtonPrimary
          width="fit-content"
          type="submit"
          isDisabled={!handler?.watch("ids")?.some((value) => value?.id)}
          _disabled={{
            background: "#D6D6D6",
            cursor: "default",
            _hover: { background: "#D6D6D6" },
          }}
        >
          アプリケーションを一括登録
        </ButtonPrimary>
        {state?.applicationList?.length ? (
          <TableContainer>
            <CommonTable
              columns={state?.columns}
              data={state?.applicationList}
            />
          </TableContainer>
        ) : null}
        {state?.applicationList?.length ? (
          <Pagination
            total={state?.total}
            pageNumber={state?.pageNumber}
            pageSize={state?.pageSize}
            siblingCount={1}
            onPageNumberChange={handler?.onPageNumberChange}
            onPageSizeChange={handler?.onPageSizeChange}
          />
        ) : null}
      </form>
      {state?.modalName === "ADD_APPLICATION" && (
        <RegisterApplicationModal
          isOpen={state?.modalName === "ADD_APPLICATION"}
          title={"アプリケーションを一括登録"}
          onClose={handler?.onCloseModal}
          applicationSelected={
            state?.applicationSelected as TApplicationListTable[]
          }
        />
      )}
      {state?.modalName === "EDIT_APPLICATION" && (
        <EditApplicationModal
          applicationSelected={state?.applicationSelected as TApplicationDetail}
          isOpen={state?.modalName === "EDIT_APPLICATION"}
          title={"アプリケーション情報"}
          onClose={handler?.onCloseModal}
        />
      )}
    </Box>
  );
};

import { Heading, HeadingProps } from "@chakra-ui/react";
import { FC } from "react";

export const TextTableHeader: FC<HeadingProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Heading fontSize="12px" fontWeight="bold" color="#2C2C2C" {...otherProps}>
      {children}
    </Heading>
  );
};

"use strict;";

import { calculateActiveRangeEndTime } from "./timeCalculate";
import { calculateMinMaxTimezone } from "./commonDataProcessing";

import { gridUnits, adjustTime, addRangeEndTime } from "./Const";

/**
 * @remarks
 * 24時間帯のアプリ使用状況のデータを生成する
 *
 * @param screenShotsOneArrayData 1次元配列のスクリーンショットデータ　@see [DummyData](src/data/example/screenShotsOneline.json)
 * @param screenShotsData スクリーンショット情報 @see [DummyData](src/data/dummy1/screenshots_dummy.json)
 *
 * @returns
 * @see {@link ScreenShotsGridDataArray}
 * @see [DummyData](src/data/example/generate24HourScreenShotsInfo.json)
 */
const generate24HourScreenShotsInfo = (
  screenShotsOneArrayData: ScreenShotsAtOneMinute[],
  screenShotsData: ScreenShotsDataAtOneHour[]
): ScreenShotsGridData[] => {
  const data: ScreenShotsGridData[] = screenShotsData.map(
    (screenShotsAtOneHour: ScreenShotsDataAtOneHour, index: number) => {
      const foundScreenShotsFirstIndex =
        screenShotsAtOneHour?.detaildata?.findIndex(
          (v: ScreenShotsAtOneMinute) => v.screenshot.length
        );
      if (foundScreenShotsFirstIndex < 0) {
        return {
          screenShotsIndex: -1,
        };
      } else {
        const searchTimeZone =
          screenShotsAtOneHour.detaildata[foundScreenShotsFirstIndex].time;
        const foundScreenShotsOneArrayIndex = screenShotsOneArrayData.findIndex(
          (v: ScreenShotsAtOneMinute) => v.time === searchTimeZone
        );
        return {
          screenShotsIndex: foundScreenShotsOneArrayIndex,
        };
      }
    }
  );
  return data;
};

/**
 * @remarks
 * 24時間帯より詳細のアプリ使用状況のデータを生成する
 *
 * @param activeTimeZone グラフで表示する中央の時間帯（24時間帯：number、12時間帯以下：string） {@link useMouseHoveredTimeZone}
 * @param timeZoneIndex 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 * @param screenShotsOneArrayData 1次元配列のスクリーンショットデータ　@see [DummyData](src/data/example/screenShotsOneline.json)
 * @param screenShotsData スクリーンショット情報 @see [DummyData](src/data/dummy1/screenshots_dummy.json)
 *
 * @returns
 * @see {@link ScreenShotsGridDataArray}
 * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo12hCount.json)
 * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo6hCount.json)
 * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo3hCount.json)
 * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo1hCount.json)
 * @see [DummyData](src/data/example/generateUnder24HourScreenShotsInfo30mCount.json)
 */
const generateUnder24HourScreenShotsInfo = (
  activeTimeZone: string | number,
  timeZoneIndex: number,
  screenShotsOneArrayData: ScreenShotsAtOneMinute[],
  screenShotsData: ScreenShotsDataAtOneHour[]
): ScreenShotsGridData[] => {
  // 1. 表示したい時間帯の範囲の時間帯を取得
  const stringActiveTimeZone: string =
    typeof activeTimeZone === "number"
      ? `${activeTimeZone}:00`
      : activeTimeZone;

  const { minTime } = calculateMinMaxTimezone({
    activeTimeZone: stringActiveTimeZone,
    adjustTimeByGenerateTimeZone: adjustTime[timeZoneIndex],
  });

  const splitHourAndMinutes = minTime.split(":");
  const time = {
    hour: parseInt(splitHourAndMinutes[0]),
    minutes: parseInt(splitHourAndMinutes[1]),
  };

  // 2. 時間帯の範囲から表示するgrid数に合わせて各gridの開始時間を生成する
  const gridStartTimeArray: string[] = [...Array(gridUnits[timeZoneIndex])].map(
    (_: undefined, i: number) => {
      return calculateActiveRangeEndTime(
        time,
        addRangeEndTime[timeZoneIndex] * i
      );
    }
  );

  // 3. 各gridの開始時間をもとに、gridで扱う時間範囲を生成する
  const result = gridStartTimeArray.map((gridStartTime: string, i: number) => {
    const splitGridStartTimeToHourAndMinutes = gridStartTime.split(":");
    const gridStartTimeObject = {
      hour: parseInt(splitGridStartTimeToHourAndMinutes[0]),
      minutes: parseInt(splitGridStartTimeToHourAndMinutes[1]),
    };
    const gridTimeZoneArray: string[] = [
      ...Array(addRangeEndTime[timeZoneIndex]),
    ].map((_: undefined, i: number) =>
      calculateActiveRangeEndTime(gridStartTimeObject, i)
    );
    // 4. gridの時間範囲内で表示するスクリーンショットがあるかないかを判定する
    // 表示する時間かundefined
    const hasScreenShotsTime: string | undefined = gridTimeZoneArray.find(
      (timeZone: string, i: number) => {
        const foundGridTimeZoneScreenShotsObject = screenShotsOneArrayData.find(
          (v: ScreenShotsAtOneMinute) => v.time === timeZone
        );
        if (
          !foundGridTimeZoneScreenShotsObject ||
          !foundGridTimeZoneScreenShotsObject.screenshot.length
        ) {
          return false;
        }

        return timeZone;
      }
    );

    if (hasScreenShotsTime === undefined) {
      return {
        screenShotsIndex: -1,
      };
    } else {
      const foundScreenShotsOneArrayIndex = screenShotsOneArrayData.findIndex(
        (v: ScreenShotsAtOneMinute) => v.time === hasScreenShotsTime
      );
      return {
        screenShotsIndex: foundScreenShotsOneArrayIndex,
      };
    }
  });

  return result;
};

export { generate24HourScreenShotsInfo, generateUnder24HourScreenShotsInfo };

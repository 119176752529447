import { defineStyle, defineStyleConfig } from "@chakra-ui/react";

const custom = defineStyle({
  minH: "70px",
  border: "1px solid #B1B1B1",
  fontSize: "14px",
  fontWeight: "bold",
  lineHeight: "20px",
  px: "10px",
  py: "8px",
  _placeholder: { color: "#A5A5A5", fontWeight: "medium" },
  _invalid: {
    borderColor: "#E53E3E",
    boxShadow: "0 0 0 1px #E53E3E",
  },
});

export const textareaTheme = defineStyleConfig({
  variants: { custom },
});

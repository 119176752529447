import {
  Box,
  Button,
  Center,
  Flex,
  FormControl,
  FormErrorMessage,
  Image,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Switch,
  Text,
} from "@chakra-ui/react";
import { categoryOptions } from "lib/Const";
import { FC, ReactNode } from "react";
import { TApplicationListTable } from "../../../../@types/Application";
import copy from "../../../../images/copy-icon.svg";
import group_person from "../../../../images/group-person-icon.svg";
import buttonNext from "../../../../images/icons/button_next.svg";
import { useRegisterApplicationModal } from "../../../../lib/hooks/ManagementSettings/useRegisterApplicationModal";
import { CommonModal } from "../../CommonModal";
import { ButtonSubmit } from "../Button/ButtonSubmit";
import { RemoveApplicationModal } from "./RemoveApplicationModal";

export type AddApplicationModalProps = {
  applicationSelected: TApplicationListTable[];
  title: ReactNode;
  isOpen: boolean;
  onClose: (props?: { isFetchData?: boolean }) => void;
};

export const RegisterApplicationModal: FC<AddApplicationModalProps> = (
  props
) => {
  const { isOpen, title, onClose } = props;
  const { state, handler } = useRegisterApplicationModal(props);

  return (
    <CommonModal
      isOpen={isOpen}
      title={title}
      onClose={() => {
        handler?.onCloseModal();
        onClose?.();
      }}
      minW={1300}
      h={724}
      isCentered
      overflowBody="hidden"
      fixedContent={true}
    >
      <form
        onSubmit={handler?.handleSubmit(handler?.onSubmit)}
        style={{ display: "flex", flexDirection: "row" }}
        tabIndex={0}
      >
        <Flex
          direction="column"
          flex={1}
          maxWidth={state?.isOpenNote ? "984px" : "auto"}
        >
          <Flex paddingInline="20px" width="calc(100% - 18px)">
            <Box
              minWidth={state?.isOpenNote ? "16.5%" : "13%"}
              maxWidth={state?.isOpenNote ? "16.5%" : "13%"}
              paddingRight="24px"
            >
              <Text fontSize="12px" fontWeight="700" color="#2C2C2C">
                実行ファイル名
              </Text>
            </Box>
            <Box
              minWidth={state?.isOpenNote ? "25.5%" : "20%"}
              maxWidth={state?.isOpenNote ? "25.5%" : "20%"}
              paddingRight="24px"
            >
              <Text fontSize="12px" fontWeight="700" color="#2C2C2C">
                アプリケーション名
              </Text>
            </Box>
            <Box
              minWidth={state?.isOpenNote ? "25.5%" : "20%"}
              maxWidth={state?.isOpenNote ? "25.5%" : "20%"}
              paddingRight="24px"
            >
              <Text fontSize="12px" fontWeight="700" color="#2C2C2C">
                カテゴリ
              </Text>
            </Box>
            <Box
              minWidth={state?.isOpenNote ? "16.5%" : "12.5%"}
              maxWidth={state?.isOpenNote ? "16.5%" : "12.5%"}
              paddingRight="24px"
            >
              <Text fontSize="12px" fontWeight="700" color="#2C2C2C">
                キー入力内容の記録
              </Text>
            </Box>
            <Box
              minWidth={state?.isOpenNote ? "16%" : "34.5%"}
              maxWidth={state?.isOpenNote ? "16%" : "34.5%"}
            >
              <Text fontSize="12px" fontWeight="700" color="#2C2C2C">
                スクリーンショットの記録
              </Text>
            </Box>
          </Flex>
          <Flex
            direction="column"
            maxHeight="550px"
            minHeight="550px"
            overflowY="auto"
            border="1px solid #D6D6D6"
            borderRadius="10px"
            marginTop="10px"
            onScroll={handler?.handleScroll}
          >
            {state?.applicationList?.map((value, index) => {
              return (
                <Flex
                  key={index}
                  background={
                    state?.isOpenNote &&
                    state?.id?.toString()?.toLowerCase() ===
                      index?.toString()?.toLowerCase()
                      ? "#EDF6FA"
                      : "unset"
                  }
                  direction="column"
                >
                  <Flex
                    direction="column"
                    css={{
                      "&:hover": {
                        ".custom-delete-cell": {
                          visibility: "unset",
                        },
                      },
                      borderBottom: "1px solid #D6D6D6",
                    }}
                    marginInline="20px"
                    paddingBlock="15px"
                  >
                    <Flex minHeight="30px" align="center">
                      <Box
                        minWidth={state?.isOpenNote ? "16.5%" : "13%"}
                        maxWidth={state?.isOpenNote ? "16.5%" : "13%"}
                        paddingRight="24px"
                        height="100%"
                      >
                        <Text color="#2C2C2C" fontSize="14px" fontWeight="bold">
                          {value?.applicationExecuteName}
                        </Text>
                      </Box>
                      <Box
                        minWidth={state?.isOpenNote ? "25.5%" : "20%"}
                        maxWidth={state?.isOpenNote ? "25.5%" : "20%"}
                        paddingRight="24px"
                        height="100%"
                      >
                        <FormControl
                          isInvalid={
                            !!state?.formState?.errors.applications?.[index]
                              ?.name
                          }
                        >
                          <Input
                            disabled={state?.loading}
                            variant="custom"
                            borderRadius="10px"
                            height="30px"
                            fontSize="12px"
                            maxLength={100}
                            {...handler?.register(`applications.${index}.name`)}
                          />
                          <FormErrorMessage fontSize="14px">
                            {state?.formState?.errors.applications?.[index]
                              ?.name &&
                              state?.formState?.errors.applications?.[index]
                                ?.name?.message}
                          </FormErrorMessage>
                        </FormControl>
                      </Box>
                      <Box
                        minWidth={state?.isOpenNote ? "25.5%" : "20%"}
                        maxWidth={state?.isOpenNote ? "25.5%" : "20%"}
                        paddingRight="24px"
                        height="100%"
                      >
                        <Menu
                          isOpen={
                            state?.isOpenMenu &&
                            state?.currentSelectedMenu === `menu-${index}`
                          }
                          onClose={() => {
                            handler?.setCurrentSelectedMenu(null);
                          }}
                        >
                          <MenuButton
                            as={Button}
                            rightIcon={
                              <Image
                                src={buttonNext}
                                alt="icon-select"
                                width="8px"
                                transform="rotate(90deg)"
                              />
                            }
                            onClick={() => {
                              if (
                                state?.currentSelectedMenu === `menu-${index}`
                              ) {
                                handler?.onCloseMenu();
                                handler?.setCurrentSelectedMenu(null);
                              } else {
                                handler?.onOpenMenu();
                                handler?.setCurrentSelectedMenu(
                                  `menu-${index}`
                                );
                              }
                            }}
                            borderRadius="10px"
                            height="30px"
                            width="100%"
                            textAlign="justify"
                            background="white !important"
                            border="1px solid #B1B1B1"
                            fontSize="12px"
                            borderColor={
                              state?.formState?.errors.applications?.[index]
                                ?.applicationType &&
                              !handler?.watch(
                                `applications.${index}.applicationType`
                              )
                                ? "#E53E3E"
                                : "#B1B1B1"
                            }
                            boxShadow={
                              state?.formState?.errors.applications?.[index]
                                ?.applicationType &&
                              !handler?.watch(
                                `applications.${index}.applicationType`
                              )
                                ? "0 0 0 1px #E53E3E"
                                : "unset"
                            }
                          >
                            {(categoryOptions as any)?.[
                              handler?.watch(
                                `applications.${index}.applicationType`
                              )
                            ]?.name || "カテゴリを選択してください"}
                          </MenuButton>
                          <Box id={`menu-${index}`}>
                            <MenuList
                              borderRadius="10px"
                              boxShadow="md"
                              border="1px solid gray"
                              fontSize="12px"
                            >
                              {Object.values(categoryOptions).map(
                                (optionValue) => (
                                  <MenuItem
                                    key={optionValue?.value}
                                    onClick={() => {
                                      // Close when select to secret
                                      if (
                                        state?.id?.toString()?.toLowerCase() ===
                                          index?.toString()?.toLowerCase() &&
                                        optionValue?.value ===
                                          categoryOptions?.secret?.value
                                      ) {
                                        handler?.closeNote();
                                      }
                                      handler.setValue(
                                        `applications.${index}.applicationType`,
                                        optionValue?.value
                                      );
                                    }}
                                    fontSize="12px"
                                  >
                                    {optionValue?.name}
                                  </MenuItem>
                                )
                              )}
                            </MenuList>
                          </Box>
                        </Menu>
                        {state?.formState?.errors.applications?.[index]
                          ?.applicationType &&
                        !handler?.watch(
                          `applications.${index}.applicationType`
                        ) ? (
                          <Text
                            fontSize="14px"
                            color="#E53E3E"
                            marginTop="0.5rem"
                          >
                            {
                              state?.formState?.errors.applications?.[index]
                                ?.applicationType?.message
                            }
                          </Text>
                        ) : null}
                      </Box>
                      <Box
                        minWidth={state?.isOpenNote ? "16.5%" : "12.5%"}
                        maxWidth={state?.isOpenNote ? "16.5%" : "12.5%"}
                        paddingRight="24px"
                        height="100%"
                      >
                        <Switch
                          colorScheme="green"
                          _checked={{
                            ".chakra-switch__track": {
                              background: "#00BA0F",
                              _hover: {
                                background: "#00BA0F",
                              },
                            },
                            ".chakra-switch__thumb": {
                              left: "calc(100% - 27.6px)",
                              transform: "translateX(0px)",
                            },
                          }}
                          css={{
                            ".chakra-switch__track": {
                              boxShadow: "none",
                              paddingBottom: "2px",
                              height: "28px",
                              width: "48px",
                              padding: "2px",
                            },
                            ".chakra-switch__thumb": {
                              height: "25.6px",
                              width: "25.6px",
                              top: "1.35px",
                              left: "2px",
                              position: "relative",
                            },
                          }}
                          disabled={state?.loading}
                          boxShadow={"none"}
                          {...handler?.register(`applications.${index}.typing`)}
                        />
                      </Box>
                      <Box
                        minWidth={state?.isOpenNote ? "16%" : "34.5%"}
                        maxWidth={state?.isOpenNote ? "16%" : "34.5%"}
                        paddingRight={state?.isOpenNote ? "unset" : "24px"}
                        height="100%"
                        position="relative"
                      >
                        <Switch
                          colorScheme="green"
                          _checked={{
                            ".chakra-switch__track": {
                              background: "#00BA0F",
                              boxShadow: "none",
                              _hover: {
                                background: "#00BA0F",
                              },
                            },
                            ".chakra-switch__thumb": {
                              left: "calc(100% - 27.6px)",
                              transform: "translateX(0px)",
                            },
                          }}
                          css={{
                            ".chakra-switch__track": {
                              boxShadow: "none",
                              height: "28px",
                              width: "48px",
                              padding: "2px",
                            },
                            ".chakra-switch__thumb": {
                              height: "25.6px",
                              width: "25.6px",
                              top: "1.35px",
                              left: "2px",
                              position: "relative",
                            },
                          }}
                          disabled={state?.loading}
                          boxShadow={"none"}
                          {...handler?.register(
                            `applications.${index}.capture`
                          )}
                        />
                        <Box
                          cursor="pointer"
                          visibility="hidden"
                          className="custom-delete-cell"
                          position="absolute"
                          right="19px"
                          top="4px"
                        >
                          <svg
                            width="16"
                            height="18"
                            viewBox="0 0 16 18"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                            onClick={() => handler?.openModalRemove(index)}
                          >
                            <path
                              d="M3 18C2.45 18 1.97917 17.8042 1.5875 17.4125C1.19583 17.0208 1 16.55 1 16V3H0V1H5V0H11V1H16V3H15V16C15 16.55 14.8042 17.0208 14.4125 17.4125C14.0208 17.8042 13.55 18 13 18H3ZM13 3H3V16H13V3ZM5 14H7V5H5V14ZM9 14H11V5H9V14Z"
                              fill="#1D1B20"
                            />
                          </svg>
                        </Box>
                      </Box>
                    </Flex>
                    <Flex
                      marginTop="8px"
                      gap="8px"
                      align="center"
                      display={
                        handler
                          ?.watch(`applications.${index}.applicationType`)
                          ?.toString() !== categoryOptions?.secret?.value &&
                        handler
                          ?.watch(`applications.${index}.applicationType`)
                          ?.toString() &&
                        handler
                          ?.watch(`applications.${index}.applicationType`)
                          ?.toString() !==
                          props?.applicationSelected?.[
                            index
                          ]?.applicationType?.toString() &&
                        state?.listUser?.[index]?.length
                          ? "flex"
                          : "none"
                      }
                    >
                      <svg
                        width="31.77"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M10 15C10.2833 15 10.5208 14.9042 10.7125 14.7125C10.9042 14.5208 11 14.2833 11 14C11 13.7167 10.9042 13.4792 10.7125 13.2875C10.5208 13.0958 10.2833 13 10 13C9.71667 13 9.47917 13.0958 9.2875 13.2875C9.09583 13.4792 9 13.7167 9 14C9 14.2833 9.09583 14.5208 9.2875 14.7125C9.47917 14.9042 9.71667 15 10 15ZM9 11H11V5H9V11ZM10 20C8.61667 20 7.31667 19.7375 6.1 19.2125C4.88333 18.6875 3.825 17.975 2.925 17.075C2.025 16.175 1.3125 15.1167 0.7875 13.9C0.2625 12.6833 0 11.3833 0 10C0 8.61667 0.2625 7.31667 0.7875 6.1C1.3125 4.88333 2.025 3.825 2.925 2.925C3.825 2.025 4.88333 1.3125 6.1 0.7875C7.31667 0.2625 8.61667 0 10 0C11.3833 0 12.6833 0.2625 13.9 0.7875C15.1167 1.3125 16.175 2.025 17.075 2.925C17.975 3.825 18.6875 4.88333 19.2125 6.1C19.7375 7.31667 20 8.61667 20 10C20 11.3833 19.7375 12.6833 19.2125 13.9C18.6875 15.1167 17.975 16.175 17.075 17.075C16.175 17.975 15.1167 18.6875 13.9 19.2125C12.6833 19.7375 11.3833 20 10 20Z"
                          fill="#FFC700"
                        />
                      </svg>
                      <Text fontSize="12px" fontWeight="700" color="#FF4B45">
                        このアプリケーションのカテゴリをシークレットに設定しているメンバーがいます。本当にカテゴリを変更しますか？
                      </Text>
                      <Image
                        onClick={() => handler?.openNote(index)}
                        src={group_person}
                        h="30px"
                        w="30px"
                        cursor="pointer"
                      />
                    </Flex>
                  </Flex>
                </Flex>
              );
            })}
          </Flex>
        </Flex>

        {state?.isOpenNote &&
          state?.id?.toString() &&
          !isNaN(Number(state?.id)) &&
          state?.listUser?.[Number(state.id)]?.length && (
            <Flex
              width="266px"
              height="100%"
              position="absolute"
              right="0"
              top="0"
              direction="column"
              paddingInline="20px"
              paddingTop="15px"
              paddingBottom="25px"
              gap="12px"
              boxShadow="-4px 0px 4px 0px #0000001A"
            >
              <Box
                alignSelf="flex-end"
                onClick={handler?.closeNote}
                cursor="pointer"
              >
                <svg
                  width="14"
                  height="14"
                  viewBox="0 0 14 14"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.4 14L0 12.6L5.6 7L0 1.4L1.4 0L7 5.6L12.6 0L14 1.4L8.4 7L14 12.6L12.6 14L7 8.4L1.4 14Z"
                    fill="#1D1B20"
                  />
                </svg>
              </Box>
              <Box
                color="#2C2C2C"
                fontWeight="700"
                fontSize="14px"
                marginTop="-6px"
              >
                {
                  state?.applicationList?.[Number(state.id)]
                    ?.applicationExecuteName
                }
              </Box>
              <Box fontSize="12px" color="#B1B1B1" fontWeight="700">
                <Text>このアプリケーションのカテゴリを</Text>
                <Text>シークレットに設定しているメンバー</Text>
              </Box>
              <Flex
                direction="column"
                padding="20px"
                gap="20px"
                border="1px solid #D5D5D5"
                flex="1"
                position="relative"
              >
                {state?.listUser?.[Number(state.id)]?.map((value, index) => {
                  return (
                    <Flex
                      key={index}
                      direction="column"
                      color="#2C2C2C"
                      fontWeight="500"
                      fontSize="12px"
                    >
                      <Box>{value}</Box>
                    </Flex>
                  );
                })}
                <Image
                  src={copy}
                  h="24px"
                  w="24px"
                  position="absolute"
                  bottom="8px"
                  right="8px"
                  cursor="pointer"
                  onClick={() => handler?.onCopy()}
                />
              </Flex>
            </Flex>
          )}

        <Center
          flex={1}
          paddingBlock="20px"
          position="absolute"
          bottom="0"
          left="calc(50% - 133px)"
        >
          <ButtonSubmit type="submit" isLoading={state?.loading}>
            確定
          </ButtonSubmit>
        </Center>
      </form>
      {state?.modalName === "REMOVE_APPLICATION" ? (
        <RemoveApplicationModal
          isOpen={state?.modalName === "REMOVE_APPLICATION"}
          onClose={handler?.onCloseModalRemove}
          onSubmit={handler?.onRemove}
        />
      ) : null}
    </CommonModal>
  );
};

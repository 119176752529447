import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import { Box, Flex, Text, Image } from "@chakra-ui/react";
import { DateContext } from "../../lib/globalStates/DateState";

type Props = {
  icon: string;
  title: string;
  description: string;
  navSize: string;
};

// サイドバー内で表示するリンクパーツ
const SidebarItem = ({ icon, title, description, navSize }: Props) => {
  const [, setDateState] = useContext(DateContext);

  const handleClickSidebarItem = () => {
    if (setDateState) {
      const initialDate = new Date(
        new Date().setDate(new Date().getDate() - 1)
      );
      setDateState(initialDate);
    }
  };

  return (
    <Flex
      flexDir="column"
      w="100%"
      alignItems={navSize === "small" ? "center" : "flex-start"}
    >
      <NavLink to={description} onClick={handleClickSidebarItem}>
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          pl={navSize === "large" ? "0.9375rem" : undefined}
        >
          <Image w="3.75rem" h="3.75rem" src={icon} />
          <Text
            style={{
              font: "normal normal bold 1.0625rem/1.5625rem Noto Sans JP",
            }}
            ml="0.3125rem"
            color="white"
            display={navSize === "small" ? "none" : "flex"}
          >
            {title}
          </Text>
        </Box>
      </NavLink>
    </Flex>
  );
};

SidebarItem.displayName = "SidebarItem";
export default SidebarItem;

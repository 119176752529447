import { Button, ButtonProps } from "@chakra-ui/react";
import { FC } from "react";

export const ButtonSubmit: FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Button
      w="266px"
      h="40px"
      background="#00BA0F"
      color="#ffffff"
      borderRadius="20px"
      fontSize="15px"
      _hover={{
        background: "#00BA0F",
        opacity: 0.8,
      }}
      _disabled={{
        background: "#D6D6D6",
        _hover: {
          cursor: "not-allowed",
          background: "#D6D6D6",
        },
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

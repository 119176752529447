import { FC, ReactNode } from "react";
import { useChangeAdminProfile } from "../../../../lib/hooks/ManagementSettings/useChangeAdminProfile";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonModal } from "../../CommonModal";
import { AdminForm } from "../Form/AdminForm";

type ChangeAdminProfileModalProps = {
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const ChangeAdminProfileModal: FC<ChangeAdminProfileModalProps> = (
  props
) => {
  const { isOpen, title, onClose } = props;
  const { admin } = useAppSelector((state) => state.userInfo);
  const { formState, handleSubmit, onSubmit, register, onCloseModal } =
    useChangeAdminProfile({ admin, onClose });

  return (
    <CommonModal
      isOpen={isOpen}
      title={title}
      onClose={onCloseModal}
      minW={970}
      isCentered
    >
      <AdminForm
        userData={admin}
        formState={formState}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
      />
    </CommonModal>
  );
};

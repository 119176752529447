import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { CreateTeam } from "../../../@types/Team";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  createTeamThunk,
  getTeamListThunk,
} from "../../../redux/thunk/teamThunk";
import { EMPTY_STRING } from "../../Const";
import { REQUIRED_FIELD } from "../../util/messages";

type UseCreateTeamProps = {
  onClose: () => void;
};

const schema = z.object({
  teamname: z.string().trim().min(1, REQUIRED_FIELD),
});

type CreateTeamForm = z.infer<typeof schema>;

export const useCreateTeam = (props: UseCreateTeamProps) => {
  const { onClose } = props;
  const dispatch = useAppDispatch();
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<CreateTeamForm>({
    resolver: zodResolver(schema),
  });

  const onCloseModal = () => {
    onClose();
    reset();
  };

  const onSubmit = (values: CreateTeamForm) => {
    if (adcompanyid && aduserid) {
      const payload: CreateTeam = {
        upcrud: "C",
        upcompanyid: adcompanyid,
        upteamname: values.teamname,
        upteamkana: EMPTY_STRING,
      };
      dispatch(
        createTeamThunk({
          payload,
          onSuccess: () => {
            dispatch(
              getTeamListThunk({
                payload: {
                  adcompanyid,
                  aduserid,
                },
              })
            );
            onCloseModal();
          },
        })
      );
    }
  };

  return {
    errors,
    isSubmitting,
    handleSubmit,
    register,
    reset,
    onSubmit,
    onCloseModal,
  };
};

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { AdminData, ChangeUserProfile } from "../../../@types/User";
import { useAppDispatch } from "../../../redux/storeConfig";
import {
  changeAdminProfileThunk,
  getAdminDataThunk,
} from "../../../redux/thunk/userThunk";
import { EMPTY_STRING } from "../../Const";
import { REQUIRED_FIELD } from "../../util/messages";
import { useMessage } from "../useMessage";

type UseChangeAdminProfileType = {
  admin: AdminData | null;
  onClose: () => void;
};

const schema = z.object({
  firstname: z.string().trim().min(1, REQUIRED_FIELD),
  lastname: z.string().trim().min(1, REQUIRED_FIELD),
  firstnamekana: z.string().trim().min(1, REQUIRED_FIELD),
  lastnamekana: z.string().trim().min(1, REQUIRED_FIELD),
  occupation: z.string().trim().optional(),
  title: z.string().trim().optional(),
  contract: z.string().trim().optional(),
  location: z.string().trim().optional(),
  authority: z.string().trim().min(1, REQUIRED_FIELD),
});

export type AdminDataForm = z.infer<typeof schema>;

export const useChangeAdminProfile = (props: UseChangeAdminProfileType) => {
  const { admin, onClose } = props;
  const dispatch = useAppDispatch();
  const { showMessage } = useMessage();
  const { handleSubmit, register, reset, formState } = useForm<AdminDataForm>({
    resolver: zodResolver(schema),
    values: {
      authority: admin?.gtadauthority?.toString() || EMPTY_STRING,
      contract: admin?.gtadcontract || EMPTY_STRING,
      location: admin?.gtadlocation || EMPTY_STRING,
      occupation: admin?.gtadoccupation || EMPTY_STRING,
      title: admin?.gtadtitle || EMPTY_STRING,
      firstname: admin?.gtaduserfirstname || EMPTY_STRING,
      lastname: admin?.gtaduserlastname || EMPTY_STRING,
      firstnamekana: admin?.gtaduserfirstnamekana || EMPTY_STRING,
      lastnamekana: admin?.gtaduserlastnamekana || EMPTY_STRING,
    },
  });

  const onCloseModal = () => {
    onClose();
    reset();
  };

  const onSubmit = async (values: AdminDataForm) => {
    if (admin) {
      const payload: ChangeUserProfile = {
        upadcompanyid: admin?.gtadcompanyid,
        upaduserid: admin?.gtaduserid,
        upadfirstname: values.firstname,
        upadfirstnamekana: values?.firstnamekana,
        upadlastname: values.lastname,
        upadlastnamekana: values?.lastnamekana,
        upadoccupation: values.occupation || EMPTY_STRING,
        upadlocation: values.location || EMPTY_STRING,
        upadtitle: values.title || EMPTY_STRING,
        upadcontract: values.contract || EMPTY_STRING,
        upadauthority: Number(values.authority),
      };
      dispatch(
        changeAdminProfileThunk({
          payload,
          onSuccess: (message) => {
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
            onCloseModal();
            dispatch(
              getAdminDataThunk({
                payload: {
                  adcompanyid: admin?.gtadcompanyid,
                  aduserid: admin?.gtaduserid,
                },
              })
            );
          },
          onError: (message: string) => {
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  };

  return {
    formState,
    handleSubmit,
    register,
    reset,
    onSubmit,
    onCloseModal,
  };
};

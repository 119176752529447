import {
  Center,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { useCreateTeam } from "../../../../lib/hooks/ManagementSettings/useCreateTeam";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonModal } from "../../CommonModal";
import { ButtonSubmit } from "../Button/ButtonSubmit";
import { TextHeading } from "../Typography/TextHeading";

type CreateTeamModalModalProps = {
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const CreateTeamModalModal: FC<CreateTeamModalModalProps> = (props) => {
  const { isOpen, title, onClose } = props;
  const { isLoading } = useAppSelector((state) => state.loading);
  const { errors, handleSubmit, onSubmit, register, onCloseModal } =
    useCreateTeam({
      onClose,
    });

  return (
    <CommonModal
      title={title}
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FormControl isInvalid={!!errors.teamname}>
          <FormLabel>
            <TextHeading>チーム名を設定してください</TextHeading>
          </FormLabel>
          <Input
            variant="custom"
            border="2px solid #D5D5D5"
            borderRadius="10px"
            disabled={isLoading}
            {...register("teamname")}
          />
          <FormErrorMessage fontSize="14px">
            {errors.teamname && errors.teamname.message}
          </FormErrorMessage>
        </FormControl>
        <Center pt="30px" pb="20px">
          <ButtonSubmit type="submit" isLoading={isLoading}>
            確定
          </ButtonSubmit>
        </Center>
      </form>
    </CommonModal>
  );
};

import { Box, Center, Spinner } from "@chakra-ui/react";
import { useAppSelector } from "../../redux/storeConfig";

const Loading = () => {
  const { isLoading } = useAppSelector((state) => state.loading);

  if (!isLoading) return null;
  return (
    <Box
      position="fixed"
      top={0}
      left={0}
      width="100vw"
      height="100vh"
      zIndex={2000}
    >
      <Center
        position="absolute"
        top="0"
        right="0"
        bottom="0"
        left="0"
        backgroundColor="rgba(255, 255, 255,  0.7)"
      >
        <Spinner
          thickness="4px"
          speed="0.65s"
          emptyColor="gray.200"
          color="blue.500"
          size="xl"
        />
      </Center>
    </Box>
  );
};

export default Loading;


import { useState } from 'react';

import { initMouseHoveredTimeZone12HourData } from '../../lib/Const';

type Values = {
  mouseHoveredTimeZone: HoveredTimeZone;
};

type Actions = {
  substituteTimeZoneIndex: (
    activeTimeZone: HoveredTimeZoneItemPosition
  ) => void;
};

type UseMouseHoveredTimeZone = [Values, Actions];

/**
 * @remarks
 * 総作業時間グラフで選択した時間帯の状態管理するためのhooks
 *
 * Shortest name:  {@link useMouseHoveredTimeZone}
 * Full name:      {@link useMouseHoveredTimeZone}
 *
 * @returns
 * [
 *  values: {
 *    mouseHoveredTimeZone: 総作業時間グラフで選択した時間帯
 *  },
 *  actions: {
 *    substituteTimeZoneIndex: {@link substituteTimeZoneIndex}
 *  }
 * ]
 *
 * @public
 */
const useMouseHoveredTimeZone = (): UseMouseHoveredTimeZone => {
  /**
   * mouseHoveredTimeZoneのデータ例
   *
   * @example
   * [
   *  { position: 12 },
   *  { position: '12:00' },
   *  { position: '12:00' },
   *  { position: '12:00' },
   *  { position: '12:00' },
   *  { position: '12:00' },
   * ]
   */
  const [mouseHoveredTimeZone, setMouseHoveredTimeZone] =
    useState<HoveredTimeZone>(initMouseHoveredTimeZone12HourData);

  /**
   * @remarks
   * 総作業時間グラフで選択した時間帯を更新する
   *
   * @param activeTimeZone 更新する時間帯
   */
  const substituteTimeZoneIndex = (
    activeTimeZone: HoveredTimeZoneItemPosition
  ) => {
    if (!activeTimeZone) return;
    // 24時間帯で選択した場合、数値が渡ってくる
    // 24時間帯より以下は文字列で渡ってくる
    let activeTimeZoneString: number | string;

    let newMouseHoveredTimeZone = [...mouseHoveredTimeZone];

    // 数値の場合、文字列の定時にして24時間帯へ代入する
    if (typeof activeTimeZone === 'number') {
      activeTimeZoneString = `${activeTimeZone}:00`;
      newMouseHoveredTimeZone[0].position = activeTimeZone;
    }

    // 文字列の場合、時間部分を数値にして24時間帯へ代入する
    if (typeof activeTimeZone === 'string') {
      activeTimeZoneString = activeTimeZone;
      newMouseHoveredTimeZone[0].position = parseInt(activeTimeZone);
    }

    // 24時間帯を除いた各時間帯に更新する時間帯を代入する
    const result = newMouseHoveredTimeZone.map((timezoneItem, index) => {
      if (index === 0) return timezoneItem;
      timezoneItem.position = activeTimeZoneString;
      return timezoneItem;
    });
    setMouseHoveredTimeZone(result);
  };

  const value = {
    mouseHoveredTimeZone,
  };

  const actions = {
    substituteTimeZoneIndex,
  };

  return [value, actions];
};

export default useMouseHoveredTimeZone;

import { Text, TextProps } from "@chakra-ui/react";
import { FC } from "react";

export const TextSecond: FC<TextProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Text
      color="#919191"
      fontSize="14px"
      lineHeight="21px"
      fontWeight="medium"
      {...otherProps}
    >
      {children}
    </Text>
  );
};

import { Box, Flex, Heading } from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { useTeamInfo } from "../../../../lib/hooks/ManagementSettings/useTeamInfo";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonModal } from "../../CommonModal";
import { ButtonPrimary } from "../Button/ButtonPrimary";
import { ButtonSecond } from "../Button/ButtonSecond";
import { TableTeamInfo } from "../Table/TableTeamInfo";
import { AddMemberModal } from "./AddMemberModal";
import { UpdateTeamModal } from "./UpdateTeamModal";

type TeamInfoModalProps = {
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
  onOk?: () => void;
};

export const TeamInfoModal: FC<TeamInfoModalProps> = (props) => {
  const { isOpen, title, onClose, onOk } = props;
  const { teamUserData } = useAppSelector((state) => state.team);
  const { modalName, openModal, closeModal } = useTeamInfo();

  return (
    <CommonModal
      title={title}
      isOpen={isOpen}
      onClose={onClose}
      isCentered
      minW={798}
      h={599}
      cancelText="戻る"
    >
      <Flex justify="space-between" mb="20px">
        <Heading fontSize="21px" fontWeight={800}>
          {teamUserData?.gtteamname}
        </Heading>
        <Flex gap="20px">
          <ButtonPrimary onClick={() => openModal("ADD_MEMBER")}>
            メンバーを追加
          </ButtonPrimary>
          <ButtonSecond onClick={() => openModal("UPDATE_TEAM")}>
            チーム名を変更
          </ButtonSecond>
        </Flex>
      </Flex>
      <Box
        h="calc(100% - 50px)"
        flex={1}
        overflow="auto"
        sx={{
          "::-webkit-scrollbar": {
            display: "none",
          },
        }}
      >
        <TableTeamInfo onOk={onOk} />
      </Box>

      {/* Modal */}
      <AddMemberModal
        type="TEAM_INFO"
        title="メンバーを追加"
        isOpen={modalName === "ADD_MEMBER"}
        onOk={onOk}
        onClose={closeModal}
      />

      <UpdateTeamModal
        title="チーム名の変更"
        isOpen={modalName === "UPDATE_TEAM"}
        onOk={onOk}
        onClose={closeModal}
      />
    </CommonModal>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { UpdateMemberProfile } from "../../../@types/Member";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  getMemberListThunk,
  updateMemberThunk,
} from "../../../redux/thunk/memberThunk";
import { EMPTY_STRING } from "../../Const";
import { generatePassword } from "../../util/helper";
import { INVALID_EMAIL, REQUIRED_FIELD } from "../../util/messages";
import { useMessage } from "../useMessage";

type UseInviteMemberProps = {
  onClose: () => void;
};

const schema = z.object({
  firstname: z.string().trim().min(1, REQUIRED_FIELD),
  lastname: z.string().trim().min(1, REQUIRED_FIELD),
  firstnamekana: z.string().trim().min(1, REQUIRED_FIELD),
  lastnamekana: z.string().trim().min(1, REQUIRED_FIELD),
  occupation: z.string().trim(),
  title: z.string().trim(),
  contract: z.string().trim(),
  location: z.string().trim(),
  authority: z.string().trim().min(1, REQUIRED_FIELD),
  email: z.string().trim().min(1, REQUIRED_FIELD).email(INVALID_EMAIL),
});

export type MemberFormType = z.infer<typeof schema>;

export const useInviteMember = (props: UseInviteMemberProps) => {
  const { onClose } = props;
  const { showMessage } = useMessage();
  const dispatch = useAppDispatch();
  const { adcompanyid } = useAppSelector((state) => state.userInfo);
  const { handleSubmit, register, reset, formState } = useForm<MemberFormType>({
    resolver: zodResolver(schema),
  });

  const closeModal = () => {
    onClose();
    reset();
  };

  const onSubmit = (values: MemberFormType) => {
    if (adcompanyid) {
      const payload: UpdateMemberProfile = {
        upcrud: "C",
        upcompanyid: adcompanyid,
        upuserid: values.email,
        upuserpic: values.email,
        upuserpassword: generatePassword(),
        upuserfirstname: values.firstname,
        upuserfirstnamekana: values.firstnamekana,
        upuserlastname: values.lastname,
        upuserlastnamekana: values.lastnamekana,
        upoccupation: values.occupation,
        uplocation: values.location,
        uptitle: values.title,
        upcontract: values.contract,
        upauthority: Number(values.authority),
      };
      dispatch(
        updateMemberThunk({
          payload,
          onSuccess: (message) => {
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
            closeModal();
            dispatch(
              getMemberListThunk({
                payload: {
                  urcompanyid: adcompanyid,
                },
              })
            );
          },
          onError: (message) => {
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  };

  return {
    formState,
    closeModal,
    handleSubmit,
    register,
    reset,
    onSubmit,
  };
};

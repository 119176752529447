
import { useState } from 'react';
import { useDisclosure } from '@chakra-ui/react';

type GenerateDetailAppInfoTime = number | undefined;

type Values = {
  generateDetailAppInfoTime: GenerateDetailAppInfoTime;
  isAppDetailModalOpen: boolean;
};

type Actions = {
  setGenerateDetailAppInfoTime: SetStateActionDispatcher<GenerateDetailAppInfoTime>;
  onAppDetailModalClose: () => void;
  openUsedAppDetailInfoModalControll: (appInfoDataid: number) => void;
};

type UseAppDetailModal = [Values, Actions];

/**
 * @remarks
 * 使用したアプリケーションの詳細画面を制御するためのhooks
 *
 * Shortest name:  {@link useAppDetailModal}
 * Full name:      {@link useAppDetailModal}
 *
 * @returns
 * [
 *  values: {
 *    generateDetailAppInfoTime: 使用したアプリケーションの詳細画面に表示するデータのindex,
 *    isAppDetailModalOpen: 使用したアプリケーションの詳細画面が開いているかどうかのフラグ
 *  },
 *  actions: {
 *    setGenerateDetailAppInfoTime: 使用したアプリケーションの詳細画面に表示するデータのindexを更新する,
 *    onAppDetailModalClose: 使用したアプリケーションの詳細画面を閉じる
 *    openUsedAppDetailInfoModalControll: {@link openUsedAppDetailInfoModalControll}
 *  }
 * ]
 *
 * @public
 */
const useAppDetailModal = (): UseAppDetailModal => {
  const [generateDetailAppInfoTime, setGenerateDetailAppInfoTime] =
    useState<GenerateDetailAppInfoTime>(undefined);
  const {
    isOpen: isAppDetailModalOpen,
    onOpen: onAppDetailModalOpen,
    onClose: onAppDetailModalClose,
  } = useDisclosure();

  /**
   * @remarks
   * 使用したアプリケーションの詳細画面を開く
   *
   * @param appInfoDataid 使用したアプリケーションの詳細画面に表示するデータのindex
   */
  const openUsedAppDetailInfoModalControll = (appInfoDataid: number) => {
    setGenerateDetailAppInfoTime(appInfoDataid);
    onAppDetailModalOpen();
    return;
  };

  const values: Values = {
    generateDetailAppInfoTime,
    isAppDetailModalOpen,
  };
  const actions: Actions = {
    setGenerateDetailAppInfoTime,
    onAppDetailModalClose,
    openUsedAppDetailInfoModalControll,
  };

  return [values, actions];
};

export default useAppDetailModal;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useMemo, useEffect } from "react";
import { useSelector } from "react-redux";

import { Box, Flex, Progress } from "@chakra-ui/react";
import { ComposedChart, ResponsiveContainer, XAxis, YAxis } from "recharts";

// import hook
import useTimeZoneIndex from "../../../lib/hooks/useTimeZoneIndex";
import useMouseHoveredTimeZone from "../../../lib/hooks/useMouseHoveredTimeZone";
import useAppInfo from "../../../lib/hooks/useAppInfo";
import useAppDetailModal from "../../../lib/hooks/useAppDetailModal";
import useScheduleDatas from "../../../lib/hooks/useScheduleDatas";
import useGridViewScheduleDatas from "../../../lib/hooks/useGridViewScheduleDatas";
import useGridStartTimeZoneData from "../../../lib/hooks/useGridStartTimeZoneData";
import useScheduleModal from "../../../lib/hooks/useScheduleModal";
import useScreenShotsOneArrayData from "../../../lib/hooks/useScreenShotsOneArrayData";
import useScreenshotHourArrayDatas from "../../../lib/hooks/useScreenshotHourArrayDatas";
import useMouseKeyboardDatas from "../../../lib/hooks/useMouseKeyboardDatas";

// import library
import {
  generateScreenShotsData,
  generateScreenShotsOneLine,
} from "../../../lib/screenshotsData";
import { generateAppInfoData } from "../../../lib/appInfoData";

// import component
import UsedAppGridView from "./../UsedAppGridView";
import ScheduleGridView from "../ScheduleGridView";

type MostUsageAppTimelineProps = {
  member: TeamMember;
};

type StateType = {
  team: {
    selectedTeam: TeamInfo;
    discoveryData: TeamInfoArray;
    scheduleData: TeamScheduleData[];
    appInfoData: TeamAppInfo[];
    screenShotData: TeamScreenShotData[];
    mouseKeyboardData: TeamMountKeyboardReport[];
  };
};

interface RootState {
  team: {
    appInfoData: TeamAppInfo[];
    scheduleData: TeamScheduleData[];
  };
}

const MostUsageAppTimeline = React.memo(
  ({ member }: MostUsageAppTimelineProps) => {
    const team = useSelector((state: StateType) => state.team);

    const appDataLoading = useSelector(
      (state: RootState) => state.team.appInfoData
    );

    const scheduleDataLoading = useSelector(
      (state: RootState) => state.team.scheduleData
    );

    /** @see {@link useMouseHoveredTimeZone} */
    const [{ mouseHoveredTimeZone }] = useMouseHoveredTimeZone();

    /** @see {@link useTimeZoneIndex} */
    const [{ timeZoneIndex, getActiveTimeZoneIndex, xAxisInterval }] =
      useTimeZoneIndex();

    /** @see {@link useGridViewScheduleDatas} */
    const [{ gridViewScheduleDatas }, { setGridViewScheduleDatas }] =
      useGridViewScheduleDatas();

    /** @see {@link useGridStartTimeZoneData} */
    const [{ gridStartTimeZoneData }, { setGridStartTimeZoneData }] =
      useGridStartTimeZoneData();

    /** @see {@link useAppInfo} */
    const [{ appInfo }, { setAppInfo }] = useAppInfo();

    /** @see {@link useAppDetailModal} */
    const [
      { generateDetailAppInfoTime, isAppDetailModalOpen },
      {
        setGenerateDetailAppInfoTime,
        onAppDetailModalClose,
        openUsedAppDetailInfoModalControll,
      },
    ] = useAppDetailModal();

    /** @see {@link useScheduleDatas} */
    const [{ scheduleDatas }, { setScheduleDatas }] = useScheduleDatas();
    const [{ screenshotHourArrayDatas }, { setScreenshotHourArrayDatas }] =
      useScreenshotHourArrayDatas();

    /** @see {@link useScheduleModal} */
    const [
      { scheduleDataGridNum, isScheduleModalOpen },
      {
        setScheduleDataGridNum,
        onScheduleModalClose,
        openScheduleModalControll,
      },
    ] = useScheduleModal();

    /** @see {@link useScreenShotsOneArrayData} */
    const [
      { screenShotsOneArrayData, screenShotsData },
      { setScreenShotsOneArrayData, setScreenshotsData },
    ] = useScreenShotsOneArrayData();

    /** @see {@link useMouseKeyboardDatas} */
    const [{ mouseKeyboardDatas }, { setMouseKeyboardDatas }] =
      useMouseKeyboardDatas();

    useEffect(() => {
      const teamAppInfoData = team?.appInfoData?.filter(
        (teamAppInfo: TeamAppInfo) => {
          return teamAppInfo.teamid === team?.selectedTeam?.teamid;
        }
      );

      const teamScheduleData = team?.scheduleData?.filter(
        (teamScheduleData: TeamScheduleData) => {
          return teamScheduleData.teamid === team?.selectedTeam?.teamid;
        }
      );

      const teamScreenShotData = team?.screenShotData?.filter(
        (teamScreenShotData: TeamScreenShotData) => {
          return teamScreenShotData.teamid === team?.selectedTeam?.teamid;
        }
      );

      const teamMouseKeyboardData = team?.mouseKeyboardData?.filter(
        (teamMouseKeyboardData: TeamMountKeyboardReport) => {
          return teamMouseKeyboardData.teamid === team?.selectedTeam?.teamid;
        }
      );

      if (teamAppInfoData?.length > 0) {
        let memberInfoData: AppInfoAt24Hours | [] = [];
        teamAppInfoData[0]?.member?.forEach((memberInfo: MemberAppInfo) => {
          if (memberInfo.memberid === member.memberid) {
            memberInfoData = memberInfo.appdata;
          }
        });
        setAppInfo(memberInfoData);
      }


      if (teamScheduleData?.length > 0) {
        let memberScheduleData: ScheduleDatas = [];
        teamScheduleData[0]?.member?.forEach(
          (memberInfo: MemberScheduleData) => {
            if (memberInfo.memberid === member.memberid) {
              memberScheduleData = memberInfo.scheduledata;
            }
          }
        );
        setScheduleDatas(memberScheduleData);
      }

      if (teamScreenShotData?.length > 0) {
        let memberScreenShotData: ScreenShotsDataAtOneHour[] = [];
        teamScreenShotData[0]?.member?.forEach(
          (memberInfo: MemberScreenShotData) => {
            if (memberInfo.memberid === member.memberid) {
              memberScreenShotData = memberInfo.screenshotdata;
            }
          }
        );
        const screenShotsOneline: ScreenShotsAtOneMinute[] =
          generateScreenShotsOneLine(memberScreenShotData);
        setScreenshotsData(memberScreenShotData);
        setScreenShotsOneArrayData(screenShotsOneline);
      }

      if (teamMouseKeyboardData?.length > 0) {
        let memberMouseKeyboardData: MountKeyboardReport1Hour[] = [];
        teamMouseKeyboardData[0]?.teamdata?.member?.forEach(
          (memberInfo: MemberMountKeyboardReport) => {
            if (memberInfo.memberid === member.memberid) {
              memberMouseKeyboardData = memberInfo.amountdata;
            }
          }
        );
        setMouseKeyboardDatas(memberMouseKeyboardData);
      }
    }, [member, team]);

    useEffect(() => {
      // 画面に表示する正確な時間帯のindex
      const activeTimeZoneIndex = getActiveTimeZoneIndex;
      const newScreenShotsOneArrayData = screenShotsOneArrayData.map(
        (object) => ({
          ...object,
        })
      );
      // 表示する時間帯によるスクリーンショットのデータを生成する
      const screenShotsDatas = generateScreenShotsData({
        timeZoneIndex,
        screenShotsOneArrayData: newScreenShotsOneArrayData,
        screenShotsData,
        activeTimeZone: mouseHoveredTimeZone[activeTimeZoneIndex].position,
      });
      setScreenshotHourArrayDatas(screenShotsDatas!);
    }, [
      getActiveTimeZoneIndex,
      mouseHoveredTimeZone,
      screenShotsData,
      screenShotsOneArrayData,
      timeZoneIndex,
    ]);

    // 総作業時間グラフを表現するためのデータを生成する
    const graphData = useMemo(() => {
      /**
       * 時間帯の詳細度が低いグラフで選択した時間帯を参照する
       * 24時間帯はインデックスが0
       */
      const activeTimeZoneIndex = getActiveTimeZoneIndex;

      const newAppInfoData = appInfo?.filter((object: AppInfoAtOneHour) => {
        return object.time_range;
      });

      const appInfoData = generateAppInfoData({
        timeZoneIndex,
        appInfoData: newAppInfoData,
        activeTimeZone: mouseHoveredTimeZone[activeTimeZoneIndex].position,
      });
      let displayData;
      if (
        timeZoneIndex === 0 &&
        appInfo.length > 0 &&
        appInfoData !== undefined
      ) {
        displayData = [...appInfoData, { id: 29 }];
      }
      return displayData;
    }, [getActiveTimeZoneIndex, appInfo, timeZoneIndex, mouseHoveredTimeZone]);

    //   JSX.Elementのパーツ生成
    //   特定の時間帯でもっとも長く使用したアプリケーションをGrid状で表示するためのパーツ
    const generateGridData = useMemo(() => {
      const props = {
        timeZoneIndex,
        mouseHoveredTimeZone,
        appInfo,
        screenshotHourArrayDatas,
        screenShotsOneArrayData,
        mouseKeyboardDatas,
        member,
        openUsedAppDetailInfoModalControll,
      };
      return <UsedAppGridView {...props} />;
    }, [
      timeZoneIndex,
      mouseHoveredTimeZone,
      appInfo,
      screenshotHourArrayDatas,
      screenShotsOneArrayData,
      mouseKeyboardDatas,
      member,
    ]);

    // スケジュールをGrid状で表示するためのパーツ
    const generateScheduleGridData = useMemo(() => {
      const props = {
        timeZoneIndex,
        mouseHoveredTimeZone,
        scheduleDatas,
        setGridViewScheduleDatas,
        setGridStartTimeZoneData,
        openScheduleModalControll,
      };
      return <ScheduleGridView {...props} />;
    }, [timeZoneIndex, mouseHoveredTimeZone, scheduleDatas]);

    const CustomXAxisTick = (props: any) => {
      const { x, y, payload, graphData } = props;
      const id = payload.value;
      const currentIndex = graphData.findIndex((item: any) => item.id === id);
      const currentDataItem = graphData[currentIndex];

      const previousIndex = currentIndex - 1;
      const previousDataItem =
        previousIndex >= 0 ? graphData[previousIndex] : [];

      let opacity = "30%";

      if (
        (currentDataItem && currentDataItem?.appSum?.length > 0) ||
        (previousDataItem && previousDataItem?.appSum?.length > 0)
      ) {
        opacity = "100%";
      }

      if (id === 19) {
        return (
          <g>
            <g>
              <circle cx={x} cy={y - 9} r={11} fill="#fff" />
              <text
                x={x}
                y={y - 5}
                textAnchor="middle"
                fill="#2c2c2c"
                color="#2c2c2c"
                fontFamily="Roboto"
                opacity={`${opacity}`}
                fontSize="0.75rem"
              >
                {id}
              </text>
            </g>
          </g>
        );
      }
      return (
        <g>
          <g>
            <text
              x={x}
              y={y - 5}
              textAnchor="middle"
              fill="#2c2c2c"
              color="#2c2c2c"
              fontFamily="Roboto"
              opacity={`${opacity}`}
              fontSize="0.75rem"
            >
              {id <= 23 ? id : id - 24}
            </text>
          </g>
        </g>
      );
    };
    return (
      <Box
        width="100%"
        px="1.25rem"
        my="0.6875rem"
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        borderRadius="10px"
      >
        {appDataLoading.length === 0 && scheduleDataLoading.length !== 0 ? (
          <Flex height="7.375rem" width="70%" alignItems="center">
            <Box width="100%">
              <Progress size="xs" isIndeterminate />
            </Box>
          </Flex>
        ) : (
          <>
            <ResponsiveContainer width="95%" height={30}>
              <ComposedChart
                data={graphData}
                margin={{
                  top: 0,
                  right: 0,
                  left: 0,
                  bottom: 0,
                }}
              >
                <XAxis
                  dataKey="id"
                  interval={xAxisInterval}
                  orientation="top"
                  padding={{ right: 10, left: 10 }}
                  tickLine={false}
                  tick={<CustomXAxisTick graphData={graphData} />}
                />
                <YAxis hide={true} />
              </ComposedChart>
            </ResponsiveContainer>

            {generateGridData}
            {generateScheduleGridData}
          </>
        )}
      </Box>
    );
  }
);

MostUsageAppTimeline.displayName = "MostUsageAppTimeline";
export default MostUsageAppTimeline;

import {
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Input,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { useChangeEmail } from "../../../../lib/hooks/ManagementSettings/useChangeEmail";
import { CommonModal } from "../../CommonModal";
import { ButtonSubmit } from "../Button/ButtonSubmit";
import { TextHeading } from "../Typography/TextHeading";
import { TextSecond } from "../Typography/TextSecond";

type ChangeEmailAddressModalProps = {
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const ChangeEmailAddressModal: FC<ChangeEmailAddressModalProps> = (
  props
) => {
  const { isOpen, title, onClose } = props;
  const { isSubmitting, errors, handleSubmit, onSubmit, register, reset } =
    useChangeEmail();

  const closeModal = () => {
    onClose();
    reset();
  };

  return (
    <CommonModal title={title} isOpen={isOpen} onClose={closeModal} isCentered>
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <FormControl isInvalid={!!errors.mailaddress}>
          <FormLabel mb="4px">
            <TextHeading>メールアドレス</TextHeading>
          </FormLabel>
          <Input
            variant="custom"
            type="email"
            border="2px solid #D5D5D5"
            borderRadius="10px"
            disabled={isSubmitting}
            {...register("mailaddress")}
          />
          <FormErrorMessage fontSize="14px">
            {errors.mailaddress && errors.mailaddress.message}
          </FormErrorMessage>
          <FormHelperText mt="10px">
            <TextSecond>
              メールアドレスを変更すると、元のメールアドレスと新しいメールアドレスへ通知が送られます。
            </TextSecond>
          </FormHelperText>
        </FormControl>
        <Center pt="30px" pb="20px">
          <ButtonSubmit type="submit" isLoading={isSubmitting}>
            確定
          </ButtonSubmit>
        </Center>
      </form>
    </CommonModal>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { z, ZodType } from "zod";
import { INVALID_EMAIL } from "../../util/messages";

type ChangeMailAddressForm = {
  mailaddress: string;
};

// TODO
const schema: ZodType<ChangeMailAddressForm> = z.object({
  mailaddress: z.string().email(INVALID_EMAIL),
});

export const useChangeEmail = () => {
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
  } = useForm<ChangeMailAddressForm>({
    resolver: zodResolver(schema),
  });

  const onSubmit = (values: ChangeMailAddressForm) => {
    // TODO: handle when API is available
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve(values);
      }, 1000);
    });
  };

  return {
    errors,
    isSubmitting,
    handleSubmit,
    register,
    reset,
    onSubmit,
  };
};

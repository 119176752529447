import { Box, Flex } from "@chakra-ui/react";
import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import TeamInfo from "../parts/Discovery/TeamInfo";
import TeamMenu from "../parts/Discovery/TeamMenu";

import PageTitleWithCalendar from "../parts/PageTitleWithCalendar";

// // グローバル状態管理のConxetをimport
import { DateContext } from "../../lib/globalStates/DateState";

import { Action, Dispatch } from "@reduxjs/toolkit";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { TUserState } from "../../@types/User";
import {
  resetState,
  setAppInfoData,
  setDiscoveryData,
  setScheduleData,
} from "../../redux/slice/teamSlice";
import MemberInfo from "../parts/Discovery/MemberInfo";
import { EMPTY_STRING } from "lib/Const";

type ActionCreate<TP> = (p: TP) => { type: string; payload: TP };
type CountRecallStateType = [
  countRecall: number,
  setCountRecall: null | React.Dispatch<React.SetStateAction<number>>
];
export const CountRecallContext = createContext<CountRecallStateType>([
  0,
  null,
]);

const Discovery = React.memo(() => {
  // // グローバルで状態管理する日付情報を扱うためのhooks
  const [dateState, setDateState] = useContext(DateContext);
  const numYear = dateState?.getFullYear();
  const numMonth = dateState?.getMonth() + 1;
  const numDate = dateState?.getDate();
  const [isErrorAPI, setIsErrorAPI] = useState(false);
  const [countRecall, setCountRecall] = useState(0);
  const dateSelected = `${numYear}/${numMonth
    .toString()
    .padStart(2, "0")}/${numDate?.toString().padStart(2, "0")}`;

  // 画面のタイトルと説明、DatePickerを表示するためのヘッダーパーツ
  const pageHead = useMemo(() => {
    const props = {
      title: "ディスカバリー",
      description: "",
      date: dateState,
      setDateState,
    };
    return <PageTitleWithCalendar {...props} />;
  }, [dateState, setDateState]);

  const dispatch = useDispatch();

  const { aduserid, adcompanyid } = useSelector(
    (state: TUserState) => state.userInfo
  );

  var errFlag = 0;
  var loopFlag = 0;

  useEffect(() => {
    const userId =
      JSON.parse(localStorage.getItem("redux") ?? "{}")?.userInfo?.aduserid ??
      EMPTY_STRING;
    const FormData = require("form-data");
    let formData = new FormData();
    formData.append("adcompanyid", adcompanyid);
    formData.append("aduserid", aduserid);
    formData.append("addate", dateSelected);
    formData.append("useridauth", userId);
    // Define the API endpoints and corresponding action dispatch functions
    const apiEndpoints = [
      { endpoint: "getAdminDiscoveryData", action: setDiscoveryData },
      { endpoint: "getAdminScheduleData", action: setScheduleData },
      { endpoint: "getAdminAppInfodata", action: setAppInfoData },
    ];
    const fetchDataAndDispatch = async (
      endpoint: string,
      action: ActionCreate<string>,
      dispatch: Dispatch<Action>
    ) => {
      try {
        const response = await axios({
          method: "post",
          url: process.env.REACT_APP_API_URL + endpoint,
          data: formData,
          headers: {
            "Content-Type": `multipart/form-data; boundary=${formData._boundary}`,
          },
        });
        if (response.data) {
          dispatch(action(response.data));
        }
      } catch (error) {
        errFlag++;
        dispatch(resetState());
      } finally {
        loopFlag++;
        if (loopFlag === apiEndpoints.length - 1 && errFlag > 0) {
          setIsErrorAPI(true);
        }
      }
    };
    dispatch(setAppInfoData([]));
    setIsErrorAPI(false);
    apiEndpoints.forEach(({ endpoint, action }) => {
      fetchDataAndDispatch(endpoint, action, dispatch);
    });
  }, [
    dateState,
    dispatch,
    errFlag,
    loopFlag,
    adcompanyid,
    aduserid,
    dateSelected,
    countRecall,
  ]);

  return (
    <CountRecallContext.Provider value={[countRecall, setCountRecall]}>
      <Box px="5rem">
        {pageHead}
        <Flex justifyContent="space-between">
          <Flex>
            <TeamMenu />
          </Flex>
          <Flex flexDir="column" flex={1}>
            <TeamInfo isErrorAPI={isErrorAPI} />
            <MemberInfo />
          </Flex>
        </Flex>
      </Box>
    </CountRecallContext.Provider>
  );
});

Discovery.displayName = "Discovery";
export default Discovery;

import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseRequest } from "../../@types/Request";
import { BaseResponse } from "../../@types/Response";
import {
  AddMemberIntoTeam,
  CreateTeam,
  DeleteTeam,
  GetTeamList,
  GetTeamUserPayload,
  TeamFrame,
  UpdateTeam,
} from "../../@types/Team";
import { EMPTY_STRING } from "../../lib/Const";
import { teamServices } from "../../services/teamService";

export const getTeamListThunk = createAsyncThunk(
  "team/getTeamList",
  async ({ payload }: BaseRequest<GetTeamList>) => {
    try {
      const response = await teamServices.getTeamList(payload);
      return response.data?.[0]?.gtteamframe;
    } catch (error) {
      return [] as TeamFrame[];
    }
  }
);

export const createTeamThunk = createAsyncThunk(
  "team/createTeam",
  async ({ payload, onSuccess, onError }: BaseRequest<CreateTeam>) => {
    try {
      const response = await teamServices.createTeam(payload);
      const { message, status } = response.data?.[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data;
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

export const getTeamUserDataThunk = createAsyncThunk(
  "team/getTeamUserData",
  async ({ payload, onSuccess }: BaseRequest<GetTeamUserPayload>) => {
    try {
      const response = await teamServices.getTeamUserData(payload);
      onSuccess?.(EMPTY_STRING, response.data?.[0]);
      return response.data?.[0];
    } catch (error) {
      return null;
    }
  }
);

export const addMemberIntoTeamThunk = createAsyncThunk(
  "team/addMemberIntoTeam",
  async ({ payload, onSuccess, onError }: BaseRequest<AddMemberIntoTeam>) => {
    try {
      const response = await teamServices.addMemberIntoTeam(payload);
      const { message, status } = response.data?.[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data;
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

export const updateTeamThunk = createAsyncThunk(
  "team/updateTeam",
  async ({ payload, onSuccess, onError }: BaseRequest<UpdateTeam>) => {
    try {
      const response = await teamServices.updateTeam(payload);
      const { message, status } = response.data?.[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data;
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

export const deleteTeamThunk = createAsyncThunk(
  "team/deleteTeam",
  async ({ payload, onSuccess, onError }: BaseRequest<DeleteTeam>) => {
    try {
      const response = await teamServices.deleteTeam(payload);
      const { message, status } = response.data?.[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data;
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

import { Button, ButtonProps } from "@chakra-ui/react";
import { FC } from "react";

export const ButtonSecond: FC<ButtonProps> = (props) => {
  const { children, ...otherProps } = props;
  return (
    <Button
      background="#2C2C2C"
      color="#ffffff"
      borderRadius="20px"
      fontSize="11px"
      height="30px"
      px="20px"
      _hover={{
        background: "#2C2C2C",
        opacity: 0.8,
      }}
      _disabled={{
        background: "#D6D6D6",
        _hover: {
          cursor: "not-allowed",
          background: "#D6D6D6",
        },
      }}
      {...otherProps}
    >
      {children}
    </Button>
  );
};

import { zodResolver } from "@hookform/resolvers/zod";
import {
  ChangeEvent,
  useCallback,
  useLayoutEffect,
  useMemo,
  useState,
} from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import {
  MemberFrame,
  TUpdateAdminTeamBelongDataPayload,
} from "../../../@types/Member";
import { TeamFrame } from "../../../@types/Team";
import { setTeamBelongMember } from "../../../redux/slice/memberSlice";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  getMemberListThunk,
  updateAdminTeamBelongMemberThunk,
} from "../../../redux/thunk/memberThunk";
import { EMPTY_STRING } from "../../Const";
import { useMessage } from "../useMessage";

type UseChangeTeamProps = {
  member: MemberFrame | null;
  onClose: () => void;
};

const schema = z.object({
  tb_teamid: z.string().array(),
});

type ChangeTeamForm = z.infer<typeof schema>;

export const useChangeTeam = (props: UseChangeTeamProps) => {
  const { member, onClose } = props;
  const dispatch = useAppDispatch();
  const { showMessage } = useMessage();
  const { adcompanyid } = useAppSelector((state) => state.userInfo);
  const { teamBelongMember } = useAppSelector((state) => state.member);
  const { teamList } = useAppSelector((state) => state.team);
  const [searchValue, setSearchValue] = useState<string>("");
  const [data, setData] = useState<TeamFrame[]>([]);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    control,
    watch,
  } = useForm<ChangeTeamForm>({
    resolver: zodResolver(schema),
    values: {
      tb_teamid: teamBelongMember.reduce((result, curr) => {
        if (curr.gtteamflag) {
          return [...result, curr.gtteamid];
        }
        return result;
      }, [] as string[]),
    },
  });

  const teamIds = watch("tb_teamid");

  const isEmpty = useMemo(() => {
    return !teamIds.length;
  }, [teamIds]);

  const onCloseModal = () => {
    onClose();
    setSearchValue("");
    reset();
    dispatch(setTeamBelongMember([]));
  };

  const onChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchValue(event.target.value);
  };

  const onSubmit = (values: ChangeTeamForm) => {
    if (member && adcompanyid) {
      const payload: TUpdateAdminTeamBelongDataPayload = {
        upcompanyid: adcompanyid,
        upuserid: member.gtuserid,
        upteamid: teamList.map((team) => team.gtteamid),
        upteamcheck: teamList.map((team) =>
          values.tb_teamid.includes(team.gtteamid) ? 1 : 0
        ),
      };
      dispatch(
        updateAdminTeamBelongMemberThunk({
          payload,
          onSuccess: (message) => {
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
            onCloseModal();
            dispatch(
              getMemberListThunk({
                payload: {
                  urcompanyid: adcompanyid,
                },
              })
            );
          },
          onError: (message) => {
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  };

  const onSearchTeam = useCallback(() => {
    if (searchValue) {
      const filteredData =
        teamList.filter((item) =>
          item.gtteamname
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        ) || [];
      setData(filteredData);
    } else {
      setData(teamList);
    }
  }, [searchValue, teamList]);

  useLayoutEffect(() => {
    onSearchTeam();
  }, [onSearchTeam]);

  return {
    control,
    errors,
    isSubmitting,
    searchValue,
    data,
    isEmpty,
    onCloseModal,
    handleSubmit,
    register,
    reset,
    onSubmit,
    onChangeSearchValue,
    onSearchTeam,
    setSearchValue,
  };
};

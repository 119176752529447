import { createAsyncThunk } from "@reduxjs/toolkit";
import { BaseRequest } from "../../@types/Request";
import {
  AdminDataPayload,
  AuthorityFrame,
  ChangeUserPIC,
  ChangeUserPassword,
  ChangeUserProfile,
  GetAdminAuthorityList,
  TAdminLoginTopData,
  TAdminLoginTopPayload,
} from "../../@types/User";
import { EMPTY_STRING } from "../../lib/Const";
import { userServices } from "../../services/userService";
import { BaseResponse } from "./../../@types/Response";

export const getAdminDataThunk = createAsyncThunk(
  "userInfo/getAdminData",
  async ({ payload }: BaseRequest<AdminDataPayload>) => {
    try {
      const response = await userServices.getAdminProfile(payload);
      return response.data?.[0];
    } catch (error) {
      return null;
    }
  }
);

export const changeUserPictureThunk = createAsyncThunk(
  "userInfo/changeUserPicture",
  async ({ payload, onSuccess }: BaseRequest<ChangeUserPIC>) => {
    try {
      const response = await userServices.changeUserPicture(payload);
      onSuccess?.();
      return response.data;
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

export const changeUserPasswordThunk = createAsyncThunk(
  "userInfo/changeUserPassword",
  async ({ payload, onSuccess, onError }: BaseRequest<ChangeUserPassword>) => {
    try {
      const response = await userServices.changeUserPassword(payload);
      const { message, status } = response.data[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data;
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

export const changeAdminProfileThunk = createAsyncThunk(
  "userInfo/changeAdminProfile",
  async ({ payload, onSuccess, onError }: BaseRequest<ChangeUserProfile>) => {
    try {
      const response = await userServices.changeAdminProfile(payload);
      const { message, status } = response.data[0];
      if (status === "OK") {
        onSuccess?.(message);
      } else {
        onError?.(message);
      }
      return response.data;
    } catch (error) {
      return [] as BaseResponse[];
    }
  }
);

export const getAdminAuthorityListThunk = createAsyncThunk(
  "userInfo/getAdminAuthorityList",
  async ({ payload, onSuccess }: BaseRequest<GetAdminAuthorityList>) => {
    try {
      const response = await userServices.getAdminAuthorityList(payload);
      onSuccess?.();
      return response.data?.[0]?.gtauthorityframe;
    } catch (error) {
      return [] as AuthorityFrame[];
    }
  }
);

export const loginAdminTopThunk = createAsyncThunk(
  "userInfo/adminLoginTop",
  async ({
    payload,
    onSuccess,
    onError,
  }: BaseRequest<TAdminLoginTopPayload>) => {
    try {
      const response = await userServices.loginAdminTop(payload);
      onSuccess?.(EMPTY_STRING, response.data);
      return response.data;
    } catch (error) {
      onError?.(EMPTY_STRING);
      return [] as TAdminLoginTopData[];
    }
  }
);

import { FC, ReactNode } from "react";
import { useInviteMember } from "../../../../lib/hooks/ManagementSettings/useInviteMember";
import { CommonModal } from "../../CommonModal";
import { InviteMemberForm } from "../Form/InviteMemberForm";

type InviteMemberModalProps = {
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const InviteMemberModal: FC<InviteMemberModalProps> = (props) => {
  const { isOpen, title, onClose } = props;
  const { formState, handleSubmit, onSubmit, register, closeModal } =
    useInviteMember({
      onClose,
    });

  return (
    <CommonModal
      title={title}
      isOpen={isOpen}
      onClose={closeModal}
      minW={970}
      isCentered
    >
      <InviteMemberForm
        formState={formState}
        handleSubmit={handleSubmit}
        onSubmit={onSubmit}
        register={register}
      />
    </CommonModal>
  );
};

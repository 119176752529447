const TeamMessage = {
  discovery: {
    errorEmptyTeamName: `チーム名が、入力されていません。\nチーム名を、入力して下さい。`,
    errorDuplicateTeamName: `チーム名は既に存在します。`,
    errorAddTeamFail: `チームの追加が有効になっていませんでした。`,
    errorTeamNameLength: `255文字以上入力いただくことはできません。`,
    errorAPIProcess: `データがありません`,
  },
};

export default TeamMessage;

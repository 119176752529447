import { selectAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(selectAnatomy.keys);

const custom = definePartsStyle({
  field: {
    height: "37px",
    border: "1px solid #B1B1B1",
    borderRadius: "4px",
    color: "#2C2C2C",
    fontWeight: "bold",
    background: "#FFF",
    fontSize: "14px",
    cursor: "pointer",
    _hover: {
      background: "#FFF",
    },
    _invalid: {
      borderColor: "#E53E3E",
      boxShadow: "0 0 0 1px #E53E3E",
    },
  },
});

export const selectTheme = defineMultiStyleConfig({ variants: { custom } });

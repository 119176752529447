import { menuAnatomy } from "@chakra-ui/anatomy";
import { createMultiStyleConfigHelpers } from "@chakra-ui/react";

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(menuAnatomy.keys);

// define custom variants
const custom = definePartsStyle({
  list: {
    py: "0px",
    borderRadius: "10px",
  },
  item: {
    h: "40px",
    fontSize: "14px",
    fontWeight: "bold",
    px: "20px",
    py: "10px",
    _hover: {
      background: "#EDEDED",
    },
    _first: {
      borderTopLeftRadius: "8px",
      borderTopRightRadius: "8px",
    },
    _last: {
      borderBottomLeftRadius: "8px",
      borderBottomRightRadius: "8px",
    },
  },
});

// export the custom variants in the component theme
export const menuTheme = defineMultiStyleConfig({ variants: { custom } });

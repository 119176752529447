import { Box, Stack } from "@chakra-ui/layout";
import { FC } from "react";
import { useAlertManagement } from "../../../lib/hooks/ManagementSettings/useAlertManagement";
import { ButtonPrimary } from "./Button/ButtonPrimary";
import { CreateAlertModal } from "./Modal/CreateAlertModal";
import { TableAlert } from "./Table/TableAlert";

export const AlertManagement: FC = () => {
  const { modalName, openModal, closeModal } = useAlertManagement();

  return (
    <Box borderRadius="10px" background="white" p="20px">
      <Stack gap="10px">
        <Box>
          <ButtonPrimary onClick={() => openModal("CREATE_ALERT")}>
            アラートを作成
          </ButtonPrimary>
        </Box>
        <TableAlert />
      </Stack>

      {/* Modals */}
      {modalName === "CREATE_ALERT" ? (
        <CreateAlertModal
          alertData={null}
          title="アラートを作成"
          isOpen={modalName === "CREATE_ALERT"}
          onClose={closeModal}
        />
      ) : null}
    </Box>
  );
};

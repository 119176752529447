import { ChangeEvent, useLayoutEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import { getTeamListThunk } from "../../../redux/thunk/teamThunk";
import { EMPTY_STRING } from "../../Const";

type ModalName = "INVITE_MEMBER";

export const useMemberManagement = () => {
  const dispatch = useAppDispatch();
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);
  const [modalName, setModalName] = useState<ModalName | null>(null);
  const [filter, setFilter] = useState<string>(EMPTY_STRING);

  const onChangeFilter = (event: ChangeEvent<HTMLSelectElement>) => {
    setFilter(event.target.value);
  };

  const openModal = (name: ModalName) => {
    setModalName(name);
  };

  const closeModal = () => {
    setModalName(null);
  };

  useLayoutEffect(() => {
    if (adcompanyid && aduserid) {
      dispatch(
        getTeamListThunk({
          payload: {
            adcompanyid,
            aduserid,
          },
        })
      );
    }
  }, [adcompanyid, aduserid, dispatch]);

  return {
    modalName,
    filter,
    onChangeFilter,
    openModal,
    closeModal,
  };
};

import { ChevronRightIcon } from "@chakra-ui/icons";
import { Button, Icon } from "@chakra-ui/react";
import { FC } from "react";
import { TextHeading } from "./Typography/TextHeading";

type ButtonMenuProps = {
  id: number;
  text: string;
  selectedId: number;
  onSelect: () => void;
};

export const MenuSettingItem: FC<ButtonMenuProps> = ({
  id,
  text,
  selectedId,
  onSelect,
}) => {
  return (
    <Button
      variant="ghost"
      h="48px"
      p="14px"
      borderRadius="10px"
      background="white"
      size="lg"
      onClick={onSelect}
    >
      <TextHeading mr="auto">{text}</TextHeading>
      <Icon
        as={ChevronRightIcon}
        opacity={selectedId === id ? 1 : 0.3}
        boxSize="32px"
        mr="-4px"
      />
    </Button>
  );
};

import React, { Fragment } from "react";
import { Grid, GridItem } from "@chakra-ui/react";
import MostedUsedAppPopOverCustomSecond from "./MostedUsedAppPopOverCustomSecond";
import { calculateTimeByCategoryPerHour } from "../../lib/caterogyCulculate";

type Props = {
  timeZoneIndex: number;
  mouseHoveredTimeZone: HoveredTimeZone;
  appInfo: AppInfoAt24Hours;
  member: TeamMember;
  openUsedAppDetailInfoModalControll: (appInfoDataid: number) => void;
};

// 総作業時間グラフに特定の時間帯ごとにもっとも長く使用したアプリケーションをGrid状で表示するためのパーツ
const UsedAppGridView = React.memo(
  ({
    timeZoneIndex,
    appInfo,
    member,
    openUsedAppDetailInfoModalControll,
  }: Props) => {
    // 画面に表示する正確な時間帯のindex
    const newAppInfoData = appInfo?.filter((object: AppInfoAtOneHour) => {
      return object.time_range !== 29;
    });

    const resultNew = calculateTimeByCategoryPerHour(newAppInfoData);

    if (resultNew === undefined) return null;

    // 表示する時間帯によって、表示時間単位を変える
    // 30分帯以外は、表示時間単位を分にする
    // 30分帯のみ、表示時間単位を秒にする
    const mostUsedAppTimeShowMinute = timeZoneIndex === 5 ? false : true;

    // 表示するGridの個数だけ生成する
    const item = resultNew.map(
      (
        v: [
          CalculatedCategoryPer1Minute | undefined,
          OrganizeAppInfoByCategory | {}
        ],
        i: number
      ) => {
        const color = !v[0] ? "#F2F2F2" : "";

        // 時間帯に使用した時間数が0秒の場合
        if (!v[0]) {
          return (
            <GridItem
              key={`grid-${i}`}
              colStart={i + 1}
              colEnd={i + 2}
              bg={color}
            />
          );
        }

        // 時間帯に使用した時間数が1秒以上の場合
        return (
          <Fragment key={i}>
            <MostedUsedAppPopOverCustomSecond
              appInfoCountObject={v}
              i={i}
              showMinute={mostUsedAppTimeShowMinute}
              member={member}
              modalOpen={openUsedAppDetailInfoModalControll}
            />
          </Fragment>
        );
      }
    );
    return (
      <Grid
        templateColumns={`repeat(${resultNew.length}, 1fr)`}
        pt='2'
        alignItems='center'
        width={"95%"}
        px='10px'
      >
        {item}
      </Grid>
    );
  }
);
UsedAppGridView.displayName = "UsedAppGridView";
export default UsedAppGridView;

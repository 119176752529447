import { zodResolver } from "@hookform/resolvers/zod";
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { z } from "zod";
import { MemberFrame } from "../../../@types/Member";
import { AddMemberIntoTeam } from "../../../@types/Team";
import { useAppDispatch, useAppSelector } from "../../../redux/storeConfig";
import {
  addMemberIntoTeamThunk,
  getTeamListThunk,
  getTeamUserDataThunk,
} from "../../../redux/thunk/teamThunk";
import { EMPTY_STRING } from "../../Const";
import { getName } from "../../util/helper";
import { useMessage } from "../useMessage";

type UseAddMember = {
  type: "TEAM_INFO" | "TEAM_LIST";
  onClose: () => void;
  onOk?: () => void;
};

const schema = z.object({
  upteamuserid: z.string().array(),
});

type AddMemberForm = z.infer<typeof schema>;

export const useAddMember = (props: UseAddMember) => {
  const { type, onClose, onOk } = props;
  const dispatch = useAppDispatch();
  const { showMessage } = useMessage();
  const { adcompanyid, aduserid } = useAppSelector((state) => state.userInfo);
  const { memberList } = useAppSelector((state) => state.member);
  const { teamUserData } = useAppSelector((state) => state.team);
  const [searchValue, setSearchValue] = useState<string>("");
  const [data, setData] = useState<MemberFrame[]>([]);
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors, isSubmitting },
    control,
    watch,
  } = useForm<AddMemberForm>({
    values: {
      upteamuserid:
        teamUserData?.gtteamuserframe.map((item) => item.gtteamuserid) || [],
    },
    resolver: zodResolver(schema),
  });

  const teamUserIds = watch("upteamuserid");

  const isEmpty = useMemo(() => {
    return !teamUserIds.length;
  }, [teamUserIds]);

  const onCloseModal = () => {
    reset();
    setSearchValue("");
    onClose();
  };

  const onChangeSearchValue = (event: ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    setSearchValue(event.target.value);
  };

  const onSubmit = (values: AddMemberForm) => {
    if (adcompanyid && aduserid && teamUserData) {
      const payload: AddMemberIntoTeam = {
        upcompanyid: adcompanyid,
        upteamid: teamUserData?.gtteamid,
        upteamuserid: values.upteamuserid,
      };
      dispatch(
        addMemberIntoTeamThunk({
          payload,
          onSuccess: (message) => {
            showMessage({
              status: "success",
              title: message || EMPTY_STRING,
            });
            onOk?.();
            onCloseModal();
            if (type === "TEAM_INFO") {
              dispatch(
                getTeamUserDataThunk({
                  payload: {
                    adcompanyid,
                    adteamid: teamUserData?.gtteamid,
                  },
                })
              );
            }
            dispatch(
              getTeamListThunk({
                payload: {
                  adcompanyid,
                  aduserid,
                },
              })
            );
          },
          onError: (message) => {
            showMessage({
              status: "error",
              title: message,
            });
          },
        })
      );
    }
  };

  const onSearchTeam = useCallback(() => {
    if (searchValue) {
      setData(
        memberList.filter((item) =>
          getName([item.gtuserlastname, item.gtuserfirstname])
            .toLocaleLowerCase()
            .includes(searchValue.toLocaleLowerCase())
        )
      );
    } else {
      setData(memberList);
    }
  }, [memberList, searchValue]);

  useEffect(() => {
    onSearchTeam();
  }, [onSearchTeam]);

  return {
    control,
    errors,
    isSubmitting,
    searchValue,
    data,
    isEmpty,
    handleSubmit,
    register,
    reset,
    onSubmit,
    onChangeSearchValue,
    onSearchTeam,
    setSearchValue,
    onCloseModal,
  };
};


import { useState } from 'react';

type GridStartTimeZoneData = [] | string[];

type Values = {
  gridStartTimeZoneData: GridStartTimeZoneData;
};

type Actions = {
  setGridStartTimeZoneData: SetStateActionDispatcher<GridStartTimeZoneData>;
};

type UseGridStartTimeZoneData = [Values, Actions];

/**
 * @remarks
 * 総作業時間グラフで選択した時間帯によって、Grid状で表示するスケジュールデータの時間の配列を状態管理するためのhooks
 *
 * Shortest name:  {@link useGridStartTimeZoneData}
 * Full name:      {@link useGridStartTimeZoneData}
 *
 * @returns
 * [
 *  values: {
 *    gridStartTimeZoneData: [ // 総作業時間グラフで選択した時間帯によって、Grid状で表示するスケジュールデータの時間の配列
 *      Grid状で表示するスケジュールデータの時間,…
 *    ]
 *  },
 *  actions: {
 *    setGridStartTimeZoneData: 総作業時間グラフで選択した時間帯によって、Grid状で表示するスケジュールデータの時間の配列を更新する
 *  }
 * ]
 *
 * @public
 */
const useGridStartTimeZoneData = (): UseGridStartTimeZoneData => {
  /**
   * ダミーデータを使った場合のgridStartTimeZoneDataの値の例
   * ※24時間帯の表示をした場合
   *
   * @example
   * [
   *  "0:00",
   *  "1:00",
   *  "2:00",
   *  "3:00",
   *  "4:00",
   *  "5:00",
   *  "6:00",
   *  "7:00",
   *  "8:00",
   *  "9:00",
   *  "10:00",
   *  "11:00",
   *  "12:00",
   *  "13:00",
   *  "14:00",
   *  "15:00",
   *  "16:00",
   *  "17:00",
   *  "18:00",
   *  "19:00",
   *  "20:00",
   *  "21:00",
   *  "22:00",
   *  "23:00",
   *  "24:00"
   * ]
   */
  const [gridStartTimeZoneData, setGridStartTimeZoneData] =
    useState<GridStartTimeZoneData>([]);

  const values = { gridStartTimeZoneData };

  const actions = { setGridStartTimeZoneData };

  return [values, actions];
};

export default useGridStartTimeZoneData;

import { useState } from "react";

type ModalName = "ADD_MEMBER" | "UPDATE_TEAM";

export const useTeamInfo = () => {
  const [modalName, setModalName] = useState<ModalName | null>(null);

  const openModal = (name: ModalName) => {
    setModalName(name);
  };

  const closeModal = () => {
    setModalName(null);
  };
  return {
    modalName,
    openModal,
    closeModal,
  };
};

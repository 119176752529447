import {
  Center,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Grid,
  GridItem,
  Input,
} from "@chakra-ui/react";
import { FC, ReactNode } from "react";
import { useChangePassword } from "../../../../lib/hooks/ManagementSettings/useChangePassword";
import { useAppSelector } from "../../../../redux/storeConfig";
import { CommonModal } from "../../CommonModal";
import { ButtonSubmit } from "../Button/ButtonSubmit";
import { TextHeading } from "../Typography/TextHeading";
import { TextSecond } from "../Typography/TextSecond";

type ChangePasswordModalProps = {
  title: ReactNode;
  isOpen: boolean;
  onClose: () => void;
};

export const ChangePasswordModal: FC<ChangePasswordModalProps> = (props) => {
  const { isOpen, title, onClose } = props;
  const { isLoading } = useAppSelector((state) => state.loading);
  const { errors, handleSubmit, onSubmit, register, onCloseModal } =
    useChangePassword({ onClose });

  return (
    <CommonModal
      title={title}
      isOpen={isOpen}
      onClose={onCloseModal}
      isCentered
    >
      <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
        <TextSecond mb="10px">
          別のサイトで使用していない、安全なパスワードを設定してください。
        </TextSecond>
        <Grid templateColumns="repeat(1, 1fr)" gap="20px">
          <GridItem>
            <FormControl isInvalid={!!errors.password}>
              <FormLabel mb="4px">
                <TextHeading>新しいパスワード</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                type="password"
                placeholder="パスワードを入力してください"
                border="2px solid #D5D5D5"
                borderRadius="10px"
                disabled={isLoading}
                {...register("password")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.password && errors.password.message}
              </FormErrorMessage>
            </FormControl>
          </GridItem>
          <GridItem>
            <FormControl isInvalid={!!errors.confirmpassword}>
              <FormLabel mb="4px">
                <TextHeading>新しいパスワードを再入力</TextHeading>
              </FormLabel>
              <Input
                variant="custom"
                type="password"
                placeholder="パスワードを入力してください"
                border="2px solid #D5D5D5"
                borderRadius="10px"
                disabled={isLoading}
                {...register("confirmpassword")}
              />
              <FormErrorMessage fontSize="14px">
                {errors.confirmpassword && errors.confirmpassword.message}
              </FormErrorMessage>
              <FormHelperText mt="10px">
                <TextSecond color="#FC3737" fontSize="13px" fontWeight="medium">
                  ※英字、英数字を含むうんたらかんたら
                </TextSecond>
              </FormHelperText>
            </FormControl>
          </GridItem>
        </Grid>
        <Center pt="30px" pb="20px">
          <ButtonSubmit type="submit" isLoading={isLoading}>
            確定
          </ButtonSubmit>
        </Center>
      </form>
    </CommonModal>
  );
};

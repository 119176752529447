import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useAppDispatch } from "../../redux/storeConfig";
import { loginAdminTopThunk } from "../../redux/thunk/userThunk";
import { ROUTERS } from "../routers";
import useLoginUser from "./useLoginUser";
import { useMessage } from "./useMessage";

export const useAuth = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { showMessage } = useMessage();
  const { setLoginUser, loginUser } = useLoginUser();

  const login = useCallback(
    (urcompanyid: string, urmailaddress: string, urpassword: string) => {
      dispatch(
        loginAdminTopThunk({
          payload: {
            urcompanyid,
            urmailaddress,
            urpassword,
          },
          onSuccess: (_, response) => {
            if (response.length > 0) {
              if (response[0].urauthority > 0) {
                setLoginUser(response);
                showMessage({ title: "ログインしました", status: "success" });
                navigate(ROUTERS.DISCOVERY, {
                  state: { loginUser: response },
                  replace: true,
                });
              } else {
                showMessage({ title: "権限がありません", status: "error" });
              }
            } else {
              showMessage({ title: "ユーザが見つかりません", status: "error" });
            }
          },
          onError: () => {
            showMessage({ title: "ユーザが見つかりません", status: "error" });
          },
        })
      );
    },
    [dispatch, navigate, setLoginUser, showMessage]
  );

  return { login, loginUser };
};


import { useState } from 'react';

type Values = {
  screenshotHourArrayDatas: ScreenShotsGridDataArray;
};

type Actions = {
  setScreenshotHourArrayDatas: SetStateActionDispatcher<ScreenShotsGridDataArray>;
};

type UseScreenshotHourArrayDatas = [Values, Actions];

/**
 * @remarks
 *
 * Shortest name:  {@link useScreenshotHourArrayDatas}
 * Full name:      {@link useScreenshotHourArrayDatas}
 *
 * @returns
 * [
 *  values: {
 *    screenshotHourArrayDatas: [
 *      {
   *    "screenShotsIndex": -1
   *    },
 *    ︙
 *    ]
 *  },
 *  actions: {
 *    setScreenshotHourArrayDatas
 *  }
 * ]
 *
 * @public
 */
const useScreenshotHourArrayDatas = (): UseScreenshotHourArrayDatas => {
  /**
   * ダミーデータを使った場合のscreenshotHourArrayDatasの値の例
   *
   * @example
   * [
   *  {
   *    "screenShotsIndex": -1
   *  },
   *  {
   *    "screenShotsIndex": -1
   *  },
   *  {
   *    "screenShotsIndex": 660
   *  },
   *  {
   *    "screenShotsIndex": 720
   *  }
   * ]
   */
  const [screenshotHourArrayDatas, setScreenshotHourArrayDatas] = useState<ScreenShotsGridDataArray>([]);

  const values = { screenshotHourArrayDatas };

  const actions = { setScreenshotHourArrayDatas };

  return [values, actions];
};

export default useScreenshotHourArrayDatas;

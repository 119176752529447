import { useCallback, useState } from "react";
import type { ChangeEventHandler, FC, MouseEventHandler } from "react";
import { Button, InputRightElement, Stack, chakra } from "@chakra-ui/react";
import { FaLock, FaRegBuilding, FaUserAlt } from "react-icons/fa";
import { PrimaryButton } from "../../button/PrimaryButton";
import { FormInputComponent } from "./FormInputComponent";

type Props = {
  loading: boolean;
  login: (
    urcompanyid: string,
    urmailaddress: string,
    urpassword: string
  ) => void;
};

const CFaRegBuilding = chakra(FaRegBuilding);
const CFaUserAlt = chakra(FaUserAlt);
const CFaLock = chakra(FaLock);

export const FormComponent: FC<Props> = ({ loading, login }) => {
  const [urcompanyid, setCompanyid] = useState("");
  const [urmailaddress, setMailaddress] = useState("");
  const [urpassword, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const onChangeCompanyid: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => setCompanyid(value),
    []
  );
  const onChangeMailaddress: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => setMailaddress(value),
    []
  );
  const onChangePassword: ChangeEventHandler<HTMLInputElement> = useCallback(
    ({ target: { value } }) => setPassword(value),
    []
  );
  const handleShowClick: MouseEventHandler = useCallback(
    () => setShowPassword(!showPassword),
    [showPassword]
  );
  const onClickLogin = useCallback(
    () => login(urcompanyid, urmailaddress, urpassword),
    [login, urcompanyid, urmailaddress, urpassword]
  );

  return (
    <form onSubmit={onClickLogin}>
      <Stack
        spacing={4}
        p="1rem"
        backgroundColor="whiteAlpha.900"
        boxShadow="md"
      >
        <FormInputComponent
          input={{
            onChange: onChangeCompanyid,
            type: "text",
            placeholder: "会社コード",
            value: urcompanyid,
          }}
          leftElement={{ LeftIcon: CFaRegBuilding }}
        />
        <FormInputComponent
          input={{
            onChange: onChangeMailaddress,
            type: "email",
            placeholder: "メールアドレス",
            value: urmailaddress,
          }}
          leftElement={{ LeftIcon: CFaUserAlt }}
        />
        <FormInputComponent
          input={{
            onChange: onChangePassword,
            type: showPassword ? "text" : "password",
            placeholder: "パスワード",
            value: urpassword,
          }}
          leftElement={{ LeftIcon: CFaLock }}
        >
          <InputRightElement width="4.5rem">
            <Button h="1.75rem" size="sm" onClick={handleShowClick}>
              {showPassword ? "隠す" : "表示"}
            </Button>
          </InputRightElement>
        </FormInputComponent>
        <PrimaryButton
          type="submit"
          disabled={urmailaddress === ""}
          loading={loading}
          onClick={onClickLogin}
        >
          ログイン
        </PrimaryButton>
      </Stack>
    </form>
  );
};


import {
  adjustTime,
  dataSpliceLength,
  TIMEZONE12HINDEX,
  TIMEZONE30MINDEX,
  MAXTIMEZONE,
} from './Const';

import { timeSumOrSub } from './timeCalculate';

/**
 * @remarks
 * timeをキーに、値はstring型のオブジェクト
 */
type TargetObj = {
  time: string;
};

type Target<X> = {
  time_range: number;
  detaildata: X[];
};

type TargetData<X> = Target<X>;

type TotallingDataPer1Minute = {
  [index: string]: number;
};

/**
 * @remarks
 * 指定した時間帯と時間から最小時間帯と最大時間帯を計算する
 *
 * @param CalculateMinMaxTimezoneProps @see {@link CalculateMinMaxTimezoneProps}
 *  @param activeTimeZone {string} - 総作業時間グラフで選択した時間帯
 *  @param adjustTimeByGenerateTimeZone @see {@link AdjustTimeItem}
 *
 * @returns
 * @see {@link MinMaxTimeZone}
 */
const calculateMinMaxTimezone = ({
  activeTimeZone,
  adjustTimeByGenerateTimeZone,
}: CalculateMinMaxTimezoneProps): MinMaxTimeZone => {
  let timeZone: number, minutes: number;
  timeZone = parseInt(activeTimeZone);
  minutes = parseInt(activeTimeZone.substr(activeTimeZone.indexOf(':') + 1));

  const time = { hour: timeZone, minutes };

  /** @see {@link MinMaxTimeZone} */
  return timeSumOrSub({
    time,
    addTime: adjustTimeByGenerateTimeZone,
  });
};

/**
 * @remarks
 * 1日のデータから指定した最小時間帯の時間と最大時間帯の時間を基準にその間にあるデータを取得する
 *
 * @typeParam T - 24時間単位のデータ
 * @typeParam U - 最小時間帯の時間帯（時間）
 * @typeParam V - 最大時間帯の時間帯（時間）
 * @typeParam X - {@link TargetObj}
 *
 * @param timeZoneData 24時間単位のデータ
 * @param minTimeZone {number} - 最小時間帯の時間帯（時間）
 * @param maxTimeZone {number} - 最大時間帯の時間帯（時間）
 *
 * @returns
 * T - 24時間単位のデータ
 */
const generateDataFromMinMaxTimeZone = <
  T extends TargetData<X>,
  U extends number,
  V extends number,
  X extends TargetObj
>(
  timeZoneData: T[],
  minTimeZone: U,
  maxTimeZone: V
): (T | undefined)[] => {
  return timeZoneData
    .map((oneHourValue: T, index: number) => {
      if (
        oneHourValue.time_range >= minTimeZone &&
        oneHourValue.time_range <= maxTimeZone
      ) {
        return oneHourValue;
      }
      return undefined;
    })
    .filter(Boolean);
};

/**
 * @remarks
 * 2次元配列のデータを1次元配列に変換する
 *
 * @typeParam T - 2次元配列のデータ
 * @typeParam X - {@link TargetObj}
 *
 * @param appInfoData {@typeParam T} - 2次元配列のデータ
 *
 * @returns
 * X[] - 1次元配列
 */
const makeDataOneLine = <T extends TargetData<X>, X extends TargetObj>(
  appInfoData: T[]
): X[] => {
  return (appInfoData as T[])
    .map((oneHourValue: T, index: number) => {
      return oneHourValue.detaildata.map((data: X, i: number) => {
        return data;
      });
    })
    .reduce((pre, current) => {
      pre.push(...current);
      return pre;
    }, []);
};

/**
 * @remarks
 * 1次元配列のデータを元にグラフに表示する区切りのデータを集計する
 *
 * @typeParam T - {@link TargetObj}
 * @typeParam U - 集計した1次元配列のデータを分割する単位
 * @typeParam V - 最大時間帯
 *
 * @param oneLineTimeZoneData 1次元配列のデータ
 * @param dataSpliceUnit {@typeParam U} - 集計した1次元配列のデータを分割する単位
 * @param maxTime {@typeParam V} - 最大時間帯
 *
 * @returns
 * timeをキーに、値はstring型のオブジェクトを配列にした配列
 */
const countTimeZoneDataFromOneLineTimeZoneData = <
  T extends TargetObj,
  U extends number,
  V extends string
>(
  oneLineTimeZoneData: T[],
  dataSpliceUnit: U,
  maxTime: V
): T[][] => {
  const dataChunkLength = Math.floor(
    oneLineTimeZoneData.length / dataSpliceUnit
  );

  let lastDataFrom24hour: T[] | string = '';

  if (
    dataSpliceUnit !== dataSpliceLength[TIMEZONE30MINDEX] &&
    maxTime === MAXTIMEZONE
  ) {
    lastDataFrom24hour = oneLineTimeZoneData.slice(
      oneLineTimeZoneData.length - 1
    );
  }

  let splitTimeZoneData = [...Array(dataChunkLength)]
    .map((v: any, i: number) => {
      return oneLineTimeZoneData.splice(0, dataSpliceUnit);
    })
    .filter((arr) => arr.length > 0);

  if (
    dataSpliceUnit !== dataSpliceLength[TIMEZONE30MINDEX] &&
    maxTime === MAXTIMEZONE
  ) {
    splitTimeZoneData.push(lastDataFrom24hour as T[]);
  }

  return splitTimeZoneData;

  // return splitTimeZoneData.map((halfHourData: T[], index: number) => {
  //   const id = halfHourData[0].time;

  //   return countTimeZoneData<T, X>(id, halfHourData);
  // });
};

/**
 * @remarks
 * 1次元配列のデータの最小時間帯の時間と最大時間帯の時間が同じ場合、分単位の最小・最大値を基準にその間にあるデータをより詳細に取得する
 *
 * @typeParam T - 1次元配列のデータ
 * @typeParam U - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 * @typeParam V - 最小時間帯の時間
 * @typeParam W - 最大時間帯の時間
 * @typeParam X - {@link TargetObj}
 *
 * @param targetData {@typeParam T} - 1次元配列のデータ
 * @param dataSpliceIndex {@typeParam U} - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 * @param minTime {@typeParam V} - 最小時間帯の時間
 * @param maxTime {@typeParam W} - 最大時間帯の時間
 *
 * @returns
 * {
 *  reSplitTimeZoneData: {@typeParam T} - 1次元配列のデータ,
 *  dataSpliceUnit: 集計した1次元配列のデータを分割する単位
 * }
 */
const _resplitSameTimeZoneTargetData = <
  T extends TargetData<X>,
  U extends number,
  V extends string,
  W extends string,
  X extends TargetObj
>(
  targetData: T[],
  dataSpliceIndex: U,
  minTime: V,
  maxTime: W
) => {
  const dataSpliceUnit = dataSpliceLength[dataSpliceIndex];

  const firstComeTimeIndex = (targetData as T[])[0].detaildata.findIndex(
    (obj: X) => obj.time === minTime
  );
  const lastComeTimeIndex = (targetData as T[])[0].detaildata.findIndex(
    (obj: X) => obj.time === maxTime
  );

  const newTimeZoneData = (targetData as T[])[0].detaildata.slice(
    firstComeTimeIndex,
    lastComeTimeIndex + 1
  );
  (targetData as T[])[0].detaildata = newTimeZoneData;
  return { reSplitedData: targetData, dataSpliceUnit };
};

/**
 * @remarks
 * 1次元配列のデータを最小時間帯の時間から最大時間帯の時間までに切り取って一定間隔に区切る
 *
 * @typeParam T - 1次元配列のデータ
 * @typeParam U - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 * @typeParam V - 最小時間帯の時間
 * @typeParam W - 最大時間帯の時間
 * @typeParam X - {@link TargetObj}
 *
 * @param targetData {@typeParam T} - 1次元配列のデータ
 * @param dataSpliceIndex {@typeParam U} - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 * @param minTime {@typeParam V} - 最小時間帯の時間
 * @param maxTime {@typeParam W} - 最大時間帯の時間
 *
 * @returns
 * {
 *  reSplitTimeZoneData: {@typeParam T} - 1次元配列のデータ,
 *  dataSpliceUnit: 集計した1次元配列のデータを分割する単位
 * }
 */
const _resplitTargetData = <
  T extends TargetData<X>,
  U extends number,
  V extends string,
  W extends string,
  X extends TargetObj
>(
  targetData: T[],
  dataSpliceIndex: U,
  minTime: V,
  maxTime: W
): {
  reSplitTimeZoneData: T[];
  dataSpliceUnit: number;
} => {
  const dataSpliceUnit = dataSpliceLength[dataSpliceIndex];

  // 30分帯表示の際、最小表示時間帯と最大表示時間帯が同じになるケースがあるため、別の処理をする
  if (parseInt(minTime) === parseInt(maxTime)) {
    /**
     * {
     *  reSplitTimeZoneData: {@typeParam T} - 1次元配列のデータ,
     *  dataSpliceUnit: 集計した1次元配列のデータを分割する単位
     * }
     */
    const { reSplitedData, dataSpliceUnit } = _resplitSameTimeZoneTargetData(
      targetData,
      dataSpliceIndex,
      minTime,
      maxTime
    );
    return { reSplitTimeZoneData: reSplitedData, dataSpliceUnit };
  }

  let firstComeTimeZone = targetData.shift();
  let lastComeTimeZone = targetData.pop();
  const firstComeTimeZoneIndex = (firstComeTimeZone as T).detaildata.findIndex(
    (obj: X) => obj.time === minTime
  );

  const lastComeTimeZoneIndex = (lastComeTimeZone as T).detaildata.findIndex(
    (obj: X) => obj.time === maxTime
  );

  const newFirstComeTimeZoneData = (firstComeTimeZone as T).detaildata.slice(
    firstComeTimeZoneIndex
  );

  const newLastComeTimeZoneData = (lastComeTimeZone as T).detaildata.slice(
    0,
    lastComeTimeZoneIndex + dataSpliceUnit
  );

  (firstComeTimeZone as T).detaildata = newFirstComeTimeZoneData;
  (lastComeTimeZone as T).detaildata = newLastComeTimeZoneData;

  targetData.unshift(firstComeTimeZone as T);
  targetData.push(lastComeTimeZone as T);

  return { reSplitTimeZoneData: targetData, dataSpliceUnit };
};

/**
 * @remarks
 * 12時間帯のアプリ使用状況のデータを生成する
 *
 * @typeParam T - 総作業時間グラフで選択した時間帯
 * @typeParam U - 24時間単位のデータ
 * @typeParam V - {@link TargetObj}
 *
 * @param activeTimeZone {@typeParam T} - 総作業時間グラフで選択した時間帯
 * @param appInfoData {@typeParam U} -  24時間単位のデータ
 *
 * @returns
 * timeをキーに、値はstring型のオブジェクトを配列にした配列
 */
const generate12HourData = <
  T extends HoveredTimeZoneItemPosition,
  U extends TargetData<V>[],
  V extends TargetObj
>(
  activeTimeZone: T,
  appInfoData: U
): V[][] | undefined => {
  // mouseHoveredTimeZone;
  // activeTimeZone: 0~24のうちのどれか

  if (!activeTimeZone) return undefined;

  const stringActiveTimeZone: string = `${parseInt(
    activeTimeZone as string
  )}:00`;

  /** @see {@link MinMaxTimeZone} */
  const { minTime, maxTime } = calculateMinMaxTimezone({
    activeTimeZone: stringActiveTimeZone,
    adjustTimeByGenerateTimeZone: adjustTime[TIMEZONE12HINDEX],
  });

  // データの順番は正しいという前提で下記の処理になる

  // 12時間分のデータを生成
  const timeZone12HData: (TargetData<V> | undefined)[] =
    generateDataFromMinMaxTimeZone(
      appInfoData,
      parseInt(minTime),
      parseInt(maxTime)
    );


  if (!timeZone12HData.length) return undefined;

  // 時間帯ごとにデータが入っている2次元配列のdata部分のみを抽出して2次元配列化する
  // さらに、抽出した2次元配列を1次元配列化する
  const oneLineTimeZone12HData: V[] = (timeZone12HData as TargetData<V>[])
    .map((oneHourValue: TargetData<V>, index: number) => {
      return oneHourValue.detaildata.map((data: V, i: number) => {
        return data;
      });
    })
    .reduce((pre, current) => {
      pre.push(...current);
      return pre;
    }, []);

  const dataSpliceUnit = dataSpliceLength[TIMEZONE12HINDEX];

  const dataChunkLength = Math.floor(
    oneLineTimeZone12HData.length / dataSpliceUnit
  );

  let lastDataFrom24hour: V[] | string = '';

  if (maxTime === MAXTIMEZONE) {
    lastDataFrom24hour = oneLineTimeZone12HData.slice(
      oneLineTimeZone12HData.length - 1
    );
  }

  let result2 = [...Array(dataChunkLength)].map((v: any, i: number) => {
    return oneLineTimeZone12HData.splice(0, dataSpliceUnit);
  });

  if (maxTime !== MAXTIMEZONE) {
    result2.splice(-1, 1);
  } else {
    result2.push(lastDataFrom24hour as V[]);
  }

  return result2;
};

/**
 * @remarks
 * 12時間帯より詳細なアプリ使用状況のデータを生成する
 * 6時間帯、3時間帯、1時間帯、30分帯
 *
 * @typeParam T - 総作業時間グラフで選択した時間帯
 * @typeParam U - 24時間単位のデータ
 * @typeParam V - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 * @typeParam X - {@link TargetObj}
 *
 * @param activeTimeZone {@typeParam T} - 総作業時間グラフで選択した時間帯
 * @param appInfoData {@typeParam U} - 24時間単位のデータ
 * @param timeZoneIndex {@typeParam V} - 24時間帯 ~ 30分帯のindex({@link useTimeZoneIndex})
 *
 * @returns
 * timeをキーに、値はstring型のオブジェクトを配列にした配列
 */
const generateUnder12HourData = <
  T extends HoveredTimeZoneItemPosition,
  U extends TargetData<X>[],
  V extends number,
  X extends TargetObj
>(
  activeTimeZone: T,
  appInfoData: U,
  timeZoneIndex: V
): X[][] | undefined => {
  // mouseHoveredTimeZone;
  // activeTimeZone: 0:00~24:00のうち、15分きざみのどれか

  if (!activeTimeZone) return undefined;

  /** @see {@link MinMaxTimeZone} */
  const { minTime, maxTime } = calculateMinMaxTimezone({
    activeTimeZone: activeTimeZone as string,
    adjustTimeByGenerateTimeZone: adjustTime[timeZoneIndex],
  });

  // データの順番は正しいという前提で下記の処理になる

  let timeZoneSpecifiedRangeData: (TargetData<X> | undefined)[] =
    generateDataFromMinMaxTimeZone(
      appInfoData,
      parseInt(minTime),
      parseInt(maxTime)
    );

  if (!timeZoneSpecifiedRangeData.length) return undefined;

  /**
   * {
   *  reSplitTimeZoneData: {@typeParam T} - 1次元配列のデータ,
   *  dataSpliceUnit: 集計した1次元配列のデータを分割する単位
   * }
   */
  const { reSplitTimeZoneData, dataSpliceUnit } = _resplitTargetData(
    timeZoneSpecifiedRangeData as U,
    timeZoneIndex,
    minTime,
    maxTime
  );

  timeZoneSpecifiedRangeData = reSplitTimeZoneData;

  // 時間帯ごとにデータが入っている2次元配列のdata部分のみを抽出して2次元配列化する
  // さらに、抽出した2次元配列を1次元配列化する
  const oneLineTimeZoneData: X[] = makeDataOneLine(
    timeZoneSpecifiedRangeData as U
  );

  const result = countTimeZoneDataFromOneLineTimeZoneData<X, number, string>(
    oneLineTimeZoneData,
    dataSpliceUnit,
    maxTime
  );

  return result;
};

/**
 * @remarks
 * 配列になっている複数要素を同じキー同士で合計して、1つのオブジェクトにする
 *
 * @typeParam T - 特定のキーに対して、その操作量や秒数、回数などをまとめたデータの型
 *
 * @param keyDatadArray {@typeParam T} - 特定のキーに対して、その操作量や秒数、回数などをまとめたデータの配列
 *
 * @returns
 * T - 特定のキーに対して、その操作量や秒数、回数などをまとめたデータ
 */
const makeOneKeyDataObject = <T extends TotallingDataPer1Minute>(
  keyDatadArray: (T | undefined)[]
): T | undefined => {
  // 引数の配列の要素からundefinedの型を排除する
  const keyData: T[] | [] = keyDatadArray.filter(
    (item): item is Exclude<typeof item, undefined> => item !== undefined
  );

  if (!keyData.length) return undefined;

  // 同じキー同士で合計して、1つのオブジェクトにする
  const calculatedDataOneObject = keyData.reduce((prev: T, current: T) => {
    const concatKeys = Object.keys(prev).concat(Object.keys(current));
    const uniqueKeys = concatKeys.filter((x, i, self) => self.indexOf(x) === i);
    const addedKeyData = uniqueKeys.reduce((acc: T, keyName: string) => {
      const x = prev[keyName] === undefined ? 0 : prev[keyName];
      const y = current[keyName] === undefined ? 0 : current[keyName];
      (acc as TotallingDataPer1Minute)[keyName] = x + y;
      return acc;
    }, {} as T);
    return addedKeyData;
  }, {} as T);

  return calculatedDataOneObject;
};

export {
  countTimeZoneDataFromOneLineTimeZoneData,
  calculateMinMaxTimezone,
  makeDataOneLine,
  generateDataFromMinMaxTimeZone,
  generate12HourData,
  generateUnder12HourData,
  makeOneKeyDataObject,
};

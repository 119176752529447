import { createSlice } from "@reduxjs/toolkit";
import { GetAdminOfficeIP } from "../../@types/Ip";
import { getOfficeIPThunk } from "../thunk/ipThunk";

type IPState = {
  ips: GetAdminOfficeIP[];
};

const initialState: IPState = {
  ips: [],
};

const ipSlice = createSlice({
  name: "ip",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOfficeIPThunk.fulfilled, (state, action) => {
      state.ips = action.payload;
    });
  },
});

export default ipSlice.reducer;

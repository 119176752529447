import React, { useEffect, useState } from "react";
import {
  Box,
  Text,
  Flex,
  Button,
  Table,
  Thead,
  Tbody,
  Tr,
  Th,
  Td,
  TableContainer,
  Menu,
  MenuList,
  MenuItem,
  MenuButton,
  useDisclosure,
  Image,
  Tooltip,
} from "@chakra-ui/react";
import "../../../styles/discovery.css";
import { useDispatch, useSelector } from "react-redux";
import { selectTeam } from "../../../redux/slice/teamSlice";
import buttonSort from "../../../images/icons/button_sort.svg";
import settings3Point from "../../../images/icons/settings_3point.svg";
import { TeamInfoModal } from "../ManagementSettings/Modal/TeamInfoModal";
import { CreateTeamModalModal } from "../ManagementSettings/Modal/CreateTeamModal";
import { useTeamMenu } from "../../../lib/hooks/ManagementSettings/useTeamMenu";
import { DeleteModal } from "../DeleteModal";
import { UpdateTeamModal } from "../ManagementSettings/Modal/UpdateTeamModal";
import { AddMemberModal } from "../ManagementSettings/Modal/AddMemberModal";
import { sortByJapanese } from "./../../../lib/util/helper";

interface RootState {
  team: {
    selectedTeam: TeamInfo;
    discoveryData: TeamInfoArray;
  };
}

const TeamMenu = React.memo(() => {
  const dispatch = useDispatch();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const {
    isOpen: isOpenMenu,
    onOpen: onOpenMenu,
    onClose: onCloseMenu,
  } = useDisclosure();
  const {
    modalName,
    team,
    isOwner,
    onOpenModal,
    closeModal,
    recallData,
    deleteTeam,
  } = useTeamMenu();
  const listTeam = useSelector((state: RootState) => state.team.discoveryData);
  const [listTeamSorted, setListTeamSorted] = useState<TeamInfoArray>([]);
  const [typeSort, setTypeSort] = useState<string | null>(null);
  const [scrollCount, setScrollCount] = useState<number>(0);
  const [idSelected, setIdSelected] = useState<string | null>("");
  const handleTeamInfoClick = (team: TeamInfo) => {
    dispatch(selectTeam(team));
  };
  useEffect(() => {
    dispatch(selectTeam(listTeam?.[0]));
  }, [dispatch, listTeam]);

  useEffect(() => {
    if (listTeam && typeSort) {
      const listTeamSortTemp = [...listTeam];
      if (typeSort === "asc") {
        listTeamSortTemp.sort((a, b) => {
          return sortByJapanese(a?.teamname, b?.teamname);
        });
      } else {
        listTeamSortTemp.sort((a, b) => {
          return sortByJapanese(b?.teamname, a?.teamname);
        });
      }
      setListTeamSorted(listTeamSortTemp);
    }
  }, [listTeam, typeSort]);

  const menuActions = [
    "チーム情報を確認",
    "メンバーを追加",
    "チーム名を変更",
    "削除",
  ];

  const handleScroll = (_event: any) => {
    setScrollCount(scrollCount + 1);
  };

  useEffect(() => {
    if (idSelected && scrollCount) {
      const transform = (
        document.querySelector(`#${idSelected}`)?.firstElementChild as any
      )?.style?.transform;

      const regex =
        /translate3d\((-?\d+(?:\.\d+)?)px,\s*(-?\d+(?:\.\d+)?)px,\s*(-?\d+(?:\.\d+)?)px\)/;
      const match = transform.match(regex);

      if (match) {
        const number = parseInt(match[2], 10);
        if (number < 175 || number > 416) {
          onCloseMenu();
          setIdSelected(null);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scrollCount]);

  return (
    <Box className="menu-team">
      {isOwner && (
        <Button className="btn-add-team" onClick={onOpen}>
          <Text>チームを追加</Text>
        </Button>
      )}
      <TableContainer className="table-team-menu-head">
        <Table variant="simple">
          <Thead>
            <Tr>
              <Th
                className="table-th"
                width="9rem"
                px={0}
                textAlign="left"
                onClick={() => {
                  switch (typeSort) {
                    case "asc":
                      setTypeSort("desc");
                      break;
                    case "desc":
                      setTypeSort(null);
                      setListTeamSorted([]);
                      break;
                    default:
                      setTypeSort("asc");
                      break;
                  }
                }}
                cursor="pointer"
              >
                <Flex align="center">
                  名前
                  <Image
                    src={buttonSort}
                    mb="0"
                    h="1rem"
                    w="1rem"
                    display={typeSort ? "initial" : "none"}
                    transform={typeSort === "asc" ? "rotate(180deg)" : ""}
                  />
                </Flex>
              </Th>
              <Th px={0} className="table-th">
                所属人数
              </Th>
              <Th paddingRight={0}></Th>
            </Tr>
          </Thead>
        </Table>
      </TableContainer>
      <TableContainer
        className="table-team-menu-body"
        maxH="28rem"
        overflowY="auto"
        onScroll={handleScroll}
      >
        <Table variant="simple">
          <Tbody>
            {(listTeamSorted?.length ? listTeamSorted : listTeam)?.map(
              (itemTeam: TeamInfo, index: number) => (
                <Tr key={index}>
                  <Td
                    px={0}
                    minW="5.42rem"
                    w="9rem"
                    onClick={() => handleTeamInfoClick(itemTeam)}
                  >
                    <Button w="9rem" className="team-btn">
                      {itemTeam?.teamname?.length * 0.5 > 8 ? (
                        <Tooltip
                          label={itemTeam.teamname}
                          placement="top-start"
                          bg="#f2f2f2"
                          justifyContent="center"
                          alignItems="center"
                          fontFamily="Source Han Sans"
                          color="#2c2c2c"
                          boxShadow="1px 1px 3px #00000029"
                          maxWidth="35rem"
                          fontSize="0.6875rem"
                          padding="0.5rem 0.5rem"
                          width="-webkit-fit-content"
                        >
                          <Text
                            maxW="8rem"
                            whiteSpace="nowrap"
                            overflow="hidden"
                            textOverflow="ellipsis"
                          >
                            {itemTeam?.teamname}
                          </Text>
                        </Tooltip>
                      ) : (
                        <Text
                          maxW="8rem"
                          whiteSpace="nowrap"
                          overflow="hidden"
                          textOverflow="ellipsis"
                        >
                          {itemTeam?.teamname}
                        </Text>
                      )}
                    </Button>
                  </Td>
                  <Td className="col-emp" px={0} maxW="3rem">
                    {itemTeam?.teamdata[0]?.member?.length}人
                  </Td>
                  {isOwner && (
                    <Td pr={0}>
                      <Menu
                        isOpen={isOpenMenu && idSelected === `menu-${index}`}
                        onOpen={() => {}}
                        onClose={() => {
                          setIdSelected(null);
                        }}
                      >
                        <MenuButton
                          data-testid="menu-button"
                          h="2.125rem"
                          w="2.125rem"
                          onClick={() => {
                            if (`menu-${index}` === idSelected) {
                              onCloseMenu();
                              setIdSelected(null);
                            } else {
                              onOpenMenu();
                              setIdSelected(`menu-${index}`);
                            }
                          }}
                        >
                          <Image
                            src={settings3Point}
                            mb="0"
                            h="100%"
                            w="100%"
                          />
                        </MenuButton>
                        <Box id={`menu-${index}`}>
                          <MenuList className="team-action" id="team-menu">
                            {menuActions.map((item, index) => (
                              <MenuItem
                                key={index}
                                className={
                                  item === "削除"
                                    ? "team-action-item delete-item"
                                    : item === "チーム情報を確認"
                                    ? "team-action-item first-team-action-item"
                                    : "team-action-item"
                                }
                                onClick={() => {
                                  onOpenModal({ team: itemTeam, name: item });
                                }}
                              >
                                {item === "削除" ? (
                                  <span style={{ color: "#ff4b45" }}>
                                    {item}
                                  </span>
                                ) : (
                                  item
                                )}
                              </MenuItem>
                            ))}
                          </MenuList>
                        </Box>
                      </Menu>
                    </Td>
                  )}
                </Tr>
              )
            )}
          </Tbody>
        </Table>
      </TableContainer>

      <TeamInfoModal
        title="チーム情報"
        isOpen={modalName === "TEAM_INFO"}
        onOk={recallData}
        onClose={closeModal}
      />

      <AddMemberModal
        type="TEAM_LIST"
        title="メンバーを追加"
        isOpen={modalName === "ADD_MEMBER"}
        onOk={recallData}
        onClose={closeModal}
      />

      <UpdateTeamModal
        title="チーム名の変更"
        isOpen={modalName === "UPDATE_TEAM"}
        onOk={recallData}
        onClose={closeModal}
      />

      <DeleteModal
        isOpen={modalName === "DELETE_TEAM" && !!team}
        message="このチームを削除しますか？"
        onOk={deleteTeam}
        onClose={closeModal}
      />

      <CreateTeamModalModal
        title="チームの作成"
        isOpen={isOpen}
        onClose={() => onClose()}
      />
    </Box>
  );
});

export default TeamMenu;

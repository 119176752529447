import { Store } from "@reduxjs/toolkit";
import axios from "axios";
import { setLoading } from "../redux/slice/loadingSlice";
import { API_URL } from "./env";

const axiosInstance = axios.create({
  baseURL: `${API_URL}`,
  // headers: {
  //   "Access-Control-Allow-Origin": "*",
  // },
});

export const axiosSetup = (store: Store) => {
  const dispatch = store.dispatch;
  let countRequest = 0;
  axiosInstance.interceptors.request.use(
    async (config) => {
      ++countRequest;
      dispatch(setLoading(true));
      return config;
    },
    (error) => {
      --countRequest;
      dispatch(setLoading(false));
      return Promise.reject(error);
    }
  );

  axiosInstance.interceptors.response.use(
    (response) => {
      --countRequest;
      if (!countRequest) {
        dispatch(setLoading(false));
      }
      return response;
    },
    (error) => {
      --countRequest;
      dispatch(setLoading(false));
      return Promise.reject(error);
    }
  );
};

export default axiosInstance;
